import { createTheme } from '@material-ui/core/styles';

const lightTheme = createTheme({
    palette: {
        type: 'light', // Switching the dark mode on is a single property value change.
        //secondary: { main: ' here is app bar color ' } E3C313
        secondary: {
            main: process.env.REACT_APP_THEME_LIGHT_COLOR, // here is app bar color
            light: process.env.REACT_APP_THEME_LIGHT_COLOR,
            dark: process.env.REACT_APP_THEME_LIGHT_COLOR
        },
        primary: {
            main: process.env.REACT_APP_THEME_LIGHT_COLOR, // here is buttons & icons button
            light: process.env.REACT_APP_THEME_LIGHT_COLOR,
            dark: process.env.REACT_APP_THEME_LIGHT_COLOR
        }
    },
});

const DarkTheme = createTheme({
    palette: {
        type: 'dark', // Switching the dark mode on is a single property value change.
        // secondary: { main: '#424242'}

    },
});

export const Themes = { light: lightTheme, dark: DarkTheme }
//export const Themes = { light: lightTheme }
export const defaultTheme = Themes.light
