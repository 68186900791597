import analytics from "./firebaseAnalytics";
import { logEvent } from "firebase/analytics";
import { isSuperAdmin } from "../helper/resourceHelper";


export const visitedPageList = (page) => {
    let event = 'visited_' + page + '_list'
    logEvent(analytics, event);
}



