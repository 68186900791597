import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import { getListControllerProps, Title } from 'react-admin';

const styles = {
    root: {
        display: 'flex',
        justifyContent: 'space-between',


    },
    filter: {
        flex: 1
    }
};

const Header = ({
    children,
    classes,
    className,
    title,
    actions,
    actionProps,
    filters,
    filterValues,
    hideFilter,
    displayedFilters,
    setFilters,
    setPerPage,
    changeParams,
    changeConfig,
    config,
    ...rest
}) => {
    const controllerProps = getListControllerProps(rest)
    return (
        <div className={classnames(classes.root, className)} {...rest}>
            <Title title={title} />
            {filters &&
                React.cloneElement(filters, {
                    ...controllerProps,
                    displayedFilters: displayedFilters,
                    filterValues: filterValues,
                    setFilters: setFilters,
                    hideFilter: hideFilter,
                    context: 'form',
                    className: classes.filter
                })
            }
            {filters.props.children &&
                filters.props.children.props &&
                filters.props.children.props.label !== "resources.common.search" &&
                React.cloneElement(filters.props.children, {
                    setPerPage,
                    setFilters,
                    hideFilter,
                    changeConfig,
                    config,
                })}
            {actions && React.cloneElement(actions, { ...rest, filters, displayedFilters: displayedFilters, filterValues: filterValues, setFilters: setFilters, hideFilter: hideFilter })}
        </div>
    )
}

Header.propTypes = {
    changeParams: PropTypes.func,
    classes: PropTypes.object,
    className: PropTypes.string,
    debounce: PropTypes.number,
    title: PropTypes.any,
    actions: PropTypes.element,
    actionProps: PropTypes.object,
    filters: PropTypes.element,
    filterValues: PropTypes.object,
    displayedFilters: PropTypes.object,
    setFilters: PropTypes.func,
    setPerPage: PropTypes.func,
};

export default withStyles(styles)(Header);