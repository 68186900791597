import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { translate, useNotify, useRedirect } from 'react-admin';
import compose from 'recompose/compose';

import { Divider, Card, CardContent, Box, Grid, Typography, ButtonBase, Button } from '@mui/material'

import { updateDeviceConfiguationAsSuperAdmin } from '../../rest/restClient';
import { getFormattedPrice, isSuperAdmin } from '../../helper/resourceHelper';

const styles = theme =>
    createStyles({
        floating: {
            color: theme.palette.getContrastText(theme.palette.primary.main),
            margin: 0,
            top: 'auto',
            right: 20,
            bottom: 60,
            left: 'auto',
            position: 'fixed',
            zIndex: 1000,
        },
        floatingLink: {
            color: 'inherit',
        },
    });

const isMissing = (data) => {
    return data === null || data === undefined;

}

const planIdIncluded = (plan) => {
    return plan.plan_id != undefined && plan.plan_id != null;

}

const enhance = compose(
    translate,
    withStyles(styles)
);

const SelectedProfilCard = enhance(({ translate, ...props }) => {
    const { plan, isCurrentConfig, isCurrentSubs, price, duration, confAckReceivedAt, downlinkSent } = props;
    const taxDescription = plan.currency && plan.currency.toLowerCase() === "aud" ? "Including GST" : "Inc.TAX"
    const priceDescription = `${price} ${duration}\n${taxDescription}`
    return (

        <Card sx={{ display: 'flex', width: "100%", margin: 1, border: 3, borderColor: 'primary.main' }}>
            <ButtonBase
                onClick={() => props.onClick(plan.plan_id)}
                sx={{ display: 'flex', width: "100%" }}
            >
                <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "center", margin: 3, width: "30%", textAlign: "center" }}>
                    <Typography variant="h6" color="text.main"><strong>{plan.title}</strong> </Typography>
                    {
                        isCurrentConfig ? <Typography variant="body2" color="primary.main">
                            {translate("resources.devices.config.plans.current_conf")}
                        </Typography> : null
                    }
                    {
                        isCurrentConfig && confAckReceivedAt && (downlinkSent === true) ? <Typography variant="body2" color="primary.main">
                            {translate("resources.devices.config.plans.since") + ": " + new Date(confAckReceivedAt).toLocaleString()}
                        </Typography> : null
                    }
                    {
                        isCurrentSubs ? <Typography variant="body2" color="primary.main">
                            {translate("resources.devices.config.plans.current_subs")}
                        </Typography> : null
                    }
                </Box>


                <Divider orientation="vertical" flexItem />

                <CardContent sx={{ width: "100%", display: 'flex', flexDirection: "column", justifyContent: "center", textAlign: "left" }}>

                    <Typography variant="subtitle1" color="text.secondary">
                        {plan.description.split("/").map(function (line) { return (<strong> {line}  < br />        </strong>) })}
                    </Typography>

                    {!isCurrentSubs && <Typography variant="subtitle1" color="error.main">
                        <strong>{translate("resources.devices.config.plans.notice")} : </strong> {translate("resources.devices.config.plans.switching_sub_alert_to")} : {price}

                    </Typography>}

                </CardContent>
                <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "center", pl: 1, pb: 1, width: "40%", textAlign: "left" }}>
                    {priceDescription.split("\n").map(function (line) { return (<Typography variant="h6" color="text.secondary"><strong>{line}</strong> </Typography>) })}

                </Box>
            </ButtonBase>
        </Card >
    )

});


const NonSelectedProfilCard = enhance(({ translate, ...props }) => {
    const { plan, isCurrentConfig, isCurrentSubs, price, duration, confAckReceivedAt, downlinkSent } = props;

    const taxDescription = plan.currency && plan.currency.toLowerCase() === "aud" ? "Including GST" : "Inc.TAX"
    const priceDescription = `${price} ${duration}\n${taxDescription}`
    return (<Card sx={{ display: 'flex', width: "100%", margin: 1 }}>
        <ButtonBase
            onClick={() => props.onClick(plan.plan_id)}
            sx={{ display: 'flex', width: "100%" }}
        >
            <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "center", margin: 3, width: "30%", textAlign: "center" }}>
                <Typography variant="h6" color="text.main"><strong>{plan.title}</strong> </Typography>
                {
                    isCurrentConfig ? <Typography variant="body2" color="primary.main">
                        {translate("resources.devices.config.plans.current_conf")}
                    </Typography> : null
                }
                {
                    isCurrentConfig && confAckReceivedAt && (downlinkSent === true) ? <Typography variant="body2" color="primary.main">
                        {translate("resources.devices.config.plans.since") + ": " + new Date(confAckReceivedAt).toLocaleString()}
                    </Typography> : null
                }
                {
                    isCurrentSubs ? <Typography variant="body2" color="primary.main">
                        {translate("resources.devices.config.plans.current_subs")}
                    </Typography> : null
                }
            </Box>
            <Divider orientation="vertical" flexItem />
            <CardContent sx={{ display: 'flex', flexDirection: "column", justifyContent: "center", width: "100%", textAlign: "left" }}>
                <Typography variant="subtitle1" color="text.secondary">
                    {plan.description.split("/").map(function (line) { return (<strong> {line}  < br />        </strong>) })}
                </Typography>
            </CardContent>
            <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "center", pl: 1, pb: 1, width: "40%", textAlign: "left" }}>
                {priceDescription.split("\n").map(function (line) { return (<Typography variant="h6" color="text.secondary"><strong>{line}</strong> </Typography>) })}

            </Box>
        </ButtonBase>
    </Card >)


});

const saveAsSuperAdmin = ({ deviceConfigurationId, selectedPlanId, highGEnabled, translate, notify, redirectTo }) => {
    let data = { id: deviceConfigurationId, subscription_plan_id: selectedPlanId }
    if (highGEnabled !== null) {
        data.high_g_enabled = highGEnabled
    }
    updateDeviceConfiguationAsSuperAdmin({ device_configuration: data })
        .then((response) => {

            if (response.status === 204 || response.status === 200) {
                notify(translate('resources.common.update_success'), 'info');
                redirectTo("list", "/devices");
            } else {
                alert("Error : response status " + response.status);
            }
        })
        .catch((e) => {
            alert("Error request " + e);
        })

}


const ConfigProfilCard = ({ translate, highGEnabled, ...props }) => {
    const redirect = useRedirect();
    const notify = useNotify();
    const { input, record, choices, source } = props
    const deviceConfigurationId = record && record.configuration && record.configuration.device_configuration_id ? record.configuration.device_configuration_id : null;
    const currentSubs = record && record.last_subscription && record.last_subscription.status && record.last_subscription.status.toLowerCase() === "active" && record.last_subscription.plan && record.last_subscription.plan.plan_id ? record.last_subscription.plan.plan_id : null;
    const confAckReceivedAt = record && record.configuration && record.configuration.ack_received_at ? record.configuration.ack_received_at : null;
    const downlinkSent = record && record.configuration && record.configuration.downlink_sent ? record.configuration.downlink_sent : null;
    const plans = choices
    const selectedPlanId = input.value
    const onClick = (value) => {
        input.onChange(value)
    }
    if (!currentSubs && !isSuperAdmin()) {
        return (
            <Typography variant="body1" color="text.main" sx={{ textAlign: 'center' }}>
                No active subscription on this device. To set configuration please subscribe
                <Button variant='text'
                    onClick={() => {
                        redirect('/devices/' + record.device_id + "/subscribe")
                    }}
                >
                    here
                </Button >
            </Typography>
        )
    }
    if (!deviceConfigurationId) return null;
    if (!plans) return null;
    const currentConfig = record.configuration.subscription_plan_id
    const differentConfSubs = (currentConfig !== null && currentSubs !== null && currentConfig !== currentSubs)
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} key={"title142"}>
                <Typography variant="h6" mt={2} mb={1}>{translate("resources.devices.config.plans.select_plan")}</Typography>
            </Grid>

            {differentConfSubs === true &&
                <Grid item xs={12} key={"title143"}>
                    <Typography variant="body2" color="error.main" mt={1} mb={1}>{translate("resources.devices.config.plans.diff_conf_subs")}</Typography>
                </Grid>
            }
            {plans.sort((a, b) => a.amount - b.amount).map((plan, index) => (

                <Grid item xs={12} mt={5} key={index}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {isMissing(plan) || !planIdIncluded(plan) ?
                            null :
                            selectedPlanId ?
                                (plan.plan_id === selectedPlanId ?
                                    <SelectedProfilCard
                                        onClick={onClick}
                                        plan={plan}
                                        confAckReceivedAt={confAckReceivedAt}
                                        downlinkSent={downlinkSent}
                                        isCurrentConfig={plan.plan_id === currentConfig}
                                        isCurrentSubs={plan.plan_id === currentSubs}
                                        duration={'/ ' + translate('resources.' + plan.frequency)}
                                        price={getFormattedPrice(plan)} /> :
                                    <NonSelectedProfilCard
                                        onClick={onClick}
                                        plan={plan}
                                        confAckReceivedAt={confAckReceivedAt}
                                        downlinkSent={downlinkSent}
                                        isCurrentConfig={plan.plan_id === currentConfig}
                                        isCurrentSubs={plan.plan_id === currentSubs}
                                        duration={'/ ' + translate('resources.' + plan.frequency)}
                                        price={getFormattedPrice(plan)} />)
                                :
                                (plan.plan_id === currentSubs ?
                                    <SelectedProfilCard
                                        onClick={onClick}
                                        plan={plan}
                                        confAckReceivedAt={confAckReceivedAt}
                                        downlinkSent={downlinkSent}
                                        isCurrentConfig={plan.plan_id === currentConfig}
                                        isCurrentSubs={plan.plan_id === currentSubs}
                                        duration={'/ ' + translate('resources.' + plan.frequency)}
                                        price={getFormattedPrice(plan)} /> :
                                    <NonSelectedProfilCard
                                        onClick={onClick}
                                        plan={plan}
                                        confAckReceivedAt={confAckReceivedAt}
                                        downlinkSent={downlinkSent}
                                        isCurrentConfig={plan.plan_id === currentConfig}
                                        isCurrentSubs={plan.plan_id === currentSubs}
                                        duration={'/ ' + translate('resources.' + plan.frequency)}
                                        price={getFormattedPrice(plan)} />)

                        }

                    </Box>


                </Grid>
            ))}
            {isSuperAdmin() &&
                selectedPlanId !== null &&
                selectedPlanId !== undefined &&

                <Button variant='contained'
                    sx={{ marginLeft: 0, marginRight: 0, marginTop: "1em", marginBottom: 0 }}
                    onClick={() => {
                        saveAsSuperAdmin({ deviceConfigurationId, selectedPlanId, highGEnabled, translate, notify, redirectTo: redirect });
                    }}
                >
                    Save as Super Admin
                </Button>
            }


        </Grid>


    )
};


export default enhance(ConfigProfilCard);



