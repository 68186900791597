import React, { useState, useEffect } from 'react'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import { Create, showNotification, translate, useDataProvider, useRedirect, useLocale } from 'react-admin'
import CheckDevice from './CheckDevice'
import AttachDevice from './AttachDevice'
import SelectPlan from './SelectPlan'
import SelectPlanType from './SelectPlanType';
import {
    addSubscription,
    deviceAttach,
    deviceStatus,
    rootOrganization,
    getFopSepa,
    getFopCard,
    getPlans,
    searchVoucher,
} from '../../../rest/restClient'
import { connect } from 'react-redux'
import { Elements } from '@stripe/react-stripe-js'


import deepmerge from 'deepmerge'

const CHECK_DEVICE = 1
const ATTACH_DEVICE = 2
const SELECT_PLAN_TYPE = 3
const SELECT_PLAN = 4

const hasActiveSubscription = device => {
    return (
        device.last_subscription &&
        device.last_subscription.status === 'active' &&
        device.last_subscription.end_date &&
        new Date() < new Date(device.last_subscription.end_date)
    )
}

const styles = theme => ({
    main: {
        marginTop: theme.spacing(4),

    },


})

const _DeviceCreate = (props) => {

    const dataProvider = useDataProvider();
    const [pageNumber, setPageNumber] = useState(1);
    const [saving, setSaving] = useState(false);
    const [payload, setPayload] = useState({});
    const [form, setForm] = useState({});
    const [stripe, setStripe] = useState(null);
    const [reRenderSelectPlan, setReRenderSelectPlan] = useState('0');
    const [reRenderSelectPlanType, setReRenderSelectPlanType] = useState('0');
    const redirect = useRedirect();
    const locale = useLocale();

    useEffect(() => {
        if (window.Stripe) {
            setStripe(window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY))
        } else {
            document.querySelector('#stripe-js').addEventListener('load', () => {
                setStripe(window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY))
            })
        }

    }, []);



    const handleCheckDevice = async (values) => {
        // check if element exist
        const { translate, showNotification } = props

        if (values !== undefined && values.name !== undefined) {

            deviceStatus(values.name)
                .then((response) => {
                    const device = response.json
                    rootOrganization(device.organization.organization_id)
                        .then((response) => {
                            const rootOrganizationIdRes = response.json
                            if (rootOrganizationIdRes.root_organization_id != process.env.REACT_APP_ROOT_ORGANIZATION_ID) {
                                showNotification(translate('resources.devices.not_found'), 'warning')
                                return
                            }
                            if (device.last_subscription && device.last_subscription.status === 'active') {
                                showNotification(translate('resources.devices.available'), 'info')
                                setPage(ATTACH_DEVICE, values, { device: device })
                            } else {
                                const operator = device.product.operator;
                                getPlans(operator, device.organization.organization_id, device.device_id, locale).then(response => {
                                    var plans = response.json
                                    setPage(SELECT_PLAN_TYPE, values, { plans: plans, device: device })

                                })
                            }
                        }).catch(response => {

                            switch (response.status) {
                                case 400:
                                    showNotification(translate('resources.devices.not_found'), 'warning')
                                    return
                                case 404:
                                    showNotification(translate('resources.devices.not_found'), 'warning')
                                    return
                                default:
                                    showNotification(
                                        `${translate('resources.internal_error')}: ${response.body.description} (${response.status
                                        })`,
                                        'warning'
                                    )
                                    return
                            }
                        })

                })
                .catch(response => {

                    switch (response.status) {
                        case 400:
                            showNotification(translate('resources.devices.already_attached'), 'warning')
                            break
                        case 404:
                            showNotification(translate('resources.devices.not_found'), 'warning')
                            return
                        default:
                            showNotification(
                                `${translate('resources.internal_error')}: ${response.body.description} (${response.status
                                })`,
                                'warning'
                            )
                            return
                    }
                })
        }
    }

    const handleAttachDevice = (values) => {
        // check if element exist
        const { showNotification } = props
        const { voucher, selectedPlan, device } = payload

        if (hasActiveSubscription(device)) {
            //console.log("[create/DeviceCreate]  device has active subscription > device_ID : "+ device.device_id);                       
            attachDevice()
        } else {
            let subscription = { device_id: device.device_id }

            if (voucher) {
                //console.log("[create/DeviceCreate]  handleAttachDevice with voucher code : "+ voucher.code +" > device_ID : "+ device.device_id);                       
                subscription['voucher_code'] = voucher.code

                addSubscription(subscription)
                    .then(() => {
                        attachDevice()
                    })
                    .catch(response => {
                        //console.log("[create/DeviceCreate] [voucher] error catched at  addSubscription");                       
                        showNotification(
                            `${translate('resources.internal_error')}: ${response.body.description} (${response.status
                            })`,
                            'warning'
                        );
                        redirect('list', 'devices');
                    })
            }
        }
    }

    const attachDevice = () => {
        const { translate, showNotification } = props
        const { device } = payload
        const attach = {
            name: device.name,
            type: device.type,
            serial_number: device.serial_number,
        }

        deviceAttach(attach)
            .then(() => {
                showNotification(translate('resources.devices.attached'), 'info')
                redirect('list', 'devices');
            })
            .catch(response => {
                showNotification(
                    `${translate('resources.internal_error')}: ${response.body.description} (${response.status
                    })`,
                    'warning'
                )
                redirect('list', 'devices');
            })

    }
    const handleSelectPlanType = (e, form) => {
        const { translate, showNotification } = props
        if (!payload.selectedPlanType) {
            showNotification(translate('resources.subscriptions.no_selected_plan_type'), 'warning')
        } else {
            setPage(SELECT_PLAN, form, payload)
        }
    }
    const handleChangeSelectPlanType = (selectedPlanType) => {
        const payloadTmp = payload
        payloadTmp.selectedPlanType = selectedPlanType
        setPayload(payloadTmp)
        setReRenderSelectPlanType(selectedPlanType ? selectedPlanType : '0')
    }


    const handleSelectPlan = (e, form) => {
        const { translate, showNotification } = props
        if (!payload.selectedPlan && !payload.voucher) {
            showNotification(translate('resources.subscriptions.no_selected_plan'), 'warning')
        } else {
            setPage(ATTACH_DEVICE, form, payload)
        }
    }

    const handleChangeSelectPlan = (selectedPlan) => {
        const payloadTmp = payload
        payloadTmp.selectedPlan = selectedPlan
        payloadTmp.voucher = null
        setPayload(payloadTmp)
        setReRenderSelectPlan(selectedPlan ? selectedPlan.plan_id : '0')

    }

    const handleSaving = saving => {
        setSaving(saving)

    }


    const handleChangeVoucher = e => {
        const { translate, showNotification } = props
        const code = e.target.value.trim()
        if (code.length === 10) {
            searchVoucher(code)
                .then(response => {
                    const { json } = response
                    const payloadTmp = payload
                    payloadTmp.selectedPlan = null
                    payloadTmp.voucher = json
                    setPayload(payloadTmp)

                })
                .catch(response => {
                    switch (response.status) {
                        case 400:
                            showNotification(
                                translate('resources.subscriptions.voucher_already_burnt'),
                                'warning'
                            )
                            break
                        case 404:
                            showNotification(
                                translate('resources.subscriptions.voucher_not_found'),
                                'warning'
                            )
                            return
                        default:
                            showNotification(
                                `${translate('resources.internal_error')}: ${response.body.description} (${response.status
                                })`,
                                'error'
                            )
                            return
                    }
                })
        }
    }

    const setPage = (pageNumberTmp, form = {}, data = {}) => {
        const overwriteMerge = (destinationArray, sourceArray) => sourceArray
        const payloadTmp = deepmerge(payload, data, { arrayMerge: overwriteMerge })
        setPayload(payloadTmp)
        setForm(form)
        setPageNumber(pageNumberTmp)
    }

    const renderForm = (props) => {

        const { device } = payload

        switch (pageNumber) {
            case CHECK_DEVICE:
                return <CheckDevice onSubmit={handleCheckDevice} nextPage={handleCheckDevice} {...props} />
            case ATTACH_DEVICE:
                return (

                    <Elements stripe={stripe}>
                        <AttachDevice
                            onSubmit={(e, values) => handleAttachDevice(e, values)}// ?? 
                            nextPage={(e, values) => handleAttachDevice(e, values)}
                            previousPage={() =>
                                hasActiveSubscription(device)
                                    ? setPage(CHECK_DEVICE)
                                    : setPage(SELECT_PLAN)
                            }
                            value={payload}
                            stripe={stripe}
                            saving={saving}
                            handleSaving={value => handleSaving(value)}
                            {...props}
                        />
                    </Elements>

                )
            case SELECT_PLAN_TYPE:
                return (
                    <SelectPlanType
                        key={reRenderSelectPlanType}
                        onSubmit={form => handleSelectPlanType(form)}
                        nextPage={form => handleSelectPlanType(form)}
                        previousPage={() => setPage(CHECK_DEVICE)}
                        value={payload}
                        onChangePlanType={value => handleChangeSelectPlanType(value)}
                        {...props}
                    />
                )
            case SELECT_PLAN:
                return (
                    <SelectPlan
                        {...props}
                        key={reRenderSelectPlan}
                        onSubmit={form => handleSelectPlan(form)}
                        nextPage={form => handleSelectPlan(form)}
                        previousPage={() => setPage(SELECT_PLAN_TYPE)}
                        checkDevicePage={() => setPage(CHECK_DEVICE)}
                        value={payload}
                        onChangePlan={value => handleChangeSelectPlan(value)}
                        onChangeVoucher={value => handleChangeVoucher(value)}
                        selectedPlan={payload.selectedPlan}

                    />
                )
            default:
                return null;
        }
    }



    return (
        <Create {...props} className={props.classes.main} title={'resources.devices.activate'}>
            {renderForm(props)}
        </Create>
    )
}




const DeviceCreate = compose(
    translate,
    withStyles(styles),
    connect(
        null,
        { showNotification }
    )
)(_DeviceCreate)

export default DeviceCreate