
import L from "leaflet";
import React, { useState, useEffect, useRef } from 'react'
import { createControlComponent } from "@react-leaflet/core";
import 'leaflet-routing-machine'
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css'
import 'leaflet/dist/leaflet.css'
delete L.Icon.Default.prototype._getIconUrl;
import { transparenteIcon } from './icons/bluee'


L.Icon.Default.mergeOptions({
    iconRetinaUrl: null,
    iconUrl: transparenteIcon,
    shadowUrl: null,
    //iconSize: [12, 12],
});

const diff_minutes = (dt2, dt1) => {
    let diff = (new Date(dt2).getTime() - new Date(dt1).getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));

}

const distance = (lat1, lon1, lat2, lon2) => {
    const radlat1 = Math.PI * lat1 / 180
    const radlat2 = Math.PI * lat2 / 180
    const theta = lon1 - lon2
    const radtheta = Math.PI * theta / 180
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist)
    dist = dist * 180 / Math.PI
    dist = dist * 60 * 1.1515
    return dist * 1.609344
}




const createRoutineMachineLayer = (props) => {

    const { positions, routingMode } = props

    let wayPoints = positions.map(position => L.latLng(position.lat, position.lng))

    if (positions.length > 25 && routingMode !== 'boat') {
        //optimze number of waypoints
        let latestSkippedIdx = -1;
        //keep first & last
        for (let i = positions.length - 2; i >= 1; i--) {
            //skip some intermediate waypoint if there are contiguous
            if (latestSkippedIdx - 1 !== i && diff_minutes(positions[i].timestamp, positions[i - 1].timestamp) <= 30) {
                positions.splice(i, 1);
                latestSkippedIdx = i;
            }
            //skip intermediate waypoint if close from next one
            else if (distance(positions[i].lat, positions[i].lng, positions[i - 1].lat, positions[i - 1].lng) <= 0.5 && distance(positions[i].lat, positions[i].lng, positions[i + 1].lat, positions[i + 1].lng) <= 0.5) {
                positions.splice(i, 1);
                latestSkippedIdx = i;
            }
        }
        wayPoints = positions.map(position => L.latLng(position.lat, position.lng)).slice(0, 24)
    }
    if (wayPoints.length <= 1) {
        wayPoints = []
    }
    const options = { profile: `mapbox/${routingMode}` }
    const mapboxToken = 'pk.eyJ1IjoidmRpZXVsZXNhaW50IiwiYSI6ImNqdXdiNDNweTAyc2o0M3BvMnl5NjFuZGQifQ.6D_l4A8S1-Egmo-627D3wg'

    const instance = L.Routing.control({
        router: L.Routing.mapbox(mapboxToken, options),
        waypoints: wayPoints,
        lineOptions: {
            styles: [{
                color: "#2196f3", opacity: 0.8,
                weight: 6
            }]
        },
        addWaypoints: false,
        draggableWaypoints: false,
        routeWhileDragging: false,

    });

    return instance;



};

const RoutingMachine = createControlComponent(createRoutineMachineLayer);

export default RoutingMachine;


