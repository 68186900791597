import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import { withStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { translate } from 'ra-core'
import { Toolbar } from 'react-admin'

const styles = ({
    form: {
        alignItems: 'center',
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        height: 'inherit',
        justifyContent: 'space-between'
    },
})

export class WizardForm extends Component {
    handleSubmitWithRedirect = (redirect = this.props.redirect) =>
        // this.props.handleSubmit(
        //values =>
        this.props.nextPage
            ? this.props.nextPage(this.props.handleSubmit(), redirect)
            : this.props.save(this.props.handleSubmit(), redirect)
    //  )

    render() {
        const { classes = {}, className, invalid, pristine, saving, submitOnEnter, toolbar } = this.props
        return (
            <form
                className={classnames(classes.form, className)}
                classes={classes}
                style={styles.form}
            >
                {this.props.children}
                {toolbar &&
                    React.cloneElement(toolbar, {
                        handleSubmitWithRedirect: this.handleSubmitWithRedirect,
                        invalid,
                        pristine,
                        submitOnEnter,
                        saving,
                        style: { display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap', backgroundColor: 'inherit' },
                        classes,
                    })}
            </form>
        )
    }
}

WizardForm.propTypes = {
    basePath: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    handleSubmit: PropTypes.func, // passed by react-final-form
    invalid: PropTypes.bool,
    nextPage: PropTypes.func,
    previousPage: PropTypes.func,
    pristine: PropTypes.bool,
    record: PropTypes.object,
    resource: PropTypes.string,
    redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    submitOnEnter: PropTypes.bool,
    title: PropTypes.string,
    toolbar: PropTypes.element,
    validate: PropTypes.func,
    version: PropTypes.number,
}

WizardForm.defaultProps = {
    nextPage: null,
    submitOnEnter: true,
    title: '',
    toolbar: <Toolbar />,
    redirect: false,
}


export default withStyles(styles)(translate(props => <Form {...props} component={WizardForm} />))
