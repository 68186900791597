import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose'
import LocationHistoryIcon from '@material-ui/icons/History';
import { Link } from 'react-router-dom';
import { Button, Responsive, translate } from 'react-admin';
import MuiButton from '@material-ui/core/Button';

const LocationHistoryButton = ({
    basePath = '',
    recordId = '',
    translate,
    ...rest
}) => (
    <Responsive
        small={
            <MuiButton
            component={Link}
            to={'/locations/' + recordId + '/history'}
            variant="fab"
            color="primary"
            {...rest}>
                <LocationHistoryIcon/>
            </MuiButton>
        }
        medium={
            <Button 
                label="resources.devices.labels.locationsHistory"
                color="primary"
                component={Link}
                to={'/locations/' + recordId + '/history'}
                {...rest}
            >
                <LocationHistoryIcon/>
            </Button>
        }
    />
);

LocationHistoryButton.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.object,
    recordId: PropTypes.string,
};

const enhance = compose(
    translate,
);

export default enhance(LocationHistoryButton);