
import React from 'react'
import compose from 'recompose/compose'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import UncheckedIcon from "@material-ui/icons/RadioButtonUnchecked"
import CheckedIcon from "@material-ui/icons/RadioButtonChecked"
import { TopToolbar, ListButton, translate } from 'react-admin'
import { Menu, MenuItem, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { changeConfig, SELECT_RIGHTMENU, ROUTING_MODE, DISABLE_CLUSTERING_AT_ZOOM } from '../configuration/actions'
import DeleteLinkWithConfirmation from '../../ui/link/DeleteLinkWithConfirmation'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import MenuIcon from '@material-ui/icons/Menu'
import NavigationIcon from '@material-ui/icons/Navigation'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import PrintIcon from '@mui/icons-material/Print';
import Divider from '@material-ui/core/Divider'
import ExportLocationsHistory from '../exportPdf/ExportLocationsHistory';
import GroupWorkIcon from '@mui/icons-material/GroupWork';

const styles = theme => ({
    actions: {
        alignItems: 'center'
    },
    icons: {
        marginRight: '1rem'
    }
})

class LocationHistoryActions extends React.Component {
    state = {
        anchorEl: null,
        anchorElSub: null,
    }
    PrintPdfKey = 0;
    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    }
    handleClickPrintPdf = () => {
        this.printPdfKey = this.printPdfKey + 1;
        this.setState({ anchorEl: null });
    }
    handleChangeClustringMode = () => {

        if (this.props.disableClusteringAtZoom) {
            this.props.changeConfig(DISABLE_CLUSTERING_AT_ZOOM, false);
        } else {
            this.props.changeConfig(DISABLE_CLUSTERING_AT_ZOOM, true);
        }


    }


    handleClose = () => {
        this.setState({ anchorEl: null });
    }

    handleSubClick = event => {
        this.setState({ anchorElSub: event.currentTarget });
    }

    handleSubClose = () => {
        this.setState({ anchorElSub: null });
    }

    handleSubItemClick = (routingMode) => {
        this.props.changeConfig(ROUTING_MODE, routingMode);
        this.setState({ anchorElSub: null });
    }

    onToggleMenuOpen = () => {
        this.setState({ anchorEl: null })
        this.props.changeConfig(SELECT_RIGHTMENU, !this.props.selectRightMenu)
    }

    render() {
        const { classes, recordId, translate, routingMode, disableClusteringAtZoom } = this.props

        const { anchorEl, anchorElSub } = this.state

        return (
            <TopToolbar className={classes.actions}>
                <MenuItem key="exportPdf" onClick={this.handleClickPrintPdf} ><PrintIcon className={classes.icons} /><ExportLocationsHistory key={this.printPdfKey}  {... this.props} /></MenuItem>
                <MenuItem key="clustring" onClick={this.handleChangeClustringMode} ><GroupWorkIcon className={classes.icons} />{disableClusteringAtZoom ? translate('resources.map.clustring.on') : translate('resources.map.clustring.off')} </MenuItem>
                <ListButton basePath={"locations"} label={translate("resources.locations.name", { smart_count: 2 })} />
                <IconButton
                    aria-owns={anchorEl ? 'menu' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                >
                    <MenuItem key="routing" onClick={this.handleSubClick}><NavigationIcon className={classes.icons} />{translate('resources.map.routing')} <ArrowRightIcon /></MenuItem>
                    <Menu
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left"
                        }}
                        id="sub-menu"
                        anchorEl={anchorElSub}
                        open={Boolean(anchorElSub)}
                        onClose={this.handleSubClose}>
                        <MenuItem key="driving" onClick={() => this.handleSubItemClick("driving")}>{routingMode === 'driving' ? <CheckedIcon className={classes.icons} /> : <UncheckedIcon className={classes.icons} />} {translate('resources.map.routing_mode.driving')}</MenuItem>
                        <MenuItem key="cycling" onClick={() => this.handleSubItemClick("cycling")}>{routingMode === 'cycling' ? <CheckedIcon className={classes.icons} /> : <UncheckedIcon className={classes.icons} />} {translate('resources.map.routing_mode.cycling')}</MenuItem>
                        <MenuItem key="walking" onClick={() => this.handleSubItemClick("walking")}>{routingMode === 'walking' ? <CheckedIcon className={classes.icons} /> : <UncheckedIcon className={classes.icons} />} {translate('resources.map.routing_mode.walking')}</MenuItem>
                        <MenuItem key="boat" onClick={() => this.handleSubItemClick("boat")}>{routingMode === 'boat' ? <CheckedIcon className={classes.icons} /> : <UncheckedIcon className={classes.icons} />} {translate('resources.map.routing_mode.boat')}</MenuItem>
                        <MenuItem key="boat" onClick={() => this.handleSubItemClick("no_routing")}>{routingMode === 'no_routing' ? <CheckedIcon className={classes.icons} /> : <UncheckedIcon className={classes.icons} />} {translate('resources.map.routing_mode.no_routing')}</MenuItem>
                    </Menu>
                    <Divider />
                    <MenuItem key="export" component={Link} to={`/export/create?selectedIds=${recordId}`} onClick={this.handleClose}><ImportExportIcon className={classes.icons} />{translate("resources.locations.labels.exportData")}</MenuItem>
                    <Divider />
                    <DeleteLinkWithConfirmation recordId={recordId} />
                    <Divider />
                    <MenuItem key="map-menu" onClick={this.onToggleMenuOpen}><MenuIcon className={classes.icons} />{translate("resources.locations.labels.hideMenu")}</MenuItem>
                </Menu>


            </TopToolbar>
        )
    }
}

const mapStateToProps = state => ({
    config: state.config,
    selectRightMenu: state.config[SELECT_RIGHTMENU],
    routingMode: state.config[ROUTING_MODE],
    disableClusteringAtZoom: state.config[DISABLE_CLUSTERING_AT_ZOOM],

})


const enhance = compose(
    translate,
    connect(
        mapStateToProps,
        {
            changeConfig,
        }
    ),
    withStyles(styles)
)

export default enhance(LocationHistoryActions)