import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import logoSrcTicatag from './ticatag-logo.png'
import logoSrcMytag from './mytag-logo.png'
import logoSrcPyng from './pyng-logo-croped.png'


const getLogoByRootOrg = () => {
    if (process.env.REACT_APP_ROOT_ORGANIZATION.toLowerCase() === 'mytag') {
        return logoSrcPyng
    }
    return logoSrcTicatag
}

const useStyles = makeStyles(() => ({
    logo: {
        width: "180px",
        height: "50px",
        marginLeft: 4,
        marginRight: 4,
        marginTop: 2,
        marginBottom: 2,
    },


}));


const logo = () => {
    const classes = useStyles();
    // no logo for Ticatag
    if (process.env.REACT_APP_ROOT_ORGANIZATION.toLowerCase() === 'ticatag') {
        return null
    }
    return (
        <div className={classes.logoContainer}> <img src={getLogoByRootOrg()} alt="TICATAG-logo" className={classes.logo} /></div>)

}
export default (logo)

