import englishMessages from './i18n/en'
import frenchMessages from './i18n/fr'
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { resolveBrowserLocale } from 'react-admin'
import { USER_FAV_LANGUAGE } from './pages/configuration/actions';

const messages = {
    fr: frenchMessages,
    en: englishMessages
};

export const supportedLanguage = (lang) => {
    const language = lang.toLowerCase();
    if (language.substring(0, 2) === "en" || language.substring(0, 2) === "fr") {
        return language.substring(0, 2)
    } else {
        return null
    }

}

const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'fr') {
        return messages[locale];
    }

    // Always fallback on english
    return englishMessages;
},
    localStorage.getItem(USER_FAV_LANGUAGE) && messages[localStorage.getItem(USER_FAV_LANGUAGE)] ? localStorage.getItem(USER_FAV_LANGUAGE) : resolveBrowserLocale(),
    [
        { locale: 'en', name: 'English' },
        { locale: 'fr', name: 'Français' }
    ],);
export default i18nProvider
