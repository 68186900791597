import React from 'react'
import { Field } from 'react-final-form'
import { TopToolbar, DateInput, Edit, FormTab, email, required, TabbedForm, SelectInput, TextInput, Toolbar, translate } from 'react-admin'
import PhoneInput from 'react-phone-number-input'
import { withStyles } from '@material-ui/core/styles'
import { SaveAccountButton } from './SaveAccountButton'
import 'react-phone-number-input/style.css'

const styles = theme => ({
    phoneInputStyle: {
        width: 256,
        marginTop: 10,
        margin: 0,
        border: 0,
        display: 'inline-flex',
        padding: 0,
        position: 'relative',
        minWidth: 0,
        flexDirection: 'row',
        "& .react-phone-number-input__input": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        "& .PhoneInputCountry": {

            marginRight: 15,
        },
    },
    list: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        marginTop: 20,
    },

})

const lang = [
    { id: 'fr-FR', name: 'resources.users.lang.fr' },
    { id: 'en-EN', name: 'resources.users.lang.en' },
]






const PhoneNumberInput = withStyles(styles)(
    translate((props) => {
        const { translate, classes } = props
        return (
            <PhoneInput
                country="FR"
                flagUrl="https://flag.pk/flags/4x3/{xx}.svg"
                placeholder={translate('resources.users.phoneNumber')}
                value={props.input.value}
                onChange={props.input.onChange}
                className={classes.phoneInputStyle}
            />
        )
    })
)


const PhoneNumberField = props => {

    return <Field name="phone_number" component={PhoneNumberInput} />
}

const AccountActions = () => (
    <TopToolbar />
)

const AccountEditToolBar = ({ record, ...props }) => (
    <Toolbar {...props} >
        <SaveAccountButton
            previousData={record}
            redirect={false} />
    </Toolbar>
);


const AccountEdit = withStyles(styles)(translate(({ classes, ...props }) => (
    <Edit {...props} title={props.translate('resources.account.edit')} actions={<AccountActions />} className={classes.list}>
        <TabbedForm toolbar={<AccountEditToolBar />}>
            <FormTab label="resources.users.profile">
                <TextInput variant="standard" margin="normal" label="resources.users.companyName" source="company_name" />
                <TextInput variant="standard" margin="normal" label="resources.users.firstName" source="first_name" validate={required()} />
                <TextInput variant="standard" margin="normal" label="resources.users.lastName" source="last_name" validate={required()} />
                <PhoneNumberField />
                <SelectInput variant="standard" margin="normal" label="resources.users.language" source="lang_key" choices={lang} validate={[required()]} />
                <DateInput variant="standard" margin="normal" label="resources.users.birth_date" source="birth_date" />
            </FormTab>
            <FormTab label="resources.users.password">
                <TextInput variant="standard" margin="normal" label="resources.users.password" source="password" type="password" />
                <TextInput variant="standard" margin="normal" label="resources.users.cpassword" source="cpassword" type="password" />
            </FormTab>
            <FormTab label="resources.users.email">
                <TextInput variant="standard" margin="normal" label="resources.users.email" source="email" type="email" validate={email()} />
                <TextInput variant="standard" margin="normal" label="resources.users.cemail" source="cemail" type="email" validate={email()} />
            </FormTab>
        </TabbedForm>
    </Edit>
))
);

export default (AccountEdit)