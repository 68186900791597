// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { isSuperAdmin } from "../helper/resourceHelper";

/*console.log("firebase")
console.log("apiKey:", process.env.REACT_APP_FIREBASE_API_KEY)
console.log("authDomain: ", process.env.REACT_APP_FIREBASE_AUTH_DOMAIN)
console.log("projectId: ", process.env.REACT_APP_FIREBASE_PROJECT_ID)
console.log("storageBucket: ", process.env.REACT_APP_FIREBASE_STORAGE_BUCKET)
console.log("messagingSenderId: ", process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID)
console.log("appId: ", process.env.REACT_APP_FIREBASE_APP_ID)
console.log("measurementId: ", process.env.REACT_APP_FIREBASE_MEASUREMENT_ID)*/

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// User properties

//setUserProperties(analytics, { id : 'apples' });

//console.log("isSuperAdmin ==> ", isSuperAdmin())
export default analytics


