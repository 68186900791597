// not used for the moment

import { crudCreate, CRUD_CREATE, CRUD_UPDATE, UPDATE } from 'react-admin'
import { addEvent, updateDeviceAddress, updateDeviceIcon, updateDeviceStatus, updateEventAddress } from '../../rest/restClient'

export const updateStatus = (id, record) => ({
    type: CRUD_UPDATE,
    payload: { id, 
        data: {...record, status: 'active' } 
    },
    meta: { resource: 'locations', 
        optimistic: true, 
        fetch: UPDATE,
        callback: ({ payload, requestPayload }) => { 
            updateDeviceStatus({ id, status: 'active' })
        }
    },
})

export const updateIcon = (id, device, icon) => ({
    type: CRUD_UPDATE,
    payload: { id, 
        data: {...device, icon_name: icon } 
    },
    meta: { resource: 'locations',
        optimistic: true, 
        fetch: UPDATE,
        callback: ({ payload, requestPayload }) => { 
            updateDeviceIcon( {id: payload.id, device: {...payload.data }})
        }
    },
})

export const acknowledgeRollover= (device)=> ({
    type: CRUD_CREATE,
    payload: { 
        device_id: device.id,
        name: 'rollover',
        user_id: localStorage.getItem('userId'),
        organization_id: localStorage.getItem('organizationId'),
        status: 'clear',
        timestamp: (new Date()).toISOString()  
    },
    meta: { resource: 'events', 
        callback: ({ payload, requestPayload }) => { 
            addEvent(payload)
            device.last_rollover = payload;
            updateDeviceRollover(device.id, {...device })
        }
    },
})

export const acknowledgeMotion= (device)=> ({
    type: CRUD_CREATE,
    payload: { 
        device_id: device.id,
        name: 'motion',
        user_id: localStorage.getItem('userId'),
        organization_id: localStorage.getItem('organizationId'),
        status: 'still',
        timestamp: (new Date()).toISOString()  
    },
    meta: { resource: 'events', 
        callback: ({ payload, requestPayload }) => { 
            addEvent(payload)
            device.last_motion = payload;
            updateDeviceMotion(device.id, {...device })
        }
    },
})

export const updateDeviceRollover = (id, data) => ({
    type: CRUD_UPDATE,
    payload: { id, data: { ...data } },
    meta: { 
        resource: 'locations', 
        optimistic: true, 
        fetch: UPDATE,
    },
});

export const updateDeviceMotion = (id, data) => ({
    type: CRUD_UPDATE,
    payload: { id, data: { ...data } },
    meta: { 
        resource: 'locations', 
        optimistic: true, 
        fetch: UPDATE,
    },
});

export const updateFormattedAddress = (id, record, data, resource) => ({
    type: CRUD_UPDATE,
    payload: { id, 
        data: {...record, formatted_address: data } 
    },
    meta: { resource: resource, 
        optimistic: true, 
        fetch: UPDATE,
        callback: ({ payload, requestPayload }) => { 
            resource === 'locations' ? 
                updateDeviceAddress({ id:id, formatted_address:data}) : 
                updateEventAddress({ id:id, formatted_address:data})
        } 
    },
}) 

export const createGeofence = record => {
    return crudCreate('geofences', record, 'geofences', null)
}
