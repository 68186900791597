import React from 'react'
import compose from 'recompose/compose'
//react-admin
import { TopToolbar, Filter, ListButton, translate } from 'react-admin'
import { PER_PAGE_SENSOR } from '../../helper/resourceHelper'
import ContainerFilter from './filters'
import { deviceName } from '../../rest/restClient'
import { getDeviceIdFromUrl } from '../../utils/utils'
import ExportButton from '../../ui/button/ExportButton'
import List from '../../ui/list/List'
import { Container } from './container'
import { withStyles } from '@material-ui/core/styles'
import PrintIcon from '@mui/icons-material/Print';
import ExportMeasuresHistory from '../exportPdf/ExportMeasuresHistory';
import { MenuItem } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import SensorCharts from './sensorCharts'
import SensorTable from './sensorTable'
import { AqiHelpLegendComponent } from './helpLegendComponents'


const styles = theme => ({
    actions: {
        alignItems: 'center'
    },

    list: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        "& .MuiPaper-elevation1": {
            "box-shadow": "none",
        },

        marginTop: 5,
    },
    chartList: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        "& .MuiPaper-elevation1": {
            "box-shadow": "none",
        },
        marginX: 5,
    },
    icons: {
        marginRight: '1rem'
    }
})

// ticatag

const enhanceActions = compose(
    translate,
    withStyles(styles)
)

const MeasuresHistoryActions = enhanceActions(({
    classes,
    recordId,
    translate, ...props
}) => {
    return (
        <TopToolbar className={classes.actions}>
            <MenuItem key="exportPdf"  ><PrintIcon className={classes.icons} /><ExportMeasuresHistory translate={translate} recordId={recordId} {...props} /></MenuItem>
            <ExportButton basePath={'/export/create'} selectedIds={[recordId]} />
            <ListButton basePath={"measures"} label={translate("resources.measures.name", { smart_count: 2 })} />
        </TopToolbar>
    )
})

const SensorChartList = (props) => {
    const { unitToShow, showHelpLegend, HelpLegendComponent, type, yDomain, tickCount, referenceAreas, unit, label, key, show, showDangerThreshold, chartStyleLineColor, chartStyleUnitStr, chartStyleAvgColor, chartStyleDangerColor, chartStyleMaxColor, chartStyleMinColor, eventName, match, classes, allListsFilters } = props

    const chartOptions = {
        eventName: eventName,
        type: type,
        referenceAreas: referenceAreas,
        yDomain: yDomain,
        showHelpLegend: showHelpLegend,
        HelpLegendComponent: HelpLegendComponent,
        tickCount: tickCount,
        unitToShow: unitToShow,
        unit: unit,
        label: label,
        key: key,
        show: show,
        showDangerThreshold: showDangerThreshold,
        style: {
            lineColor: chartStyleLineColor,
            dangerColor: chartStyleDangerColor,
            maxColor: chartStyleMaxColor,
            minColor: chartStyleMinColor,
            avgColor: chartStyleAvgColor,
            unitStr: chartStyleUnitStr
        }
    }

    return (

        <List
            basePath={`/measures/${match.params.id}`}
            title={" "}
            filters={
                <Filter style={{ display: "none" }}>
                </Filter >
            }
            filter={{
                names: [eventName],
                device_ids: match.params.id,
                ...allListsFilters
            }}
            actions={<div></div>}
            className={classes.chartList}
            resource="locations_events"
            pagination={false}
            perPage={25}
            sort={{
                field: 'timestamp',
                order: 'DESC',
            }}
            {...props}
        >

            <SensorCharts {...props} deviceId={match.params.id} chartOptions={chartOptions} />

        </List>
    )
}



const SensorTableList = (props) => {
    const { label, name, match, classes, allListsFilters } = props


    return (
        <List
            basePath={`/measures/${match.params.id}`}
            title={" "}
            filters={
                <Filter>
                </Filter >
            }
            filter={{
                names: [name],
                device_ids: match.params.id,
                ...allListsFilters
            }}
            actions={<div></div>}
            className={classes.chartList}
            resource="locations_events"
            pagination={false}
            perPage={25}
            sort={{
                field: 'timestamp',
                order: 'DESC',
            }}
            {...props}
        >

            <SensorTable {...props} deviceId={match.params.id} label={label} name={name} />

        </List>
    )
}


const _SensorHistoryList = (props) => {
    const { classes, translate } = props
    const [allListsFilters, setAllListsFilters] = useState({});
    const [title, setTitle] = useState('');



    useEffect(() => {

        deviceName(getDeviceIdFromUrl()).then(event => {

            setTitle(event.json.name)
        })

    }, {})


    return (
        <div>

            <List
                title={translate('resources.title.measures.history')}
                basePath={`/measures/${props.match.params.id}`}
                filters={
                    <Filter>
                        <ContainerFilter {...props} setAllListsFilters={setAllListsFilters} />
                    </Filter >
                }
                filter={{
                    names: ['humidity', 'temperature', 'co2', 'battery',],
                    device_ids: props.match.params.id,

                }}
                actions={<MeasuresHistoryActions recordId={props.match.params.id} {...props} />}
                className={classes.list}
                resource="locations_events"
                pagination={false}
                perPage={PER_PAGE_SENSOR}
                sort={{
                    field: 'timestamp',
                    order: 'DESC',
                }}
                {...props}
            >



                <Box>
                    <SensorChartList
                        {...props}
                        type="AreaChart"
                        eventName="humidity"
                        label={translate('resources.humidity.head')}
                        key="humidity"
                        show={true}
                        showDangerThreshold={false}
                        chartStyleLineColor="#348b07"
                        chartStyleAvgColor="gray"
                        chartStyleMaxColor="red"
                        chartStyleMinColor="green"
                        allListsFilters={allListsFilters}

                    />
                    <SensorChartList
                        {...props}
                        type="AreaChart"
                        eventName="temperature"
                        label={translate('resources.temperatures.head')}
                        key="temperature"
                        show={true}
                        showDangerThreshold={false}
                        chartStyleLineColor="#348b07"
                        chartStyleAvgColor="gray"
                        chartStyleMaxColor="red"
                        chartStyleMinColor="green"
                        allListsFilters={allListsFilters}

                    />
                    <SensorChartList
                        {...props}
                        type="AreaChart"
                        eventName="co2"
                        label={translate('resources.co2.head')}
                        key="co2"
                        show={true}
                        showDangerThreshold={true}
                        chartStyleLineColor="#348b07"
                        chartStyleAvgColor="gray"
                        chartStyleDangerColor="#f30000"
                        chartStyleMaxColor="red"
                        chartStyleMinColor="green"
                        allListsFilters={allListsFilters}

                    />
                    <SensorChartList
                        {...props}
                        type="AreaChart"
                        eventName="battery"
                        label={translate('resources.battery.head')}
                        key="battery"

                        yDomain={[0, 100]}
                        show={true}
                        showDangerThreshold={false}
                        chartStyleLineColor="#348b07"
                        chartStyleAvgColor="gray"
                        chartStyleMaxColor="red"
                        chartStyleMinColor="green"
                        allListsFilters={allListsFilters}
                        unitToShow="percent"

                    />
                    <SensorChartList
                        {...props}
                        type="AreaChart"
                        eventName="battery"
                        label={translate('resources.battery.head')}
                        key="battery"
                        show={true}
                        showDangerThreshold={false}
                        chartStyleLineColor="#348b07"
                        chartStyleAvgColor="gray"
                        chartStyleMaxColor="red"
                        chartStyleMinColor="green"
                        allListsFilters={allListsFilters}
                        unitToShow="millivolt"

                    />
                    <SensorChartList
                        {...props}
                        type="AreaChart"
                        eventName="pressure"

                        yDomain={[800, 1200]}
                        label={translate('resources.pressure.head')}
                        key="pressure"
                        show={true}
                        showDangerThreshold={false}
                        chartStyleLineColor="#348b07"
                        chartStyleAvgColor="gray"
                        chartStyleMaxColor="red"
                        chartStyleMinColor="green"
                        allListsFilters={allListsFilters}

                    />
                    <SensorChartList
                        {...props}
                        type="AreaChart"
                        eventName="luminosity"
                        label={translate('resources.luminosity.head')}
                        key="luminosity"
                        show={true}
                        showDangerThreshold={false}
                        chartStyleLineColor="#348b07"
                        chartStyleAvgColor="gray"
                        chartStyleMaxColor="red"
                        chartStyleMinColor="green"
                        allListsFilters={allListsFilters}

                    />
                    <SensorChartList
                        {...props}
                        type="AreaChart"
                        eventName="formaldehyde"
                        label={translate('resources.formaldehyde.head')}
                        key="formaldehyde"
                        show={true}
                        showDangerThreshold={false}
                        chartStyleLineColor="#348b07"
                        chartStyleAvgColor="gray"
                        chartStyleMaxColor="red"
                        chartStyleMinColor="green"
                        allListsFilters={allListsFilters}
                    />
                    <SensorChartList
                        {...props}
                        type="AreaChart"
                        eventName="tvoc"
                        label={translate('resources.tvoc.head')}
                        key="tvoc"
                        show={true}
                        showDangerThreshold={false}
                        chartStyleLineColor="#348b07"
                        chartStyleAvgColor="gray"
                        chartStyleMaxColor="red"
                        chartStyleMinColor="green"
                        allListsFilters={allListsFilters}
                    />

                    <SensorChartList
                        {...props}
                        type="AreaChart"
                        eventName="ammonia"
                        label={translate('resources.ammonia.head')}
                        key="ammonia"
                        show={true}
                        showDangerThreshold={false}
                        chartStyleLineColor="#348b07"
                        chartStyleAvgColor="gray"
                        chartStyleMaxColor="red"
                        chartStyleMinColor="green"
                        allListsFilters={allListsFilters}
                    />
                    <SensorChartList
                        {...props}
                        type="AreaChart"
                        eventName="noise"
                        label={translate('resources.noise.head')}
                        key="noise"
                        show={true}
                        showDangerThreshold={false}
                        chartStyleLineColor="#348b07"
                        chartStyleAvgColor="gray"
                        chartStyleMaxColor="red"
                        chartStyleMinColor="green"
                        allListsFilters={allListsFilters}
                    />
                    <SensorChartList
                        {...props}
                        type="AreaChart"
                        eventName="mox"
                        label={translate('resources.mox.head')}
                        key="mox"
                        show={false}
                        showDangerThreshold={false}
                        chartStyleLineColor="#348b07"
                        chartStyleAvgColor="gray"
                        chartStyleMaxColor="red"
                        chartStyleMinColor="green"
                        allListsFilters={allListsFilters}
                    />
                    <SensorChartList
                        {...props}
                        type="BarChart"
                        referenceAreas={[
                            { yMin: 0, yMax: 2, color: "#008000" },
                            { yMin: 2, yMax: 3, color: "#66b266" },
                            { yMin: 3, yMax: 4, color: "#ffff00" },
                            { yMin: 4, yMax: 5, color: "#ffa500" },
                            { yMin: 5, yMax: 6, color: "#ff0000" },
                            { yMin: 6, yMax: 7, color: "#ff0000" }
                        ]}

                        yDomain={[0, 6]}
                        showHelpLegend={true}
                        HelpLegendComponent={<AqiHelpLegendComponent translate={translate} />}
                        tickCount={6}
                        eventName="aqi"
                        label={translate('resources.aqi.head')}
                        key="aqi"
                        show={true}
                        showDangerThreshold={false}
                        chartStyleLineColor="#348b07"
                        chartStyleAvgColor="gray"
                        chartStyleMaxColor="red"
                        chartStyleMinColor="green"
                        allListsFilters={allListsFilters}
                    />
                    <SensorChartList
                        {...props}
                        type="AreaChart"
                        eventName="pm1"
                        label={translate('resources.pm1.head')}
                        key="pm1"
                        show={true}
                        showDangerThreshold={false}
                        chartStyleLineColor="#348b07"
                        chartStyleAvgColor="gray"
                        chartStyleMaxColor="red"
                        chartStyleMinColor="green"
                        allListsFilters={allListsFilters}
                    />
                    <SensorChartList
                        {...props}
                        type="AreaChart"
                        eventName="pm2p5"
                        label={translate('resources.pm2P5.head')}
                        key="pm2P5"
                        show={true}
                        showDangerThreshold={false}
                        chartStyleLineColor="#348b07"
                        chartStyleAvgColor="gray"
                        chartStyleMaxColor="red"
                        chartStyleMinColor="green"
                        allListsFilters={allListsFilters}
                    />
                    <SensorChartList
                        {...props}
                        type="AreaChart"
                        eventName="pm10"
                        label={translate('resources.pm10.head')}
                        key="pm10"
                        show={true}
                        showDangerThreshold={false}
                        chartStyleLineColor="#348b07"
                        chartStyleAvgColor="gray"
                        chartStyleMaxColor="red"
                        chartStyleMinColor="green"
                        allListsFilters={allListsFilters}
                    />

                    <SensorChartList
                        {...props}
                        type="AreaChart"
                        eventName="benzen"
                        label={translate('resources.benzen.head')}
                        key="benzen"
                        show={true}
                        showDangerThreshold={false}
                        chartStyleLineColor="#348b07"
                        chartStyleAvgColor="gray"
                        chartStyleMaxColor="red"
                        chartStyleMinColor="green"
                        allListsFilters={allListsFilters}
                    />

                    <SensorChartList
                        {...props}
                        type="HighGChart"
                        eventName="high_g"
                        label={translate('resources.high_g.head')}
                        key="high_g"
                        referenceAreas={[
                            { label: translate('resources.high_g.duration'), valueKey: "duration", color: "#82ca9d", unit: "ms" },
                            { label: translate('resources.high_g.average'), valueKey: "average", color: "#8884d8", unit: "G" },
                            { label: translate('resources.high_g.peak'), valueKey: "peak", color: "#b30000", unit: "G" }
                        ]}
                        show={true}
                        showDangerThreshold={false}
                        chartStyleLineColor="#348b07"
                        chartStyleAvgColor="gray"
                        chartStyleMaxColor="red"
                        chartStyleMinColor="green"
                        allListsFilters={allListsFilters}
                    />

                    <SensorTableList
                        {...props}
                        label="resources.presence.head"
                        name="presence"
                        allListsFilters={allListsFilters} />
                    <SensorTableList
                        {...props}
                        label="resources.door.head"
                        name="door"
                        allListsFilters={allListsFilters} />
                    <SensorTableList
                        {...props}
                        label="resources.waterLeak.head"
                        name="water_leak"
                        allListsFilters={allListsFilters} />
                    <SensorTableList
                        {...props}
                        label="resources.shock.head"
                        name="shock"
                        allListsFilters={allListsFilters} />
                    <SensorTableList
                        {...props}
                        label="resources.battery_status.head"
                        name="battery_status"
                        allListsFilters={allListsFilters} />
                    <SensorTableList
                        {...props}
                        label="resources.button.head"
                        name="button"
                        allListsFilters={allListsFilters} />
                    <SensorTableList
                        {...props}
                        label="resources.motion.head"
                        name="motion"
                        allListsFilters={allListsFilters} />
                </Box>
            </List>

        </div>

    )

}

export default withStyles(styles)(translate(_SensorHistoryList))
