import React, { cloneElement } from 'react'
import style from 'jss-material-ui'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { AutocompleteInput, TopToolbar, Filter, ReferenceInput, TextInput, translate } from 'react-admin'
import { Menu } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import List from '../../../ui/list/List'
import Map from '../../../ui/maps/map1'
import MenuItem from './menuItem'
import { PER_PAGE_MAP, isAdminPermissions } from '../../../helper/resourceHelper'
import { changeConfig, SELECT_RIGHTMENU } from '../../configuration/actions'
import { withStyles } from '@material-ui/core/styles'

const listStyles = (theme) => {
    return ({
        organizationFilter: { width: 150 },
        ownerFilter: { width: 350 },
        root: {
            backgroundColor: theme.palette.background.default,
            "& .MuiPaper-root": {
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
            }
        },
        list: {
            marginTop: 40,
            "& .MuiPaper-root": {
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
            },

        },
    })
}

const styles = theme => ({
    actions: {
        alignItems: 'center',
    },

})

const MenuButton = style(IconButton)({
    root: {
        display: 'flex',
        width: 48,
        alignItems: 'center'
    },
})

const onToggleMenuOpen = (props) => {
    props.changeConfig(SELECT_RIGHTMENU, !props.selectRightMenu)
}


const mapStateToProps = state => ({
    config: state.config,
    selectRightMenu: state.config[SELECT_RIGHTMENU],
})

const enhance = compose(
    translate,
    connect(
        mapStateToProps,
        {
            changeConfig,
        }
    ),
    withStyles(styles)
)

const LocationActions = enhance(({

    basePath,
    classes,
    displayedFilters,
    filters,
    filterValues,
    hideFilter,
    resource,
    showFilter,
    permissions,
    ...props
}) => (
    <TopToolbar className={classes.actions}>
        {filters &&
            cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                hideFilter,
                context: 'button',
            })}
        <MenuButton aria-label="Menu" onClick={() => onToggleMenuOpen(props)}>
            <Menu color="inherit" />
        </MenuButton>
    </TopToolbar>
))

const LocationList = ({ classes, translate, ...props }) => (
    <List
        {...props}
        title={translate('resources.locations.name', { smart_count: 2 })}
        className={classes.list}
        actions={<LocationActions />}
        filters={<Filter {...props}>
            <TextInput
                variant="standard"
                margin="normal"
                label={'resources.common.search'}
                source="q"
                alwaysOn
            />
            {isAdminPermissions() && <ReferenceInput label="resources.devices.fields.organization" source="organization_id" reference="organizations" className={classes.organizationFilter}>
                <AutocompleteInput optionText="name" matchSuggestion={() => true} />
            </ReferenceInput>}
            {isAdminPermissions() && <ReferenceInput label="resources.devices.fields.owner" source="owner_id" reference="users" className={classes.ownerFilter}>
                <AutocompleteInput optionText="email" matchSuggestion={() => true} />
            </ReferenceInput>}
        </Filter>
        }
        filter={{
            includes: 'last_location,last_rollover,last_motion,images,status,last_subscription,type,last_seen,product,organization_id,group_devices',
            excludes: 'xtraks'
        }}
        sort={{ field: 'status', order: 'DESC' }}
        pagination={false}
        perPage={PER_PAGE_MAP}
    >
        <Map lastLocation={true} menuItem={MenuItem} translate={translate} />
    </List>
)

export default compose(translate,
    withStyles(listStyles)
)(LocationList)