import React from 'react'
import { Form } from 'react-final-form'
import { SaveButton, Toolbar, translate } from 'react-admin'
import { withStyles } from '@material-ui/core/styles'
import { VGrid } from '../../../ui/Grid'
import {

    Divider,

    Grid,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@material-ui/core'

import style from 'jss-material-ui'
import { WizardForm } from '../../../ui/form/WizardForm'
import PreviousButton from '../../../ui/button/PreviousButton'

const styles = theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            padding: '0 1em 1em 1em',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0 1em 5em 1em',
        },
    },
    control: {
        padding: theme.spacing(2),
    },
    card: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
        height: 151,
    },
    gridItem: {
        textAlign: 'center',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    tableDevice: {
        maxWidth: 250,
    },
    tableFop: {
        maxWidth: 650,
    },
    tableSubscription: {
        maxWidth: 400,
    },
    tableCell: {
        borderBottom: 0,
        fontSize: '0.875rem',
        fontWeight: 400,
    },
    offer: {
        border: `1px solid ${theme.palette.primary.main}`,
        padding: 30,
    },
})

const SDivider = style(Divider)(theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        width: '80%',
        marginBottom: 15,
    },
}))



const DeviceCreateToolbar = props => {
    return (
        <Toolbar {...props}>
            <PreviousButton label={props.translate('ra.navigation.next')} onClick={props.previousPage} />
            <SaveButton label={props.translate('resources.devices.activate')} handleSubmitWithRedirect={props.handleSubmit} submitOnEnter={true} saving={props.saving} />
        </Toolbar>
    )
}

const hasActiveSubscription = device => {
    return (
        device.last_subscription &&
        device.last_subscription.status === 'active' &&
        device.last_subscription.end_date &&
        new Date() < new Date(device.last_subscription.end_date)
    )
}


const AttachDevice = (props) => {
    const { previousPage, value, saving, translate, classes } = props
    const { device, voucher } = value


    const handleSubmit = () => {
        const { device, voucher } = props.value

        if (hasActiveSubscription(device) || voucher) {
            //console.log("[create/AttachDevice] Using voucher or device has active subscription > device_ID : "+ device.device_id);                       
            props.nextPage()
        }
    }


    return (
        <WizardForm
            {...props}
            onSubmit={handleSubmit}
            nextPage={handleSubmit}
            submitOnEnter={true}
            toolbar={<DeviceCreateToolbar {...props} handleSubmit={handleSubmit} previousPage={previousPage} saving={saving} />}
            redirect="list"
            saving={saving}
        >
            <VGrid>
                <Typography align="center" paragraph={true} variant={'h5'}>
                    {translate('resources.devices.review_info').toUpperCase()}
                </Typography>
            </VGrid>
            {hasActiveSubscription(device) && (
                <VGrid center>
                    <Typography variant="h6" gutterBottom>
                        {translate('resources.devices.your_device')}
                    </Typography>
                    <Table className={classes.tableDevice}>
                        <TableBody>
                            <TableRow key={0}>
                                <TableCell className={classes.tableCell} component="th" scope="row">
                                    {translate('resources.devices.fields.name')}
                                </TableCell>
                                <TableCell className={classes.tableCell} component="th" scope="row">
                                    {device.name}
                                </TableCell>
                            </TableRow>
                            <TableRow key={1}>
                                <TableCell className={classes.tableCell} component="th" scope="row">
                                    {translate('resources.devices.identifier')}
                                </TableCell>
                                <TableCell className={classes.tableCell} component="th" scope="row">
                                    {device.serial_number}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </VGrid>
            )}
            {!hasActiveSubscription(device) && (
                <Grid container className={classes.root} spacing={0}>
                    <Grid item xs={12} sm={6}>
                        <SDivider />
                        <Typography variant="h6" gutterBottom>
                            {translate('resources.devices.your_device')}
                        </Typography>
                        <Table className={classes.tableDevice}>
                            <TableBody>
                                <TableRow key={0}>
                                    <TableCell className={classes.tableCell} component="th" scope="row">
                                        {translate('resources.devices.fields.name')}
                                    </TableCell>
                                    <TableCell className={classes.tableCell} component="th" scope="row">
                                        {device.name}
                                    </TableCell>
                                </TableRow>
                                <TableRow key={1}>
                                    <TableCell className={classes.tableCell} component="th" scope="row">
                                        {translate('resources.devices.identifier')}
                                    </TableCell>
                                    <TableCell className={classes.tableCell} component="th" scope="row">
                                        {device.serial_number}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>


                    </Grid>
                    {voucher && (
                        <Grid item xs={12} sm={6} className={classes.offer}>
                            <Typography variant="h5" component="h2">
                                {translate('resources.subscriptions.voucher_name', {
                                    quantity: voucher.plan.frequency_interval,
                                })}
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                {translate('resources.subscriptions.voucher_title', {
                                    voucher_code: voucher.code,
                                })}
                            </Typography>
                        </Grid>
                    )}

                </Grid>
            )}
        </WizardForm>
    )
}




export default withStyles(styles)(translate(props => <Form {...props} component={AttachDevice} />))

