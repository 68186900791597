//import 'moment-duration-format'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { translate, GET_LIST } from 'react-admin'
import dataProviderFactory from '../../dataProvider'
import { TimeSeries } from 'pondjs'
import styled from 'jss-material-ui'
import { getDateTimeToLocalString, getDateTimeToFullLocalString } from '../../utils/date'
import { Paper99White, Title100Chart, H100Grid } from '../../ui/Grid'
import HelpIcon from '@mui/icons-material/Help';
import { Area, AreaChart, LineChart, Cell, BarChart, Bar, Rectangle, ReferenceArea, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine, Label, ComposedChart, Scatter } from 'recharts';
import InfoCard from './infoCard'
import { Box, Button, Popover, Paper, Typography, IconButton } from '@mui/material'
//ticatag




export const P100 = styled('p')({
    root: {
        textAlign: 'right',
        width: '100%',
        marginTop: 2,
        marginBottom: 6,
    },
})


const getUnitSymbol = (unit) => {
    if (unit === null || unit === undefined) {
        return ''
    }
    if (unit === 'percent') return "%"
    if (unit === 'celsius') return "°C"
    if (unit === 'millivolt') return "mV"
    if (unit === 'microgram_per_m3') return "µg/m³"
    if (unit === 'hpa') return "hPa"


    return unit
}




const HelpLegend = ({ handleClickHelpLegend }) => {

    return (
        <IconButton variant="contained" onClick={handleClickHelpLegend} color="primary">
            <HelpIcon />
        </IconButton>
    )
}

/**
 * Measures chart for events with values in a continues range 
 * 
 */

const MeasuresAreaChart = ({ chartProperties, translate }) => {
    const [helpLegendOpen, setHelpLegendOpen] = React.useState(false);

    const defsContent = chartProperties.thresholdPercentFromMax && chartProperties.eventName ? (
        <defs >
            <linearGradient id={`gradient${chartProperties.eventName}`} x1="0" y1="0" x2="0" y2="1" >
                <stop offset={0} stopColor="red" stopOpacity={1} />
                <stop offset={`${chartProperties.thresholdPercentFromMax}%`} stopColor="red" stopOpacity={1} />
                <stop offset={`${chartProperties.thresholdPercentFromMax}%`} stopColor="white" stopOpacity={0} />
                <stop offset={1} stopColor="white" stopOpacity={0} />
            </linearGradient>
        </defs>
    ) : null;


    const handleClickHelpLegend = () => {
        setHelpLegendOpen(!helpLegendOpen);
    };


    if (chartProperties && chartProperties.show === false) {
        return null
    }




    return (<div >
        <H100Grid style={{ justifyContent: "start" }}>
            <Typography variant='h6' fontWeight="800" pt={2} mb={2} ml={2}> {chartProperties.label}</Typography>
            {chartProperties.unit !== null && chartProperties.unit !== undefined && <Typography variant='body1' fontWeight="400" pt={2} mb={2} ml={1}> {`(${getUnitSymbol(chartProperties.unit)})`}</Typography>}
            {chartProperties.showHelpLegend === true && <HelpLegend handleClickHelpLegend={handleClickHelpLegend} />}
        </H100Grid>
        <H100Grid style={{ display: helpLegendOpen ? 'block' : 'none' }}>
            {chartProperties.HelpLegendComponent}
        </H100Grid>
        <H100Grid style={{ justifyContent: "end" }}>
            {chartProperties.showDangerThreshold === true &&
                <InfoCard title={translate('resources.co2.treshold')} value={chartProperties.dangerThreshold + getUnitSymbol(chartProperties.unit)} />
            }
            <InfoCard title={translate('resources.co2.max')} value={chartProperties.max + " " + getUnitSymbol(chartProperties.unit)} />
            <InfoCard title={translate('resources.co2.avg')} value={chartProperties.avg + " " + getUnitSymbol(chartProperties.unit)} />
            <InfoCard title={translate('resources.co2.min')} value={chartProperties.min + " " + getUnitSymbol(chartProperties.unit)} />

        </H100Grid>

        <ResponsiveContainer width="100%" minWidth={500} aspect={3 / 1} minHeight={200} >
            <AreaChart data={chartProperties.series[0].data} width={600} height={300} margin={{ top: 20, right: 40, left: 60, bottom: 80 }} >

                {defsContent}
                <ReferenceLine y={chartProperties.dangerThreshold} label={{ value: translate('resources.co2.treshold'), fill: "red", offset: 50, angle: 0, position: 'left' }} stroke="red" strokeDasharray="3 0" alwaysShow={true} />
                <CartesianGrid strokeWidth={0.5} strokeDasharray='1 0' vertical={false} />
                <Tooltip labelFormatter={getDateTimeToFullLocalString} formatter={(value, name, props) => [value + " " + getUnitSymbol(chartProperties.unit), chartProperties.label]} />
                <Area type="monotone" name={chartProperties.series[0].name} dataKey="y" stroke="green" fill={`url(#gradient${chartProperties.eventName})`} isAnimationActive={false} strokeWidth={3} dot={false} activeDot={{ stroke: chartProperties.style.lineColor, strokeWidth: 1, r: 6 }} />
                <XAxis dataKey="x" type="number" tickCount={24} angle={-45} tickFormatter={getDateTimeToLocalString} dy={30} tickLine={false} domain={['dataMin', 'dataMax']} />
                <YAxis dataKey="y" type="number" tickCount={chartProperties.tickCount ? chartProperties.tickCount : 10} domain={Array.isArray(chartProperties.yDomain) && chartProperties.yDomain.length === 2 ? chartProperties.yDomain : [0, chartProperties.ymax]} />
                {
                    Array.isArray(chartProperties.referenceAreas) &&
                    chartProperties.referenceAreas.map((refArea, index) => (
                        <ReferenceArea
                            key={index}
                            x1={chartProperties.series[0].data[0].time}
                            x2={chartProperties.series[0].data[chartProperties.series[0].data.length - 1].time}
                            y1={refArea.yMin}
                            y2={refArea.yMax}
                            fill={refArea.color}
                            fillOpacity={0.2}
                        />
                    ))

                }
            </AreaChart>
        </ResponsiveContainer>
    </div>)
}


const MeasuresBarChart = ({ chartProperties, translate }) => {
    const [helpLegendOpen, setHelpLegendOpen] = React.useState(false);
    const handleClickHelpLegend = () => {
        setHelpLegendOpen(!helpLegendOpen);
    };
    if (chartProperties && chartProperties.show === false) {
        return null
    }
    return (<div >
        <H100Grid style={{ justifyContent: "start" }}>
            <Typography variant='h6' fontWeight="800" pt={2} mb={2} ml={2}> {chartProperties.label}</Typography>
            {chartProperties.unit !== null && chartProperties.unit !== undefined && <Typography variant='body1' fontWeight="400" pt={2} mb={2} ml={1}> {`(${getUnitSymbol(chartProperties.unit)})`}</Typography>}
            {chartProperties.showHelpLegend === true && <HelpLegend handleClickHelpLegend={handleClickHelpLegend} />}
        </H100Grid>
        <H100Grid style={{ display: helpLegendOpen ? 'block' : 'none' }}>
            {chartProperties.HelpLegendComponent}
        </H100Grid>
        <H100Grid style={{ justifyContent: "end" }}>
            {chartProperties.showDangerThreshold === true &&
                <InfoCard title={translate('resources.co2.treshold')} value={chartProperties.dangerThreshold + getUnitSymbol(chartProperties.unit)} />
            }
            <InfoCard title={translate('resources.co2.max')} value={chartProperties.max + " " + getUnitSymbol(chartProperties.unit)} />
            <InfoCard title={translate('resources.co2.avg')} value={chartProperties.avg + " " + getUnitSymbol(chartProperties.unit)} />
            <InfoCard title={translate('resources.co2.min')} value={chartProperties.min + " " + getUnitSymbol(chartProperties.unit)} />
        </H100Grid>

        <ResponsiveContainer width="100%" minWidth={800} aspect={3 / 1} minHeight={300} >
            <BarChart data={chartProperties.series[0].data} width={1000} height={300} margin={{ top: 20, right: 40, left: 60, bottom: 80 }} >
                <CartesianGrid strokeWidth={0.5} strokeDasharray='1 0' vertical={false} />
                <Tooltip labelFormatter={getDateTimeToFullLocalString} formatter={(value, name, props) => [value + " " + getUnitSymbol(chartProperties.unit), chartProperties.label]} />
                <XAxis dataKey="x" type="number" tickCount={24} angle={-45} tickFormatter={getDateTimeToLocalString} dy={30} tickLine={false} domain={['dataMin', 'dataMax']} />
                <YAxis dataKey="y" type="number" tickCount={chartProperties.tickCount ? chartProperties.tickCount : 10} domain={Array.isArray(chartProperties.yDomain) && chartProperties.yDomain.length === 2 ? chartProperties.yDomain : [0, chartProperties.ymax]} />

                <Bar
                    name={chartProperties.series[0].name}
                    dataKey={`y`}
                    barSize={15}
                    fill="black"
                >
                    {
                        chartProperties.series[0].data.map((entry, index) => {

                            for (const range of chartProperties.referenceAreas) {
                                if (entry.y >= range.yMin && entry.y < range.yMax) {
                                    return (
                                        <Cell fill={range.color} key={`cell-${index}`} />
                                    )
                                }
                            }

                            return (<Cell fill={range.color} key={`cell-${index}`} />)


                        })
                    }

                </Bar>

            </BarChart>
        </ResponsiveContainer>
    </div>)
}



const HighGChart = ({ chartProperties, translate }) => {


    // Extracting all max values of peak and average of G
    var allMaxValuesG = chartProperties.series[0].data.map(function (dataPoint) {
        return Math.max(dataPoint.y2, dataPoint.y3);
    });

    // Finding the overall maximum of value1 and value2
    const gForceMax = Math.ceil(Math.max(...allMaxValuesG));

    // Extracting all durations
    var allValuesDuration = chartProperties.series[0].data.map(function (dataPoint) {
        return dataPoint.y1;
    });

    // Finding the maximum value1
    const durationMax = Math.ceil(Math.max(...allValuesDuration)) + 100;

    if (chartProperties && chartProperties.show === false) {
        return null
    }
    return (<div >
        <H100Grid style={{ justifyContent: "start" }}>
            <Typography variant='h6' fontWeight="800" pt={2} mb={2} ml={2}> {chartProperties.label}</Typography>
            {chartProperties.unit !== null && chartProperties.unit !== undefined && <Typography variant='body1' fontWeight="400" pt={2} mb={2} ml={1}> {`(${getUnitSymbol(chartProperties.unit)})`}</Typography>}

        </H100Grid>


        <ResponsiveContainer width="100%" minWidth={800} aspect={3 / 1} minHeight={300} >
            <ComposedChart data={chartProperties.series[0].data} width={1000} height={300} margin={{ top: 20, right: 40, left: 60, bottom: 80 }} >
                <CartesianGrid strokeWidth={0.5} strokeDasharray='1 0' vertical={false} />
                <Tooltip labelFormatter={getDateTimeToFullLocalString} formatter={(value, name, props) => {
                    console.log("Tooltip props ==> ", props)
                    if (props.dataKey === "y1") return [value + " " + getUnitSymbol(chartProperties.referenceAreas[0].unit), name]
                    if (props.dataKey === "y2") return [value + " " + getUnitSymbol(chartProperties.referenceAreas[1].unit), name]
                    if (props.dataKey === "y3") return [value + " " + getUnitSymbol(chartProperties.referenceAreas[2].unit), name]
                    return [value, name]
                }} />
                <XAxis dataKey="x" type="number" tickCount={24} angle={-45} tickFormatter={getDateTimeToLocalString} dy={30} tickLine={false} domain={['dataMin - 1000000', 'dataMax + 1000000']} />
                <YAxis dataKey="y1" label={{ value: translate('resources.high_g.durationInMs'), angle: -90, position: 'insideRight' }} type="number" yAxisId="right" orientation="right" tickCount={chartProperties.tickCount ? chartProperties.tickCount : 10} domain={Array.isArray(chartProperties.yDomain) && chartProperties.yDomain.length === 2 ? chartProperties.yDomain : [0, durationMax]} />
                <YAxis dataKey="y2" label={{ value: translate('resources.high_g.gForce'), angle: -90, position: 'insideLeft' }} type="number" yAxisId="left" orientation="left" tickCount={chartProperties.tickCount ? chartProperties.tickCount : 10} domain={Array.isArray(chartProperties.yDomain) && chartProperties.yDomain.length === 2 ? chartProperties.yDomain : [0, gForceMax]} />
                <YAxis dataKey="y3" hide={true} type="number" yAxisId="left3" orientation="left" tickCount={chartProperties.tickCount ? chartProperties.tickCount : 10} domain={Array.isArray(chartProperties.yDomain) && chartProperties.yDomain.length === 2 ? chartProperties.yDomain : [0, gForceMax]} />



                <Bar
                    name={chartProperties.referenceAreas[0].label}
                    dataKey={`y1`}
                    yAxisId="right"
                    barSize={5}
                    fill={chartProperties.referenceAreas[0].color}
                >
                    {
                        chartProperties.series[0].data.map((entry, index) => {


                            return (<Cell fill={chartProperties.referenceAreas[0].color} key={`cell-${index}`} />)


                        })
                    }

                </Bar>

                <Scatter
                    name={chartProperties.referenceAreas[1].label}
                    dataKey="y2"
                    yAxisId="left"
                    fill={chartProperties.referenceAreas[1].color}
                />

                <Scatter
                    name={chartProperties.referenceAreas[2].label}
                    dataKey="y3"
                    yAxisId="left3"
                    fill={chartProperties.referenceAreas[2].color}
                />

            </ComposedChart>
        </ResponsiveContainer>
    </div>)
}




const SensorCharts = translate(({ classes, ...props }) => {

    const [device, setDevice] = useState(null)
    const { data, ids, deviceId, translate, eventName, chartOptions } = props

    useEffect(async () => {
        const dataProvider = await dataProviderFactory('rest')

        dataProvider(GET_LIST, 'devices', {
            filter: { device_ids: deviceId, includes: 'device_id,configuration' },
        })
            .then(devices => {
                setDevice(devices.data[0])

            })
    }, [])


    const points = []
    const dataPoints = []
    const chartProperties = { ...chartOptions }


    if (ids.length === 0) {
        // no data to display 
        return null
    }

    let filteredData = {}
    // filter units if they are different
    if (chartProperties.unitToShow !== undefined && chartProperties.unitToShow !== null) {
        filteredData = Object.keys(data)
            .filter((key) => data[key].unit === chartProperties.unitToShow)
            .reduce((result, key) => {
                result[key] = data[key];
                return result;
            }, {});
    } else {
        filteredData = Object.keys(data)
            .filter((key) => data[key].unit === data[ids[0]].unit)
            .reduce((result, key) => {
                result[key] = data[key];
                return result;
            }, {});
    }

    //get time/values for standard charts
    for (let i = 1; i <= ids.length; i += 1) {

        const event = filteredData[ids[ids.length - i]]

        if (event !== undefined && event !== null && eventName === event.name && chartProperties.type === "HighGChart") {
            const time = Date.parse(event.timestamp)
            const referenceAreas = chartProperties.referenceAreas
            const value1 = Math.round(event[referenceAreas[0].valueKey] * 100) / 100
            const value2 = (Math.round(event[referenceAreas[1].valueKey] * 100) / 100) / 1024
            const value3 = (Math.round(event[referenceAreas[2].valueKey] * 100) / 100) / 1024
            dataPoints.push({ x: time, y1: value1, y2: value2, y3: value3 })
            points.push([time, value1])
            points.push([time, value2])
            points.push([time, value3])
            //get unit
            chartProperties.unit = event.unit !== undefined ? event.unit : null;

        } else if (event !== undefined && event !== null && eventName === event.name) {
            const time = Date.parse(event.timestamp)
            const value = Math.round(event.value * 100) / 100
            dataPoints.push({ x: time, y: value })
            points.push([time, value])

            //get unit
            chartProperties.unit = event.unit !== undefined ? event.unit : null;

        }


        //temp unit for co2 
        if (event !== undefined && event !== null && event.name === "co2") {
            // no data to display 
            chartProperties.unit = "ppm"
        }
    }



    // Make the TimeSeries here from the points collected above
    const series = new TimeSeries({
        name: eventName,
        columns: ['time', eventName],
        points: points,
    })


    chartProperties.series = [{
        name: eventName,
        data: dataPoints
    }];


    // calculate statistics
    if (points.length > 0) {
        chartProperties.avg = parseInt(series.avg(eventName), 10)
        chartProperties.max = parseInt(series.max(eventName), 10)
        chartProperties.min = parseInt(series.min(eventName), 10)
        chartProperties.maxTime = series.range() ? series.range().end() : 0
        chartProperties.minTime = series.range() ? series.range().begin() : 0
        chartProperties.ymax = chartProperties.max + (10 - (chartProperties.max % 10))
    } else {
        chartProperties.show = false;
    }


    // add threshold 
    if (chartProperties.showDangerThreshold === true) {
        if (device != null && device.configuration) {
            chartProperties.dangerThreshold = device.configuration.co2_alert_threshold
            chartProperties.thresholdPercentFromMax = ((chartProperties.max - chartProperties.dangerThreshold) / chartProperties.max) * 100;
        } else {
            chartProperties.showDangerThreshold = false
            chartProperties.dangerThreshold = null
        }

    }

    return (
        <Paper elevation={3} sx={{
            backgroundColor: '#fff',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            margin: 5
        }}>
            <div id="chartsToExport" style={{ backgroundColor: "white" }}>
                {chartProperties.type === "AreaChart" && <MeasuresAreaChart chartProperties={chartProperties} translate={translate} />}

                {chartProperties.type === "BarChart" && <MeasuresBarChart chartProperties={chartProperties} translate={translate} />}

                {chartProperties.type === "HighGChart" && <HighGChart chartProperties={chartProperties} translate={translate} />}
            </div >
        </Paper>

    )


})

export default SensorCharts;


