import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { Edit, FormDataConsumer, UPDATE, GET_LIST, showNotification, SimpleForm, translate, Toolbar } from 'react-admin'

import { withStyles } from '@material-ui/core/styles'

import L from 'leaflet'
import { BingProvider } from 'leaflet-geosearch'

import dataProviderFactory from '../../../dataProvider'
import GeofenceForm from './GeofenceForm'
import { formatAddress, geoReverse } from '../../../utils/geocode'

import { isRootOrganizationUser } from '../../../helper/resourceHelper'

const styles = theme => ({
    list: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        marginTop: 20,
    }
})

const provider = new BingProvider({
    params: {
        key: process.env.REACT_APP_BING_API_KEY,
    },
})

class GeofenceEdit extends Component {
    state = {
        dataProvider: null,
        center: null,
        zoom: 5,
        radius: null,
        place: '',
        places: [],
        deviceId: null,
        device: '',
        devices: [],
        active: null,
        onEntry: null,
        onExit: null,
        geofenceId: null,
    }

    async UNSAFE_componentWillMount() {

        const dataProvider = await dataProviderFactory('rest')
        this.setState({ dataProvider })
    }

    handlePlaceChange = (event, { newValue }) => {
        this.setState({
            place: newValue,
        })
    }

    handleDeviceChange = (event, { newValue }) => {
        this.setState({
            device: newValue,
        })
    }

    handlePlacesFetchRequested = ({ value }) => {
        provider.search({ query: value }).then(results => {
            this.setState({ places: results })
        })
    }

    handleDevicesFetchRequested = ({ value }) => {
        this.state
            .dataProvider(GET_LIST, 'devices', {
                pagination: { page: 1, perPage: 50 },
                sort: { field: 'name', order: 'ASC' },
                filter: { q: value, includes: 'device_id,last_location' },
            })
            .then(devices => {
                this.setState({ devices: devices.data })
            })
    }

    handleSubmit = () => {
        const { showNotification, translate } = this.props
        const { deviceId, center, radius, active, onEntry, onExit, place, geofenceId } = this.state
        if (!deviceId && isRootOrganizationUser()) {
            showNotification(translate('resources.geofences.no_device_selected'), 'warning')
            return
        }

        if (!center.lat & !center.lng) {
            showNotification(translate('resources.geofences.no_center_defined'), 'warning')
            return
        }

        const geofence = {
            device_id: deviceId,
            latitude: center.lat,
            longitude: center.lng,
            formatted_address: place,
            active: active,
            radius: radius,
            on_entry: onEntry,
            on_exit: onExit,
        }
        this.state
            .dataProvider(UPDATE, 'geofences', {
                id: geofenceId,
                data: geofence,
            })
            .then(() => {
                showNotification(translate('ra.notification.updated', { smart_count: 1, },))
                this.props.history.push('/geofences')
            })
            .catch(() => {
                showNotification(translate('resources.internal_error'))
            })
    }

    handlePlacesClearRequested = () => {
        this.setState({
            places: [],
        })
    }

    handleDevicesClearRequested = () => {
        this.setState({
            devices: [],
        })
    }

    onPlaceSelected = (event, { suggestion, suggestionValue }) => {
        event.preventDefault()
        this.changeCenter({ lat: suggestion.y, lng: suggestion.x, address: suggestionValue }, 100)
    }

    onDeviceSelected = (event, { suggestion }) => {
        event.preventDefault()
        this.setState({
            deviceId: suggestion.device_id,
        })
        if (suggestion.last_location) {
            if (!suggestion.last_location.formatted_address)
                this.reverseGeocoding(suggestion.last_location.latitude, suggestion.last_location.longitude)

            this.changeCenter(
                {
                    lat: suggestion.last_location.latitude,
                    lng: suggestion.last_location.longitude,
                    address: suggestion.last_location.formatted_address ? suggestion.last_location.formatted_address : "",
                },
                100
            )
        }
    }

    changeCenter = ({ lat, lng, address }, radius) => {
        let zoom = 9

        if (radius < 100) {
            zoom = 18
        }
        if (radius < 250) {
            zoom = 16
        } else if (radius < 1000) {
            zoom = 14
        } else if (radius < 1500) {
            zoom = 13
        } else if (radius < 3000) {
            zoom = 12
        } else if (radius <= 7500) {
            zoom = 11
        } else if (radius <= 10000) {
            zoom = 10
        } else if (radius <= 20000) {
            zoom = 9
        } else if (radius <= 50000) {
            zoom = 8
        } else if (radius <= 80000) {
            zoom = 7
        } else if (radius <= 100000) {
            zoom = 6
        } else if (radius >= 100000) {
            zoom = 5
        }

        this.setState({
            center: {
                lat: lat,
                lng: lng,
            },
            radius: radius,
            place: address,
            zoom: zoom,
        })
    }

    handleRadiusChange = (event, value) => {
        const { place, center } = this.state
        this.changeCenter({ lat: center.lat, lng: center.lng, address: place }, value)
    }

    handleSwitchChange = name => event => {
        this.setState({ [name]: event.target.checked })
    }

    _onEdited = e => {
        const layer = e.layer
        const lat = layer._latlng.lat
        const lng = layer._latlng.lng
        this.changeCenter({ lat: lat, lng: lng, address: '' }, Math.round(layer._mRadius / 100) * 100)
        this.reverseGeocoding(lat, lng)

    }

    async reverseGeocoding(latitude, longitude) {
        try {
            const res = await geoReverse(latitude, longitude)
            const json = await res.json()
            const formatted_address = formatAddress(json.address)
            this.setState({ place: formatted_address })
        } catch (ex) { }
    }

    render() {
        const { props } = this
        const { classes } = props
        const { radius, center, zoom, place, places, device, devices, active, onEntry, onExit } = this.state
        return (
            <Edit {...props} title={props.translate('resources.geofences.edit')} className={classes.list}>
                <SimpleForm save={this.handleSubmit} toolbar={<Toolbar alwaysEnableSaveButton />}>
                    <FormDataConsumer>
                        {({ formData }) => {
                            if (!center) {
                                this.changeCenter({ lat: formData.latitude, lng: formData.longitude, address: formData.formatted_address }, formData.radius)
                                this.setState({
                                    device: formData.device ? formData.device.name : '',
                                    deviceId: formData.device_id,
                                    geofenceId: formData.geofence_id,
                                    active: formData.active,
                                    onEntry: formData.on_entry,
                                    onExit: formData.on_exit,
                                })
                            }
                            return (
                                <GeofenceForm
                                    radius={radius ? radius : formData.radius}
                                    center={center ? center : { lat: formData.latitude, lng: formData.longitude, }}
                                    zoom={zoom}
                                    place={place !== '' ? place : formData.formatted_address}
                                    places={places}
                                    device={device === '' && formData.device ? formData.device.name : device}
                                    devices={devices}
                                    active={active !== null ? active : formData.active}
                                    onEntry={onEntry !== null ? onEntry : formData.on_entry}
                                    onExit={onExit !== null ? onExit : formData.on_exit}
                                    handleDevicesFetchRequested={this.handleDevicesFetchRequested.bind(this)}
                                    handleDevicesClearRequested={this.handleDevicesClearRequested.bind(this)}
                                    onDeviceSelected={this.onDeviceSelected.bind(this)}
                                    handleDeviceChange={this.handleDeviceChange.bind(this)}
                                    handlePlacesFetchRequested={this.handlePlacesFetchRequested.bind(this)}
                                    handlePlacesClearRequested={this.handlePlacesClearRequested.bind(this)}
                                    onPlaceSelected={this.onPlaceSelected.bind(this)}
                                    handlePlaceChange={this.handlePlaceChange.bind(this)}
                                    handleRadiusChange={this.handleRadiusChange.bind(this)}
                                    handleSwitchChange={this.handleSwitchChange.bind(this)}
                                    onEdited={this._onEdited.bind(this)}
                                />
                            )
                        }
                        }
                    </FormDataConsumer>
                </SimpleForm>
            </Edit>
        )
    }
}

export default compose(
    withStyles(styles),
    translate,
    connect(
        null,
        { showNotification }
    )
)(GeofenceEdit)
