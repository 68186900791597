import React from 'react';
import compose from 'recompose/compose'
import PropTypes from 'prop-types';
import LocationIcon from '@material-ui/icons/Map';
import { Link } from 'react-router-dom';
import { linkToRecord, translate } from 'react-admin';
import { Button, Tooltip } from '@material-ui/core'
import { useTheme } from '@mui/material';



const noLocationSince48Hours = (record) => {

    if (record
        && record.last_location
        && record.last_location.timestamp) {

        const dateToCompare = new Date(record.last_location.timestamp);
        const currentDate = new Date();
        const fortyEightHoursAgo = new Date(currentDate - 48 * 60 * 60 * 1000);

        return dateToCompare < fortyEightHoursAgo

    }

    return true
}


const LocationButton = ({
    basePath = '',
    record = {},
    translate,
    ...rest
}) => {
    const theme = useTheme();
    return (<Tooltip title={translate(`resources.devices.browse_location`)}>
        <Button
            component={Link}
            to={`${linkToRecord('locations', record.id)}/show`}
            {...rest}
        >
            <LocationIcon style={{ color: noLocationSince48Hours(record) === true ? "orange" : theme.palette.primary.main }} />
        </Button>
    </Tooltip>
    );
}


LocationButton.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.object,
    record: PropTypes.object,
};

const enhance = compose(
    translate,
);

export default enhance(LocationButton);