import React from 'react'

export const getId = record => {
    if (record) {
        return record.last_location ? record.id : record.event_id
    } else {
        return ''
    }
}

export const getLatitude = device => Math.round(_getLatitude(device) * 100000) / 100000

const _getLatitude = device => {
    if (!device) return 46.5

    return device.last_location
        ? device.last_location.latitude
            ? device.last_location.latitude
            : 46.5
        : device.latitude
            ? device.latitude
            : 46.5
}

export const getLongitude = device => Math.round(_getLongitude(device) * 100000) / 100000

const _getLongitude = device => {
    if (!device) return 3.5

    return device.last_location
        ? device.last_location.longitude
            ? device.last_location.longitude
            : 3.5
        : device.longitude
            ? device.longitude
            : 3.5
}

export const getAccuracy = device => {
    if (!device || !device.accuracy) return 0

    return device.accuracy
}


export const getAltitude = device => {
    if (!device || !device.altitude) return null

    return device.altitude
}


export const getSpeed = device => {
    if (!device) return null
    if (device.speed > 0 || device.speed === 0) {
        return device.speed
    } else {
        return null
    }

}


export const getGeofenceLatitude = geofence => Math.round(geofence.latitude * 100000) / 100000

export const getGeofenceLongitude = geofence => Math.round(geofence.longitude * 100000) / 100000

export const isDeviceLocalisable = device => {
    if (!device) return false

    return (
        /*        (device.last_subscription &&
            device.last_subscription.status === 'active') &&    **/
        (device.last_location &&
            device.last_location.latitude &&
            !isNaN(device.last_location.latitude) &&
            device.last_location.longitude &&
            !isNaN(device.last_location.longitude)) ||
        (device.latitude && !isNaN(device.latitude) && device.longitude && !isNaN(device.latitude))
    )
}

export const sVars = {
    savedZoom: null,
    savedBounds: null,
    leafletMap: React.createRef(),
    leafletFG: React.createRef(),
}

export const filterLowercase = props => {
    let res = {}

    Object.keys(props).forEach(key => {
        if (key === key.toLowerCase()) res[key] = props[key]
    })
    return res
}

export const getFormattedAddress = record => {
    // keep this attribute priority, marker overloaded formatted_address
    return record.formatted_address
        ? record.formatted_address
        : record.last_location
            ? record.last_location.formatted_address
            : null
}

export const hasLocation = device => {
    const { latitude, longitude } = device.last_location ? device.last_location : device

    return latitude && longitude
}

export const hasAlertFired = device => {
    return device && device.status && device.status === 'alert'
}

/**
 * JavaScript Get URL Parameter
 *
 * @param String prop The specific URL parameter you want to retreive the value for
 * @return String|Object If prop is provided a string value is returned, otherwise an object of all properties is returned
 */
export const getUrlParams = (prop, defaultVal = '') => {
    var params = {}
    var search = decodeURIComponent(window.location.href.slice(window.location.href.indexOf('?') + 1))
    var definitions = search.split('&')

    definitions.forEach(function (val) {
        var parts = val.split('=', 2)
        params[parts[0]] = parts[1]
    })

    return prop in params
        ? params[prop] === 'true'
            ? true
            : params[prop] === 'false'
                ? false
                : params[prop]
        : defaultVal
}

export const getDeviceIdFromUrl = (prop, defaultVal = '') => {
    var search = decodeURIComponent(window.location.href.slice(window.location.href.indexOf('/measures/') + 10))
    var definitions = search.split('/')

    if (definitions && definitions.length > 0) return definitions[0]
    else return defaultVal
}

export const isWidthUpSM = () => {
    return window.innerWidth > 600
}

export const isWidthUpMD = () => {
    return window.innerWidth > 800
}

