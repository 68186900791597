import React, { useState, useEffect } from 'react'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import { Create, showNotification, translate, useDataProvider, useLocale } from 'react-admin'
import CheckSubscription from './CheckSubscription'
import AttachDevice from './AttachDevice'
import SelectPlan from './SelectPlan'
import {
    addSubscription,
    cancelSubscription,
    getFopSepa,
    getFopCard,
    getSubscriptions,
    getPlans,
    searchVoucher,
    deviceProduct,
} from '../../../rest/restClient'
import { connect } from 'react-redux'
import { Elements } from '@stripe/react-stripe-js'

import deepmerge from 'deepmerge'
import { canCancelSubscription, getPostPaidSubscription, isYabby } from '../../../helper/resourceHelper'
import logger from '../../../logger/log'
import SelectPlanType from './SelectPlanType';



const CHECK_SUBSCRIPTION = 1
const ATTACH_DEVICE = 2
const SELECT_PLAN_TYPE = 3
const SELECT_PLAN = 4

const hasActiveSubscription = device => {
    return (
        device.last_subscription &&
        device.last_subscription.status === 'active' &&
        device.last_subscription.end_date &&
        new Date() < new Date(device.last_subscription.end_date)
    )
}


const styles = theme => ({
    main: {
        marginTop: theme.spacing(4),

    },


})


const _DeviceSubscribe = (props) => {

    const dataProvider = useDataProvider();
    const [pageNumber, setPageNumber] = useState(1);
    const [saving, setSaving] = useState(false);
    const [payload, setPayload] = useState({ subscriptions: [] });
    const [form, setForm] = useState({});
    const [stripe, setStripe] = useState(null);
    const [reRenderSelectPlan, setReRenderSelectPlan] = useState('0');
    const [reRenderSelectPlanType, setReRenderSelectPlanType] = useState('0');
    const [reRenderCheckSubscription, setReRenderCheckSubscription] = useState('0');
    const locale = useLocale();

    useEffect(() => {
        getSubscriptions(props.match.params.id).then(response => {
            const payloadTmp = payload
            if (response.json && response.json._embedded) {
                payloadTmp.subscriptions = response.json._embedded.subscriptions
                setPayload(payloadTmp)
                setReRenderCheckSubscription(toString(payloadTmp.subscriptions.length))
            }
        })

    }, []);


    const handleCheckSubscription = (values) => {
        const deviceId = props.match.params.id
        deviceProduct(deviceId).then(response => {
            const device = response.json;
            const operator = device && device.product && device.product.operator ? device.product.operator : null;
            getPlans(operator, device.organization.organization_id, device.device_id, locale).then(response => {
                var plans = response.json

                setPage(SELECT_PLAN_TYPE, values, { plans: plans, device: device })

            })
        });


    }

    const handleSaving = saving => {
        setSaving(saving)

    }

    const handleAttachDevice = (values) => {
        // check if element exist
        const { showNotification, translate } = props

        const { voucher } = payload


        let subscription = { device_id: props.match.params.id }

        if (voucher) {
            //console.log("[subscribe/DeviceCreate]  handleAttachDevice with voucher code : "+ voucher.code +" > device_ID : "+ device.device_id);                       
            subscription['voucher_code'] = voucher.code
            logger.info("page/device/subscribe/DeviceSubscribe : Adding subscription plan voucherCode " + subscription.voucher_code + ":  for deviceId : " + subscription.device_id)
            addSubscription(subscription)
                .then(() => {
                    //console.log("[subscribe/DeviceCreate] [voucher] error catched at  addSubscription");                       
                    showNotification(translate('resources.subscriptions.subscription_success'), 'info')
                    this.props.history.push('/#/devices')
                })
                .catch(() => { })
        }
    }

    const handleCancelSubscription = (values) => {
        const postPaidSubscription = getPostPaidSubscription(payload.subscriptions)

        logger.info("page/device/subscribe/DeviceSubscribe : canceling subscription  suscription_id : " + postPaidSubscription.subscription_id + " for device_id :  " + postPaidSubscription.device_id)
        cancelSubscription(postPaidSubscription.subscription_id)
            .then(() => {
                showNotification(translate('resources.subscriptions.subscription_canceled'), 'info')
                props.history.push('/#/devices')
            })
            .catch(() => {
                showNotification(translate('resources.subscriptions.subscription_canceled'), 'info')
            })
    }



    const handleSelectPlanType = (e, form) => {
        const { translate, showNotification } = props
        if (!payload.selectedPlanType) {
            showNotification(translate('resources.subscriptions.no_selected_plan_type'), 'warning')
        } else {
            setPage(SELECT_PLAN, form, payload)
        }
    }


    const handleChangeSelectPlanType = (selectedPlanType) => {
        const payloadTmp = payload
        payloadTmp.selectedPlanType = selectedPlanType
        setPayload(payloadTmp)
        setReRenderSelectPlanType(selectedPlanType ? selectedPlanType : '0')
    }


    const handleSelectPlan = (e, form) => {
        const { translate, showNotification } = props
        if (!payload.selectedPlan && !payload.voucher) {
            showNotification(translate('resources.subscriptions.no_selected_plan'), 'warning')
        } else {
            setPage(ATTACH_DEVICE, form, payload)
        }
    }


    const handleChangeSelectPlan = (selectedPlan) => {
        const payloadTmp = payload
        payloadTmp.selectedPlan = selectedPlan
        payloadTmp.voucher = null
        setPayload(payloadTmp)
        setReRenderSelectPlan(selectedPlan ? selectedPlan.plan_id : '0')
    }

    const handleChangeVoucher = e => {
        const { translate, showNotification } = props
        const code = e.target.value.trim()
        if (code.length === 10) {
            searchVoucher(code)
                .then(response => {
                    const { json } = response
                    const payloadTmp = payload
                    payloadTmp.selectedPlan = null
                    payloadTmp.voucher = json
                    setPayload(payload)
                })
                .catch(response => {
                    switch (response.status) {
                        case 400:
                            showNotification(
                                translate('resources.subscriptions.voucher_already_burnt'),
                                'warning'
                            )
                            break
                        case 404:
                            showNotification(
                                translate('resources.subscriptions.voucher_not_found'),
                                'warning'
                            )
                            return
                        default:
                            showNotification(
                                `${translate('resources.internal_error')}: ${response.body.description} (${response.status
                                })`,
                                'error'
                            )
                            return
                    }
                })
        }
    }

    const setPage = (pageNumberTmp, form = {}, data = {}) => {
        const overwriteMerge = (destinationArray, sourceArray) => sourceArray
        const payloadTmp = deepmerge(payload, data, { arrayMerge: overwriteMerge })
        setPayload(payloadTmp)
        setForm(form)
        setPageNumber(pageNumberTmp)
    }

    const renderForm = (props) => {

        const { device } = payload
        switch (pageNumber) {
            case CHECK_SUBSCRIPTION:

                return <CheckSubscription key={reRenderCheckSubscription} onSubmit={canCancelSubscription(payload.subscriptions) ? handleCancelSubscription : handleCheckSubscription} subscriptions={payload.subscriptions} nextPage={canCancelSubscription(payload.subscriptions) ? handleCancelSubscription : handleCheckSubscription}   {...props} />

            case ATTACH_DEVICE:
                return (
                    <Elements stripe={stripe}>
                        <AttachDevice
                            onSubmit={(e, values) => handleAttachDevice(e, values)}// ?? 
                            nextPage={(e, values) => handleAttachDevice(e, values)}
                            previousPage={() =>
                                hasActiveSubscription(device)
                                    ? setPage(CHECK_DEVICE)
                                    : setPage(SELECT_PLAN)
                            }
                            value={payload}
                            stripe={stripe}
                            saving={saving}
                            handleSaving={value => handleSaving(value)}
                            {...props}
                        />
                    </Elements>

                )
            case SELECT_PLAN_TYPE:
                return (
                    <SelectPlanType
                        key={reRenderSelectPlanType}
                        onSubmit={form => handleSelectPlanType(form)}
                        nextPage={form => handleSelectPlanType(form)}
                        previousPage={() => setPage(CHECK_SUBSCRIPTION)}
                        value={payload}
                        onChangePlanType={value => handleChangeSelectPlanType(value)}
                        {...props}
                    />
                )
            case SELECT_PLAN:
                return (
                    <SelectPlan
                        key={reRenderSelectPlan}
                        onSubmit={form => handleSelectPlan(form)}
                        nextPage={form => handleSelectPlan(form)}
                        previousPage={() => setPage(SELECT_PLAN_TYPE)}
                        checkSubscriptionPage={() => setPage(CHECK_SUBSCRIPTION)}
                        value={payload}
                        onChangePlan={value => handleChangeSelectPlan(value)}
                        onChangeVoucher={value => handleChangeVoucher(value)}
                        selectedPlan={payload.selectedPlan}
                        {...props}
                    />
                )
            default:
                return null;
        }
    }


    return (
        <Create {...props} className={props.classes.main} title={'resources.devices.activate'} basePath={"/devices/:id/subscribe"} resource="devices">
            {renderForm(props)}
        </Create>
    )

}



const mapStateToProps = (state, props) => ({
    saving: props.saving || state.admin.saving,
})

const DeviceSubscribe = compose(
    translate,
    withStyles(styles),
    connect(
        mapStateToProps,
        { showNotification }
    )
)(_DeviceSubscribe)
export default DeviceSubscribe