import React, { Component } from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
//material
import { withStyles } from '@material-ui/core/styles'
import { green ,red, orange } from '@material-ui/core/colors'
import { Chip } from '@material-ui/core'
import {
    IconButton,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Menu,
    MenuItem,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
//react-admin
import { translate } from 'react-admin'
//ticatag
import { getTiFizHPActivityStatus, hasActiveSubscription, hasRolloverDetected, hasMotionDetected, hasWriteAccess, isSuperAdmin, isTiFizHP } from '../../../helper/resourceHelper'

import { hasAlertFired, hasLocation, sVars } from '../../../utils/utils'
//icons
import { getAvatar, SmallAvatar } from '../../../utils/avatar'
import ChangeIcon from './ChangeIcon'

const styles = theme => ({
    listItem: {
        padding: '8px',
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.background.rightbarBorder,
        borderStyle: 'solid',
    },
    listItemSelected: {
        padding: '8px',
        backgroundColor: theme.palette.background.active,
        borderLeftWidth: 2,
        borderLeftColor: theme.palette.background.activemenuborder,
        borderStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.background.rightbarBorder,
    },
    listItemText: {
        paddingRight: 0,
    },
    listItemTextSelected: {
        paddingRight: 0,
    },
    listItemSecondaryAction: {
        top: '30%',
    },
    text: {
        textTransform: 'uppercase',
        fontSize: 10,
        height: 24,
    },
    rollover: {
        marginLeft: 20
    },
    ok: { backgroundColor: green[500], color: 'white' },
    nok: { backgroundColor: red[500], color: 'white' },
})

const getTiFizHPStatusClasses = (device, classes) => {
    let res = [classes.text]

    if(getTiFizHPActivityStatus(device) === 'moving'){
        res.push(classes.ok)
    } else {
        res.push(classes.nok)
    }
    return res;
}

const getSecondaryText = (classes, device, translate) => {
    const displayTimestampOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    }
    if (hasActiveSubscription(device) || isSuperAdmin()) {
        if (!hasLocation(device)) {
            return <span style={{ color: orange['500'] }}>{translate('resources.devices.no_location')}</span>
        }

        const { formatted_address = '' } = device.last_location
        const latitude = Math.round(device.last_location.latitude * 100000) / 100000
        const longitude = Math.round(device.last_location.longitude * 100000) / 100000

        return (
            <div>
                {isTiFizHP(device) && 
                    <div>
                        <Chip className={getTiFizHPStatusClasses(device, classes)}
                        label={translate(`resources.devices.activity.${getTiFizHPActivityStatus(device)}`)}/>
                        
                        {hasRolloverDetected(device) &&
                                <Chip className={[classes.nok, classes.text, classes.rollover]}
                                label={translate(`resources.devices.rollover.${device.last_rollover.status}`)}/>
                        }
                        <br />
                    </div>
                }
                {device.last_motion &&
                    <div>
                        <Chip className={getTiFizHPStatusClasses(device, classes)}
                            label={translate(`resources.devices.activity.${device.last_motion.status}`)}/>
                        <br />
                    </div>
                }           
                <small>
                    {new Date(device.last_location.timestamp).toLocaleDateString(
                        'fr-FR',
                        displayTimestampOptions
                    )}
                    {formatted_address.length > 0 && <br />}
                    {formatted_address}
                    {formatted_address.length === 0 && <br />}
                    {formatted_address.length === 0 && `${latitude}, ${longitude}`}
                </small>
            </div>
        )
    } else {
        return <span style={{ color: red['500'] }}>{translate('resources.devices.no_subscription')}</span>
    }
}

class menuItem extends Component {
    state = {
        anchorEl: null,
    }

    handleActionsClick = event => {
        this.setState({ anchorEl: event.currentTarget })
    }

    handleActionsClose = () => {
        if (sVars.leafletMap.current) sVars.savedZoom = sVars.leafletMap.current.leafletElement.getZoom()

        this.setState({ anchorEl: null })
    }

    getRightIconMenu = () => {
        const { onSelect, record, translate, updateIcon, updateStatus, acknowledgeRollover, acknowledgeMotion } = this.props

        if (isSuperAdmin() || hasActiveSubscription(record)) {
            const { anchorEl } = this.state
            return (
                <div>
                    <IconButton
                        aria-label="More"
                        aria-owns={anchorEl ? 'menu-item-actions' : null}
                        aria-haspopup="true"
                        onClick={this.handleActionsClick}
                    >
                        <MoreVert />
                    </IconButton>
                    <Menu
                        id="menu-item-actions"
                        anchorEl={this.state.anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleActionsClose}
                    >
                        {hasAlertFired(record) &&
                            hasWriteAccess() && (
                                <MenuItem
                                    key="acknowledgeAlert"
                                    onClick={() => {
                                        updateStatus(record.id, record)
                                        this.handleActionsClose()
                                    }}
                                >
                                    {translate('resources.devices.labels.acknowledgeAlert')}
                                </MenuItem>
                            )}
                        {hasRolloverDetected(record) &&
                            hasWriteAccess() && (
                                <MenuItem
                                    key="acknowledgeRollover"
                                    onClick={() => {
                                        acknowledgeRollover(record)
                                        this.handleActionsClose()
                                    }}
                                >
                                    {translate('resources.devices.labels.acknowledgeRollover')}
                                </MenuItem>
                            )
                        }
                        {hasMotionDetected(record) &&
                            hasWriteAccess() && (
                                <MenuItem
                                    key="acknowledgeMotion"
                                    onClick={() => {
                                        acknowledgeMotion(record)
                                        this.handleActionsClose()
                                    }}
                                >
                                    {translate('resources.devices.labels.acknowledgeMotion')}
                                </MenuItem>
                            )
                        }                        
                        <MenuItem
                            key="locationsHistory"
                            component={Link}
                            to={'/locations/' + record.id + '/history'}
                            onClick={() => {
                                onSelect(record)
                                this.handleActionsClose()
                            }}
                        >
                            {translate('resources.devices.labels.locationsHistory')}
                        </MenuItem>
                        {hasWriteAccess() &&
                        <ChangeIcon 
                            updateIcon={updateIcon} 
                            icon={record.icon ? record.icon : getAvatar(record)} 
                            size={localStorage.getItem(`${record.id}_icon_size`) ? localStorage.getItem(`${record.id}_icon_size`) : "medium"} 
                            {...this.props} />}
                    </Menu>
                </div>
            )
        } else {
            return null
        }
    }

    noLocalisation(device) {
        if (!device.last_location && (!device.latitude || !device.longitude)) {
            return true
        }
        return false
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            nextProps.record !== this.props.record ||
            nextProps.selected !== this.props.selected ||
            this.state.anchorEl !== nextState.anchorEl
        )
            return true

        return false
    }

    render(history) {
        const { disabled = false, record, classes, onClick, selected, translate } = this.props

        if (!record) return <div />

        return (
            <ListItem
                button
                key={record.id}
                onClick={() => onClick(record)}
                disabled={this.noLocalisation(record) || disabled}
                className={selected ? classNames(classes.listItemSelected) : classNames(classes.listItem)}
            >
                <SmallAvatar src={getAvatar(record)} />
                <ListItemText primary={record.name.toUpperCase()} secondary={getSecondaryText(classes, record, translate)} />
                <ListItemSecondaryAction className={classNames(classes.listItemSecondaryAction)}>
                    {this.getRightIconMenu(history)}
                </ListItemSecondaryAction>
            </ListItem>
        )
    }
}

menuItem.propTypes = {
    classes: PropTypes.object.isRequired,
    disabled: PropTypes.boolean,
    index: PropTypes.number.isRequired,
    record: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired,
    translate: PropTypes.func.isRequired,
    updateIcon: PropTypes.func.isRequired,
    updateStatus: PropTypes.func.isRequired,
    acknowledgeRollover: PropTypes.func.isRequired,
    acknowledgeMotion: PropTypes.func.isRequired,
}

const enhance = compose(
    withStyles(styles),
    translate
)

export default enhance(menuItem)
