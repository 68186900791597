import React, { useState } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'
import { SaveButton, Toolbar, translate, TextInput, required } from 'react-admin'
import { withStyles } from '@material-ui/core/styles'
import { VGrid } from '../../../ui/Grid'
import {

    Grid,
    Typography,
} from '@material-ui/core'

import style from 'jss-material-ui'

import { WizardForm } from '../../../ui/form/WizardForm'
import PreviousButton from '../../../ui/button/PreviousButton'

const styles = theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            padding: '0 1em 1em 1em',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0 1em 5em 1em',
        },
    },
    control: {
        padding: theme.spacing(2),
    },
    card: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
        height: 151,
    },
    gridItem: {
        textAlign: 'center',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    tableDevice: {
        maxWidth: 250,
    },
    tableFop: {
        maxWidth: 650,
    },
    tableSubscription: {
        maxWidth: 400,
    },
    tableCell: {
        borderBottom: 0,
        fontSize: '0.875rem',
        fontWeight: 400,
    },
    offer: {
        border: `1px solid ${theme.palette.primary.main}`,
        padding: 30,
    },
})



const DeviceCreateToolbar = props => (
    <Toolbar {...props}>
        <PreviousButton label={props.translate('ra.navigation.next')} onClick={props.previousPage} />
        <SaveButton label={props.translate('resources.subscriptions.add_subscription')} handleSubmitWithRedirect={props.handleSubmitForm} submitOnEnter={true} saving={props.saving} />
    </Toolbar>
)


const AttachDevice = (props) => {


    const { previousPage, value, saving, translate, classes } = props
    const { voucher } = value



    const handleSubmitForm = () => {

        const { voucher } = props.value

        if (voucher) {
            props.nextPage()
        }
    }




    return (
        <WizardForm
            {...props}
            onSubmit={handleSubmitForm}
            nextPage={handleSubmitForm}
            submitOnEnter={true}
            toolbar={<DeviceCreateToolbar {...props} handleSubmitForm={handleSubmitForm} previousPage={previousPage} saving={saving} />}
            redirect="list"
            saving={saving}
        >
            <VGrid>
                <Typography align="center" paragraph={true} variant={'h5'}>
                    {translate('resources.devices.review_info').toUpperCase()}
                </Typography>
            </VGrid>
            <Grid container className={classes.root} spacing={0}>

                {voucher && (
                    <Grid item xs={12} className={classes.offer}>
                        <Typography variant="h5" component="h2">
                            {translate('resources.subscriptions.voucher_name', {
                                quantity: voucher.plan.frequency_interval,
                            })}
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            {translate('resources.subscriptions.voucher_title', {
                                voucher_code: voucher.code,
                            })}
                        </Typography>
                    </Grid>
                )}

            </Grid>
        </WizardForm>
    )
}




export default withStyles(styles)(translate(props => <Form {...props} component={AttachDevice} />))


