import React from 'react'
import { Link } from 'react-admin'

export const LinkFullNameField = props => (
    <Link label={props.label} to={`/users/${props.record.id}`}>
        <FullNameField {...props} />
    </Link>
)

LinkFullNameField.defaultProps = {
    source: 'user_id',
    addLabel: true,
}

export const fullName = ({ record = {} }) => {
    const { email = '', first_name = '', last_name = '' } = record
    let value = ``

    if (first_name.length === 0 && last_name.length === 0) {
        value = email
    } else {
        value = `${first_name} ${last_name}`
    }

    return value
}

export const FullNameField = props => {
    return (
        <div
            style={{
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
            }}
        >
            {fullName(props)}
        </div>
    )
}
