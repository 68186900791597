import React from 'react'
import { Card, Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import style from 'jss-material-ui'
import { orange } from '@material-ui/core/colors/index'
import { defaultTheme, Themes } from './theme'
//import defaultTheme from './theme'

import { AppBar, Layout, MenuItemLink, translate, UserMenu } from 'react-admin'
import { ThemeMenu, LanguageMenu } from './colorsAndInt'

import { AccountIcon } from '../pages/account'
import { ConfigurationIcon } from '../pages/configuration'
import { FopIcon } from '../pages/fop'
import { InvoiceIcon } from '../pages/invoice'
import MenuItem from '@material-ui/core/MenuItem';
import HelpIcon from '@material-ui/icons/HelpOutline'
import { withStyles } from '@material-ui/core/styles'
import Logo from './logo'
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from '@material-ui/core/Toolbar';//   
import { changeConfig, CHANGE_THEME } from '../pages/configuration/actions'


export const Title = style(Typography)((theme, { fontSize, warning }) => ({
    root: {
        margin: 0,
        padding: 0,
        fontSize: 12,
        color: '#2196f3'
    },
}))

export const TitlePlan = style(Card)((theme, { fontSize, warning }) => ({
    root: {
        fontWeight: 600,
        margin: 0,
        padding: 0,
        boxShadow: `0 3px 2px -2px ${theme.palette.secondary.light} !important`,
        color: warning === null ? orange['500'] : '#222',
        fontSize: fontSize !== undefined ? fontSize : 12,
        marginTop: 10,
        marginBottom: 10,
    },
}))

export const Text = style(Typography)((theme, { medium, primary, small, warning }) => ({
    root: {
        color: warning === null ? orange['500'] : primary ? theme.color.primary.contrastText : undefined,
        fontSize: small ? 10 : medium ? 12 : 11,
    },
}))

const SMenuItem = style(MenuItem)((theme) => ({
    root: {
        color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.54)' : undefined,
        display: 'flex',
        alignItems: 'flex-start',
    },
}))

const MyUserMenuStyles = theme => ({
    icon: {
        paddingRight: '1.2em',
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
    },
    menuItemLink: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
    },


})


const MyUserMenu = withStyles(MyUserMenuStyles)(translate(({ classes, ...props }) => {
    return (
        <UserMenu {...props} >

            <MenuItemLink
                to={`/account/${localStorage.getItem('userId')}`}
                primaryText={props.translate('resources.common.account')}
                leftIcon={<AccountIcon />}
                className={classes.menuItemLink}
            />
            <MenuItemLink
                to={`/configuration`}
                primaryText="Configuration"
                leftIcon={<ConfigurationIcon />}
                className={classes.menuItemLink}
            />
            <MenuItemLink
                key='fops'
                to={`/fops`}
                primaryText={props.translate('resources.fops.name', { smart_count: 2, })}
                leftIcon={<FopIcon />}
                className={classes.menuItemLink}
            />
            <MenuItemLink
                key='invoices'
                to={`/invoices`}
                primaryText={props.translate('resources.invoices.name', { smart_count: 2, })}
                leftIcon={<InvoiceIcon />}
                className={classes.menuItemLink}
            />
            <SMenuItem component="a" href="https://ticatag.atlassian.net/jira/servicedesk/projects/SUPPORT" target="_blank" className={classes.menuItemLink}>
                <span className={classes.icon}>
                    <HelpIcon />
                </span>
                {props.translate('resources.common.help')}
            </SMenuItem>
        </UserMenu>
    )
}));
const useStyles = makeStyles((theme) => ({
    menu: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginBottom: '20px',
        //alignItems: 'flex-end'
        justifyContent: 'space-between',



    },
    logo: {
        flex: 1,
        alignSelf: 'center',
        minHeight: 0

    },
    title: {
        flex: 1,
        marginLeft: -5,
        width: 'auto',

    },





}));

/*
const MyAppBar = (props) => {
    const classes = useStyles();

    return (<AppBar className={classes.menu} {...props} userMenu={<MyUserMenu className={classes.menu} />}>
        <Typography
            variant="h6"
            color="inherit"
            className={classes.title}
            id="react-admin-title"
        />

        <LanguageMenu />


    </AppBar>);
}

--------------- Version with logo---------------------
---------------------------------------------------------
*/


const MyAppBar = (props) => {
    const classes = useStyles();
    return (<AppBar className={classes.menu} {...props} userMenu={<MyUserMenu className={classes.menu} />}>
        <Typography
            variant="h6"
            color="inherit"
            className={classes.title}
            id="react-admin-title"
        />
        <Toolbar className={classes.logo}>
            <Logo />
        </Toolbar>
        <LanguageMenu />
    </AppBar>);
}




const MyLayout = (props) => {

    return (<Layout  {...props} appBar={MyAppBar} marginBottom="100" />);
}

export default connect(
    state => ({

        theme: state.config.theme ? Themes[state.config.theme] : defaultTheme,

    }),
    {}
)(MyLayout);



