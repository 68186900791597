import style from 'jss-material-ui'
import { Card, Paper, Typography } from '@material-ui/core'

export const Card100 = style(Card)({
    root: {
        display: 'block',
        height: 'inherit',
        overflow: 'auto',
        width: '100%',
    },
})

export const PaperWhite = style(Paper)({
    root: {
        backgroundColor: '#fff',
        marginTop: 20,
        marginBottom: 20,
        marginLeft: 10,
        marginRight: 10,
        padding: 6,
    },
})

export const Paper99White = style(Paper)({
    root: {
        backgroundColor: '#fff',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        marginBottom: 20,
        marginTop: 20,
        marginLeft: 10,
        marginRight: 10,
    },
})

const GridCore = (
    theme,
    { border, mAround, mBetween, mEnd, mEvenly, mStart, noflex, noflexwrap, overflow, sEnd, sStart, sStrech }
) => ({
    alignItems: sStart ? 'flex-start' : sStrech ? 'stretch' : sEnd ? 'flex-end' : 'center',
    border: border === true ? '1px solid #eee' : 'none',
    display: 'flex',
    flex: noflex ? null : '0 0 auto',
    flexDirection: 'row',
    flexWrap: noflexwrap ? null : 'wrap',
    justifyContent: mAround
        ? 'space-around'
        : mEvenly
            ? 'space-evenly'
            : mStart
                ? 'flex-start'
                : mBetween
                    ? 'space-between'
                    : mEnd
                        ? 'flex-end'
                        : 'center',
})

export const HGrid = style('div')((theme, props) => ({
    root: {
        ...GridCore(theme, props),
    },
}))

export const HFlexGrid = style('div')((theme, props) => ({
    root: {
        ...GridCore(theme, props),
        width: '100%',
        height: '100%',
        flex: '1 0 auto',
    },
}))

export const H100Grid = style('div')((theme, props) => ({
    root: {
        ...GridCore(theme, props),
        width: '100%',
        flex: '1 0 auto',
    },
}))

export const H300Grid = style('div')((theme, props) => ({
    root: {
        ...GridCore(theme, props),
        flex: '1 0 auto',
        width: 300,
    },
}))

export const HFlex300Grid = style('div')((theme, props) => ({
    root: {
        ...GridCore(theme, props),
        flex: '1 0 auto',
        width: 300,
        height: '100%',
    },
}))

export const H500Grid = style('div')((theme, props) => ({
    root: {
        ...GridCore(theme, props),
        flex: '1 0 auto',
        width: 600,
    },
}))

export const H800Grid = style('div')((theme, props) => ({
    root: {
        ...GridCore(theme, props),
        flex: '1 0 auto',
        width: 1080,
    },
}))

export const VGrid = style('div')((theme, props) => ({
    root: {
        ...GridCore(theme, props),
        flexDirection: 'column',
    },
}))

export const Scroll = style('div')((theme, props) => ({
    root: {
        display: 'block',
        overflow: 'auto',
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}))

export const V100Grid = style('div')((theme, props) => ({
    root: {
        ...GridCore(theme, props),
        flexDirection: 'column',
        flex: '1 0 auto',
        width: '100%',
    },
}))

export const VFlexGrid = style('div')((theme, props) => ({
    root: {
        ...GridCore(theme, props),
        flexDirection: 'column',
        flex: '1 0 auto',
        width: '100%',
        height: '100%',
    },
}))

export const H310Grid = style('div')((theme, props) => ({
    root: {
        ...GridCore(theme, props),
        flexDirection: 'row',
        flex: '0 0 auto',
        width: 310,
        height: '100%',
    },
}))

export const H600FlexGrid = style('div')((theme, props) => ({
    root: {
        ...GridCore(theme, props),
        flexDirection: 'row',
        flex: '1 1 auto',
        width: 300,
        height: '100%',
    },
}))

export const Divider = style('hr')({
    root: {
        flex: '1 1 auto',
        width: '100%',
        border: '1px solid #eee',
        marginBottom: 20,
    },
})

export const Space = style('br')((theme, { x2, x3 }) => ({
    root: {
        border: 'none',
        flex: '1 1 auto',
        width: '100%',
        height: x3 !== undefined ? 150 : x2 !== undefined ? 100 : 50,
    },
}))

export const TitleForm = style(Typography)({
    root: {
        align: 'center',
        top: 10,
        paragraph: true,
        variant: 'h5',
    },
})

export const Title100Form = style(Typography)((theme, props) => ({
    root: {
        fontWeight: 700,
        textAlign: 'center',
        width: '100%',
        marginTop: props.variant ? 14 : 4,
        marginBottom: props.variant ? 14 : 4,
    },
}))

export const Title100Chart = style(Typography)((theme, props) => ({
    root: {
        fontWeight: 800,
        fontSize: '1.2rem',
        textAlign: 'left',
        width: '100%',
        paddingTop: 20,
        marginBottom: 10,
    },
}))


export const SubTitle100Form = style(Typography)((theme, props) => ({
    root: {
        fontWeight: 400,
        fontSize: 12,
        textAlign: 'center',
        width: '100%',
        marginTop: props.variant ? 14 : 4,
        marginBottom: props.variant ? 14 : 4,
    },
}))

