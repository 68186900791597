import React from 'react'

import {
    Create,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    translate,
} from 'react-admin'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
    list: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        marginTop: 20,
    }
})

const validateRequired = [required('Champs obligatoire')]

const OrganizationCreate = translate(({ classes, translate, ...props }) => (
    <Create {...props} title={'resources.organizations.create'} className={classes.list}>
        <SimpleForm redirect="list">
            <TextInput
                label={translate('resources.organization.name')}
                source="name"
                translate={translate}
                validate={validateRequired}
            />
            <ReferenceInput
                label={translate('resources.organization.parent')}
                source="parent_organization_id"
                reference="organizations"
                translate={translate}
                validate={validateRequired}
                defaultValue={() => localStorage.getItem('organizationId')}
            >
                <SelectInput optionText="name" validate={validateRequired} />
            </ReferenceInput>

            <SelectInput label={translate('resources.organization.root')}
                source="root_organization_id"
                optionText="label"
                optionValue="root_organization_id"
                defaultValue={process.env.REACT_APP_ROOT_ORGANIZATION_ID}
                choices={[{ root_organization_id: process.env.REACT_APP_ROOT_ORGANIZATION_ID, label: 'TICATAG' }]}
                validate={validateRequired} />
            <TextInput label={translate('resources.organization.description')} source="description" />
        </SimpleForm>
    </Create>
))

export default withStyles(styles)(OrganizationCreate)
