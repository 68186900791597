import React, { Component, useCallback } from 'react';
import { connect } from 'react-redux';
import { SaveButton, showNotification, translate, useNotify, useRedirect } from 'react-admin';
import compose from 'recompose/compose'
import { addFopSepa } from '../../../../rest/restClient'
import { useStripe, useElements, IbanElement } from '@stripe/react-stripe-js';

const SaveSepaButtonView = (props) => {

    const stripe = useStripe();
    const elements = useElements();
    const notify = useNotify();
    const redirectTo = useRedirect();
    const { basePath } = props;

    const handleClick = useCallback(
        async (values, redirect) => {

            if (!stripe || !elements) {
                notify('no stripe or no element')
                return;
            }
            if (!values || !values.accountholderName || !values.email) {
                notify('Name missing', { type: 'warning' })
                return;
            }
            if (!values || !values.email) {
                notify('Email missing', { type: 'warning' })
                return;
            }

            const iban = elements.getElement(IbanElement);

            const accountholderName = values.accountholderName;
            const email = values.email;
            stripe
                .createToken(iban, {
                    currency: 'eur',
                    account_holder_name: accountholderName.value,
                    account_holder_type: 'individual',
                })
                .then(payload => {

                    addFopSepa(payload.token.id)
                        .then(async response => {

                            const result = await stripe.confirmSepaDebitSetup(response.json.client_secret, {
                                payment_method: {
                                    sepa_debit: iban,
                                    billing_details: {
                                        name: accountholderName,
                                        email: email,
                                    },
                                }
                            });

                            if (result.error) {
                                notify(result.error.message, { type: 'warning' })

                            } else {

                                notify(result, { type: 'success' })
                                redirectTo("list", "/fops");

                            }
                        })
                        .catch(response => {

                            notify(translate('resources.subscriptions.add_fop_error'), { type: 'warning' })
                            redirectTo("list", "/fops");
                        })
                })
                .catch(response => {
                    showNotification(translate('resources.subscriptions.add_card_error'), 'warning')
                    redirectTo("list", "/fops");
                })

        },
        [notify, redirectTo, basePath]
    );


    return (
        <SaveButton
            {...props}
            onSave={handleClick}
            label="Set up SEPA Direct Debit"


        />
    );

}

export const SaveSepaButton = compose(
    connect(
        undefined,
        { showNotification }
    ),
    translate,
)(SaveSepaButtonView)

