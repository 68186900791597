import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import { List, ListItem, ListItemText, ListSubheader } from '@material-ui/core'
import Scrollbar from '@opuscapita/react-perfect-scrollbar'
import { withStyles } from '@material-ui/core/styles'
import { translate } from 'react-admin'
import * as ReactDOM from 'react-dom'
import { getId } from '../../utils/utils'

export const styles = theme => ({
    list: {
        display: 'flex',
        flexDirection: 'column',
        flex: '0 0 auto',
        height: '100%',
        width: 310,
        backgroundColor: theme.palette.background.rightbar,
        overflow: 'hidden',
        border: 'solid 1px #d9d9d9',
        borderBottom: 'none',
        marginRight: -4,
        padding: 0,
    },
    listItems: {
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: 6,
    },
    listHeader: {
        width: '100%',
    },
})

class Menu extends React.Component {
    constructor(props) {
        super(props)
        this.clickedDeviceId = null
        this.scrollbar = null
        this.scrollTo = null
        this.scrollOffsetTop = 0
    }
    static deviceName = ''

    handleClick = device => {
        this.clickedDeviceId = getId(device)
        this.props.onSelect(device)
    }

    componentDidUpdate() {
        const { idSelected } = this.props

            if (this.scrollbar && idSelected !== this.clickedDeviceId) {
            if (this.scrollTo) {
                const domItem = ReactDOM.findDOMNode(this.scrollTo)
                const topItem = domItem ? domItem.offsetTop : undefined
                if (topItem) {
                    this.scrollbar.setScrollTop(topItem - this.scrollOffsetTop)
                }
            }
        }
    }

    handleScrollY({ offsetTop }) {
        this.scrollOffsetTop = offsetTop
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.data !== this.props.data || nextProps.idSelected !== this.props.idSelected) return true

        return false
    }

    render() {
        const { ids, idSelected = '', data, classes, noSelect, onSelect, updateIcon, updateStatus, acknowledgeRollover, acknowledgeMotion, translate } = this.props
        const devicesOrLocation = this.props.lastLocation
            ? 'resources.devices.name'
            : 'resources.devices.location'
        const listItems =
            ids.length > 0
                ? ids.map((id, idx) => {
                      return (
                          <this.props.menuItem
                              disabled={noSelect && id !== idSelected}
                              index={idx}
                              key={'item-' + id}
                              onClick={this.handleClick.bind(this)}
                              record={data[id]}
                              ref={id === idSelected ? scrollTo => (this.scrollTo = scrollTo) : undefined}
                              selected={id === idSelected}
                              updateStatus={updateStatus}
                              updateIcon={updateIcon}
                              acknowledgeRollover={acknowledgeRollover}
                              acknowledgeMotion={acknowledgeMotion}
                              onSelect={onSelect}
                          />
                      )
                  })
                : []

        return (
            <List component="nav" dense={true} className={classes.list}>
                <ListSubheader className={classes.listHeader}>
                    {
                        <ListItem button className={classes.listHeader} disabled={true}>
                            <ListItemText inset>
                                {ids.length}{' '}
                                {translate(devicesOrLocation, {
                                    smart_count: ids.length > 1 ? 2 : 1,
                                })}
                            </ListItemText>
                        </ListItem>
                    }
                </ListSubheader>
                {ids.length > 0 && (
                    <Scrollbar
                        onScrollY={this.handleScrollY.bind(this)}
                        className={classes.listItems}
                        ref={scrollBar => (this.scrollbar = scrollBar)}
                    >
                        {listItems}
                    </Scrollbar>
                )}
            </List>
        )
    }
}

Menu.propTypes = {
    data: PropTypes.object.isRequired,
    idSelected: PropTypes.string,
    ids: PropTypes.arrayOf(PropTypes.any).isRequired,
    lastLocation: PropTypes.bool,
    menuItem: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    updateIcon: PropTypes.func.isRequired,
    updateStatus: PropTypes.func.isRequired,
    acknowledgeRollover: PropTypes.func.isRequired,
    acknowledgeMotion: PropTypes.func.isRequired,
}

Menu.defaultProps = {
    data: {},
    ids: [],
    idSelected: '',
    lastLocation: false,
}

export default compose(
    translate,
    withStyles(styles)
)(Menu)
