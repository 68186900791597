import { AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_LOGIN, AUTH_LOGOUT } from 'react-admin'
import { AUTHORITIES, ROLE_ORGA_USER } from './pages/configuration/actions'

export default (type, params) => {
    // called when the user attempts to log in < UserNotAlowedAlert />
    if (type === AUTH_LOGIN) {
        // accept all username/password combinations
        return Promise.resolve()
    }
    // called when the user clicks on the logout button
    if (type === AUTH_LOGOUT) {
        const authorization = 'Bearer ' + (localStorage.getItem('token') || '')
        return fetch(process.env.REACT_APP_AUTH_LOGOUT, {
            method: 'POST',
            headers: {
                Authorization: authorization,
            },
        })
            .then(() => {

                deleteAllCookiesData();

                setTimeout(function () {
                    window.location.href = process.env.REACT_APP_AUTH_URL
                }, 1000)

                return Promise.resolve()
            })
            .catch(error => {
                console.log(error)
            })
    }
    // called when the API returns an error
    if (type === AUTH_ERROR) {
        const { status } = params
        if (status === 401) {
            //|| status === 403
            localStorage.removeItem('userName')
            localStorage.removeItem('userId')
            localStorage.removeItem('token')
            localStorage.removeItem(AUTHORITIES)
            window.location.href = process.env.REACT_APP_AUTH_URL
            return Promise.reject()
        }
        return Promise.resolve()
    }
    // called when the user navigates to a new location
    if (type === AUTH_CHECK) {

        return localStorage.getItem('userId') && (localStorage.getItem('userAllowed') === "true")
            ? Promise.resolve()
            : (window.location.href = process.env.REACT_APP_AUTH_URL)

    }
    if (type === AUTH_GET_PERMISSIONS) {
        const role = localStorage.getItem(AUTHORITIES)
        return role ? Promise.resolve(role) : ROLE_ORGA_USER
    }
    return Promise.reject('Unknown method')
}

export async function waitLogged() {
    let isLogged = false

    try {
        let count = 10

        while (isLogged === null && count > 0) {
            await sleep(500)

            isLogged = await localStorage.getItem('userId')
            count--
        }
    } catch (error) { }
    return isLogged ? true : false
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

function deleteAllCookiesData() {

    sessionStorage.clear()

    localStorage.clear()


    caches.keys().then(keys => {
        keys.forEach(key => caches.delete(key))
    })


    indexedDB.databases().then(dbs => {
        dbs.forEach(db => indexedDB.deleteDatabase(db.name))
    })

    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }




}
