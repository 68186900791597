import React from 'react'
import { TopToolbar, FunctionField, Show, SimpleShowLayout, TextField, translate } from 'react-admin'
import { ROLE_ORGA_ADMIN, ROLE_SUPER_ADMIN, ROLE_ORGA_USER, ROLE_USER } from '../../configuration/actions'
import { containerTabbedHeight } from '../../../helper/resourceHelper'
import { withStyles } from '@material-ui/core/styles'

const AccountActions = () => (
    <TopToolbar />
)

const AccountTitle = () => {
    return <span />;
};
const styles = theme => ({
    list: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
    }
})


const AccountShow = translate(({ translate, classes, ...props }) => {
    return (
        <Show basePath="configuration" resource="account" id={1} elevation={0} {...props} title={<AccountTitle />} actions={<AccountActions />} className={classes.list}>
            <SimpleShowLayout {...props} style={{ minHeight: containerTabbedHeight() }}>
                <TextField label={translate('resources.users.email')} source="email" />
                <TextField label={translate('resources.users.firstName')} source="first_name" />
                <TextField label={translate('resources.users.lastName')} source="last_name" />
                <FunctionField
                    label={translate('resources.users.role')}
                    render={record => {
                        switch (record.role) {
                            case ROLE_ORGA_ADMIN:
                                return translate('resources.role.admin')
                            case ROLE_SUPER_ADMIN:
                                return translate('resources.role.superadmin')
                            case ROLE_ORGA_USER:
                                return translate('resources.role.member')
                            case ROLE_USER:
                                return translate('resources.role.owner')
                            default:
                                return translate('resources.role.user')
                        }
                    }}
                />
                <FunctionField
                    label={translate('resources.organization.organization')}
                    render={() => localStorage.getItem('organizationName')}
                />
            </SimpleShowLayout>
        </Show>
    )
})
export default withStyles(styles)(AccountShow)
