
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';




const StyledTextComponent = ({ text }) => {
    return (
        <div>
            {text.split('\n').map((paragraph, index) => (
                <p key={index}>
                    {paragraph.split('*').map((line, i) => (
                        i % 2 === 0 ? (
                            <span key={i}>{line}</span>
                        ) : (
                            <strong key={i}>{line}</strong>
                        )
                    ))}
                </p>
            ))}
        </div>
    );
};



export const AqiHelpLegendComponent = ({ translate }) => {
    function createData(refLevel, airInfo, tvoc, airQuality, color) {
        return { refLevel, airInfo, tvoc, airQuality, color };
    }

    const rows = [
        createData(translate("resources.aqi.help_legend.level1"), translate("resources.aqi.help_legend.air_info_level1"), "<0.3", translate("resources.aqi.help_legend.air_quality_level1"), "#008000"),
        createData(translate("resources.aqi.help_legend.level2"), translate("resources.aqi.help_legend.air_info_level2"), "0.3-1.0", translate("resources.aqi.help_legend.air_quality_level2"), "#66b266"),
        createData(translate("resources.aqi.help_legend.level3"), translate("resources.aqi.help_legend.air_info_level3"), "1.0-3.0", translate("resources.aqi.help_legend.air_quality_level3"), "#ffff00"),
        createData(translate("resources.aqi.help_legend.level4"), translate("resources.aqi.help_legend.air_info_level4"), "3.0-10.0", translate("resources.aqi.help_legend.air_quality_level4"), "#ffa500"),
        createData(translate("resources.aqi.help_legend.level5"), translate("resources.aqi.help_legend.air_info_level5"), ">10.0", translate("resources.aqi.help_legend.air_quality_level5"), "#ff0000"),
    ];

    return (

        <TableContainer component={Paper} sx={{ justifyContent: "center", justifyItems: "center", width: "100%", display: "flex", marginY: 5 }} >
            <Table sx={{ width: "90%" }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>{translate("resources.aqi.help_legend.head_ref_levels")}</TableCell>
                        <TableCell align="center">{translate("resources.aqi.help_legend.head_air_info")}</TableCell>
                        <TableCell align="center">{translate("resources.aqi.help_legend.head_tvoc_mg_m3")}</TableCell>
                        <TableCell align="center">{translate("resources.aqi.help_legend.head_air_quality")}</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.refLevel}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: row.color }}
                        >
                            <TableCell component="th" scope="row">
                                {row.refLevel}
                            </TableCell>
                            <TableCell align="center">{row.airInfo}</TableCell>
                            <TableCell align="center">{row.tvoc}</TableCell>
                            <TableCell align="center">{row.airQuality}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}