import React, { useCallback } from 'react';
import { useForm } from 'react-final-form';
import { CREATE, useCreate, SaveButton, useNotify, useRedirect, translate } from 'react-admin';





export const DeviceAddButton = translate(props => {


    const [create] = useCreate();
    const redirectTo = useRedirect();
    const notify = useNotify();
    const form = useForm();
    const { basePath, previousData, translate } = props;
    const handleSave = useCallback(
        (values, redirect) => {

            switch (values.technology) {
                case 'sigfox':
                    values.mac_address = values.sigfox_id
                    values.serial_number = values.sigfox_id
                    values.pac = values.sigfox_pac
                    values.type = 'unb'
                    break;
                case 'lora':
                    values.mac_address = values.dev_eui
                    values.serial_number = values.dev_eui
                    values.type = 'unb'
                    break;
                case 'sim':
                    values.mac_address = values.imei
                    values.serial_number = values.imei
                    values.type = 'unb'
                    break;
                case 'ble':
                    values.type = 'ibeacon'
                    break;
                default:
                    break;
            }
            values.status = 'inactive'


            create(
                {
                    payload: { data: values },
                    resource: 'devices',
                    fetch: CREATE,

                },
                {
                    type: 'devices',
                    onSuccess: () => {
                        notify(translate('resources.devices.add_new_device_succes'), 'info');
                        //redirectTo(false);
                        form.change('imei', undefined);
                        form.change('dev_eui', undefined);
                        form.change('sigfox_id', undefined);
                        form.change('mac_address', undefined);
                        form.change('secondary_mac_address', undefined);
                    },
                    onFailure: (error) => {
                        notify(error.message);
                        //redirectTo(false);

                    }
                }
            )



        },
        [create, notify, redirectTo]
    );

    // set onSave props instead of handleSubmitWithRedirect
    return <SaveButton {...props} onSave={handleSave} />;
});
