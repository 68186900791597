import React from 'react'

import style from 'jss-material-ui'
import { withStyles } from '@material-ui/core/styles'
import { translate } from 'react-admin'

import {
    FormControlLabel,
    FormGroup,
    Grid,
    MenuItem,
    Paper,

    TextField,
    Typography,
} from '@material-ui/core'

import Switch from '@mui/material/Switch'
import Slider from '@mui/material/Slider'
import Autosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'

import { MapContainer, TileLayer } from 'react-leaflet'

import 'leaflet/dist/leaflet.css'

import MapDraw from '../mapDraw'
import { V100Grid } from '../../../ui/Grid'

const styles = theme => ({
    container: {
        width: '100%',
    },
    address: {
        flexGrow: 1,
        position: 'relative',
        marginTop: 0,
        marginBottom: 20,
        width: '80%',
    },
    autocompleteInputDevice: {
        marginTop: 0
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 10000,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    button: {
        margin: '10px 24px',
        position: 'relative',
    },
    iconPaddingStyle: {
        paddingRight: '0.5em',
    },
})

function renderInput(inputProps) {
    const { classes, ref, ...other } = inputProps

    return (
        <TextField
            fullWidth
            InputProps={{
                inputRef: ref,
                classes: {
                    input: classes.input,
                },
                ...other,
            }}
        />
    )
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
    const label = suggestion.label ? suggestion.label : suggestion.name
    const matches = match(label, query)
    const parts = parse(label, matches)

    return (
        <MenuItem selected={isHighlighted} component="div">
            <div>
                {parts.map((part, index) => {
                    return part.highlight ? (
                        <span key={String(index)} style={{ fontWeight: 500 }}>
                            {part.text}
                        </span>
                    ) : (
                        <strong key={String(index)} style={{ fontWeight: 300 }}>
                            {part.text}
                        </strong>
                    )
                })}
            </div>
        </MenuItem>
    )
}

function renderSuggestionsContainer(options) {
    const { containerProps, children } = options

    return (
        <Paper elevation={2} {...containerProps} square>
            {children}
        </Paper>
    )
}

function getSuggestionValue(suggestion) {
    return suggestion.label ? suggestion.label : suggestion.name
}

const SMap = style(MapContainer)({
    root: {
        margin: 0,
        display: 'flex',
        flex: '1',
        flexDirection: 'row',
        height: 350,
        width: 350,
    },
})

const GeofenceForm = ({
    classes,
    radius,
    center,
    zoom,
    place,
    places,
    device,
    devices,
    handleDevicesFetchRequested,
    handleDevicesClearRequested,
    onDeviceSelected,
    handleDeviceChange,
    handlePlacesFetchRequested,
    handlePlacesClearRequested,
    onPlaceSelected,
    handlePlaceChange,
    handleRadiusChange,
    handleSwitchChange,
    active,
    onEntry,
    onExit,
    onEdited,
    translate,
}) => (
    <V100Grid>
        <Grid container className={classes.container}>
            <Grid item xs={12} sm={7}>
                <Typography variant="h6" gutterBottom>
                    {translate('resources.devices.name', { smart_count: 1 })}
                </Typography>
                <Autosuggest
                    theme={{
                        container: classes.address,
                        suggestionsContainerOpen: classes.suggestionsContainerOpen,
                        suggestionsList: classes.suggestionsList,
                        suggestion: classes.suggestion,
                    }}
                    renderInputComponent={renderInput}
                    suggestions={devices}
                    onSuggestionsFetchRequested={handleDevicesFetchRequested}
                    onSuggestionsClearRequested={handleDevicesClearRequested}
                    onSuggestionSelected={onDeviceSelected}
                    renderSuggestionsContainer={renderSuggestionsContainer}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={{
                        classes,
                        placeholder: translate('resources.geofences.device_search'),
                        value: device,
                        onChange: handleDeviceChange,
                    }}
                />
                <Typography variant="h6" gutterBottom>
                    {translate('resources.geofences.center')}
                </Typography>
                <Autosuggest
                    theme={{
                        container: classes.address,
                        suggestionsContainerOpen: classes.suggestionsContainerOpen,
                        suggestionsList: classes.suggestionsList,
                        suggestion: classes.suggestion,
                    }}
                    renderInputComponent={renderInput}
                    suggestions={places}
                    onSuggestionsFetchRequested={handlePlacesFetchRequested}
                    onSuggestionsClearRequested={handlePlacesClearRequested}
                    onSuggestionSelected={onPlaceSelected}
                    renderSuggestionsContainer={renderSuggestionsContainer}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={{
                        classes,
                        placeholder: translate('resources.geofences.enter_address'),
                        value: place,
                        onChange: handlePlaceChange,
                    }}
                />
                <Typography variant="h6" gutterBottom>
                    {translate('resources.geofences.fields.radius')}
                </Typography>
                <Grid container className={classes.container} spacing={8}>
                    <Grid item xs={12} sm={7}>
                        <Slider
                            value={radius}
                            aria-labelledby="label"
                            onChange={handleRadiusChange}
                            min={100}
                            max={50000}
                            step={50}
                        />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <Typography id="label">
                            {radius < 10000 ? `${radius} m` : `${radius / 1000} km`}
                        </Typography>
                    </Grid>
                </Grid>
                <FormGroup row>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={active}
                                onChange={handleSwitchChange('active')}
                                value="active"
                            />
                        }
                        label={translate('resources.geofences.fields.active')}
                    />
                </FormGroup>
                <Typography variant="h6" gutterBottom>
                    {translate('resources.notifications.name', { smart_count: 1 })}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    {translate('resources.geofences.trigger')}
                </Typography>
                <FormGroup row>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={onEntry}
                                onChange={handleSwitchChange('onEntry')}
                                value="onEntry"
                            />
                        }
                        label={translate('resources.geofences.fields.on_entry')}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={onExit}
                                onChange={handleSwitchChange('onExit')}
                                value="onExit"
                            />
                        }
                        label={translate('resources.geofences.fields.on_exit')}
                    />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={5}>
                <SMap key={(center && zoom && radius ? center.lat + center.lng + zoom + radius : 0).toString()} center={center} zoom={zoom} style={{ width: '100%', height: '100%' }}>
                    <TileLayer
                        attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <MapDraw onEdited={onEdited} editing={true} center={center} radius={radius} />


                </SMap>
            </Grid>

        </Grid>
    </V100Grid>
)

export default withStyles(styles)(translate(GeofenceForm))
