

import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { translate } from 'react-admin';
import compose from 'recompose/compose';

import { Card, Box, Typography } from '@mui/material'


const styles = theme =>
    createStyles({
        floating: {
            color: theme.palette.getContrastText(theme.palette.primary.main),
            margin: 0,
            top: 'auto',
            right: 20,
            bottom: 60,
            left: 'auto',
            position: 'fixed',
            zIndex: 1000,
        },
        floatingLink: {
            color: 'inherit',
        },
    });



const enhance = compose(
    translate,
    withStyles(styles)
);







const TiLockerConfInfo = ({ translate, ...props }) => {
    const { record } = props
    const lastUnlockRequest = record && record.configuration && record.configuration.unlock_request_date_time ? record.configuration.unlock_request_date_time : null;
    const lastGeolocationRequest = record && record.configuration && record.configuration.geolocation_upload_period_request_date_time ? record.configuration.geolocation_upload_period_request_date_time : null;
    const lastBikeSearchRequest = record && record.configuration && record.configuration.bike_search_request_date_time ? record.configuration.bike_search_request_date_time : null;


    return (<Card sx={{ display: 'flex', width: "100%", margin: 1 }}>

        <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "center", margin: 3, width: "80%", textAlign: "flex-start" }}>
            <Typography variant="h6" color="text.main"><strong>{translate("resources.devices.config.tilocker.history")}</strong> </Typography>
            <Box sx={{ display: 'flex', flexDirection: "row" }}>
                <Typography variant="body2" color="text.main">{translate("resources.devices.config.tilocker.unlock_request_date_time")}{" : "}</Typography>
                <Typography variant="body2" color="primary.main" ml={2}>{new Date(lastUnlockRequest).toLocaleString()}</Typography>
            </Box>


            <Box sx={{ display: 'flex', flexDirection: "row" }}>
                <Typography variant="body2" color="text.main">{translate("resources.devices.config.tilocker.geolocation_upload_period_request_date_time")}{" : "}</Typography>
                <Typography variant="body2" color="primary.main" ml={2}>{new Date(lastGeolocationRequest).toLocaleString()}</Typography>
            </Box>


            <Box sx={{ display: 'flex', flexDirection: "row" }}>
                <Typography variant="body2" color="text.main"> {translate("resources.devices.config.tilocker.bike_search_request_date_time")}{" : "}</Typography>
                <Typography variant="body2" color="primary.main" ml={2}>{new Date(lastBikeSearchRequest).toLocaleString()}</Typography>
            </Box>


        </Box>

    </Card >)
};


export default enhance(TiLockerConfInfo);



