
import React, { Fragment, cloneElement } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Chip } from '@material-ui/core'
import {
    ArrayField,
    BooleanField,
    TopToolbar,
    BulkDeleteButton,
    CreateButton,
    Datagrid,
    EditButton,
    FunctionField,
    List,
    ReferenceField,
    SingleFieldList,
    TextField,
    translate,
} from 'react-admin'
import { hasWriteAccess } from '../../../helper/resourceHelper'

const styles = theme => ({
    chip: {
        textTransform: 'uppercase',
        margin: 5,
    },
    list: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        marginTop: 20,

    }
})

const StringToLabelObject = ({ record, children, ...rest }) =>
    cloneElement(children, {
        record: { label: record },
        ...rest,
    })

const HookActions = ({ basePath, filterValues, onUnselectItems, permissions, resource, selectedIds }) => (
    <TopToolbar>
        {hasWriteAccess(permissions) && <Fragment>
            <BulkDeleteButton basePath={basePath} selectedIds={selectedIds} filterValues={filterValues} resource={resource} onUnselectItems={onUnselectItems} />
        </Fragment>}
        {hasWriteAccess(permissions) && <CreateButton basePath={basePath} />}
    </TopToolbar>
)


const HookList = ({ permissions, translate, classes, ...props }) => (
    <List
        {...props}
        title={translate('resources.hooks.name', { smart_count: 2 })}
        actions={<HookActions />}
        className={classes.list}
        perPage={25}
        filter={{
            includes:
                'organization_id',
        }}
    >
        <Datagrid>
            <ReferenceField
                allowEmpty={true}
                label={translate('resources.devices.name', { smart_count: 1 })}
                source="device_id"
                reference="devices"
                sortable={false}
            >
                <TextField source="name" />
            </ReferenceField>
            <ArrayField source="events">
                <SingleFieldList>
                    <StringToLabelObject>
                        <FunctionField
                            render={record => (
                                <Chip
                                    className={classes.chip}
                                    label={translate(`resources.hooks.${record.label}`)}
                                />
                            )}
                        />
                    </StringToLabelObject>
                </SingleFieldList>
            </ArrayField>
            <FunctionField
                label={'resources.hooks.fields.target_url'}
                render={record => (
                    record.target_url.length >= 50 ? record.target_url.substring(0, 49) + '...' : record.target_url
                )}
            />
            <TextField source="version"></TextField>
            <ReferenceField
                allowEmpty
                label={'resources.organization.organization'}
                source="organization_id"
                reference="organizations"
                sortable={false}>
                <TextField source="name" />
            </ReferenceField>
            <BooleanField
                source="active"
            />
            {hasWriteAccess(permissions) && <EditButton />}
        </Datagrid>
    </List>
)


export default withStyles(styles)(translate(HookList))