import React from 'react'
import PropTypes from 'prop-types'
import L from 'leaflet'
import './markerPulse'
import './markerPulse.css'
//react-google-maps
import MarkerClusterGroup from 'react-leaflet-markercluster'
import 'react-leaflet-markercluster/dist/styles.min.css';
//ticatag
import { Marker } from 'react-leaflet'
import TifizMarker from './marker1'
import {
    getId,
    getLatitude,
    getLongitude,
    hasLocation,
    isDeviceLocalisable,
} from '../../utils/utils'
import { blueIconUrl } from './icons/bluee'
import { blue12_12_IconUrl, blue24_24_IconUrl } from './icons/blue'


const MarkerSelectIcon = new L.Icon.Pulse({
    fillColor: 'blue',
    color: 'blue',
})

const MarkerDefaultIcon = new L.Icon({
    iconUrl: blueIconUrl,
    iconSize: [12, 12],
})

const getIconSize = size => {
    if (size === 'large') {
        return [48, 48]
    } else if (size === 'small') {
        return [12, 12]
    }
    return [24, 24]
}

const getIconUrl = device => {

    if (device.icon_name) {
        return device.icon_name
    } else if (localStorage.getItem(`${device.device_id}_icon_url`)) {

        return localStorage.getItem(`${device.device_id}_icon_url`)
    }
    return null;
}



const markerClusterer = (props) => {

    const renderMarker = (device, idSelected, index) => {
        if (isDeviceLocalisable(device)) {
            const { lastLocation, onClick, translate, updateFormattedAddress } = props
            const id = getId(device)
            return (
                <TifizMarker
                    icon={
                        id === idSelected
                            ? MarkerSelectIcon
                            : MarkerDefaultIcon
                    }
                    key={index}
                    index={index}
                    lastLocation={lastLocation}
                    onClick={onClick}
                    position={[getLatitude(device), getLongitude(device)]}
                    record={device}
                    selected={id === idSelected && idSelected.length > 0}
                    translate={translate}
                    updateFormattedAddress={updateFormattedAddress}

                />
            )
        } else {
            return <div key={index} />
        }
    }

    const { data, ids, idSelected, lastLocation } = props
    let disClustZoom = props.disableClusteringAtZoom
    if (ids.length === 0) {
        return (
            <MarkerClusterGroup key={disClustZoom * 200} disableClusteringAtZoom={disClustZoom} />
        )
    }
    return (
        <MarkerClusterGroup key={disClustZoom * 200} disableClusteringAtZoom={disClustZoom}>
            {lastLocation
                ? ids
                    .filter(idx => hasLocation(data[idx]))
                    .map((id, index) => renderMarker(data[id], idSelected, index))
                : ids.map((id, index) => renderMarker(data[id], idSelected, index))}
        </MarkerClusterGroup>

    )


}

markerClusterer.propTypes = {
    data: PropTypes.object,
    ids: PropTypes.array,
    isLoading: PropTypes.bool,
    idSelected: PropTypes.string,
    lastLocation: PropTypes.bool,
    disableClusteringAtZoom: PropTypes.number,
    onClick: PropTypes.func,
    translate: PropTypes.func.isRequired,
    updateFormattedAddress: PropTypes.func.isRequired,
}

markerClusterer.defaultProps = {
    lastLocation: false,
}

export default markerClusterer;


/* To get icon from aws
icon={
                        id === idSelected
                            ? MarkerSelectIcon
                            : getIconUrl(device) ? new L.Icon({
                                iconUrl: getIconUrl(device),
                                iconSize: getIconSize(localStorage.getItem(`${device.device_id}_icon_size`))
                                }) : MarkerDefaultIcon
                    }

*/
