import React from 'react';
import compose from 'recompose/compose'
import PropTypes from 'prop-types';
import ContentSettings from '@material-ui/icons/Settings';
import { Link } from 'react-router-dom';
import { linkToRecord, translate } from 'react-admin';
import { Button, Tooltip } from '@material-ui/core'

const Settings = ({
    basePath = '',
    label = 'resources.configuration.name',
    record = {},
    translate,
    ...rest
}) => (
    <Tooltip title={translate(`resources.devices.configuration`)}>
        <Button
            color="primary"
            component={Link}
            to={linkToRecord(basePath, record.id)}
            {...rest}
        >
            <ContentSettings />
        </Button>
    </Tooltip>
);

Settings.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.object,
    label: PropTypes.string,
    record: PropTypes.object,
};

const enhance = compose(
    translate,
);


export default enhance(Settings);