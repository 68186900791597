import React from 'react'
//react-admin
import { Filter, translate } from 'react-admin'
// ticatag
import Map from '../../ui/maps/map1'
import LocationHistoryActions from './actions'
import { withStyles } from '@material-ui/core/styles'
import MenuItem from './menuItem'
import { PER_PAGE_MAP_HISTORY } from '../../helper/resourceHelper'
import LocationHistoryFilters from './filters'
import List from '../../ui/list/List'


export const styles = (theme) => {

    return ({
        root: {
            marginTop: 50,
            backgroundColor: theme.palette.background.default,
            "& .MuiPaper-root": {
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
            }
        }
    })
}

const LocationHistoryTitle = translate(({ translate }) => {
    return <span>{translate("resources.devices.labels.locationsHistory")}</span>;
})


const LocationHistoryListFilters = (props) => {
    const displayedFilters = {}

    return (
        <Filter displayedFilters={displayedFilters} variant="standard"  {...props}>
            <LocationHistoryFilters  {...props} />
        </Filter>

    );
};

const LocationHistoryList = ({ translate, ...props }) => {

    return (

        <List
            basePath={"locations/{id}/history"}
            {...props}
            title={<LocationHistoryTitle />}
            actions={<LocationHistoryActions recordId={props.match.params.id} {...props} />}
            filters={LocationHistoryListFilters(props)
            }

            filter={{
                names: 'location,computed_location',
                device_ids: props.match.params.id,
            }}
            resource="locations_events"
            sort={{ field: 'timestamp', order: 'DESC' }}
            pagination={false}
            perPage={PER_PAGE_MAP_HISTORY}
        >

            <Map history={true} menuItem={MenuItem} translate={translate} />

        </List>

    )
}

//export default translate(LocationHistoryList)
export default withStyles(styles)(translate(LocationHistoryList))
