import React, { useCallback } from 'react';

import { CREATE, translate } from 'react-admin';

import {
    SaveButton, useCreate,
    useRedirect,
    useNotify,
} from 'react-admin'


const sanitizeExportData = ({
    selectedIds,
    ...values
}) => values


const StartExportButton = props => {
    const [create] = useCreate();
    const redirectTo = useRedirect();
    const notify = useNotify();
    const { basePath, disabled } = props;
    const handleSave = useCallback(
        (values) => {

            create(
                {
                    payload: { data: { ...sanitizeExportData(values) } },
                    resource: 'export',
                    fetch: CREATE,
                },
                {
                    onSuccess: () => {
                        notify('resources.export.export_starting', { messageArgs: { smart_count: 1 } });
                        redirectTo('/devices', basePath);
                    },
                }
            );
        },
        [create, notify, redirectTo, basePath]
    );

    // set onSave props instead of handleSubmitWithRedirect
    return <SaveButton {...props} color="primary"
        label="resources.export.action" onSave={handleSave} disabled={disabled} />;
};
export default translate(StartExportButton)
