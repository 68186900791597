import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { translate } from 'react-admin'

const styles = {
    button: {
        margin: '10px 24px',
        position: 'relative',
    },
    iconPaddingStyle: {
        paddingRight: '0.5em',
    },
}

const sanitizeRestProps = ({ className, classes, saving, label, variant, translate, locale, ...rest }) => rest

class PreviousButton extends Component {
    render() {
        const { className, classes = {}, previousPage, translate, variant, ...rest } = this.props

        return (
            <Button
                className={classnames(classes.button, className)}
                variant={variant}
                type="button"
                onClick={value => previousPage(value)}
                color="primary"
                {...sanitizeRestProps(rest)}
            >
                {translate('ra.navigation.prev')}
            </Button>
        )
    }
}

PreviousButton.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    previousPage: PropTypes.func,
    label: PropTypes.string,
    pristine: PropTypes.bool,
    translate: PropTypes.func.isRequired,
    variant: PropTypes.oneOf(['contained', 'text', 'fab']),
}

PreviousButton.defaultProps = {
    previousPage: () => () => { },
}


export default withStyles(styles)(translate(PreviousButton))
