import React, { Component } from 'react'
import compose from 'recompose/compose'
import { SimpleForm, Toolbar, translate } from 'react-admin'
import { CardCvcElement, CardExpiryElement, CardNumberElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { SaveCreditCardButton } from './SaveCreditCardButton'

import { makeStyles } from '@mui/styles';
const useStyles = makeStyles({
    label: {
        fontSize: '0.875rem',
    },
    input: {
        //backgroundColor: '#E9E8E8',
    }
})

const cardOptions = ({
    style: {
        base: {
            fontSize: '16px',
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            color: 'rgba(0, 0, 0, 0.87)',
            letterSpacing: '0.025em',
            '::placeholder': {
                color: 'rgba(0, 0, 0, 0.87)',
            },
            backgroundColor: '#E9E8E8',
            height: 50,
            width: 100

        },
        invalid: {
            color: 'rgba(0, 0, 0, 0.87)',
        },
    }
})

const FopCreateToolBar = props => {
    return (
        <Toolbar {...props} stripe={props.stripe}>
            <SaveCreditCardButton {...props} submitOnEnter={true} stripe={props.stripe} />
        </Toolbar>
    )
}

export const FopForm = translate((props) => {
    const stripe = useStripe();
    const elements = useElements();
    const classes = useStyles();
    const { translate } = props
    return (
        <SimpleForm redirect="list" toolbar={<FopCreateToolBar elements={elements} stripe={stripe} {...props} />}>
            <label>
                <span className={classes.label}>{translate('resources.fops.card_number')} *</span>
                <CardNumberElement options={{ ...cardOptions }} className={classes.input} />
            </label>
            <label>
                <span className={classes.label}>{translate('resources.fops.card_expiry')} *</span>
                <CardExpiryElement options={{ ...cardOptions }} className={classes.input} />
            </label>
            <label >
                <span className={classes.label}>{translate('resources.fops.card_cvc')} *</span>
                <CardCvcElement options={{ ...cardOptions }} />
            </label>

        </SimpleForm>
    )
}
)


/*
export default compose(translate,
    withStyles(styles)
)(FopForm)*/
