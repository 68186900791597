import React, { Fragment } from 'react'
import FontAwesome from 'react-fontawesome'
import { BooleanField, TopToolbar, CreateButton, Datagrid, FunctionField, List, translate, BulkDeleteButton, DeleteButton } from 'react-admin'
import { withStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@mui/material'
import { hasWriteAccess } from '../../../helper/resourceHelper'
const styles = theme => ({
    list: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        marginTop: 5,
    },

})
const getCreditCardIcon = (record) => {
    let name = ''
    switch (record.brand) {
        case 'Visa':
            name = 'cc-visa'
            break
        case 'American Express':
            name = 'cc-amex'
            break
        case 'MasterCard':
            name = 'cc-mastercard'
            break
        case 'Discover':
            name = 'cc-discover'
            break
        case 'JCB':
            name = 'cc-jcb'
            break
        default:
            name = 'credit-card'
            break
    }
    return <FontAwesome name={name} size="2x" />
}

const getBankAccountIcon = () => {

    return <FontAwesome
        name="university"
        size="2x"
    />
}

const FopActions = ({ basePath, filterValues, onUnselectItems, permissions, resource, selectedIds }) => (
    <TopToolbar>
        <CreateButton basePath={basePath} />

    </TopToolbar>
)
const FopSepaActions = ({ basePath, filterValues, onUnselectItems, permissions, resource, selectedIds }) => (
    <TopToolbar>
        {hasWriteAccess(permissions) && <Fragment>
            <DeleteButton basePath={basePath} selectedIds={selectedIds} filterValues={filterValues} resource={resource} onUnselectItems={onUnselectItems} />
        </Fragment>}
        <CreateButton basePath={basePath} />

    </TopToolbar>
)



const Empty = ({ basePath, message }) => (
    <Box textAlign="center" m={1}>
        <Typography variant="h6" paragraph>
            {message}
        </Typography>

        <CreateButton basePath={basePath} variant="contained" />
    </Box>
);

const FopList = ({ translate, classes, ...props }) => (
    <div>
        <TopToolbar sx={{ minHeight: "5px" }}>
        </TopToolbar>
        <Typography variant="h6" m={1}>{getCreditCardIcon({ brand: "credit-card" })}&nbsp;{translate("resources.fops_card.name")}</Typography>
        <List
            resource="fop_card" title={translate('resources.fops.name', { smart_count: 2 })}
            bulkActionButtons={false}
            actions={<FopActions />}
            className={classes.list}
            empty={<Empty message={translate("resources.fops_card.found_nothing")} />}
            {...props}>
            <Datagrid>
                <FunctionField
                    label="resources.fops.labels.type"
                    render={record => getCreditCardIcon(record)}
                />
                <FunctionField
                    label="resources.fops.labels.number"
                    render={record => `•••• •••• ••••  ${record.last4}`}
                />
                <FunctionField
                    label="resources.fops.labels.expiry_date"
                    render={record => `${record.exp_month}/${record.exp_year}`}
                />
                <BooleanField source="default_card" />
            </Datagrid>
        </List>
        <Typography variant="h6" m={2} mt={10}>{getBankAccountIcon()}&nbsp;{translate("resources.fops_sepa.name")}</Typography>
        <List resource="fop_sepa"
            basePath='/fops'
            title=" "
            actions={<FopSepaActions />}
            empty={<Empty message={translate("resources.fops_sepa.found_nothing")} />}
        >
            <Datagrid>

                <FunctionField
                    label="resources.fops_sepa.labels.iban"
                    render={record => `${record.country} •••• •••• ••••  ${record.last4}`}
                />
                <FunctionField
                    label="resources.fops_sepa.labels.bank_code"
                    render={record => record.bank_code}
                />

            </Datagrid>
        </List>
    </div>
)

export default withStyles(styles)(translate(FopList))
