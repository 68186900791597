import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import Email from '@material-ui/icons/Email';
import { Button } from 'react-admin'

const sanitizeRestProps = ({
    basePath,
    crudGetAll,
    dispatch,
    exporter,
    filter,
    maxResults,
    resource,
    sort,
    ...rest
}) => rest;

class InviteUserButton extends Component {
    static propTypes = {
        basePath: PropTypes.string,
        dispatch: PropTypes.func,
        exporter: PropTypes.func,
        filter: PropTypes.object,
        label: PropTypes.string,
        maxResults: PropTypes.number.isRequired,
        resource: PropTypes.string.isRequired,
        sort: PropTypes.object,
    };

    render() {
        const { basePath, label, selectedIds, ...rest } = this.props;

        return (
            <Button
                component={Link}
                to={basePath}
                label={label}
                {...sanitizeRestProps(rest)}
            >
                <Email/>
            </Button>
        );
    }
}

export default connect()(InviteUserButton); // inject redux dispatch