import React, { useCallback } from 'react';
import {
    CREATE, crudUpdate, SaveButton, useRedirect, useCreate, translate,
    useNotify,
} from 'react-admin';

import isEqual from 'lodash/isEqual'
import { updateAccountInfo } from '../../../rest/restClient'
const sanitizeRestProps = ({
    password,
    cpassword,
    email,
    cemail,
    id,
    organization_id,
    role,
    user_id,
    ...values
}) => values;





const isBlank = (str) => {
    return (!str || /^\s*$/.test(str));
}


export const SaveAccountButton = translate(props => {

    const [create] = useCreate();
    const redirectTo = useRedirect();
    const notify = useNotify();
    const { basePath, previousData, translate, userLogout } = props;
    const handleSave = useCallback(
        (values, redirect) => {
            const sanitizedProfileValues = sanitizeRestProps(values)
            const sanitizedPreviousProfileValues = sanitizeRestProps(previousData)
            if (!isEqual(sanitizedProfileValues, sanitizedPreviousProfileValues)) {

                updateAccountInfo({
                    id: values.id, account: sanitizedProfileValues
                }).then(() => {
                    notify(translate('resources.common.update_success'), 'info');
                    redirectTo("show", "/configuration");
                })
            }


            const hasPasswordChanged = !isBlank(values.password) && !isBlank(values.cpassword)
            if (hasPasswordChanged) {
                if (values.password === values.cpassword) {
                    if (values.password.length >= 6) {
                        //changePassword({ password: values.password })
                        create(
                            {
                                payload: { data: { ...{ password: values.password } } },
                                resource: 'change_password',
                                fetch: CREATE,

                            },
                            {
                                type: 'CHANGE_PASSWORD',
                                onSuccess: () => {
                                    notify(translate('resources.users.password_changed'), 'info');
                                    redirectTo("show", "/configuration");
                                },
                            }
                        )

                    } else {
                        notify(
                            translate('resources.users.invalid_password'),
                            'warning'
                        )
                    }
                } else {
                    notify(
                        translate('resources.users.passwords_not_match'),
                        'warning'
                    )
                }
            }

            const hasEmailChanged = !isBlank(values.email) && !isBlank(values.cemail)
            if (hasEmailChanged) {
                if (values.email === values.cemail) {
                    if (values.email.length >= 5) {
                        create(
                            {
                                payload: { data: { ...{ email: values.email } } },
                                resource: 'change_email',
                                fetch: CREATE,

                            },
                            {
                                type: 'CHANGE_EMAIL',
                                onSuccess: () => {
                                    notify(translate('resources.users.email_changed'), 'info');
                                    redirectTo("show", "/configuration");
                                },
                            }
                        )
                    } else {
                        notify(
                            translate('resources.users.invalid_email'),
                            'warning'
                        )
                    }
                } else {
                    notify(
                        translate('resources.users.emails_not_match'),
                        'warning'
                    )
                }
            }

        },
        [notify, redirectTo, basePath]
    );

    // set onSave props instead of handleSubmitWithRedirect
    return <SaveButton {...props} onSave={handleSave} />;
});



