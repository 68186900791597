/**
 * Get address from coordinates
 * @param  {Number}               lat    Latitude
 * @param  {Number}               lng    Longitude
 * @return {Promise<String|Null>}        Address
 */
import { reverseGeocode } from '../rest/restClient'

export const geoReverse = (lat, lng) => {
    return reverseGeocode(Math.round(lat * 100000) / 100000, Math.round(lng * 100000) / 100000)
}

export const formatAddress = res => {
    const { house_number, road, suburb, village, town, city, county, postcode, country } = res
    let address = ''

    if (suburb) {
        address += suburb
    }

    if (house_number) {
        address += ', ' + house_number
    }
    if (road) {
        address += ' ' + road
    }
    if (postcode) {
        address += ', ' + postcode
    }
    if (village) {
        address += ' ' + village
    }
    if (city) {
        address += ', ' + city
    }

    if (county && !city && !town && !village) address += ', ' + county

    if (country) {
        address += ', ' + country
    }

    return address
}
