import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';



const InfoCard = (props) => {
    const { title, value } = props
    return (
        <Card sx={{ minWidth: 150, marginLeft: 1 }} elevation={2}>
            <CardContent>

                <Typography variant="h6" component="div" align="center">
                    {title}
                </Typography>

                <Typography variant="body1" align="center" mt={1}>
                    {value}
                </Typography>
            </CardContent>

        </Card>
    );
}


export default InfoCard
