import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ActionDelete from '@material-ui/icons/Delete';
import DeleteForeverIcon from "@material-ui/icons/DeleteForever"
import classnames from 'classnames';
import { translate, crudDelete, startUndoable } from 'ra-core';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import { MenuItem } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from 'react-admin';

const styles = theme => ({
	deleteButton: {
		color: theme.palette.error.main,
		'&:hover': {
			backgroundColor: fade(theme.palette.error.main, 0.12),
			// Reset on mouse devices
			'@media (hover: none)': {
				backgroundColor: 'transparent'
			}
		}
	},
	actions: {
        alignItems: 'center'
    },
    icons: {
        marginRight: '1rem'
    }
});

class DeleteLinkWithConfirmation extends Component {
	state = {
		showDialog: false
	};

	handleClick = () => {
		this.setState({ showDialog: true });
	};

	handleCloseClick = () => {
		this.setState({ showDialog: false });
	};

	handleDelete = event => {
		event.preventDefault();
		this.setState({ showDialog: false });
		const { dispatchCrudDelete, recordId, basePath } = this.props;
		dispatchCrudDelete('devices_erase', recordId, null, basePath, false, false)
		dispatchCrudDelete('locations_events', recordId, null, "devices", "list",false)
	};

	render() {
		const { showDialog } = this.state;
		const { label = 'ra.action.delete', classes = {}, className, translate } = this.props;
		return (
			<Fragment>
                 <MenuItem key="delete" onClick={this.handleClick} className={classes.deleteButton}><DeleteForeverIcon className={classes.icons}/>{translate("resources.locations.labels.delete")}</MenuItem>
				<Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label="Are you sure?">
					<DialogTitle>
					   {translate('resources.common.delete_history')}
				</DialogTitle>
				<DialogContent>
					<div>{translate('resources.common.delete_history_paragraph')}</div>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={this.handleDelete}
						label={label}
						className={classnames('ra-delete-button', classes.deleteButton, className)}
						key="button"
					>
						<ActionDelete />
					</Button>
					<Button label="ra.action.cancel" onClick={this.handleCloseClick}>
						<IconCancel />
					</Button>
				</DialogActions>
				</Dialog>
			</Fragment>
		);
	}
}

DeleteLinkWithConfirmation.propTypes = {
	basePath: PropTypes.string,
	classes: PropTypes.object,
	className: PropTypes.string,
	dispatchCrudDelete: PropTypes.func.isRequired,
	label: PropTypes.string,
	recordId: PropTypes.string,
	redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
	resource: PropTypes.string.isRequired,
	startUndoable: PropTypes.func,
	translate: PropTypes.func,
	undoable: PropTypes.bool
};

DeleteLinkWithConfirmation.defaultProps = {
	redirect: 'list',
	undoable: true
};

export default compose(
	connect(
		null,
		{ startUndoable, dispatchCrudDelete: crudDelete }
	),
	translate,
	withStyles(styles)
)(DeleteLinkWithConfirmation);