import React from 'react'
import { Admin, resolveBrowserLocale, Resource, useSetLocale } from 'react-admin'
//jss
import JssProvider from 'react-jss/lib/JssProvider'
//auth
import authClient from './authProvider'
import Login from './pages/login/login'
//i18n
import i18nProvider from './i18nProvider'
//overidding layout & theme
import Menu from './layout/Menu'
import Layout from './theme/layout'
import { CHANGE_THEME, USER_FAV_LANGUAGE, USER_FAV_LANGUAGE_IS_SET } from './pages/configuration/actions'
//material-ui
import { AccountEdit, AccountShow } from './pages/account'
//routes
import customRoutes, { loginSuccessWrapper } from './route/customRoutes'
//resources
import { Configuration, ConfigurationIcon } from './pages/configuration'
import { DeviceCreate, DeviceEdit, DeviceIcon, DeviceList } from './pages/device'
import { ExportCreate } from './pages/export'
import { GeofenceCreate, GeofenceEdit, GeofenceIcon, GeofenceList } from './pages/geofence'
import { HookCreate, HookEdit, HookList } from './pages/hook'
import { FopCreate, FopList } from './pages/fop'
import { LocationIcon, LocationList, LocationShow } from './pages/location'
import { MeasureIcon, MeasureList } from './pages/measure'
import { NotificationCreate, NotificationEdit, NotificationIcon, NotificationList } from './pages/notification'
import { IntegrationCreate, IntegrationIcon, IntegrationList } from './pages/integration'
import { OrganizationCreate, OrganizationEdit, OrganizationList, OrganizationIcon } from './pages/organization'
import { InvoiceList, InvoiceIcon } from './pages/invoice'
import { UserCreate, UserEdit, UserIcon, UserList } from './pages/user'
//sagas
import realtimeSaga from './sagas/realtimeSaga'
import configReducer from './reducer/configReducer'
import { hasWriteAccess, isAdminPermissions, isRootOrganizationUser, isSuperAdmin } from './helper/resourceHelper'
import dataProviderFactory from './dataProvider'
import { BrowserRouter, Redirect, Route, withRouter } from 'react-router-dom'

import { Dialog, Alert, DialogActions, DialogContent, Button } from '@mui/material';
import Logo from '../src/theme/logo'


const UserNotAlowedAlert = (props) => {

    return (
        <Dialog
            open={props.open}
            onClose={() => {
                window.location.href = process.env.REACT_APP_AUTH_URL
                return null
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
            fullWidth={true}
        >

            <DialogContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                <Logo sx={{ display: 'flex !important', alignItems: 'center', alignSelf: 'center' }} />
                <Alert onClose={() => { return null }} severity="warning" sx={{ width: '90%' }}>
                    {" Connection refused ( User organization not allowed !)"}
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button variant="text">
                    &nbsp; REDIRECTING TO LOGIN ...&nbsp;
                </Button>
            </DialogActions>
        </Dialog>
    )
}

class App extends React.Component {

    state = {
        dataProvider: null,
        isLogged: localStorage.getItem('userId') !== null && (localStorage.getItem('userAllowed') === "true"),
        loginRequired: (localStorage.getItem('userAllowed') === "false"),
        openDialog: false,
    }

    async UNSAFE_componentWillMount() {
        const dataProvider = await dataProviderFactory('rest')
        this.setState({ dataProvider })

    }

    /**
     * Add event listener
     */
    componentDidMount() {
        window.addEventListener('resize', () => {
            this.forceUpdate()
        })


    }



    render() {
        const { dataProvider } = this.state

        if (!dataProvider) {
            return (
                <div className="loader-container">
                    <div className="loader">Loading...</div>
                </div>
            )
        }


        if (!this.state.isLogged && !this.state.loginRequired) {

            return (
                <BrowserRouter>
                    <div>
                        <Route
                            path="/*"
                            render={() => {
                                if (window.location.href.indexOf('access_token=') > 0) {
                                    loginSuccessWrapper();

                                    setTimeout(() => {
                                        if (localStorage.getItem('userAllowed') === "false") {
                                            this.setState({ openDialog: true })
                                            setTimeout(() => {
                                                this.setState({ loginRequired: true })
                                            }, 4000)
                                            return null

                                        } else {
                                            if (localStorage.getItem('userAllowed') === "true") {
                                    this.setState({ isLogged: true })
                                                this.setState({ loginRequired: false })
                                    return <Redirect to="/#/devices" />
                                            } else {
                                                this.setState({ loginRequired: true })
                                                return null
                                }
                                        }


                                    }, 1000)

                                } else {
                                window.location.href = process.env.REACT_APP_AUTH_URL
                                return null
                                }

                            }}
                        />
                        <UserNotAlowedAlert open={this.state.openDialog} />

                    </div>
                </BrowserRouter>
            )
        }

        if (!this.state.isLogged && this.state.loginRequired) {
            return (
                <BrowserRouter>
                    <div>
                        <Route
                            path="/*"
                            render={() => {
                                this.setState({ loginRequired: false })
                                const authorization = 'Bearer ' + (localStorage.getItem('token') || '')
                                fetch(process.env.REACT_APP_AUTH_LOGOUT, {
                                    method: 'POST',
                                    headers: {
                                        Authorization: authorization,
                                    },
                                })
                                    .then(() => {

                                        localStorage.clear()

                                        setTimeout(function () {
                                            window.location.href = process.env.REACT_APP_AUTH_URL
                                        }, 1000)

                                        return null
                                    })
                                    .catch(error => {
                                        console.log(error)
                                    })



                            }}
                        />
                    </div>
                </BrowserRouter>
            )
        }

        if (this.state.isLogged && (window.location.href.indexOf('access_token=') > 0)) {
            return (
                <BrowserRouter>
                    <div>
                        <Route
                            path="/*"
                            render={() => {
                                this.setState({ loginRequired: false })
                                return <Redirect to="/#/devices" />

                            }}
                        />
                    </div>
                </BrowserRouter>
            )
        }

        return (
            <JssProvider>

                <Admin
                    layout={Layout}
                    authProvider={authClient}
                    //dashboard={Dashboard}
                    customReducers={{
                        config: configReducer,
                    }}
                    customRoutes={customRoutes}
                    customSagas={[realtimeSaga(dataProvider)]}
                    dataProvider={dataProvider}
                    i18nProvider={i18nProvider}
                    locale={localStorage.getItem(USER_FAV_LANGUAGE) ? localStorage.getItem(USER_FAV_LANGUAGE) : resolveBrowserLocale()}
                    loginPage={Login}
                    title={process.env.REACT_APP_ROOT_ORGANIZATION}
                    menu={Menu}
                    theme={localStorage.getItem(CHANGE_THEME)}
                >
                    {permissions => [
                        <Resource
                            key="devices"
                            name="devices"
                            icon={DeviceIcon}
                            create={hasWriteAccess(permissions) ? DeviceCreate : null}
                            edit={hasWriteAccess(permissions) ? DeviceEdit : null}
                            list={DeviceList}
                        />,
                        <Resource key="device_attach" name="devices_attach" />,
                        <Resource key="device_status" name="devices_status" />,
                        <Resource
                            key="locations"
                            name="locations"
                            icon={LocationIcon}
                            list={LocationList}
                            show={LocationShow}
                        />,
                        <Resource
                            key="measures"
                            name="measures"
                            icon={MeasureIcon}
                            list={MeasureList}
                        />,
                        <Resource
                            key="geofences"
                            name="geofences"
                            icon={GeofenceIcon}
                            create={hasWriteAccess(permissions) ? GeofenceCreate : null}
                            edit={hasWriteAccess(permissions) ? GeofenceEdit : null}
                            list={GeofenceList}
                        />,
                        <Resource
                            key="notifications"
                            name="notifications"
                            icon={NotificationIcon}
                            create={hasWriteAccess(permissions) ? NotificationCreate : null}
                            edit={hasWriteAccess(permissions) ? NotificationEdit : null}
                            list={NotificationList}
                        />,
                        <Resource
                            key="integrations"
                            name="integrations"
                            icon={IntegrationIcon}
                            create={isAdminPermissions(permissions) ? IntegrationCreate : undefined}
                            list={isAdminPermissions(permissions) ? IntegrationList : undefined}
                        />,
                        <Resource
                            key="users"
                            name="users"
                            icon={UserIcon}
                            create={isAdminPermissions(permissions) ? UserCreate : undefined}
                            edit={isAdminPermissions(permissions) ? UserEdit : undefined}
                            list={isAdminPermissions(permissions) ? UserList : undefined}
                        />,
                        <Resource
                            key="organizations"
                            name="organizations"
                            icon={OrganizationIcon}
                            create={isAdminPermissions(permissions) ? OrganizationCreate : undefined}
                            edit={isAdminPermissions(permissions) ? OrganizationEdit : undefined}
                            list={isAdminPermissions(permissions) ? OrganizationList : undefined}
                        />,
                        <Resource
                            key="invoices"
                            name="invoices"
                            icon={InvoiceIcon}
                            list={InvoiceList}
                        />,
                        <Resource
                            key="configuration"
                            name="configuration"
                            icon={ConfigurationIcon}
                            list={Configuration}
                        />,
                        <Resource
                            key="export"
                            name="export"
                            create={ExportCreate}
                        />,
                        <Resource
                            key="exportPdf"
                            name="exportPdf"

                        />,
                        <Resource key="fops_card" name="fops_card" />,
                        <Resource key="fop_sepa" name="fop_sepa" />,
                        <Resource key="fops" name="fops" create={FopCreate} list={FopList} />,
                        <Resource key="plans" name="plans" />,
                        <Resource key="locations_events" name="locations_events" />,
                        <Resource key="notifications_events" name="notifications_events" />,
                        <Resource key="integrations_john_deere" name="integrations_john_deere" />,
                        <Resource key="yabby_configuration_plan" name="yabby_configuration_plan" />,
                        <Resource key="yabby_configuration_plans" name="yabby_configuration_plans" />,
                        <Resource key="account" name="account" show={AccountShow} edit={AccountEdit} />,
                        <Resource key="hooks"
                            name="hooks"
                            create={hasWriteAccess(permissions) ? HookCreate : undefined}
                            edit={hasWriteAccess(permissions) ? HookEdit : undefined}
                            list={hasWriteAccess(permissions) ? HookList : undefined}
                        />,
                    ]
                    }
                </Admin>

            </JssProvider>
        )
    }
}


export default App;

