import React, { useState, useEffect } from 'react';
import compose from 'recompose/compose'
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { translate, useDataProvider } from 'react-admin'
import { Grid, Paper } from '@mui/material';
import { addSubscription, checkoutSuccess, checkoutSuccessL, deviceAttach, getDevicesWithSubscriptions } from '../../../rest/restClient';
import Fab from '@mui/material/Fab';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { green, red, white } from '@mui/material/colors';




const ADD_SUB_IN_PROGRESS = 0
const SUB_CREATED = 1
const ADD_SUB_ERROR = 2
const ATTACH_DEVICE_IN_PROGRESS = 3
const DEVICE_ATTACHED = 4
const ATTACH_DEVICE_ERROR = 5

const CheckoutSuccess = ({ classes, permissions, translate, ...props }) => {
    const dataProvider = useDataProvider();
    const queryParams = new URLSearchParams(props.location.search)
    const deviceId = props.match.params.id;
    const planId = queryParams.get("plan_id")
    const sessionId = queryParams.get("session_id")
    const planType = queryParams.get("plan_type")
    const reason = queryParams.get("reason")
    const [progress, setProgress] = useState(ADD_SUB_IN_PROGRESS);
    const buttonCreatedSx = {
        ...({
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };
    const buttonErrorSx = {
        ...({
            bgcolor: red[500],
            '&:hover': {
                bgcolor: red[700],
            },
        }),
    };
    const buttonInProgressSx = {
        ...({
            bgcolor: "inherit",
            '&:hover': {
                bgcolor: "inherit",
            },
        }),
    };

    const attachDevice = (deviceId) => {

        dataProvider.getOne('devices', { id: deviceId, includes: "type" })
            .then(({ data }) => {
                const attach = {
                    name: data.name,
                    type: data.type,
                    serial_number: data.serial_number,
                }

                deviceAttach(attach)
                    .then(() => {
                        setProgress(DEVICE_ATTACHED)
                        setTimeout(() => {
                            props.history.push('/#/devices')
                        }, 2000)

                    })
                    .catch(error => {
                        setProgress(ATTACH_DEVICE_ERROR)
                        setTimeout(() => {
                            window.open("https://ticatag.com/pages/support-erreur-connect?error_title=ErrorWhileDeviceAttach&device_id=" + deviceId, "_self");
                        }, 3000)
                    })


            })
            .catch(error => {
                setProgress(ATTACH_DEVICE_ERROR)
                setTimeout(() => {
                    window.open("https://ticatag.com/pages/support-erreur-connect?error_title=ErrorWhileGettingDevice&device_id=" + deviceId, "_self");
                }, 20000)
            });


    }

    useEffect(() => {
        setProgress(ADD_SUB_IN_PROGRESS)
        checkoutSuccess(sessionId, planType, process.env.REACT_APP_ROOT_ORGANIZATION_ID)
            .then((response) => {
                if (response.json && response.json && response.status == 200) {

                    let subscription = { device_id: deviceId, plan_id: planId }

                    addSubscription(subscription)
                        .then((sub) => {
                            setProgress(SUB_CREATED)
                            if (reason === "attach") {
                                setTimeout(() => {
                                    setProgress(ATTACH_DEVICE_IN_PROGRESS)
                                    attachDevice(deviceId)
                                }, 1000)
                            } else {
                                setTimeout(() => {
                                    props.history.push('/#/devices')
                                }, 2000)
                            }
                        })
                        .catch((e) => {
                            setProgress(ADD_SUB_ERROR)
                            setTimeout(() => {
                                window.open("https://ticatag.com/pages/support-erreur-connect?error_title=ErrorWhileAddingSubscription&device_id=" + deviceId, "_self");
                            }, 3000)
                        })




                } else {
                    setProgress(ADD_SUB_ERROR)
                    setTimeout(() => {
                        window.open("https://ticatag.com/pages/support-erreur-connect?error_title=subscriptionErrorWhileCheckingCheckoutSuccess&device_id=" + deviceId, "_self");
                    }, 3000)
                }

            })
            .catch(error => {
                setProgress(ADD_SUB_ERROR)
                setTimeout(() => {
                    window.open("https://ticatag.com/pages/support-erreur-connect?error_title=subscriptionErrorWhileCheckingCheckoutSuccess&device_id=" + deviceId, "_self");
                }, 3000)

            })

    }, []);
    return (
        <Paper sx={{ margin: 10 }} >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                        <Box sx={{ m: 1, position: 'relative' }}>
                            {(progress === SUB_CREATED || progress === DEVICE_ATTACHED) ?
                                <Fab
                                    aria-label="save"
                                    color="none"
                                    sx={buttonCreatedSx}
                                >
                                    <CheckIcon />
                                </Fab> :
                                (progress === ADD_SUB_ERROR || progress === ATTACH_DEVICE_ERROR) ?
                                    <Fab
                                        aria-label="save"
                                        color="none"
                                        sx={buttonErrorSx}
                                    >
                                        <CloseIcon />
                                    </Fab>
                                    :
                                    <Fab
                                        aria-label="save"
                                        color="none"
                                        sx={buttonInProgressSx}
                                    >
                                        <div />
                                    </Fab>}
                            {(progress === ADD_SUB_IN_PROGRESS || progress === ATTACH_DEVICE_IN_PROGRESS) && (
                                <CircularProgress
                                    size={68}
                                    sx={{
                                        color: green[500],
                                        position: 'absolute',
                                        top: -6,
                                        left: -6,
                                        zIndex: 1,
                                    }}
                                />
                            )}
                        </Box>
                        <Box sx={{ minWidth: 20 }} />
                        <Typography
                            variant="h5"
                        >{progress === ADD_SUB_IN_PROGRESS &&
                            translate('resources.subscriptions.subscription_creating')}
                            {progress === SUB_CREATED &&
                                translate('resources.subscriptions.subscription_success')}
                            {progress === ADD_SUB_ERROR &&
                                translate('resources.subscriptions.subscription_creation_error')}
                            {progress === ATTACH_DEVICE_IN_PROGRESS &&
                                translate('resources.devices.attaching')}
                            {progress === DEVICE_ATTACHED &&
                                translate('resources.devices.attached')}
                            {progress === ATTACH_DEVICE_ERROR &&
                                translate('resources.devices.error_attachement_checkout')}
                        </Typography>

                    </Box>
                </Grid>
            </Grid>
        </Paper>

    )
}

export default compose(
    translate,

)(CheckoutSuccess)

