import React, { useState } from 'react'
import {
    AutocompleteArrayInput,
    Create,
    FormDataConsumer,
    ReferenceArrayInput,
    required,
    SelectInput,
    SimpleForm,
    Toolbar,
    translate,
} from 'react-admin'
//import { DateTimeInput } from 'react-admin-date-inputs2';
import DateTimePicker from '@mui/lab/DateTimePicker';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StartExportButton from '../../../ui/button/StartExportButton'
import { getUrlParams } from '../../../utils/utils'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@mui/material';



const styles = theme => ({
    list: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        marginTop: 20,
    }
})


const dataChoices = [
    { data: 'location_history', text: 'resources.location.head' },
    { data: 'temperature_history', text: 'resources.temperatures.head' },
    { data: 'humidity_history', text: 'resources.humidity.head' },
    { data: 'door_history', text: 'resources.door.head' },
    { data: 'shock_history', text: 'resources.shock.head' },
    { data: 'co2_history', text: 'resources.co2.head_min' },
]
const formatChoices = (data) => {
    const choices = [{ format: 'csv' }, { format: 'json' }]

    if (data === 'location_history') {
        choices.push({ format: 'gpx' })
    }

    return choices
}

const ExportCreateToolbar = props => (
    <Toolbar {...props} >
        <StartExportButton {...props} />
    </Toolbar>
);

const ExportCreate = ({ translate, ...props }) => {
    const selectedIds = getUrlParams('selectedIds')
    const { classes } = props
    const device_ids = selectedIds !== '' ? [selectedIds] : []
    const [start_date, setStartDate] = useState(new Date());
    const [end_date, setEndDate] = useState(new Date());
    const [disabled, setDisabled] = useState(false);

    const handleStartDateChange = (value) => {
        setStartDate(value);
        setDisabled(parseInt((end_date - value) / (1000 * 60 * 60 * 24), 10) > 7 ? true : false)
    }
    const handleEndDateChange = (value) => {
        setEndDate(value);
        setDisabled(parseInt((value - start_date) / (1000 * 60 * 60 * 24), 10) > 7 ? true : false)
    }
    return (
        <Create {...props} title={'resources.export.create'} className={classes.list}>
            <SimpleForm toolbar={<ExportCreateToolbar disabled={disabled} />}    >
                <ReferenceArrayInput source="device_ids" label={'resources.title.devices.list'} reference="devices" defaultValue={device_ids} validate={[required()]}>
                    <AutocompleteArrayInput optionText="name" matchSuggestion={() => true} />
                </ReferenceArrayInput>
                <SelectInput
                    choices={dataChoices}
                    source="data"
                    label="resources.export.fields.data"
                    optionText="text"
                    optionValue="data"
                    defaultValue="location_history"
                    validate={[required()]}
                />
                <FormDataConsumer>
                    {({ formData }) => {
                        formData.start_date = start_date
                        formData.end_date = end_date
                        return (
                            <SelectInput
                                choices={formatChoices(formData.data)}
                                source="format"
                                optionText="format"
                                optionValue="format"
                                defaultValue="csv"
                                validate={[required()]}
                            />)
                    }

                    }
                </FormDataConsumer>


                <Typography variant="body2" mt={3} mb={2} color="error">{disabled === true ? translate("resources.export.maxDurationWarning") : ""}</Typography>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        format="DD/MM/YYYY hh:mm"
                        label={translate("resources.subscriptions.fields.start_date")}
                        value={start_date}
                        onChange={handleStartDateChange}
                        renderInput={(params) => <TextField {...params} />}
                    />

                    <DateTimePicker
                        format="DD/MM/YYYY hh:mm"
                        label={translate("resources.subscriptions.fields.end_date")}
                        value={end_date}
                        onChange={handleEndDateChange}

                        renderInput={(params) => <TextField style={{ "margin-left": "10px" }} {...params} />}
                    />
                </LocalizationProvider>
            </SimpleForm>
        </Create>
    )
}

export default withStyles(styles)(translate(ExportCreate))

