import React from 'react'
import styled from 'jss-material-ui'
import { Avatar, Card, CardContent, CardHeader } from '@material-ui/core'
import { hasRolloverDetected, hasGroupDevices, getNumberOfDaysSinceLastSeen } from '../../helper/resourceHelper'
import PropTypes from 'prop-types'
import { getFormattedAddress, getLatitude, getLongitude, getAltitude, hasAlertFired, getAccuracy, getSpeed } from '../../utils/utils'
import { Text, Title } from '../../theme/layout'
import {
    translate
} from 'react-admin';

const SCard = styled(Card)({
    root: {
        boxShadow: 'none',
        margin: 0,
        padding: 0,

    },
})

const SAvatar = styled(Avatar)({
    root: {
        width: '40 !important',
        height: '40 !important',
    },
})

const SCardHeader = styled(CardHeader)({
    root: {
        margin: 0,
        marginBottom: 10,
        padding: 0,
    },
    subheader: {
        fontSize: 12
    }
})

const SCardContent = styled(CardContent)({
    root: {
        padding: '0 !important',
    },
})

const MarkerPopup = props => {
    const { index, record, translate } = props
    const { name, serial_number, mac_address, thumbnail_url, product } = record
    const formatted_address = getFormattedAddress(record)
    const { timestamp } = record.last_location || record
    const hasAlert = hasAlertFired(record) || record.last_seen
    const ID = serial_number ? serial_number : mac_address
    const displayTimestampOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    }

    let avatar_url = thumbnail_url
    if (!avatar_url && product && product.image_url) {
        avatar_url = product.image_url
    }

    return (
        <SCard>
            <SCardHeader
                title={
                    name === 'location'
                        ? `${translate('resources.devices.labels.location_nbr')}${index + 1}`
                        : name
                }
                subheader={ID && `#ID : ${ID}`}
                avatar={avatar_url && avatar_url.length > 0 && <SAvatar src={avatar_url} />}
            />
            <SCardContent>
                <Title>{translate('resources.devices.labels.location_geo')}</Title>
                <Text>
                    {new Date(timestamp).toLocaleDateString('fr-FR', displayTimestampOptions)}
                    <br />
                    {formatted_address}
                </Text>
                <Title>{translate('resources.devices.labels.coordinates')}</Title>
                <Text>
                    {translate('resources.devices.labels.latitude')} : {getLatitude(record)}
                    <br />
                    {translate('resources.devices.labels.longitude')} : {getLongitude(record)}
                    <br />
                    {getAltitude(record) !== null && translate('resources.devices.labels.altitude') + ": " + getAltitude(record) + " m"}
                    {getAltitude(record) !== null && <br />}
                </Text>
                <Title>{translate('resources.devices.labels.measures')}</Title>
                <Text>
                    {getAccuracy(record) > 50 && translate('resources.devices.labels.accuracy') + ": ( +/- " + getAccuracy(record) + "m )"}
                    {getAccuracy(record) > 50 && <br />}
                    {(getSpeed(record) > 0 || getSpeed(record) === 0) && translate('resources.devices.labels.speed') + ": " + getSpeed(record) + " km/h"}
                    {(getSpeed(record) > 0 || getSpeed(record) === 0) && <br />}
                </Text>
                {hasAlert && renderAlert(props)}
                {hasRolloverDetected(record) &&
                    <SCardContent>
                        <Title>{translate('resources.devices.rollover.name')}</Title>
                        <Text>
                            {translate('resources.devices.rollover.state')} : {translate(`resources.devices.rollover.${record.last_rollover.status}`)}
                            <br />
                            {translate('resources.devices.timestamp')} : {new Date(record.last_rollover.timestamp).toLocaleDateString('fr-FR', displayTimestampOptions)}
                        </Text>
                    </SCardContent>
                }
                {hasGroupDevices(record) && renderGroup(props)}
            </SCardContent>
        </SCard>
    )
}

const renderAlert = translate(props => {
    const { record, translate } = props
    const { last_seen } = record
    const displayTimestampOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    }

    return (
        <SCardContent>
            <Title warning>{translate('resources.devices.labels.last_transmission')}</Title>
            <Text warning>{new Date(last_seen).toLocaleDateString('fr-FR', displayTimestampOptions)}</Text>
            {getNumberOfDaysSinceLastSeen(record) > 3 && (
                <Text warning>
                    <b>{translate('resources.devices.labels.checkBattery')}</b>
                </Text>
            )}
        </SCardContent>
    )
})

const renderGroup = translate(props => {
    const { record, translate } = props
    const displayTimestampOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    }

    return (
        <SCardContent>
            <Title warning>{translate('resources.devices.group')}</Title>
            {
                record.group_devices.map(device => {
                    const infos = [<Text>{`${device.name} #${device.serial_number}`}</Text>]
                    if (device.last_location) {
                        infos.push(<Text>{`${translate('resources.location.head')} : ${device.last_location.formatted_address} - ${new Date(device.last_location.timestamp).toLocaleDateString('fr-FR', displayTimestampOptions)}`}</Text>)
                    }
                    if (device.last_temperature) {
                        infos.push(<Text>{`${translate('resources.temperatures.head')} : ${device.last_temperature.value} °C - ${new Date(device.last_temperature.timestamp).toLocaleDateString('fr-FR', displayTimestampOptions)}`}</Text>)
                    }
                    if (device.last_humidity) {
                        infos.push(<Text>{`${translate('resources.humidity.head')} : ${device.last_humidity.value} % - ${new Date(device.last_humidity.timestamp).toLocaleDateString('fr-FR', displayTimestampOptions)}`}</Text>)
                    }
                    if (device.last_door) {
                        infos.push(<Text>{`${translate('resources.door.head')} : ${translate(`resources.door.${device.last_door.status}`)} - ${new Date(device.last_door.timestamp).toLocaleDateString('fr-FR', displayTimestampOptions)}`}</Text>)
                    }
                    if (device.last_shock) {
                        infos.push(<Text>{`${translate('resources.shock.head')} : ${translate(`resources.shock.${device.last_shock.status}`)} - ${new Date(device.last_shock.timestamp).toLocaleDateString('fr-FR', displayTimestampOptions)}`}</Text>)
                    }
                    return infos
                })
            }
        </SCardContent>
    )
})

MarkerPopup.propTypes = {
    index: PropTypes.number,
    record: PropTypes.object,
    translate: PropTypes.func,
}

export default MarkerPopup
