import React, { Component, useCallback } from 'react';
import { connect } from 'react-redux';
import { SaveButton, showNotification, translate, useNotify, useRedirect } from 'react-admin';
import compose from 'recompose/compose'
import { addFopCard } from '../../../../rest/restClient'
import { useStripe, useElements, CardNumberElement } from '@stripe/react-stripe-js';




const SaveCreditCardButtonView = (props) => {

    const stripe = useStripe();
    const elements = useElements();
    const notify = useNotify();
    const redirectTo = useRedirect();
    const { basePath } = props;

    const handleClick = useCallback(
        (values, redirect) => {

            const { history, translate, showNotification, handleSubmitWithRedirect } = props;

            const card = elements.getElement(CardNumberElement);
            stripe
                .createToken(card)
                .then(payload => {
                    addFopCard(payload.token.id)
                        .then(response => {
                            showNotification(translate('resources.subscriptions.add_card_success'), 'info')
                            history.push('/fops')
                        })
                        .catch(response => {
                            showNotification(
                                translate('resources.subscriptions.add_fop_error'),
                                'warning'
                            )
                        })
                })
                .catch(response => {
                    showNotification(translate('resources.subscriptions.add_card_error'), 'warning')
                })

        },
        [notify, redirectTo, basePath]
    );

    return (

        <SaveButton
            {...props}
            handleSubmitWithRedirect={handleClick}
            onSave={(values, redirect) => { }}
            label="Set up Credit Card"


        />
    );

}

export const SaveCreditCardButton = compose(
    connect(
        undefined,
        { showNotification }
    ),
    translate,
)(SaveCreditCardButtonView)

