import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
//import { DateTimePicker } from 'material-ui-pickers'
import DateTimePicker from '@mui/lab/DateTimePicker';
import { translate } from 'react-admin'
import { today, yesterday } from './quicksearch'


const styles = theme => ({
    advancedSearch: {
        display: 'flex',
        flex: 2,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 10,
        marginRight: 10,
        marginLeft: 10,
        minHeight: 50,
        minWidth: 600,
    },
    input: {
        ' & :nth-child(1n)': {
            color: theme.palette.text.primary,
        },
    },

    label: {
        marginLeft: 5,
        marginRight: 5,
        fontSize: 11,
        marginTop: 10,
        height: 30,
    },
    pickers: {
        margin: 5,
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 30,

    },

    title: {
        color: theme.palette.text.primary,
        height: 15,
        marginBottom: 10,
    },
})

const criterias = (start_date = yesterday(), end_date = today()) => {

    if (isValidDate(start_date) && isValidDate(end_date)) {
        return ({
            filter: {
                start_date: start_date.toISOString(),
                end_date: end_date.toISOString(),
                search_result: 'return_all',
            },
        })
    } else {
        return ({
            filter: {
                start_date: yesterday(),
                end_date: today().toISOString(),
                search_result: 'return_all',
            },
        })
    }
}
function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}

class AdvancedSearch extends React.Component {
    state = {
        startDateTime: new Date(),
        endDateTime: new Date(),
    }

    handleStartDateTimeChange = dateTime => {

        if (dateTime !== undefined) {
            this.search(dateTime, this.state.endDateTime)
            this.setState({
                startDateTime: dateTime,

            })
        }


    }

    handleEndDateTimeChange = dateTime => {

        if (dateTime !== undefined) {
            this.search(this.state.startDateTime, dateTime)
            this.setState({
                endDateTime: dateTime,

            })
        }

    }

    search = (startDateTime, endDateTime) => {

        if (startDateTime !== undefined && endDateTime !== undefined) {

            this.props.setFilters(criterias(startDateTime, endDateTime).filter, {})
        }
    }

    render() {
        const { classes, translate } = this.props
        const { startDateTime, endDateTime } = this.state

        return (
            <div className={classes.advancedSearch}>
                <Typography variant="caption" color="primary" className={classes.title}>
                    {translate('resources.devices.advsearch')}
                </Typography>
                <div className={classes.pickers}>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>

                        <DateTimePicker
                            inputFormat="dd/MM/yyyy HH:mm"
                            label={translate('resources.devices.from')}
                            value={startDateTime}
                            onChange={this.handleStartDateTimeChange.bind(this)}
                            allowSameDateSelection={true}
                            InputProps={{
                                className: classes.input,
                            }}
                            renderInput={(params) => <TextField {...params} helperText="ex. 25/02/2022 16:05 " />}

                        />

                        <DateTimePicker
                            label={translate('resources.devices.to')}
                            value={endDateTime}
                            inputFormat="dd/MM/yyyy HH:mm"
                            onChange={this.handleEndDateTimeChange.bind(this)}
                            allowSameDateSelection={true}
                            InputProps={{
                                className: classes.input,
                            }
                            }
                            renderInput={(params) => <TextField {...params} helperText="ex. 25/02/2022 16:05 " />}
                        />

                    </LocalizationProvider>

                </div>
            </div>
        )
    }
}

AdvancedSearch.propTypes = {
    classes: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
}

const enhance = compose(
    withStyles(styles),
    translate
)

export default enhance(AdvancedSearch)
