import React from 'react';
import { connect } from 'react-redux';
import { GET_LIST, showNotification } from 'react-admin'
import domtoimage from 'dom-to-image';
import PdfDocument from './measuresHistoryDocument';
import dataProviderFactory from '../../dataProvider'
import jsPDF from "jspdf";
import "jspdf-autotable";
import compose from 'recompose/compose'
import { saveAs } from 'file-saver';


class ExportMeasuresHistory extends React.Component {
    constructor() {
        super();
        this.docUpdated = false;
        this.state = {
            docInProgress: false,
        }



    }


    async getChartsImage() {
        let map = document.getElementById('chartsToExport');
        const src = map ? await domtoimage.toJpeg(map, { style: { "background-color": "white" } }) : null
        return src
    }

    async componentDidMount() {
        setTimeout(async () => {
            this.docUpdated = true
            this.forceUpdate();
        }, 5000);


    }

    forceUpdateHandler() {
        this.componentDidMount();
    };

    async createDocument() {
        const { translate } = this.props
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const filterValues = this.props.filterValues;
        const mapImageData = await this.getChartsImage();
        const dataProvider = await dataProviderFactory('rest')
        const res = await dataProvider(GET_LIST, 'devices', {
            filter: { device_ids: this.props.recordId, includes: 'device_id,last_location,organization_id' },
        })
        const tableData = Object.values(this.props.data).map((a, index) => [index + 1, new Date(a.timestamp).toLocaleString({ timeZone: timezone }), a.name, a.value + (a.unit === "percent" && " %" || a.unit === "celsius" && " °C" || a.name === "co2" && " ppm")]);
        const deviceDetailsData = {
            id: res.data[0].device_id,
            serial_number: res.data[0].serial_number,
            name: res.data[0].name,
            organization_id: res.data[0].organization_id,
            last_location: res.data[0].last_location,
        }
        if (tableData.length === 0) {
            return { doc: null, docName: null }
        }



        if (filterValues.start_date) {
            if (filterValues.end_date) {
                let periodData = {
                    startDate: new Date(filterValues.start_date).toLocaleString({ timeZone: timezone }),
                    endDate: new Date(filterValues.end_date).toLocaleString({ timeZone: timezone }),
                }
                return (PdfDocument(translate, deviceDetailsData, periodData, tableData, mapImageData))
            } else {
                let periodData = {
                    startDate: new Date(filterValues.start_date).toLocaleString({ timeZone: timezone }),
                    endDate: new Date().toLocaleString({ timeZone: timezone }),
                }
                return (PdfDocument(translate, deviceDetailsData, periodData, tableData, mapImageData))
            }

        } else {
            let periodData = {
                startDate: tableData[tableData.length - 1][1],
                endDate: tableData[0][1],
            }
            return (PdfDocument(translate, deviceDetailsData, periodData, tableData, mapImageData))
        }

    }

    async download() {
        const { translate, showNotification } = this.props
        this.setState({ docInProgress: true })
        const { doc, docName } = await this.createDocument();
        if (doc === null && docName === null) {
            showNotification(translate('resources.exportPdf.errors.noMeasures'))
        } else {
            doc.save(docName);
        }
        this.setState({ docInProgress: false })
    }
    render() {
        const { translate } = this.props
        if (this.docUpdated) {
            return (
                <div>
                    <span onClick={() => this.download()}>{this.state.docInProgress ? translate('resources.exportPdf.downloading') : translate('resources.exportPdf.download')} </span>
                </div>)
        } else {
            return (
                <div>
                    <span >{translate('resources.exportPdf.creationInProgress')}</span>
                </div>)
        }

    }


}







export default compose(
    connect(
        undefined,
        { showNotification }
    ),
)(ExportMeasuresHistory);
