import { hasLocation } from './utils'
import style from 'jss-material-ui'
import { Avatar } from '@material-ui/core'

export const SmallAvatar = style(Avatar)((theme, { size }) => ({
    root: {
        height: size ? size : 25,
        width: size ? size : 25,
    },
}))

export const MediumAvatar = style(Avatar)((theme, { size }) => ({
    root: {
        height: size ? size : 48,
        width: size ? size : 48,
    },
}))

export const getAvatar = device => {
    if(!hasLocation(device)){
        return null;
    }

    return device.icon_name && device.icon_name.includes('ticatag-icons') ? device.icon_name : 'https://ticatag-icons.s3.amazonaws.com/markers/blue.png'
}
