import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { Button, CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { showNotification } from 'react-admin'

const styles = {
    button: {
        margin: '10px 24px',
        position: 'relative',
    },
    iconPaddingStyle: {
        paddingRight: '0.5em',
    },
}

const sanitizeRestProps = ({
    className,
    classes,
    saving,
    label,
    invalid,
    variant,
    handleSubmitWithRedirect,
    submitOnEnter,
    redirect,
    locale,
    showNotification,
    ...rest
}) => rest

class NextButton extends Component {
    handleClick = e => {
        const { handleSubmitWithRedirect, invalid, redirect, saving, showNotification } = this.props

        if (saving) {
            // prevent double submission
            e.preventDefault()
        } else {
            if (invalid) {
                showNotification('ra.message.invalid_form', 'warning')
            }
            // always submit form explicitly regardless of button type
            if (e) {
                e.preventDefault()
            }

            //handleSubmitWithRedirect(redirect)()

            handleSubmitWithRedirect(redirect) // 13/01/22
        }
    }
    render() {
        const {
            className,
            classes = {},
            disabled,
            invalid,
            label = 'ra.action.save',
            pristine,
            redirect,
            saving,
            submitOnEnter,
            variant = 'contained',
            ...rest
        } = this.props
        const type = submitOnEnter ? 'submit' : 'button'
        return (
            <Button
                className={classnames(classes.button, className)}
                variant={variant}
                type={type}
                disabled={disabled}
                onClick={e => this.handleClick(e)}
                color={saving ? 'default' : 'primary'}
                {...sanitizeRestProps(rest)}
            >
                {saving && saving.redirect === redirect ? (
                    <CircularProgress size={25} thickness={2} className={classes.iconPaddingStyle} />
                ) : null}
                {label}
            </Button>
        )
    }
}

NextButton.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    handleSubmitWithRedirect: PropTypes.func,
    invalid: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    pristine: PropTypes.bool,
    redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    saving: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    showNotification: PropTypes.func,
    submitOnEnter: PropTypes.bool,
    variant: PropTypes.oneOf(['contained', 'text', 'fab']),
}

NextButton.defaultProps = {
    handleSubmitWithRedirect: () => () => { },
    disabled: false,
}
const mapStateToProps = state => ({
    saving: state.admin.saving,
})

const enhance = compose(
    connect(
        mapStateToProps,
        { showNotification }
    ),
    withStyles(styles)
)

export default enhance(NextButton)
