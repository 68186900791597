import { stringify } from 'query-string'
import {
    CREATE,
    DELETE,
    DELETE_MANY,
    fetchUtils,
    GET_LIST,
    GET_MANY,
    GET_MANY_REFERENCE,
    GET_ONE,
    UPDATE
} from 'react-admin'
import { PER_PAGE } from '../helper/resourceHelper'


/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default () => {
    const restMap = {
        account: {
            urn: 'uaa/account',
            resource: 'account',
            id: 'user_id',
            includes: ['user_id', 'organization_id', 'phone_number', 'company_name'],
        },
        change_password: {
            urn: 'uaa/account/change_password',
            resource: 'change_password',
        },
        change_email: {
            urn: 'uaa/account/change_email',
            resource: 'change_email',
        },
        attach: {
            urn: 'devices/attach',
            resource: 'devices',
            id: 'device_id',
        },

        devices: {
            urn: 'devices',
            resource: 'devices',
            deleteUrn: 'detach',
            id: 'device_id',
            includes: ['device_id'],
            includes_get_one: ['organization_id', 'thumbnail_url', 'product', 'last_subscription', 'configuration', 'owner_id', 'group']
        },
        devices_erase: {
            urn: 'devices',
            resource: 'devices',
            deleteUrn: 'erase',
            id: 'device_id',
        },
        device_configurations: {
            urn: 'config/devices',
            resource: 'device_configurations',
        },
        device_groups: {
            urn: 'devices/groups',
            resource: 'device_groups',
        },
        devices_status: {
            urn: 'devices/serial_number',
            resource: 'devices',
            id: 'serial_number',
        },
        export: {
            urn: 'batch/events/export',
            resource: 'export',
        },
        fops: {
            urn: 'subscriptions/fops/cards',
            resource: 'fops_card',
            id: 'fop_id',
            includes: ['fop_id'],
        },
        fops_card: {
            urn: 'subscriptions/fops/cards',
            resource: 'fops_card',
            id: 'fop_id',
            includes: ['fop_id'],
        },
        fop_sepa: {
            urn: 'subscriptions/fops/sepa',
            resource: 'fop_sepa',
            id: 'id',
            includes: ['fop_id'],
        },
        geofences: {
            urn: 'geofences',
            resource: 'geofences',
            id: 'geofence_id',
            includes: ['device_id'],
            includes_get_one: ['geofence_id', 'device']
        },
        hooks: {
            urn: 'hooks',
            resource: 'hooks',
            id: 'hook_id',
            includes: ['hook_id'],
        },
        locations: {
            urn: 'devices',
            resource: 'devices',
            id: 'device_id',
            includes: ['images', 'last_location', 'device_id'],
        },
        dashboard: {
            urn: 'devices',
            resource: 'devices',
            id: 'device_id',
            includes: ['images', 'last_location', 'device_id'],
        },
        locations_events: {
            urn: 'events',
            resource: 'events',
            id: 'event_id',
        },
        notifications: {
            urn: 'notifications',
            resource: 'notifications',
            id: 'notification_id',
            includes: ['device_id'],
        },
        notifications_events: {
            urn: 'notifications/events',
            resource: 'notifications_events',
            id: 'event',
        },
        integrations_john_deere: {
            urn: 'integrations/johndeere',
            resource: 'integrations_john_deere',
            id: 'id',
        },
        organizations: {
            urn: 'config/organizations',
            resource: 'organizations',
            id: 'organization_id',
            includes: ['parent_organization_id', 'root_organization_id'],
        },
        invoices: {
            urn: 'invoices',
            resource: 'invoices',
            id: 'invoice_id',
        },
        plans: {
            urn: 'config/plans/device',
            resource: 'plans',
            id: 'plan_id',
        },
        yabby_configuration_plan: {
            urn: 'config/yabbyconfigurationplan/device_id',
            resource: ' yabby_configuration_plan',
            id: 'device_id',
        },
        yabby_configuration_plans: {
            urn: 'config/yabbyconfigurationplan',
            resource: ' yabby_configuration_plans',
            id: 'plan_id',
        },
        measures: {
            urn: 'devices',
            resource: 'devices',
            id: 'device_id',
            includes: ['device_id'],
        },
        subscriptions_create: {
            urn: 'subscriptions/create',
            resource: 'subscriptions_create',
            id: 'subscription_id',
        },
        subscriptions: {
            urn: 'subscriptions',
            resource: 'subscriptions',
            id: 'subscription_id',
        },
        subscriptions_manager: {
            urn: 'devices',
            resource: 'devices',
            id: 'device_id',
            includes: ['last_location'],

        },
        subscriptionByDevice: {
            urn: 'subscriptions',
            resource: 'subscriptionByDevice',
            id: 'device_id',
            includes_get_one: ['device_id', 'device', 'subscription_id', 'plan']
        },
        vouchers: {
            urn: 'vouchers',
            resource: 'vouchers',
            id: 'voucher_id',
        },
        users: {
            urn: 'uaa/users',
            resource: 'users',
            createUrn: 'uaa/register',
            deleteUrn: 'remove',
            id: 'user_id',
            includes: ['organization_id', 'company_name'],
        },
        users_invite: {
            urn: 'uaa/users/invite',
            resource: 'users_invite',
            id: 'user_id',
        }
    }

    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The REST request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertRESTRequestToHTTP = (type, resource, params) => {
        let url = ''
        const options = {}
        const query = {}

        switch (type) {
            case GET_MANY:
            case GET_MANY_REFERENCE:
            case GET_LIST: {
                if (params.pagination) {
                    const { page, perPage } = params.pagination
                    if (page) {
                        query['page'] = page - 1
                    }
                    if (!perPage) {
                        query['size'] = PER_PAGE
                    } else {
                        query['size'] = perPage
                    }
                }

                if (type === 'GET_MANY' && restMap[resource].id) {
                    //dirty hack to disable pagination - a search by device ids must be implemented on back-end side
                    //query['size'] = 500

                    query[`${restMap[resource].id}s`] = params.ids

                    if (resource === 'devices') {
                        const restResource = restMap[resource]
                        const includes_get_one = restResource.includes_get_one
                        if (includes_get_one) {
                            query.includes = ''
                            includes_get_one.map(include => (query.includes += include + ','))
                        }
                    }
                }

                const filters = params.filter

                for (const filter in filters) {
                    query[filter] = filters[filter]
                }

                if (params.sort) {
                    const { field, order } = params.sort
                    if (field && order) {
                        query.sort = field + ',' + order
                    }
                }

                const id = restMap[resource].id
                if (!query.includes || !query.includes[id]) {
                    query.includes = query.includes ? query.includes + ',' + id : id
                }

                const aincludes = restMap[resource].includes
                if (aincludes) {
                    aincludes.map(include => (query.includes += ',' + include))
                }

                const urn = restMap[resource].urn
                url = `${urn}?${stringify(query)}`

                if (resource === 'yabby_configuration_plans' && params.filter && params.filter.device_id) {
                    url = `${urn}/device_id/${params.filter.device_id}`
                }
                break
            }
            case GET_ONE: {
                const query = {}
                const restResource = restMap[resource]
                const includes = restResource.includes
                if (includes) {
                    query.includes = ''
                    includes.map(include => (query.includes += include + ','))
                }
                const includes_get_one = restResource.includes_get_one
                if (includes_get_one) {
                    includes_get_one.map(include => (query.includes += include + ','))
                }
                const res = restMap[resource]
                if (resource === 'account') url = `${res.urn}?${stringify(query)}`
                else url = `${res.urn}/${params.id}?${stringify(query)}`

                break
            }
            case DELETE:
                const restResource = restMap[resource]
                url =
                    restResource && restResource.deleteUrn
                        ? `${restResource.urn}/${params.id}/${restResource.deleteUrn}`
                        : `${resource}/${params.id}`
                if (resource === 'locations_events') {
                    url = `${restResource.urn}?device_ids=${params.id}`
                } else if (resource === 'device_groups') {
                    url = `devices/groups/${params.id}`
                }
                options.method = 'DELETE'
                break
            case UPDATE: {
                let body = null
                const urn1 = restMap[resource].urn
                url = `${urn1}/${params.id}`
                options.method = 'PATCH'
                if (resource === 'notifications') {
                    body = params.data
                    body.to = body.channel === 'email' ? params.data.emails.map(el => (el.email)) : params.data.phones.map(el => (el.phone))
                    options.method = 'PUT'
                    body.options = ['append_map_to_message']
                } else if (resource === 'account') {
                    url = `${urn1}`
                } else if (resource === 'device_groups') {
                    options.method = 'PUT'
                }
                options.body = body ? JSON.stringify(body) : JSON.stringify(params.data)
                break
            }
            case CREATE: {
                let body = null
                if (resource === 'notifications') {
                    body = params.data
                    body.to = body.channel === 'email' ? params.data.emails.map(el => (el.email)) : params.data.phones.map(el => (el.phone))
                    body.options = ['append_map_to_message']
                }
                url = restMap[resource].urn;
                if (resource === 'users') {
                    url = params.data && !params.data['password'] ? restMap['users_invite'].urn : restMap[resource].createUrn
                    body = params.data
                    body.enabled = true
                }
                options.method = 'POST'
                options.body = body ? JSON.stringify(body) : JSON.stringify(params.data)
                break
            }
            default:
                throw new Error(`Unsupported fetch action type ${type}`)
        }
        return { url, options }
    }

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The REST request params, depending on the type
     * @returns {Object} REST response
     */
    const convertHTTPResponseToREST = (response, type, resource, params) => {
        const { json } = response

        switch (type) {
            case GET_LIST:
            case GET_MANY:
            case GET_MANY_REFERENCE: {
                const id = restMap[resource].id
                const resourceName = restMap[resource].resource
                if (json._embedded) {
                    const data = json._embedded[resourceName].map(resource => {
                        if (resourceName === 'notifications' && resource.to) {
                            const to = resource.to.map(to => {
                                return { dest: to }
                            })
                            return { ...resource, id: resource[id], to: to }
                        }
                        return { ...resource, id: resource[id] }
                    })
                    localStorage.setItem('lastResource', JSON.stringify(data))
                    return {
                        data: data,
                        total: json.page.total_elements,
                    }
                } else if (json.page && json.page.total_elements === 0) {
                    return {
                        data: [],
                        total: 0,
                    }
                } else if (json.page && json.page.total_elements > 0) {
                    return {
                        data: [],
                        total: json.page.total_elements,
                    }
                } else {
                    return {
                        data: json.map(resource => {
                            return { ...resource, id: resource[id] }
                        }),
                        total: json.length,
                    }
                }
            }
            case GET_ONE: {
                const resourceName = restMap[resource].resource

                if (json._embedded) {
                    const resource = json._embedded[resourceName][0]
                    if (resource) {
                        return {
                            ...resource,
                            id: params.id,
                        }
                    }
                }
                if (resource === 'notifications' && json.to) {
                    let emails = null
                    let phones = null

                    if (json.channel === 'email')
                        emails = json.to.map(to => { return { email: to } })

                    if (json.channel === 'sms')
                        phones = json.to.map(to => { return { phone: to } })

                    return { data: { ...json, id: params.id, emails: emails, phones: phones } }
                }
                return { data: { ...json, id: params.id } }
            }
            case CREATE:
                if (json) {
                    if (resource === 'notifications' && json.to) {
                        let to = null
                        let phone = null
                        if (json.to) {
                            to = json.to.map(to => {
                                return json.channel === 'email' ? { email: to, dest: to } : { phone: to, dest: to }
                            })
                        }
                        return { data: { ...json, id: params.id, to: to, phone: phone } }
                    }
                    else if (json.id) {
                        return { data: { ...params.data, id: json.id } }
                    } else if (json[restMap[resource].id]) {
                        return { data: { ...params.data, id: json[restMap[resource].id] } }
                    } else {
                        return { data: json }
                    }
                } else {
                    return { data: { id: 'fake', ...params.data }, id: 'fake' }
                }

            case UPDATE:
                if (resource === 'notifications' && json.to) {
                    let to = null
                    let phone = null
                    if (json.to) {
                        to = json.to.map(to => {
                            return json.channel === 'email' ? { email: to, dest: to } : { phone: to, dest: to }
                        })
                    }
                    return { data: { ...json, id: params.id, to: to, phone: phone } }
                } else {
                    return { data: { ...params.data, id: params.id } }
                }
            case DELETE:
                return { data: { ...params.data, id: params.id } }
            default:
                return { data: json }

        }
    }
    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a REST response
     */
    return (type, resource, params) => {

        if (type === DELETE_MANY) {
            return Promise.all(
                params.ids.map(id =>
                    httpClient(
                        restMap[resource].deleteUrn
                            ? `/${restMap[resource].urn}/${id}/${restMap[resource].deleteUrn}`
                            : `/${restMap[resource].urn}/${id}`,
                        {
                            method: 'DELETE',
                        }
                    )
                )
            ).then(responses => ({
                data: responses.map(response => response.json),
            }))
        }
        if (resource === 'subscriptionByDevice') {

            return Promise.all(
                params.ids.map(id =>
                    getSubscriptions(id)
                )
            ).then(responses => {

                return (
                    ({
                        data: responses.map((response, index) => {
                            const device_id = params.ids[index]
                            return ({ ...response.json, id: device_id })
                        }),
                    })
                )
            }


            )
        }

        const { url, options } = convertRESTRequestToHTTP(type, resource, params)

        // Use local services for : DEV_MODE
        //var localResources = ['organizations', 'plans', 'yabby_configuration_plan', 'device_configurations', 'subscriptions_create', 'subscriptions', 'subscriptionByDevice', 'fops', 'fops_card', 'fops_sepa', 'yabby_configuration_plan', 'devices', "device_configurations", "users", "users_invite", "account", "change_password", "change_email"]
        var localResources = []
        if (localResources.includes(resource)) {

            return httpClientLocal(resource, url, options)
                .then(response => convertHTTPResponseToREST(response, type, resource, params))
                .catch(error => new Promise((_, reject) => reject(error)))
        }
        return httpClient(url, options)
            .then(response => convertHTTPResponseToREST(response, type, resource, params))
            .catch(error => new Promise((_, reject) => reject(error)))
    }
}

export const deviceStatus = deviceNumber => {
    return httpClient(`devices/serial_number/${deviceNumber}`, {
        method: 'GET',
    })
}

export const rootOrganization = organizationId => {
    return httpClient(`config/organizations/${organizationId}?includes=root_organization_id`, {
        method: 'GET',
    })
}

export const organizationName = organizationId => {
    return httpClient(`config/organizations/${organizationId}`, {
        method: 'GET',
    })
}

export const deviceProduct = deviceId => {
    return httpClient(`devices/${deviceId}?includes=product,organization,device_id`, {
        method: 'GET',
    })
}

export const deviceName = deviceId => {
    return httpClient(`devices/${deviceId}`, {
        method: 'GET',
    })
}

export const deviceEventName = (deviceId, name, filters) => {
    let params = '';
    if (filters)
        params = '&' + stringify(filters)

    return httpClient(`events?names=${name}&includes=event_id&device_ids=${deviceId}${params}`, {
        method: 'GET',
    })
}

export const deviceAttach = ({ name, serial_number }) => {
    return httpClient('devices/attach', {
        method: 'POST',
        body: JSON.stringify({
            type: 'unb',
            name,
            serial_number,
        }),
    })
}




export const createIntent = subscription => {
    return httpClient('subscriptions/intent', {
        method: 'POST',
        body: JSON.stringify(subscription),
    })
}


export const getPlans = (operator, organizationId, deviceId, language) => {

    return httpClient(`config/plans/device?operator=${operator}&organizationId=${organizationId}&deviceId=${deviceId}&language=${language.toUpperCase()}`, {
        method: 'GET',
    })
}



export const getFops = () => {
    return httpClient(`subscriptions/fops/cards`, {
        method: 'GET',
    })
}

export const checkoutSession = (subscription, reason) => {
    return httpClient('subscriptions/checkout?source=' + process.env.REACT_APP_PRODUCT_NAME.toLowerCase() + '&reason=' + reason, {
        method: 'POST',
        body: JSON.stringify(subscription),
    })
}

export const checkoutSuccess = (sessionId, planType, rootOrganizationId) => {
    return httpClient("subscriptions/checkout-success?session_id=" + sessionId + "&root_organization_id=" + rootOrganizationId + '&plan_type=' + planType, {
        method: 'GET',
    })
}

export const getSubscriptions = deviceId => {
    return httpClient(`subscriptions?device_id=${deviceId}&includes=plan,subscription_id`, {
        method: 'GET',
    })
}


export const addSubscriptionL = subscription => {
    return httpClientLocal("subscriptions", 'subscriptions/', {
        method: 'POST',
        body: JSON.stringify(subscription),
    })
}


export const checkoutSessionL = (subscription, reason) => {
    return httpClientLocal("subscriptions", 'subscriptions/checkout?source=' + process.env.REACT_APP_PRODUCT_NAME.toLowerCase() + '&reason=' + reason, {
        method: 'POST',
        body: JSON.stringify(subscription),
    })
}

export const checkoutSuccessL = (sessionId, planType, rootOrganizationId) => {
    return httpClientLocal("subscriptions", "subscriptions/checkout-success?session_id=" + sessionId + "&root_organization_id=" + rootOrganizationId + '&plan_type=' + planType, {
        method: 'GET',
    })
}


export const getAppVersion = () => {

    return httpClient(`config/software_versions?software_name=connect-app`, {
        method: 'GET',
    })
}

/*
Local API 

export const createIntent = subscription => {
    return httpClientLocal("subscriptions", 'subscriptions/intent', {
        method: 'POST',
        body: JSON.stringify(subscription),
    })
}


export const getPlans = (operator, organizationId, deviceId) => {
    return httpClientLocal("plans", `config/plans/device?operator=${operator}&organizationId=${organizationId}&deviceId=${deviceId}`, {
        method: 'GET',
    })
}

export const getSubscriptions = deviceId => {
    return httpClientLocal("subscriptions", `subscriptions?device_id=${deviceId}&includes=plan,subscription_id`, {
        method: 'GET',
    })
}

export const searchVoucher = code => {
    return httpClientLocal("subscriptions", `/subscriptions/vouchers/code/${code}`, {
        method: 'GET',
    })
}

export const addSubscription = subscription => {
    return httpClientLocal("subscriptions", 'subscriptions/', {
        method: 'POST',
        body: JSON.stringify(subscription),
    })
}


export const addFopSepa = token => {
    return httpClientLocal("subscriptions", 'subscriptions/fops/sepa', {
        method: 'POST',
        body: JSON.stringify({
            token,
        }),
    })
}


export const addFopCard = token => {
    return httpClientLocal("subscriptions", 'subscriptions/fops/cards', {
        method: 'POST',
        body: JSON.stringify({
            token,
        }),
    })
}


export const getFopSepa = () => {
    return httpClientLocal("subscriptions", `subscriptions/fops/sepa`, {
        method: 'GET',
    })
}
export const getFopCard = () => {
    return httpClientLocal("subscriptions", `subscriptions/fops/cards`, {
        method: 'GET',
    })
}



*/


export const cancelSubscription = subscriptionId => {
    return httpClient(`subscriptions/${subscriptionId}/cancel`, {
        method: 'DELETE',
    })
}

export const getMarkerIcons = () => {
    return httpClient(`config/icons/markers`, {
        method: 'GET',
    })
}


export const updateDeviceStatus = ({ id, status }) => {
    return httpClient(`devices/${id}`, {
        method: 'PATCH',
        body: JSON.stringify({
            status,
        }),
    })
}

export const updateDeviceIcon = ({ id, device }) => {
    return httpClient(`devices/${id}`, {
        method: 'PATCH',
        body: JSON.stringify({
            ...device,
        }),
    })
}

export const updateDeviceNameOrgIdOwnerId = ({ id, device }) => {
    return httpClient(`devices/${id}`, {
        method: 'PATCH',
        body: JSON.stringify({
            ...device,
        }),
    })
}

export const updateDeviceAddress = ({ id, formatted_address }) => {
    return httpClient(`devices/${id}`, {
        method: 'PATCH',
        body: JSON.stringify({
            formatted_address,
        }),
    })
}

export const updateDeviceConfiguation = ({ device_configuration }) => {
    return httpClient(`config/devices/${device_configuration.id}`, {
        method: 'PATCH',
        body: JSON.stringify({
            ...device_configuration,
        }),
    })
}

export const updateDeviceConfiguationAsSuperAdmin = ({ device_configuration }) => {
    return httpClient(`config/devices/super_admin/${device_configuration.id}`, {
        method: 'PATCH',
        body: JSON.stringify({
            ...device_configuration,
        }),
    })
}



export const updateDeviceConfiguationLocal = ({ device_configuration }) => {
    return httpClientLocal("device_configurations", `config/devices/${device_configuration.id}`, {
        method: 'PATCH',
        body: JSON.stringify({
            ...device_configuration
        }),
    })
}


export const updateEventAddress = ({ id, formatted_address }) => {
    return httpClient(`events/${id}`, {
        method: 'PATCH',
        body: JSON.stringify({
            formatted_address,
        }),
    })
}

export const updateAccountInfo = ({ id, account }) => {
    return httpClient(`uaa/account`, {
        method: 'PATCH',
        body: JSON.stringify({
            ...account,
        }),
    })
}


export const addEvent = (event) => {
    return httpClient(`events`, {
        method: 'POST',
        body: JSON.stringify({
            ...event
        }),
    })
}


export const searchVoucher = code => {
    return httpClient(`/subscriptions/vouchers/code/${code}`, {
        method: 'GET',
    })
}






export const addSubscription = subscription => {
    return httpClient('subscriptions/', {
        method: 'POST',
        body: JSON.stringify(subscription),
    })
}







export const reverseGeocode = (lat, lon) => {
    return fetch(
        `https://eu1.locationiq.org/v1/reverse.php?key=9074bc8605599b&lat=${lat}&lon=${lon}&format=json&zoom=18`,
        {}
    )
}

export const getGeofenceFromDeviceId = deviceId => {
    return httpClient(`geofences?includes=geofence_id&device_id=${deviceId}`, {
        method: 'GET',
    })
}

export const getNotificationFromDeviceId = deviceId => {
    return httpClient(`notifications?includes=notification_id&device_id=${deviceId}`, {
        method: 'GET',
    })
}

export const getImage = fullPath => {
    const token = localStorage.getItem('token')
    const opts = {
        method: 'GET',
        headers: new Headers({
            Authorization: `Bearer ${token}`,
        }),
    }

    return fetch(fullPath, opts)
}

export const setImage = (deviceId, payload) => {
    const fullPath = process.env.REACT_APP_API_URL + `/devices/${deviceId}/image`
    const token = localStorage.getItem('token')
    const opts = {
        method: 'post',
        body: payload,
        headers: new Headers({
            Authorization: `Bearer ${token}`,
        }),
    }

    return fetch(fullPath, opts)
}

export const createGeofenceFromDeviceId = (
    deviceId,
    payload = {
        radius: 50,
        latitude: 48.810106,
        longitude: -3.501209,
        on_entry: false,
        on_exit: false,
        active: false,
    }
) => {
    return httpClient(`geofences`, {
        method: 'POST',
        body: JSON.stringify({ device_id: deviceId, ...payload }),
    })
}

export const createNotificationFromDeviceId = (
    deviceId,
    payload = {
        channel: 'sms',
        event: 'geofence_crossed',
        to: ['<phone number>'],
        message: '<Alert balise>',
        options: ['append_map_to_message'],
    }
) => {
    return httpClient(`notifications`, {
        method: 'POST',
        body: JSON.stringify({ device_id: deviceId, ...payload }),
    })
}

//        `http://www.mapquestapi.com/geocoding/v1/reverse?key=85mgMfFi9qyEFGyWasGrdo5eSroCrLZk&location=${lat},${lon}`,

const httpClientLocal = (resource, url, options = {}) => {
    const baseUrl = "http://localhost"
    var port = "8080";
    //console.log("  httpClientLocalll = (resource, url, options = {}) ", resource, url, options)
    switch (resource) {

        case "devices": {
            url = url.slice(7)
            port = "8080";
            break;
        }
        case "invoices": {
            url = url.slice(8)
            port = "8664";
            break;
        }
        case "yabby_configuration_plan": {
            url = url.slice(6)
            port = "8092";
            break;
        }
        case "plans": {
            url = url.slice(6)
            port = "8092";
            break;
        }
        case "account": {
            port = "9998";
            url = `/${url}`
            break;
        }
        case "users": {
            port = "9998";
            url = `/${url}`
            break;
        }
        case "users_invite": {
            port = "9998";
            url = `/${url}`
            break;
        }
        case "change_password": {
            port = "9998";
            url = `/${url}`
            break;
        }
        case "change_email": {
            port = "9998";
            url = `/${url}`
            break;
        }
        case "device_configurations": {
            url = url.slice(6)
            port = "8092";
            break;
        }

        case "subscriptions": {
            url = url.slice(13)
            port = "8084";
            break;
        }
        case "fops": {
            url = url.slice(13)
            port = "8084";
            break;
        }
        case "fops_card": {
            url = url.slice(13)
            port = "8084";
            break;
        }
        case "fop_sepa": {
            url = url.slice(13)
            port = "8084";
            break;
        }

    }

    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' })
    }
    const token = localStorage.getItem('token')
    options.headers.set('Authorization', `Bearer ${token}`)
    //console.log(" httpClientLocalll `${baseUrl}:${port}${url}`, options ", `${baseUrl}:${port}${url}`, options)
    return fetchUtils.fetchJson(`${baseUrl}:${port}${url}`, options)

}



const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' })
    }
    const token = localStorage.getItem('token')
    options.headers.set('Authorization', `Bearer ${token}`)
    return fetchUtils.fetchJson(`${process.env.REACT_APP_API_URL}/${url}`, options)
}

export function formFetch(url, payload = undefined, method = 'post') {
    const fullPath = process.env.REACT_APP_API_URL + url
    const token = localStorage.getItem('token')
    const opts = {
        method,
        headers: new Headers({
            mode: 'formdata',
            formdata: [
                {
                    key: 'image',
                    type: 'file',
                },
            ],
            Authorization: `Bearer ${token}`,
        }),
    }
    if (payload) {
        opts.body = getFormData(payload)
    }

    return fetch(fullPath, opts)
}

export function downloadInvoice(id) {
    const url = process.env.REACT_APP_API_URL + '/invoices/' + id
    const token = localStorage.getItem('token')
    const opts = {
        method: 'GET',
        headers: new Headers({
            Authorization: `Bearer ${token}`,
        }),
    }

    return fetch(url, opts)
}

const getFormData = data => {
    const formData = new FormData()

    for (let name in data) {
        formData.append(name, data[name])
    }
    return formData
}

export const getDevicesWithSubscriptions = async () => {
    const url = '/devices?includes=mac_address%2Cproduct%2Corganization_id%2Cstatus%2Ctype%2Cowner%2Cconfiguration%2Clast_subscription%2Cdevice_id%2Clast_location&page=0&size=25&sort=id%2CASC'

    const response = await httpClient(url, {
        method: 'GET',
    })
    return response
}



export const addFopSepa = token => {
    return httpClient('subscriptions/fops/sepa', {
        method: 'POST',
        body: JSON.stringify({
            token,
        }),
    })
}




export const addFopCard = token => {
    return httpClient('subscriptions/fops/cards', {
        method: 'POST',
        body: JSON.stringify({
            token,
        }),
    })
}




export const getFopSepa = () => {
    return httpClient(`subscriptions/fops/sepa`, {
        method: 'GET',
    })
}
export const getFopCard = () => {
    return httpClient(`subscriptions/fops/cards`, {
        method: 'GET',
    })
}




