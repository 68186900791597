// logger
import log from 'loglevel';
import remote from 'loglevel-plugin-remote';

const customJSON = log => ({
    timestamp: log.timestamp,
    msg: log.message,
    level: log.level.label,
});

remote.apply(log, { format: customJSON, url: 'https://f6v29s87ai.execute-api.eu-west-1.amazonaws.com/default/ticatag-connect-logs-handler', method: 'POST', timestamp: () => new Date().toISOString(), });

log.enableAll();
log.info('src/logger/log : logger start');

export default log
