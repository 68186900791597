import React from 'react'
import { Field } from 'react-final-form'
import {
    Create,
    email,
    minLength,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    translate,
} from 'react-admin'
import { ROLE_ORGA_ADMIN, ROLE_ORGA_USER, ROLE_USER } from '../../configuration/actions'
import PhoneInput from 'react-phone-number-input'
import { withStyles } from '@material-ui/core/styles'

const validateRequired = [required('Champs obligatoire')]
const validateEmail = [required('Champs obligatoire'), email('Syntaxe incorrecte')]
const validateMinLength = [
    required('Champs obligatoire'),
    minLength(6, 'La longueur de la données doit etre >= 6'),
]

const stylesPhoneNumberInput = theme => ({
    phoneInputStyle: {
        width: 256,
        marginTop: 16,
        margin: 0,
        border: 0,
        display: 'inline-flex',
        padding: 0,
        position: 'relative',
        minWidth: 0,
        flexDirection: 'column',
    },
})
const styles = theme => ({
    list: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        marginTop: 20,
    }
})

const PhoneNumberInput = withStyles(stylesPhoneNumberInput)(
    translate(props => {
        const { translate, classes } = props
        return (
            <PhoneInput
                country="FR"
                placeholder={translate('resources.users.phoneNumber')}
                value={props.input.value}
                onChange={props.input.onChange}
                className={classes.phoneInputStyle}
            />
        )
    })
)

const PhoneNumberField = props => {
    return <Field name="phone_number" component={PhoneNumberInput} />
}

const UserCreate = translate(({ classes, ...props }) => {
    return (
        <Create {...props} title={'resources.users.create'} className={classes.list}>
            <SimpleForm redirect="list">
                <TextInput
                    label="resources.users.companyName"
                    source="company_name"
                />
                <TextInput
                    label={'resources.users.firstName'}
                    source="first_name"
                />
                <TextInput
                    label={'resources.users.lastName'}
                    source="last_name"
                />
                <PhoneNumberField />
                <TextInput
                    label={'resources.users.email'}
                    source="email"
                    validate={validateEmail}
                />
                <TextInput
                    label="resources.users.password"
                    source="password"
                    type="password"
                    validate={validateMinLength}
                />
                <TextInput
                    label="resources.users.cpassword"
                    source="cpassword"
                    type="password"
                    validate={validateMinLength}
                />
                <SelectInput
                    label={'resources.users.role'}
                    source="role"
                    choices={choices}
                    optionText="full_name"
                    optionValue="value"
                    defaultValue={() => choices[0].value}
                    validate={validateRequired}
                />
                <ReferenceInput
                    label={'resources.organization.organization'}
                    source="organization_id"
                    reference="organizations"
                    validate={validateRequired}
                    defaultValue={() => localStorage.getItem('organizationId')}
                    perPage={500}
                    sort={{ field: 'name', order: 'ASC' }}
                >
                    <SelectInput source="name" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    )
})

const choices = [
    { _id: 1, full_name: 'resources.role.member', value: ROLE_ORGA_USER },
    { _id: 2, full_name: 'resources.role.admin', value: ROLE_ORGA_ADMIN },
    { _id: 3, full_name: 'resources.role.owner', value: ROLE_USER },
]

export default withStyles(styles)(UserCreate)