import React from 'react'

import {
    TopToolbar,
    CreateButton,
    EditButton,
    Datagrid,
    Filter,
    List,
    ReferenceField,
    required,
    Responsive,
    TextField,
    TextInput,
    translate,
} from 'react-admin'
import { hasWriteAccess, PER_PAGE } from '../../../helper/resourceHelper'
import { withStyles } from '@material-ui/core/styles'

const validateRequired = [required('Champs obligatoire')]

const OrganizationActions = ({ basePath }) => (
    <TopToolbar>
        <CreateButton basePath={basePath} />
    </TopToolbar>
)
const styles = theme => ({
    list: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        marginTop: 20,
    }
})

const OrganizationList = ({ classes, translate, ...props }) => (
    <List
        {...props}
        filters={
            <Filter>
                <TextInput
                    variant="standard"
                    margin="normal"
                    label={translate('resources.common.search')}
                    source="q"
                    alwaysOn
                    style={{ width: 200 }}
                />
            </Filter>
        }
        bulkActionButtons={false}
        hasCreate={props.hasCreate}
        hasEdit={hasWriteAccess()}
        hasDelete={false}
        perPage={PER_PAGE}
        title={translate('resources.organizations.name', { smart_count: 2 })}
        actions={<OrganizationActions />}
        className={classes.list}
    >
        <Responsive
            xsmall={
                <Datagrid>
                    <TextField
                        label={translate('resources.organization.name')}
                        source="name"
                        sortable={true}
                    />
                    <ReferenceField
                        label={translate('resources.organization.parent')}
                        source="parent_organization_id"
                        reference="organizations"
                        validate={validateRequired}
                        allowEmpty
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <EditButton />
                </Datagrid>
            }
            medium={
                <Datagrid>
                    <TextField
                        label={translate('resources.organization.name')}
                        source="name"
                        sortable={true}
                    />
                    <TextField
                        label={translate('resources.organization.description')}
                        source="description"
                        sortable={false}
                    />
                    <ReferenceField
                        label={translate('resources.organization.parent')}
                        source="parent_organization_id"
                        reference="organizations"
                        validate={validateRequired}
                        allowEmpty
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <EditButton />
                </Datagrid>
            }
        />
    </List>
)

export default withStyles(styles)(translate(OrganizationList))
