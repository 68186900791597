import React, { useState, cloneElement, Fragment } from 'react'
import compose from 'recompose/compose'
//react-admin
import { AutocompleteInput, TopToolbar, CreateButton, Datagrid, DateField, Filter, FunctionField, List, ReferenceField, ReferenceInput, Responsive, SelectInput, TextField, TextInput, translate, useSetLocale } from 'react-admin'
import style from 'jss-material-ui'
import deviceTypes, { isAdminPermissions, isSuperAdmin, isYabby, PER_PAGE } from '../../../helper/resourceHelper'
import { Battery } from './Battery'
import AddButton from '../../../ui/button/AddButton'
//import AddBulkButton from '../../../ui/button/AddBulkButton'
import SettingsButton from '../../../ui/button/SettingsButton'
import LocationButton from '../../../ui/button/LocationButton'
import SubscriptionButton from '../../../ui/button/SubscriptionButton'
//import { DashboardLink } from '../../../ui/link/DashboardLink'
import { hasWriteAccess } from '../../../helper/resourceHelper'
import { hasLocation } from '../../../utils/utils'
import { withStyles } from '@material-ui/core/styles'
import MobileGrid from './MobileGrid'
import TrackingModeButton from '../../../ui/button/TrackingModeButton'
import { USER_FAV_LANGUAGE, USER_FAV_LANGUAGE_IS_SET } from '../../configuration/actions'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';

export const deviceActionsStyles = theme => ({
    addDevice: {
        marginRight: '1rem',
        fontSize: 15,
        padding: '0.5rem',
        marginTop: '1rem',
        marginBottom: '0.5rem',
    },
    activateDevice: {
        fontSize: 15,
        padding: '0.5rem',
        marginTop: '1rem',
        marginBottom: '0.5rem',
    }
})

export const styles = theme => ({
    headerPadding: {
        padding: '0px 12px !important'
    },
    organizationFilter: { width: 300 },
    ownerFilter: { width: 300 },
    listItemBackground: {

        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
    },
    list: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },

    },
    filter: {
        alignSelf: 'flex-end',
        marginTop: '1rem',
        marginBottom: '0.5rem',
        marginRight: '0.6rem',
    },
    hideDashboard: {
        display: 'none'
    },
    rotatedIcon: {
        transform: 'rotate(90deg)',
        textAlign: 'center',
    },

})

const Image = style('img')({
    root: {
        height: 50,
        width: 50,
        margin: 5,

    }
})

const DeviceActions = withStyles(deviceActionsStyles)(({
    basePath,
    displayedFilters,
    filters,
    filterValues,
    hideFilter,
    resource,
    showFilter,
    permissions,
    classes,
}) => (
    <TopToolbar >
        {filters &&
            cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                hideFilter,
                filterValues,
                context: 'button',
            })}

        <Responsive
            small={
                <Fragment>
                    {hasWriteAccess(permissions) && <CreateButton basePath={basePath} label={'resources.devices.activate_new_device'} className={classes.activateDevice} variant="contained" />}
                </Fragment>
            }
            medium={
                <Fragment>
                    {isSuperAdmin() && <AddButton basePath={basePath} label={'resources.devices.add_new_device'} className={classes.addDevice} variant="contained" />}
                    {hasWriteAccess(permissions) && <CreateButton basePath={basePath} label={'resources.devices.activate_new_device'} className={classes.activateDevice} variant="contained" />}
                </Fragment>
            }
        />

    </TopToolbar>
))



const DeviceList = ({ classes, permissions, translate, ...props }) => {

    const [showDetails, setShowDetails] = useState(true)


    if (localStorage.getItem(USER_FAV_LANGUAGE) && (localStorage.getItem(USER_FAV_LANGUAGE_IS_SET) !== "true")) {
        const setLocale = useSetLocale();
        setLocale(localStorage.getItem(USER_FAV_LANGUAGE))
        localStorage.setItem(USER_FAV_LANGUAGE_IS_SET, true)
    }


    return (

        <List
            {...props}
            title={translate('resources.devices.name', { smart_count: 2 })}
            actions={<DeviceActions />}
            className={classes.list}
            filters={
                <Filter className={classes.filter} {...props}>

                    <TextInput
                        variant="standard"
                        margin="normal"
                        label={translate('resources.common.search')}
                        source="q"
                        alwaysOn
                    />


                    {isAdminPermissions() && <ReferenceInput variant="standard" margin="normal" label="resources.devices.fields.organization" source="organization_id" reference="organizations" className={classes.organizationFilter}>
                        <AutocompleteInput optionText="name" matchSuggestion={() => true} />
                    </ReferenceInput>}
                    {isAdminPermissions() && <ReferenceInput variant="standard" margin="normal" label="resources.devices.fields.owner" source="owner_id" reference="users" className={classes.ownerFilter} >
                        <AutocompleteInput optionText="email" matchSuggestion={() => true} />
                    </ReferenceInput>}
                    {isSuperAdmin() && <SelectInput source="type"
                        variant="standard"
                        margin="normal"
                        choices={deviceTypes.map(deviceType => ({
                            id: deviceType.id,
                            name: translate(deviceType.name),
                        }))}
                    />
                    }
                </Filter>
            }
            filter={{
                includes:
                    'images,last_seen,mac_address,product,organization_id,software_version,status,type,last_battery,last_location,owner,configuration',
                //organization_id: process.env.REACT_APP_ROOT_ORGANIZATION_ID
            }}

            bulkActionButtons={false}
            perPage={PER_PAGE}

        >
            <Responsive
                className={classes.listItemBackground}
                small={
                    <MobileGrid />
                }
                medium={
                    <Datagrid>
                        {showDetails && <TextField label={<UnfoldLessIcon className={classes.rotatedIcon} fontSize='small' onClick={() => (setShowDetails(false))} />} />}
                        {!showDetails && <TextField label={<UnfoldMoreIcon className={classes.rotatedIcon} fontSize='small' onClick={() => (setShowDetails(true))} />} />}
                        <FunctionField
                            source="thumbnail_url"
                            render={record =>
                                record.thumbnail_url && (
                                    <Image
                                        style={{ width: 50, height: 50 }}
                                        alt={record.product ? record.product.name : null}
                                        src={record.thumbnail_url}
                                    />
                                )
                            }
                            sortable={false}
                        />
                        <TextField source="name" />
                        {showDetails && isAdminPermissions() && (
                            <ReferenceField
                                allowEmpty
                                label={'resources.organization.organization'}
                                source="organization_id"
                                reference="organizations"
                                sortable={false}
                            >
                                <TextField source="name" />
                            </ReferenceField>
                        )}
                        <FunctionField
                            source="mac_address"
                            render={record =>
                                record.serial_number ? record.serial_number : record.mac_address
                            }
                        />

                        {showDetails && <TextField
                            source="software_version"
                            sortable="false"
                        />}
                        <FunctionField
                            source="battery"
                            render={record => <Battery record={record} />}
                            sortable={false}
                        />
                        {showDetails && <FunctionField
                            label={'resources.devices.product'}
                            source="product_marketing_name"
                            render={record => record.product ? record.product.marketing_name.toUpperCase() : null}
                        />}
                        <DateField source="last_seen" showTime />
                        {showDetails && isAdminPermissions() && (
                            <ReferenceField label="resources.devices.owner" source="owner.owner_id" reference="users" allowEmpty>
                                <FunctionField
                                    render={record => record.first_name && record.last_name ? record.first_name.toUpperCase() + ' ' + record.last_name.toUpperCase() : record.email}
                                    sortable={false}
                                />
                            </ReferenceField>
                        )
                        }
                        {hasWriteAccess(permissions) &&
                            <FunctionField
                                label={translate('resources.devices.labels.configuration')}
                                render={record => <SettingsButton basePath={"devices"} record={record} />}
                            />
                        }
                        {hasWriteAccess(permissions) &&
                            <FunctionField
                                label={translate('resources.devices.labels.subscription')}
                                render={record => record.type === 'unb' ? <SubscriptionButton basePath={"devices"} record={record} /> : null}
                            />
                        }
                        {hasWriteAccess(permissions) &&
                            <FunctionField
                                label={translate('resources.devices.labels.tracking_mode')}
                                render={record => <TrackingModeButton basePath={"devices"} key={record && record.configuration && record.configuration.recovery_mode_status ? record.configuration.recovery_mode_status : "key"} record={record} />}
                            />
                        }
                        <FunctionField
                            headerClassName={classes.headerPadding}
                            label={translate('resources.locations.name', { smart_count: 1 })}
                            render={record => hasLocation(record) ? <LocationButton record={record} /> : <div />}
                        />
                    </Datagrid>
                }
            />
        </List>
    )
}

export default compose(
    translate,
    withStyles(styles)
)(DeviceList)

/*
<FunctionField
                            className={classes.hideDashboard}
                            source="mac_address"
                            render={record =>
                                <DashboardLink record={record} />
                            }

                        />
*/