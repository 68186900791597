import React from 'react'
import { crudDelete, showNotification, translate } from 'react-admin'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

class _DeviceDetach extends React.Component {
    handleDelete = event => {
        event.preventDefault()
        const { crudDelete, resource, basePath, selectedIds } = this.props

        selectedIds.map(id => crudDelete(resource, id, null, basePath, 'list'))
    }
    render() {
        return null
    }
}

_DeviceDetach.propTypes = {
    crudDelete: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
}

const DeviceDetach = compose(
    translate,
    connect(
        {},
        { crudDelete, showNotification }
    )
)(_DeviceDetach)

export default DeviceDetach