
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { MapContainer, TileLayer, Polyline, LayersControl, MapConsumer } from 'react-leaflet'
import MarkerClusterer from './markerClusterer1'
import 'leaflet/dist/leaflet.css'
import 'react-leaflet-markercluster/dist/styles.min.css'
import 'leaflet-routing-machine'
import { getId, getUrlParams } from '../../utils/utils'
import { calculatePositions } from '../../utils/calculatePosition1'
import Routing from './routing1'
import Menu from './menu'
import style from 'jss-material-ui'
import { changeConfig, ROUTING_MODE, SELECT_RIGHTMENU, DISABLE_CLUSTERING_AT_ZOOM } from '../../pages/configuration/actions'
import { updateFormattedAddress, updateIcon, updateStatus, acknowledgeRollover, acknowledgeMotion } from './actions'
import { BingLayer } from 'react-leaflet-bing-v2'

import { PrintMap } from './printControl';

const Container = style('div')({
    root: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        width: '100%',
        overflow: 'hidden'
    },
})

const SMap = style(MapContainer)({
    root: {
        margin: 0,
        display: 'block',
        height: '100%',
        //width: '100%',
    },
})
const distance = (bounds) => {
    if (!bounds || bounds.length !== 2)
        return 0

    const lat1 = bounds[0][0]
    const lon1 = bounds[0][1]
    const lat2 = bounds[1][0]
    const lon2 = bounds[1][1]

    const radlat1 = Math.PI * lat1 / 180
    const radlat2 = Math.PI * lat2 / 180
    const theta = lon1 - lon2
    const radtheta = Math.PI * theta / 180
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist)
    dist = dist * 180 / Math.PI
    dist = dist * 60 * 1.1515
    return dist * 1.609344
}



const TifizMap = (props) => {
    const [idSelected, setidSelected] = useState(null);
    const [zoom, setZoom] = useState(13);
    const [map, setMap] = useState(null)
    const [mapLayerIsSattelite, setMapLayerIsSattelite] = useState(false)

    const { ids, data, record, lastLocation, translate, menuItem, resource } = props


    const selectRightMenu = useSelector(state => state.config[SELECT_RIGHTMENU])
    const routingMode = useSelector(state => state.config[ROUTING_MODE])
    const disableClusteringAtZoom = useSelector(state => state.config[DISABLE_CLUSTERING_AT_ZOOM])




    if (record) {
        if (record.id !== idSelected) { setidSelected(record.id) }
    }

    const locations = record ? { [record.id]: record } : data
    const locations_ids = record ? [record.id] : ids

    const device_id = idSelected || getUrlParams('device_id')
    const device = device_id ? locations[device_id] : undefined

    const { bounds, center, positions } = calculatePositions(locations_ids, locations, device)
    const hasRouting = resource === 'locations_events' && routingMode !== 'no_routing' && map && (!bounds || distance(bounds) > 1);
    const mapMenuWidth = props.menuItem && selectRightMenu ? 310 : 0;

    const routeMachineKey = toString(positions.length) + routingMode
    const mapKeyNum = center && zoom ? center.lat + center.lng + zoom : 0
    const mapKey = mapKeyNum ? mapKeyNum.toString() + mapMenuWidth.toString() : "keyidmap" + mapMenuWidth.toString()


    useEffect(() => {
        const { width } = props
        switch (width) {
            case 'xs':
            case 'sm':
                changeConfig(SELECT_RIGHTMENU, false)
                break;
            default:
                break;
        }
    });





    const changeCenter = (idDevice) => {

        if (idDevice === null) return
        setidSelected(idDevice)



    }
    const _changeCenter = (device) => {
        setZoom(map.getZoom())

        changeCenter(getId(device))

    }
    const handleClick = ({ record }) => {
        const deviceId = getId(record)
        setZoom(map.getZoom())

        if (deviceId && idSelected !== deviceId) setidSelected(deviceId)
    }
    return (
        <Container style={{ height: 'calc(100vh - 158px)', width: '100%' }} >
            <SMap
                whenCreated={setMap}
                key={mapKey}
                bounds={bounds}
                center={center}
                zoom={zoom}
                zoomSnap={0.25}
                zoomDelta={0.25}
                style={{ width: `calc(100% - ${mapMenuWidth}px)` }}
                id="mapToExport"

            >
                <MapConsumer>
                    {(map) => {
                        map.on('baselayerchange', function (e) {

                            if (e.name === 'satellite') {
                                setMapLayerIsSattelite(true)
                            } else {
                                setMapLayerIsSattelite(false)
                            }
                        });
                        return null
                    }}
                </MapConsumer>

                <LayersControl position="topright">
                    <LayersControl.BaseLayer checked={mapLayerIsSattelite ? false : true} name="road">
                        <TileLayer
                            maxZoom={19}
                            attribution="&amp;copy <a href=&quot;https://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                            url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                        />

                    </LayersControl.BaseLayer>

                    <LayersControl.BaseLayer checked={mapLayerIsSattelite ? true : false} name='satellite'>
                        <BingLayer
                            maxZoom={19}
                            bingkey={process.env.REACT_APP_BING_API_KEY}
                            type="AerialWithLabels"
                        />
                    </LayersControl.BaseLayer>
                </LayersControl>
                <PrintMap />

                {hasRouting && routingMode !== 'boat' &&
                    <Routing positions={positions} key={routeMachineKey} routingMode={routingMode} />}

                {hasRouting && routingMode === 'boat' &&
                    <Polyline positions={positions} key={routeMachineKey + 'boat'} />}
                <MarkerClusterer
                    data={locations}
                    ids={locations_ids}
                    idSelected={idSelected}
                    onClick={device => handleClick(device)}
                    translate={translate}
                    updateFormattedAddress={updateFormattedAddress}
                    disableClusteringAtZoom={disableClusteringAtZoom ? 16 : 1}
                />

            </SMap>
            {menuItem && selectRightMenu &&

                <Menu
                    data={locations}
                    ids={locations_ids}
                    idSelected={idSelected}
                    lastLocation={lastLocation}
                    menuItem={menuItem}
                    onSelect={_changeCenter}
                    updateIcon={updateIcon}
                    updateStatus={updateStatus}
                    acknowledgeRollover={acknowledgeRollover}
                    acknowledgeMotion={acknowledgeMotion}
                />
            }

        </Container>)
}



export default TifizMap
