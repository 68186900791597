import React, { useState } from 'react'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import { useForm } from 'react-final-form'

import {
    AutocompleteInput,
    TopToolbar,
    Datagrid,
    TextInput,
    Filter,
    FunctionField,
    List,
    ReferenceField,
    ReferenceInput,
    Responsive,
    TextField,
    Pagination,
    translate,
} from 'react-admin'

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextFieldMui from '@mui/material/TextField';

import DateTimePicker from '@mui/lab/DateTimePicker';


import { Button } from '@material-ui/core'
import { isSuperAdmin, PER_PAGE_INVOICE_LIST } from '../../../helper/resourceHelper'

export const styles = theme => ({
    ownerFilter: {
        width: 350,
        display: 'flex',
        flex: 5,
        flexDirection: 'row',
    },
    list: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        marginTop: 20,
    },

    input: {
        ' & :nth-child(1n)': {
            color: theme.palette.text.primary,
        },
    },

    label: {
        marginLeft: 5,
        marginRight: 5,
        fontSize: 11,
        marginTop: 10,
        height: 30,
    },
    pickers: {
        margin: 5,
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 30,
        minHeight: 50,

    },

    filters: {
        display: 'flex',
        flex: 1,
        margin: 5,
        flexDirection: 'row',
        justifyContent: 'space-between',
        minHeight: 60,
    },
    toolbar: {
        margin: 20,
    },
    divider: {
        width: 10,
    },
})


const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};

const InvoiceActions = (props) => (
    <TopToolbar className={props.classes.toolbar} />
)


const changeEndTimeInput = (params, inputValue) => {
    const { change } = useForm();

    change('end_date', Math.round(new Date(inputValue).getTime() / 1000))
    return <div>
        <TextFieldMui {...params} helperText="ex. 25/02/2022 16:05" />
        <TextInput style={{ display: 'none' }} source="end_date" value={Math.round(new Date(inputValue).getTime() / 1000)} />
    </div>
}

const changeStartTimeInput = (params, inputValue) => {
    const { change } = useForm();

    change('start_date', Math.round(new Date(inputValue).getTime() / 1000))
    return <div>
        <TextFieldMui {...params} helperText="ex. 25/02/2022 16:05" />
        <TextInput style={{ display: 'none' }} source="start_date" value={Math.round(new Date(inputValue).getTime() / 1000)} />
    </div>

}

const InvoiceListAllFilters = (classes, translate, props) => {
    const displayedFilters = {}
    const [startDateTime, setStartDateTime] = useState(null);
    const [endDateTime, setEndDateTime] = useState(null);
    return (
        <Filter displayedFilters={displayedFilters} variant="standard" className={classes.filters}  {...props}>
            {isSuperAdmin() && <ReferenceInput alwaysOn label="resources.devices.fields.owner" source="owner_id" reference="users" className={classes.ownerFilter} >
                <AutocompleteInput optionText="email" matchSuggestion={() => true} />
            </ReferenceInput>}



            {isSuperAdmin() &&
                <div alwaysOn className={classes.pickers}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>

                        <DateTimePicker
                            inputFormat="dd/MM/yyyy HH:mm"
                            label={translate('resources.devices.from')}
                            value={startDateTime}
                            onChange={(d) => {
                                setStartDateTime(d)


                            }
                            }
                            allowSameDateSelection={true}
                            InputProps={{
                                className: classes.input,
                            }}
                            renderInput={(params) => changeStartTimeInput(params, startDateTime)}

                        />
                        <div className={classes.divider} />
                        <DateTimePicker
                            label={translate('resources.devices.to')}
                            value={endDateTime}
                            onChange={(d) => {
                                setEndDateTime(d);

                            }}
                            inputFormat="dd/MM/yyyy HH:mm"
                            allowSameDateSelection={true}
                            InputProps={{
                                className: classes.input,
                            }}
                            renderInput={(params) => changeEndTimeInput(params, endDateTime)}
                        />

                    </LocalizationProvider>
                </div>
            }


        </Filter>




    );
};


const InvoiceList = ({ classes, translate, ...props }) => (
    <List
        {...props}
        title={translate('resources.invoices.name', { smart_count: 2 })}

        filters={InvoiceListAllFilters(classes, translate, props)
        }
        filter={{
            root_organization: process.env.REACT_APP_ROOT_ORGANIZATION.toLowerCase()
        }}
        actions={<InvoiceActions classes={classes} />}
        resource="invoices"
        bulkActionButtons={false}
        perPage={PER_PAGE_INVOICE_LIST}
        pagination={<Pagination rowsPerPageOptions={[5, 10, 15, 25, 50, 100, 500]} />}
        className={classes.list}
    >
        <Responsive
            xsmall={
                <Datagrid>

                    <TextField
                        label={translate('resources.invoices.number')}
                        source="number"
                        sortable={false}
                    />
                    <FunctionField
                        label={translate('resources.invoices.date')}
                        source="created"
                        render={record => new Date(record.created).toLocaleDateString()}
                        sortable={false}
                    />
                    <FunctionField
                        label={translate('resources.invoices.price')}
                        source="total_amount"
                        render={record => record.total_amount / 100 + " " + record.currency.toUpperCase()}
                        sortable={false}
                    />
                    <FunctionField
                        label={translate('resources.invoices.download')}
                        render={record =>
                            record.pdf && record.id ?
                                <Button
                                    color="primary"
                                    onClick={() => openInNewTab(record.pdf)}
                                >
                                    {translate('resources.invoices.download')}
                                </Button>
                                :
                                <Button
                                    color="primary"
                                    disabled
                                >
                                    {translate('resources.invoices.download')}
                                </Button>

                        }
                    />
                </Datagrid>
            }
            medium={
                <Datagrid>


                    {isSuperAdmin() ? (
                        <FunctionField
                            label={translate('resources.invoices.number')}
                            source="number"
                            render={record => <Button
                                color="primary"
                                onClick={() => openInNewTab("https://dashboard.stripe.com/invoices/" + record.id)}
                            >
                                {record.number}
                            </Button>}
                            sortable={false}
                        />

                    ) :
                        <TextField
                            label={translate('resources.invoices.number')}
                            source="number"
                            sortable={false}
                        />

                    }

                    <FunctionField
                        label={translate('resources.invoices.date')}
                        source="created"
                        render={record => new Date(record.created).toLocaleDateString()}
                        sortable={false}
                    />

                    <TextField
                        label={translate('resources.invoices.description')}
                        source="description"
                        sortable={false}
                    />

                    {isSuperAdmin() && (
                        <FunctionField
                            label={translate('resources.invoices.period')}
                            source="period_start"
                            render={record => new Date(record.period_start).toLocaleDateString() + " to " + new Date(record.period_end).toLocaleDateString()}
                            sortable={false}
                        />
                    )
                    }

                    <FunctionField
                        label={translate('resources.invoices.price_tax_included')}
                        source="total_amount"
                        render={record => record.total_amount / 100 + " " + record.currency.toUpperCase()}
                        sortable={false}
                    />

                    <FunctionField
                        label={translate('resources.invoices.tax_amount')}
                        source="tax_amount"
                        render={record => record.tax_amount / 100 + " " + record.currency.toUpperCase()}
                        sortable={false}
                    />



                    {isSuperAdmin() && (
                        <ReferenceField label="resources.users.customer" source="owner_id" reference="users" allowEmpty>
                            <FunctionField
                                render={record => record.first_name && record.last_name ? record.first_name.toUpperCase() + ' ' + record.last_name.toUpperCase() : record.email}
                                sortable={false}
                            />
                        </ReferenceField>
                    )
                    }
                    <FunctionField
                        label={translate('resources.invoices.download')}
                        render={record =>
                            record.pdf && record.id ?
                                <Button
                                    color="primary"
                                    onClick={() => openInNewTab(record.pdf)}
                                >
                                    {translate('resources.invoices.download')}
                                </Button>
                                :
                                <Button
                                    color="primary"
                                    disabled
                                >
                                    {translate('resources.invoices.download')}
                                </Button>

                        }
                    />
                </Datagrid>
            }
        />
    </List>
)

export default compose(
    translate,
    withStyles(styles)
)(InvoiceList)
