import React, { useRef, useState } from 'react'
import {
    AutocompleteInput,
    BooleanInput,
    Create,
    FormDataConsumer,
    PasswordInput,
    ReferenceInput,
    required,
    SelectArrayInput,
    SimpleForm,
    TextInput,
    translate,
} from 'react-admin'
import 'react-phone-number-input/style.css'
import { withStyles } from '@material-ui/core/styles'

import { isAdminPermissions } from '../../../helper/resourceHelper'
import { Typography } from '@mui/material'

const styles = theme => ({
    list: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        marginTop: 20,
    }
})

const eventChoices = [
    { id: 'new_location', name: 'resources.hooks.new_location' },
    { id: 'location_changed', name: 'resources.hooks.location_changed' },
    { id: 'state_changed', name: 'resources.hooks.state_changed' },
    { id: 'button_pressed', name: 'resources.hooks.button_pressed' },
    { id: 'button_pressed_click', name: 'resources.hooks.button_pressed_click' },
    { id: 'button_pressed_double_click', name: 'resources.hooks.button_pressed_double_click' },
    { id: 'button_pressed_hold', name: 'resources.hooks.button_pressed_hold' },
    { id: 'new_co2', name: 'resources.hooks.new_co2' },
    { id: 'new_temperature', name: 'resources.hooks.new_temperature' },
    { id: 'new_humidity', name: 'resources.hooks.new_humidity' },
    { id: 'new_door', name: 'resources.hooks.new_door' },
    { id: 'new_water_leak', name: 'resources.hooks.new_water_leak' },
    { id: 'new_shock', name: 'resources.hooks.new_shock' },
    { id: 'new_rollover', name: 'resources.hooks.new_rollover' },
    { id: 'new_battery_level', name: 'resources.hooks.new_battery_level' },
    { id: 'new_battery_status', name: 'resources.hooks.new_battery_status' },
    { id: 'new_motion', name: 'resources.hooks.new_motion' },

]

const HookCreate = withStyles(styles)(
    translate(({ classes, ...props }) => {
        const { translate } = props
        return (

            <Create {...props} title={props.translate('resources.hooks.create')} className={classes.list}>
                <SimpleForm variant="standard" margin="normal" redirect="list">
                    <Typography variant="body2" color="red" fullWidth>
                        {translate('resources.hooks.message.new_message')}
                    </Typography>
                    <Typography variant="body2" fullWidth>
                        {translate('resources.hooks.message.message_part1')}
                    </Typography>
                    <Typography variant="body2" fullWidth>
                        {translate('resources.hooks.message.message_part2')}
                    </Typography>
                    <Typography variant="body2" fullWidth>
                        {translate('resources.hooks.message.message_part3')}
                    </Typography>
                    <SelectArrayInput label="resources.hooks.fields.events" source="events" choices={eventChoices} validate={[required()]} />
                    {isAdminPermissions() && (<ReferenceInput label={translate('resources.organizations.name', { smart_count: 1 })} source="organization_id" reference="organizations" validate={[required()]} defaultValue={localStorage.getItem('organizationId')}>
                        <AutocompleteInput optionText="name" matchSuggestion={() => true} />
                    </ReferenceInput>)}
                    <ReferenceInput label={translate('resources.devices.name', { smart_count: 1 })} source="device_id" reference="devices">
                        <AutocompleteInput optionText="name" helperText={translate('resources.hooks.device.warning')} matchSuggestion={() => true} />
                    </ReferenceInput>
                    <TextInput label="resources.hooks.fields.target_url" source="target_url" validate={[required()]} fullWidth />
                    <TextInput label="resources.hooks.fields.version" source="version" defaultValue="v3" disabled helperText={translate('resources.hooks.version.warning')} />
                    <BooleanInput label="resources.hooks.fields.auth" source="auth_active" />
                    <FormDataConsumer>
                        {({ formData }) =>
                            formData.auth_active === true &&
                            <TextInput variant="standard" label="resources.hooks.fields.auth_type" source="auth_type" defaultValue="Basic" disabled />

                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData }) =>
                            formData.auth_active === true &&
                            <TextInput variant="standard" label="resources.hooks.fields.auth_username" source="auth_username" />
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData }) =>
                            formData.auth_active === true &&
                            <PasswordInput variant="standard" label="resources.hooks.fields.auth_password" source="auth_password" />
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData }) => {
                            if (formData.auth === false) {
                                formData.auth_username = null;
                                formData.auth_password = null;
                            }
                        }}
                    </FormDataConsumer>


                </SimpleForm>
            </Create>

        )
    }))

export default HookCreate
