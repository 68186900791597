export const CHANGE_THEME = 'theme'
export const CHANGE_MARKER = 'marker'
export const SELECT_RIGHTMENU = 'selectRightMenu'
export const SELECT_LEFTMENU = 'selectLeftMenu'
export const ROUTING_MODE = 'routingMode'
export const DISABLE_CLUSTERING_AT_ZOOM = 'disableClusteringAtZoom '
export const NBR_HISTORY = 'nbrHistory'
export const DATE_INF = 'dateInf'
export const DATE_SUP = 'dateSup'
export const GOOGLE_KEY = 'GOOGLE_KEY'
export const API_KEY = 'API_KEY'
export const AUTHORITIES = 'role'
export const USER_FAV_LANGUAGE = 'fav_lang'
export const USER_FAV_LANGUAGE_IS_SET = 'fav_lang_is_set'
export const VERSION_CHECK_DATE = 'version_check_date'
export const ROLE_SUPER_ADMIN = 'role_super_admin'
export const ROLE_ORGA_ADMIN = 'role_orga_admin'
export const ROLE_ORGA_USER = 'role_orga_user'
export const ROLE_USER = 'role_user'

export const changeConfig = (type, value) => {
    if (value === false) {
        localStorage.setItem(type, 'false')
    } else if (value === true) {
        localStorage.setItem(type, 'true')
    } else {
        localStorage.setItem(type, value)
    }
    if (type === GOOGLE_KEY) localStorage.setItem(API_KEY, value)


    return {
        type,
        payload: value,
    }
}

