import React from 'react'
import { Field } from 'react-final-form'
import {
    Edit,
    email,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    translate,
    SaveButton,
    DeleteButton,
    Toolbar
} from 'react-admin'
import { ROLE_ORGA_ADMIN, ROLE_ORGA_USER, ROLE_USER } from '../../configuration/actions'
import { isAdminPermissions, isSuperAdmin } from '../../../helper/resourceHelper'
import PhoneInput from 'react-phone-number-input'
import { withStyles } from '@material-ui/core/styles'

const validateRequired = [required('Champs obligatoire')]
const validateEmail = [required('Champs obligatoire'), email('Syntaxe incorrecte')]

const choices = [
    { _id: 1, full_name: 'resources.role.member', value: ROLE_ORGA_USER },
    { _id: 2, full_name: 'resources.role.admin', value: ROLE_ORGA_ADMIN },
    { _id: 3, full_name: 'resources.role.owner', value: ROLE_USER },
]

const stylesPhoneNumberInput = theme => ({
    phoneInputStyle: {
        width: 256,
        marginTop: 16,
        margin: 0,
        border: 0,
        display: 'inline-flex',
        padding: 0,
        position: 'relative',
        minWidth: 0,
        flexDirection: 'column',
    },
})
const styles = theme => ({
    list: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        marginTop: 20,
    }
})

const PhoneNumberInput = withStyles(stylesPhoneNumberInput)(
    translate(props => {
        const { translate, classes } = props
        return (
            <PhoneInput
                country="FR"
                placeholder={translate('resources.users.phoneNumber')}
                value={props.input.value}
                onChange={props.input.onChange}
                className={classes.phoneInputStyle}
            />
        )
    })
)

const PhoneNumberField = props => {
    return <Field name="phone_number" component={PhoneNumberInput} />
}


const UserEditToolbar = (props) => {
    return (
        <Toolbar {...props}>
            <SaveButton {...props} />
            {
                isSuperAdmin() && <DeleteButton {...props} />
            }

        </Toolbar>
    );
};

const UserEdit = ({ translate, classes, ...props }) => (
    <Edit {...props} title={translate('resources.users.edit')} className={classes.list}>
        <SimpleForm toolbar={<UserEditToolbar />}>
            <TextInput
                label="resources.users.companyName"
                source="company_name"
            />
            <TextInput
                label={translate('resources.users.firstName')}
                type="email"
                source="first_name"
                validate={validateRequired}
            />
            <TextInput
                label={translate('resources.users.lastName')}
                source="last_name"
                translate={translate}
                validate={validateRequired}
            />
            <PhoneNumberField />
            <TextInput
                label={translate('resources.users.email')}
                source="email"
                disabled={true}
                validate={validateEmail}
            />
            <SelectInput
                label={translate('resources.users.role')}
                source="role"
                choices={choices}
                optionText="full_name"
                optionValue="value"
                validate={validateRequired}
            />
            <ReferenceInput
                label={translate('resources.organization.organization')}
                source="organization_id"
                reference="organizations"
                disabled={!isAdminPermissions()}
                translate={translate}
                validate={validateRequired}
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
)

export default withStyles(styles)(translate(UserEdit))
