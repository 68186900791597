import React from 'react'
import { Field } from 'react-final-form'
import {
    ArrayInput,
    AutocompleteInput,
    Create,
    FormDataConsumer,
    ReferenceInput,
    required,
    number,
    maxValue,
    minValue,
    NumberInput,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    translate,
} from 'react-admin'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { withStyles } from '@material-ui/core/styles'
import { isRootOrganizationUser } from '../../../helper/resourceHelper'

const styles = theme => ({
    width100: {
        width: 320,
        marginTop: 16,
    },
    list: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        marginTop: 20,
    },
    highGThreshold: {
        marginX: "5px"
    }
})

const defaultEmail = [
    { email: localStorage.getItem('userName') }
]

const channelsChoice = [{ channel: 'email' }, { channel: 'sms' }]

const PhoneNumberInput = withStyles(styles)(
    translate(props => {
        const { translate, classes } = props
        return (
            <PhoneInput
                country="FR"
                placeholder={translate('resources.notifications.enter_phone_number')}
                value={props.input.value}
                onChange={props.input.onChange}
                className={classes.width100}
            />
        )
    })
)

const PhoneNumberField = props => {
    return <Field name={`${props.source}.phone`} component={PhoneNumberInput} />
}

const NotificationCreate = withStyles(styles)(

    translate(({ classes, ...props }) => {
        const { translate } = props
        return (
            <Create {...props} title={props.translate('resources.notifications.create')} className={classes.list}>
                <SimpleForm variant="standard" margin="normal" redirect="list">
                    <ReferenceInput source="device_id" reference="devices" filter={{ includes: 'product' }} validate={[required()]}>
                        <AutocompleteInput optionText="name" matchSuggestion={() => true} />
                    </ReferenceInput>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData.device_id ?
                            <ReferenceInput label="resources.notifications.event" source="event" reference="notifications_events" filter={{ device_id: formData.device_id }} {...rest}>
                                <SelectInput optionText={choice => translate("resources.notifications." + choice.event)} optionValue="event" />
                            </ReferenceInput> :
                            <ReferenceInput label="resources.notifications.event" source="event" reference="notifications_events" {...rest}>
                                <SelectInput optionText={choice => translate("resources.notifications." + choice.event)} optionValue="event" />
                            </ReferenceInput>
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData }) =>
                            formData.event === 'high_g_thresholds_reached' &&
                            <NumberInput variant="standard" label="resources.notifications.high_g_duration_threshold" source="high_g_duration_threshold" validate={[required(), number(), minValue(0), maxValue(100000)]} />
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData }) =>
                            formData.event === 'high_g_thresholds_reached' &&
                            <NumberInput variant="standard"
                                label="resources.notifications.high_g_peak_threshold"
                                source="high_g_peak_threshold"
                                validate={[required(), number(), minValue(1024, "minimum 1G"), maxValue(1024 * 100, "maximum 100G")]}
                                parse={v => parseInt(v * 1024)}
                                format={v => v / 1024}
                            />
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData }) =>
                            formData.event === 'high_g_thresholds_reached' &&
                            <NumberInput
                                variant="standard"
                                label="resources.notifications.high_g_average_threshold"
                                source="high_g_average_threshold"
                                validate={[required(), number(), minValue(1024, "minimum 1G"), maxValue(1024 * 100, "maximum 100G")]}
                                parse={v => parseInt(v * 1024)}
                                format={v => v / 1024}
                            />
                        }
                    </FormDataConsumer>



                    <SelectInput
                        choices={channelsChoice}
                        source="channel"
                        optionText="channel"
                        optionValue="channel"
                        defaultValue="email"
                    />
                    <FormDataConsumer>
                        {({ formData }) =>
                            formData.channel === 'email' && (
                                <ArrayInput
                                    label={translate('resources.notifications.fields.to')}
                                    source="emails"
                                    defaultValue={defaultEmail}
                                    validate={[required()]}
                                >
                                    <SimpleFormIterator>
                                        <TextInput variant="standard" margin="normal" source="email" label={translate('resources.notifications.email')} validate={[required()]} />
                                    </SimpleFormIterator>
                                </ArrayInput>
                            )
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData }) =>
                            formData.channel === 'sms' && (
                                <ArrayInput
                                    label={translate('resources.notifications.fields.to')}
                                    source="phones"
                                    validate={[required()]}
                                >
                                    <SimpleFormIterator>
                                        <PhoneNumberField />
                                    </SimpleFormIterator>
                                </ArrayInput>
                            )
                        }
                    </FormDataConsumer>
                </SimpleForm>
            </Create>
        )
    }))

export default NotificationCreate
