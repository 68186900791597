// in src/comments.js
import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { Chip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import { hasWriteAccess } from '../../../helper/resourceHelper'
import { ArrayField, ChipField, EditButton, FunctionField, ReferenceField, SingleFieldList, TextField, translate } from 'react-admin';

const listStyles = theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardHeader: {
        paddingBottom: 0,
        height: 30,
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        paddingTop: 0
    },
    cardContentRow: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        margin: '0.5rem 0',
    },
    chip: {
        textTransform: 'uppercase',
    },
});

const MobileGrid = ({ classes, ids, data, basePath, permissions, translate }) => (
    <div style={{ margin: '1em' }}>
        {ids.map((id, index) => (
            <Card key={id} className={classes.card} record={data[id]}>
                <CardHeader
                    title={
                        <div className={classes.cardTitleContent}>
                            <h6>{`#${index + 1}`}</h6>
                            {hasWriteAccess(permissions) && <EditButton
                                resource="integrations"
                                basePath={basePath}
                                record={data[id]}
                            />}
                        </div>
                    }
                    className={classes.cardHeader}
                />
                <CardContent className={classes.cardContent}>
                    <span className={classes.cardContentRow}>
                        {translate('resources.devices.name', { smart_count: 1 })}:&nbsp;
                        <ReferenceField
                            allowEmpty={true}
                            source="device_id"
                            reference="devices"
                            record={data[id]}
                            basePath="devices"
                        >
                            <TextField source="name" />
                        </ReferenceField>
                    </span>
                    <span className={classes.cardContentRow}>
                        {translate('resources.integrations.fields.channel')}:&nbsp;
                        <ChipField
                            className={classes.chip}
                            source="channel"
                            record={data[id]}
                        />
                    </span>
                    <span className={classes.cardContentRow}>
                        {translate('resources.integrations.fields.event')}:&nbsp;
                        <FunctionField
                            label={translate('resources.integrations.fields.event')}
                            render={record => (
                                <Chip
                                    className={classes.chip}
                                    label={translate(`resources.integrations.${data[id].event}`)}
                                    record={data[id]}
                                />
                            )}
                        />
                    </span>
                    <span className={classes.cardContentRow}>
                        {translate('resources.integrations.fields.to')}:&nbsp;
                        <ArrayField source="to" record={data[id]}>
                            <SingleFieldList>
                                <ChipField
                                    classes={{ chip: classes.chipLower, label: classes.label }}
                                    source="dest"
                                />
                            </SingleFieldList>
                        </ArrayField>
                    </span>
                </CardContent>
            </Card>
        ))}
    </div>
);

MobileGrid.defaultProps = {
    data: {},
    ids: [],
};

const enhance = compose(
    withStyles(listStyles),
    translate
);

export default enhance(MobileGrid);