import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import AdvancedSearchMenu from './advancedsearch'
import QuickSearchMenu from './quicksearch'
import { HGrid } from '../../ui/Grid'
import { Filter, Responsive } from 'react-admin'
import { changeConfig } from '../configuration/actions'

const ContainerFilters = props => (
    <Responsive
        xsmall={
            <HGrid noflex mEnd sCenter>
            </HGrid>
        }
        small={
            <HGrid noflex mEnd sCenter>
                <QuickSearchMenu setPerPage={props.setPerPage} setFilters={props.setAllListsFilters} changeConfig={props.changeConfig} config={props.config} />
            </HGrid>
        }
        medium={
            <HGrid noflex noflexwrap mCenter sCenter>
                <AdvancedSearchMenu setFilters={props.setAllListsFilters} changeConfig={props.changeConfig} config={props.config} />
                <QuickSearchMenu setPerPage={props.setPerPage} setFilters={props.setAllListsFilters} changeConfig={props.changeConfig} config={props.config} />
            </HGrid>
        }
    />
)

ContainerFilters.propTypes = {
    setPerPage: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    ...Filter.propTypes,
}

const mapStateToProps = state => ({
    theme: state.config.theme,
    config: state.config,
})

const enhance = compose(
    connect(
        mapStateToProps,
        {
            changeConfig,
        }
    )
)

export default enhance(ContainerFilters)

