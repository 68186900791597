import moment from 'moment'

let lastMonth = 12
let lastDays = 31
let lastHours = 23

export function getTimeToStr(timestamp) {
    const dateMonths = new Date(timestamp).getMonth()
    const dateDays = new Date(timestamp).getDate()
    const dateHours = new Date(timestamp).getHours()
    let res = 0

    if (dateHours < lastHours) {
        if (dateDays < lastDays) {
            if (dateMonths < lastMonth && dateMonths === 1) res = moment(timestamp).format('YYYY')
            else res = moment(timestamp).format('DD MMM')
        } else res = moment(timestamp).format('DD MMM')
    } else {
        if (dateHours === lastHours) {
            if (dateDays !== lastDays) res = moment(timestamp).format('DD MMM')
            else res = `${moment(timestamp).format('HH')}h ${moment(timestamp).format('mm')}mn`
        } else res = `${moment(timestamp).format('HH')}h`
    }

    lastMonth = dateMonths
    lastDays = dateDays
    lastHours = dateHours

    return res
}

export function getDateTimeToFullLocalString(timestamp) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const dateTime = new Date(timestamp)
    return dateTime.toLocaleString({ timeZone: timezone })
}

export function getDateTimeToLocalString(timestamp) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const dateTime = new Date(timestamp)
    return dateTime.toLocaleString({ timeZone: timezone }, { month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" })
}



