import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Chip } from '@material-ui/core'
import {
    ArrayField,
    AutocompleteInput,
    BulkDeleteButton,
    TopToolbar,
    ChipField,
    CreateButton,
    Datagrid,
    EditButton,
    Filter,
    FunctionField,
    List,
    ReferenceField,
    ReferenceInput,
    Responsive,
    SingleFieldList,
    TextField,
    translate,
} from 'react-admin'
import { hasWriteAccess } from '../../../helper/resourceHelper'
import MobileGrid from './MobileGrid'

const styles = theme => ({
    chip: {
        textTransform: 'uppercase',
    },
    list: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        marginTop: 20,
    }
})

const NotificationActions = ({ basePath, filterValues, onUnselectItems, permissions, resource, selectedIds }) => (
    <TopToolbar>
        {hasWriteAccess(permissions) && <Fragment>
            <BulkDeleteButton basePath={basePath} selectedIds={selectedIds} filterValues={filterValues} resource={resource} onUnselectItems={onUnselectItems} />
        </Fragment>}
        {hasWriteAccess(permissions) && <CreateButton basePath={basePath} />}

    </TopToolbar>
)

const NotificationList = ({ permissions, translate, classes, ...props }) => (
    <List
        {...props}
        title={translate('resources.notifications.name', { smart_count: 2 })}
        actions={<NotificationActions />}
        className={classes.list}
        perPage={25}
        filters={
            <Filter {...props}>
                <ReferenceInput variant="standard" margin="normal" label={translate("resources.devices.name", { smart_count: 1 })} source="device_id" reference="devices" alwaysOn>
                    <AutocompleteInput optionText="name" matchSuggestion={() => true} />
                </ReferenceInput>
            </Filter>
        }>
        <Responsive
            small={
                <MobileGrid />
            }
            medium={
                <Datagrid>
                    <ReferenceField
                        allowEmpty={true}
                        label={translate('resources.devices.name', { smart_count: 1 })}
                        source="device_id"
                        reference="devices"
                        sortable={false}
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <ChipField
                        label={translate('resources.notifications.fields.channel')}
                        className={classes.chip}
                        source="channel"
                        sortable={false}
                    />
                    <FunctionField
                        label={translate('resources.notifications.fields.event')}
                        render={record => (
                            <Chip
                                className={classes.chip}
                                label={translate(`resources.notifications.${record.event}`)}
                            />
                        )}
                    />
                    <ArrayField source="to">
                        <SingleFieldList>
                            <ChipField
                                classes={{ chip: classes.chipLower, label: classes.label }}
                                source="dest"
                            />
                        </SingleFieldList>
                    </ArrayField>
                    {hasWriteAccess(permissions) && <EditButton />}
                </Datagrid>
            }
        />
    </List>
)

export default withStyles(styles)(translate(NotificationList))