import React, { useState } from 'react'
import { translate } from 'react-admin'
import { withStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import { green, red } from '@material-ui/core/colors'
import { getNumberOfDaysSinceLastSeen } from '../../../helper/resourceHelper'
import { Box, Typography } from '@mui/material'

const batteryStyle = {
    text: {
        textTransform: 'uppercase',
        fontSize: 10,
        height: 24,
    },
    good: { backgroundColor: green[500], color: 'white' },
    bad: { backgroundColor: red[500], color: 'white' },
}

export const Battery = withStyles(batteryStyle)(
    translate(props => {
        const { translate, record, classes } = props
        const [open, setOpen] = useState(false)
        const handleClick = () => {
            setOpen(!open)
        }
        if (record.last_battery && record.last_battery.unit === 'percent') {
            return (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                    <Typography variant='body2'>{`${record.last_battery.value}%`}</Typography>
                    <Typography variant="caption" style={{ fontSize: '10px' }}  >{new Date(record.last_battery.timestamp).toLocaleDateString()}</Typography>
                </Box>
            )

        } else if (record.last_battery && record.last_battery.status) {
            if (record.last_battery.status === 'good') {
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}>
                        <Chip
                            className={[classes.text, classes.good]}
                            label={translate('resources.devices.battery_status.good')}
                        />
                        <Typography variant="caption" style={{ fontSize: '7px' }}>{new Date(record.last_battery.timestamp).toLocaleDateString()}</Typography>
                    </Box>
                )
            } else if (record.last_battery.status === 'bad') {
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}>
                        <Chip
                            className={[classes.text, classes.bad]}
                            label={translate('resources.devices.battery_status.bad')}
                        />
                        <Typography variant="caption" style={{ fontSize: '7px' }}>{new Date(record.last_battery.timestamp).toLocaleDateString()}</Typography>
                    </Box>
                )
            } else {
                return null
            }
        } else if (getNumberOfDaysSinceLastSeen(record) > -1 && getNumberOfDaysSinceLastSeen(record) <= 3) {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>
                    <Chip

                        className={[classes.text, classes.good]}
                        label={translate('resources.devices.battery_status.good')}
                        onClick={handleClick}
                    />
                    {open && record.last_battery && record.last_battery.unit === 'millivolt' &&
                        <Typography variant='caption'>{`${record.last_battery.value} mV`}</Typography>

                    }
                    {open && record.last_battery && record.last_battery.unit === 'millivolt' &&

                        <Typography variant="caption" style={{ fontSize: '7px' }}>{new Date(record.last_battery.timestamp).toLocaleDateString()}</Typography>

                    }
                </Box>
            )
        } else if (getNumberOfDaysSinceLastSeen(record) > 3) {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>
                    <Chip
                        className={[classes.text, classes.bad]}
                        label={translate('resources.devices.battery_status.bad')}
                        onClick={handleClick}
                    />
                    {open && record.last_battery && record.last_battery.unit === 'millivolt' &&
                        <Typography variant='caption'>{`${record.last_battery.value} mV`}</Typography>

                    }
                    {open && record.last_battery && record.last_battery.unit === 'millivolt' &&

                        <Typography variant="caption" style={{ fontSize: '7px' }}>{new Date(record.last_battery.timestamp).toLocaleDateString()}</Typography>

                    }
                </Box >
            )
        } else {
            return null
        }
    })
)

