import { getLatitude, getLongitude, sVars } from './utils'

export const calculatePositions = (ids, data, device) => {


    let minLat, minLong, maxLat, maxLong
    let positions = []
    let bounds = undefined
    let center = undefined


    if (ids.length === 0) {
        center = { lat: 46.9656123, lng: 1.6435824 }


        return { bounds, center, positions }
    }

    ids.filter(id => data && data[id] && (data[id].last_location || data[id].latitude)).forEach(id => {
        const lat = getLatitude(data[id])
        const lng = getLongitude(data[id])
        if (lat && lng) {
            positions.push({ lat: lat, lng: lng, timestamp: data[id].last_location && data[id].last_location.timestamp ? data[id].last_location.timestamp : data[id].timestamp })
            if (!minLat || minLat > lat) {
                minLat = lat
            }
            if (!minLong || minLong > lng) {
                minLong = lng
            }
            if (!maxLat || maxLat < lat) {
                maxLat = lat
            }
            if (!maxLong || maxLong < lng) {
                maxLong = lng
            }
        }

    })

    if (minLat && minLong && maxLat && maxLong) {

        bounds = device ? undefined : [[minLat, minLong], [maxLat, maxLong]]
        center = device
            ? {
                lat: getLatitude(device),
                lng: getLongitude(device),
            }
            : undefined


    } else {
        center = { lat: 46.9656123, lng: 1.6435824 }

    }
    return { bounds, center, positions }
}

