// in src/comments.js
import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import { hasWriteAccess } from '../../../helper/resourceHelper'
import { BooleanField, EditButton, FunctionField, ReferenceField, TextField, translate  } from 'react-admin';

const listStyles = theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardHeader: {
        paddingBottom: 0,
        height: 30,
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        paddingTop: 0
    },
    cardContentRow: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        margin: '0.5rem 0',
    },
});

const MobileGrid = ({ classes, ids, data, basePath, permissions, translate }) => (
    <div style={{ margin: '1em' }}>
        {ids.map((id, index) => (
            <Card key={id} className={classes.card} record={data[id]}>
                <CardHeader
                    title={
                        <div className={classes.cardTitleContent}>
                            <h6>{`#${index+1}`}</h6>
                            {hasWriteAccess(permissions) && <EditButton
                                resource="geofences"
                                basePath={basePath}
                                record={data[id]}
                            />}
                        </div>
                    }
                    className={classes.cardHeader}
                />
                <CardContent className={classes.cardContent}>
                    <span className={classes.cardContentRow}>
                        {translate('resources.devices.name', { smart_count: 1 })}:&nbsp;
                        <ReferenceField
                            allowEmpty={true}
                            source="device_id"
                            reference="devices"
                            record={data[id]}
                            basePath="devices"
                        >
                            <TextField source="name" />
                        </ReferenceField>                    
                    </span>
                    <span className={classes.cardContentRow}>
                        {translate('resources.geofences.fields.address')}:&nbsp;
                        <FunctionField
                            render={() => data[id].formatted_address}
                        />
                    </span>
                    <span className={classes.cardContentRow}>
                        {translate('resources.geofences.fields.radius')}:&nbsp;
                        <FunctionField
                            render={() =>
                                data[id].radius < 10000 ? `${data[id].radius} m` : `${data[id].radius / 1000} km`
                            }
                            source="radius"
                        />
                    </span>
                    <span className={classes.cardContentRow}>
                        {translate('resources.geofences.fields.active')}:&nbsp;
                        <BooleanField
                            record={data[id]}
                            source="active"
                        />
                    </span>
                </CardContent>
            </Card>
        ))}
    </div>
);

MobileGrid.defaultProps = {
    data: {},
    ids: [],
};

const enhance = compose(
    withStyles(listStyles),
    translate
);

export default enhance(MobileGrid);