// in src/comments.js
import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import { withStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core'
import { green, red } from '@material-ui/core/colors'
import { DateField, FunctionField, translate } from 'react-admin';
import MeasureHistoryButton from '../../../ui/button/MeasureHistoryButton'
import AvatarField from '../../../ui/field/AvatarField'

const listStyles = theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardHeader: {
        paddingBottom: 0,
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
    },
    cardContentRow: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        margin: '0.75rem 0',
    },
    cardContentRowBackground: {
        backgroundColor: theme.palette.grey["400"], //mm
        color: theme.palette.common.black,
    },
    text: {
        textTransform: 'uppercase',
        fontSize: 10,
        height: 24,
    },
    ok: { backgroundColor: green[500], color: 'white' },
    nok: { backgroundColor: red[500], color: 'white' },
});

const getAvatar = record => {
    const defaultAvatar = <AvatarField avatarUrl="https://ticatagsas.s3.amazonaws.com/57f3315c-e601-410a-9749-57fa11e123fc" size="45" />
    if (!record)
        return defaultAvatar

    if (record.thumbnail_url)
        return <AvatarField avatarUrl={record.thumbnail_url} size="45" />

    if (record.product && record.product.image_url)
        return <AvatarField avatarUrl={record.product.image_url} size="45" />

    return defaultAvatar
}

const MobileGrid = ({ classes, ids, data, basePath, permissions, translate }) => (
    <div style={{ margin: '1em' }}>
        {ids.map(id => (
            <Card key={id} className={classes.card} record={data[id]}>
                <CardHeader
                    title={
                        <div className={classes.cardTitleContent}>
                            <h2>{`${data[id].name}`}</h2>
                        </div>
                    }
                    avatar={getAvatar(data[id])}
                    className={classes.cardHeader}
                />
                {data[id].last_temperature &&
                    <CardContent className={[classes.cardContent, classes.cardContentRowBackground]}>
                        <span className={classes.cardContentRow}>
                            {translate('resources.temperatures.head')}:&nbsp;{data[id].last_temperature.value}°C

                        </span>
                        <span className={classes.cardContentRow}>
                            {translate('resources.measures.timestamp')}:&nbsp;
                            <DateField
                                record={data[id]}
                                source="last_temperature.timestamp"
                                type="date"
                                showTime
                            />
                        </span>
                    </CardContent>

                }
                {data[id].last_co2 &&
                    <CardContent className={classes.cardContent}>
                        <span className={classes.cardContentRow}>
                            Co2:&nbsp; {data[id].last_co2.value} ppm
                        </span>
                        <span className={classes.cardContentRow}>
                            {translate('resources.measures.timestamp')}:&nbsp;
                            <DateField
                                record={data[id]}
                                source="last_co2.timestamp"
                                type="date"
                                showTime
                            />
                        </span>
                    </CardContent>

                }
                {data[id].last_humidity &&
                    <CardContent className={classes.cardContent}>
                        <span className={classes.cardContentRow}>
                            {translate('resources.humidity.head')}:&nbsp;
                            <FunctionField
                                label={'resources.humidity.head'}
                                render={() => `${data[id].last_humidity.value}°C`}
                            />
                        </span>
                        <span className={classes.cardContentRow}>
                            {translate('resources.measures.timestamp')}:&nbsp;
                            <DateField
                                record={data[id]}
                                source="last_humidity.timestamp"
                                type="date"
                                showTime
                            />
                        </span>
                    </CardContent>

                }
                {data[id].last_door &&
                    <CardContent className={[classes.cardContent, classes.cardContentRowBackground]}>
                        <span className={classes.cardContentRow}>
                            {translate('resources.door.head')}:&nbsp;
                            <FunctionField
                                label={'resources.door.head'}
                                render={() => data[id].last_door.status === 'open' ?
                                    <Chip
                                        className={[classes.text, classes.nok]}
                                        label={`${translate('resources.door.open')}`}
                                    /> :
                                    <Chip
                                        className={[classes.text, classes.ok]}
                                        label={`${translate('resources.door.closed')}`}
                                    />
                                }
                            />
                        </span>
                        <span className={classes.cardContentRow}>
                            {translate('resources.measures.timestamp')}:&nbsp;
                            <DateField
                                record={data[id]}
                                source="last_door.timestamp"
                                type="date"
                                showTime
                            />
                        </span>
                    </CardContent>
                }
                {data[id].last_shock &&
                    <CardContent className={classes.cardContent}>
                        <span className={classes.cardContentRow}>
                            {translate('resources.shock.head')}:&nbsp;
                            <FunctionField
                                label={'resources.shock.head'}
                                render={() => data[id].last_shock.status === 'detected' ?
                                    <Chip
                                        className={[classes.text, classes.nok]}
                                        label={`${translate('resources.shock.detected')}`}
                                    /> :
                                    <Chip
                                        className={[classes.text, classes.ok]}
                                        label={`${translate('resources.shock.clear')}`}
                                    />}
                            />
                        </span>
                        <span className={classes.cardContentRow}>
                            {translate('resources.measures.timestamp')}:&nbsp;
                            <DateField
                                record={data[id]}
                                source="last_shock.timestamp"
                                type="date"
                                showTime
                            />
                        </span>
                    </CardContent>

                }
                <CardActions>
                    <MeasureHistoryButton record={data[id]} />
                </CardActions>
            </Card>
        ))}
    </div>
);

MobileGrid.defaultProps = {
    data: {},
    ids: [],
};

const enhance = compose(
    withStyles(listStyles),
    translate
);

export default enhance(MobileGrid);