import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Chip } from '@material-ui/core'
import {
    ArrayField,
    AutocompleteInput,
    BulkDeleteButton,
    TopToolbar,
    ChipField,
    CreateButton,
    Datagrid,
    EditButton,
    Filter,
    FunctionField,
    List,
    ReferenceField,
    ReferenceInput,
    Responsive,
    SingleFieldList,
    TextField,
    translate,
} from 'react-admin'
import { hasWriteAccess } from '../../../helper/resourceHelper'
import MobileGrid from './MobileGrid'
import { Box } from '@mui/material'

const styles = theme => ({
    chip: {
        textTransform: 'uppercase',
    },
    list: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        marginTop: 20,
    }
})

const IntegrationActions = ({ basePath, permissions }) => (
    <TopToolbar>
        {hasWriteAccess(permissions) && <CreateButton resource='integrations_john_deere' basePath={basePath} />}
    </TopToolbar>
)

const IntegrationJDList = ({ permissions, translate, classes, ...props }) => (
    <List
        {...props}
        title={translate('resources.integrations.name', { smart_count: 2 })}
        actions={<IntegrationActions />}
        resource='integrations_john_deere'
        className={classes.list}
        bulkActionButtons={false}
        perPage={25}
        filters={
            <Filter {...props}>
                <ReferenceInput variant="standard" margin="normal" label={translate("resources.integrations.organization", { smart_count: 1 })} source="organization_id" reference="organizations" alwaysOn>
                    <AutocompleteInput optionText="name" matchSuggestion={() => true} />
                </ReferenceInput>
            </Filter>
        }>
        <Responsive
            small={
                <MobileGrid />
            }
            medium={
                <Datagrid>
                    <ReferenceField
                        label={translate('resources.integrations.organization', { smart_count: 1 })}
                        source="organization_id"
                        reference="organizations"
                        sortable={false}
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField
                        source="johndeere_organization_id"
                        label={translate('resources.integrations.jdOrganization')}
                        sortable="false"
                    />
                    <ChipField
                        label={translate('resources.integrations.status')}
                        className={classes.chip}
                        source="status"
                        sortable={false}
                    />
                    <TextField
                        source="email"
                        sortable="false"
                    />

                    <FunctionField
                        source="last_send"
                        label={translate('resources.integrations.last_send')}
                        render={record =>
                            new Date(record.last_send * 1000).toLocaleString()
                        }
                    />

                    <FunctionField
                        source="created_at"
                        label={translate('resources.integrations.created_at')}
                        render={record =>
                            new Date(record.created_at * 1000).toLocaleString()
                        }
                    />


                </Datagrid>
            }
        />
    </List>
)

const IntegrationList = (props) => {

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "center"
        }}>
            <IntegrationJDList {...props} />
        </Box>
    )
}

export default withStyles(styles)(translate(IntegrationList))