import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import LocationHistoryList from '../pages/locationsHistory/index'
import SensorHistoryList from '../pages/sensorsHistory/index'

import { AccountShow } from '../pages/account'
import Configuration from '../pages/configuration/Configuration'
import DeviceAdd from '../pages/device/add/DeviceAdd'
import DeviceSubscribe from '../pages/device/subscribe/DeviceSubscribe'
import UserInvite from '../pages/user/invite/UserInvite'
import CheckoutSuccess from '../pages/device/checkoutSuccess/checkoutSuccess'
import { AUTHORITIES, ROLE_SUPER_ADMIN, USER_FAV_LANGUAGE, USER_FAV_LANGUAGE_IS_SET, VERSION_CHECK_DATE } from '../pages/configuration/actions'
import { organizationName, rootOrganization } from '../rest/restClient'
import { supportedLanguage } from '../i18nProvider'
import { useSetLocale } from 'react-admin'
//import DashboardShow from '../pages/dashboard/index'

function getParameterByName(name) {
    let match = RegExp('[#/]' + name + '=([^&]*)').exec(window.location.hash)
    if (!match) {
        match = RegExp('[&]' + name + '=([^&]*)').exec(window.location.hash)
    }
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

export const loginSuccessWrapper = props => {
    if (/access_token|id_token|error/.test(window.location.hash)) {
        const accessToken = getParameterByName('access_token')
        localStorage.setItem('token', accessToken)

        const decodedToken = jwt_decode(accessToken)

        const tokenExpiryDate = decodedToken.exp
        localStorage.setItem('tokenExpiryDate', tokenExpiryDate)

        const userId = decodedToken.userId
        localStorage.setItem('userId', userId)

        const organizationId = decodedToken.organizationId
        localStorage.setItem('organizationId', organizationId)

        organizationName(organizationId)
            .then(organization => {
                localStorage.setItem('organizationName', organization.json.name)
            })
            .catch(err => console.log(JSON.stringify(err)))


        const authorities = decodedToken.authorities
        localStorage.setItem(AUTHORITIES, authorities[0].toLowerCase())

        if (authorities[0].toLowerCase() === ROLE_SUPER_ADMIN) {
            localStorage.setItem('userAllowed', true)
        } else {
            rootOrganization(organizationId)
                .then((response) => {
                    const userRootOrganization = response.json.root_organization_id
                    if (userRootOrganization === process.env.REACT_APP_ROOT_ORGANIZATION_ID) {
                        localStorage.setItem('userAllowed', true)
                    } else {
                        localStorage.setItem('userAllowed', false)
                    }

                }).catch(err => localStorage.setItem('userAllowed', false))
        }

        localStorage.setItem(VERSION_CHECK_DATE, new Date().toISOString());
        const userName = decodedToken.user_name
        localStorage.setItem('userName', userName)


        localStorage.setItem('userAllowed', true)
        const lang = decodedToken.language
        /*
        console.log("[customRoutes] supportedLanguage ====>", supportedLanguage)
        console.log("[customRoutes] decodedToken ====>", decodedToken)
        console.log("[customRoutes] localStorage.getItem(locale) ====>", localStorage.getItem('locale'))
        console.log("[customRoutes] decodedToken.language ====>", decodedToken.language)
        console.log("[customRoutes] lang ====>", lang)
        console.log("[customRoutes] supportedLanguage.includes(lang.toLowerCase()) ====>", supportedLanguage.includes(lang.toLowerCase()))
        */
        if (lang && supportedLanguage(lang) != null) {
            localStorage.setItem(USER_FAV_LANGUAGE, supportedLanguage(lang))
            localStorage.setItem(USER_FAV_LANGUAGE_IS_SET, false)
        }
        if (props && props.loginSuccess) props.loginSuccess()

    }
}


export default [
    <Route key={0} exact path="/locations/:id/history" component={LocationHistoryList} />,
    <Route key={1} exact path="/uaa/account" component={AccountShow} />,
    <Route
        key={2}
        path="/login"
        render={() => {
            window.location = process.env.REACT_APP_AUTH_URL
        }}
    />,
    <Route key={3} path="/devices/attach" render={() => <Redirect to="/devices" />} />,
    <Route key={4} exact path="/configuration" component={Configuration} />,
    <Route key={5} exact path="/measures/:id/history" component={SensorHistoryList} />,
    <Route key={6} exact path="/users/invite" component={UserInvite} />,
    <Route key={7} exact path="/devices/add" component={DeviceAdd} />,
    <Route key={8} exact path="/devices/:id/subscribe" component={DeviceSubscribe} />,
    // <Route key={9} exact path="/dashboard/:id" component={DashboardShow} />,
    <Route key={10} exact path="/devices/:id/checkout-success/" component={CheckoutSuccess} />,

]
