import React, { useState } from 'react'
import { Field } from 'react-final-form'
import {
    ArrayInput,
    AutocompleteInput,
    Create,
    FormDataConsumer,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    translate,
} from 'react-admin'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { withStyles } from '@material-ui/core/styles'
import { isRootOrganizationUser } from '../../../helper/resourceHelper'
import { Box, Button, Typography } from '@mui/material'

const styles = theme => ({
    width100: {
        width: 320,
        marginTop: 16,
    },
    list: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        marginTop: 20,
    }
})


const validateRequired = [required('Champs obligatoire')]


const NO_INTEGRATION_SELECTED = 0
const JD_INTEGRATION_SELECTED = 1
const FLEET_INTEGRATION_SELECTED = 2



const IntegrationChoices = translate((props) => {
    const { integrationChoice, setIntegrationChoice } = props
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Typography variant='h5' mt={4} mb={2}>{props.translate('resources.integrations.choose_platform')}</Typography>

            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: "center",
                justifyContent: "center"
            }}>
                {
                    integrationChoice === JD_INTEGRATION_SELECTED ?
                        <Button
                            variant="contained"
                            onClick={(e) => {
                                e.preventDefault()
                                setIntegrationChoice(NO_INTEGRATION_SELECTED)
                            }}
                            sx={{ margin: 2 }}
                        >
                            John Deere
                        </Button> :
                        <Button
                            variant="outlined"
                            onClick={(e) => {
                                e.preventDefault()
                                setIntegrationChoice(JD_INTEGRATION_SELECTED)
                            }}
                            sx={{ margin: 2 }}
                        >
                            John Deere
                        </Button>
                }

                {
                    integrationChoice === FLEET_INTEGRATION_SELECTED ?
                        <Button
                            variant="contained"
                            onClick={(e) => {
                                e.preventDefault()
                                setIntegrationChoice(NO_INTEGRATION_SELECTED)
                            }}
                            sx={{ margin: 2 }}
                        >
                            Fleet
                        </Button> :
                        <Button
                            variant="outlined"
                            onClick={(e) => {
                                e.preventDefault()
                                setIntegrationChoice(FLEET_INTEGRATION_SELECTED)
                            }}
                            sx={{ margin: 2 }}
                        >
                            Fleet
                        </Button>
                }


            </Box>
        </Box>
    )
}
)
const IntegrationJDCreate = withStyles(styles)(

    translate(({ classes, ...props }) => {
        const { translate } = props
        return (
            <Create {...props} title={props.translate('resources.integrations.create')} resource='integrations_john_deere' className={classes.list}>
                <SimpleForm variant="standard" margin="normal" redirect="list">
                    <ReferenceInput
                        label={translate('resources.integrations.organization')}
                        source="organization_id"
                        reference="organizations"
                        translate={translate}
                        validate={validateRequired}
                        defaultValue={() => localStorage.getItem('organizationId')}
                    >
                        <SelectInput optionText="name" validate={validateRequired} />
                    </ReferenceInput>
                    <TextInput
                        label={translate('resources.integrations.jdOrganization')}
                        source="johndeere_organization_id"
                        translate={translate}
                        validate={validateRequired}
                    />

                    <TextInput
                        variant="standard"
                        margin="normal"
                        source="email"
                        defaultValue={localStorage.getItem('userName')}
                        label={translate('resources.integrations.email')}
                        validate={[required()]} />

                </SimpleForm>
            </Create>
        )
    }))



const IntegrationCreate = (props) => {
    const [integrationChoice, setIntegrationChoice] = useState(NO_INTEGRATION_SELECTED);
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "center"
        }}>
            <IntegrationChoices integrationChoice={integrationChoice} setIntegrationChoice={setIntegrationChoice} />
            {
                integrationChoice === JD_INTEGRATION_SELECTED &&
                <IntegrationJDCreate {...props} />
            }



        </Box>
    )
}


export default IntegrationCreate
