import defaultMessages from 'ra-language-english'

export default {
    ...defaultMessages,
    resources: {
        about: 'About',
        month: 'month',
        year: 'year',
        for: 'for',
        per: 'per',
        common: {
            errorOccured: 'An error has occured',
            account: 'Account',
            search: 'Search',
            cancel: 'Cancel',
            clear: 'Clear',
            ok: 'Ok',
            history: 'History',
            help: 'Aide',
            documentation: 'Documentation',
            developers: 'Developers',
            delete_history: 'Delete device history',
            delete_history_paragraph: 'Are you sure  ? All locations, measures will be deleted. This action is permanent.',
            update_success: "Successful update"
        },
        configuration: {
            name: 'Configuration',
            general: 'General',
        },
        devices: {
            add_bulk_device: 'Add bulk devices',
            add_new_device: 'Add device',
            add_new_device_succes: 'Device Added',
            technology: 'Technology',
            activate_new_device: 'Activate new device',
            activate: 'Activate',
            advsearch: 'Advanced search',
            go_tracking_mode: "Switch to live tracking mode",
            last_tracking_mode_entry: "Last tracking mode entry",
            success_setting_on_tracking_mode: "Live tracking mode request sent successfully",
            error_setting_on_tracking_mode: "An error accured when setting on live tracking mode",
            already_on_tracking_mode: "The device is already on live tracking mode",
            tracking_mode_pending: "We sent live tracking mode request to device.",
            setting_on_tracking_mode: "Setting live tracking mode in progress",
            setting_on_tracking_mode_timeout: "Timeout error",
            already_attached: 'Device already attached',
            attaching: "The beacon is being attached to your account...",
            attached: 'Device Attached',
            error_attachement_checkout: "An error occurred while attaching your subscription!  You will be redirected to the support contact page... ",
            available: 'Device available',
            battery_status: {
                bad: 'Bad',
                good: 'Good',
            },
            product: 'Product',
            configuration: 'Device Settings',
            create: 'Add a new device',
            delete: 'Detach',
            device_id: 'Device identifier',
            device_name: 'Device Name',
            enter_device_id: 'Please, enter your device identifier',
            enter_device_id_instruction: `Please, enter the device id written on the back of your device`,
            enter_voucher: 'Enter the code written on your voucher',
            error_attachement: 'Error Device Attachement: ',
            fastsearch: 'Quick search',
            edit_title: 'Settings %{deviceName}',
            fields: {
                name: 'Name',
                mac_address: '#ID',
                battery: 'Battery',
                software_version: 'Firmware',
                secondary_mac_address: 'Secondary mac address',
                minor: 'Minor',
                major: 'Major',
                proximity_uuid: 'Proximity UUID',
                last_subscription: {
                    status: 'Subscription',
                    start_date: 'Start date',
                    end_date: 'End date',
                },
                thumbnail_url: 'image',
                last_seen: 'Last transmission',
                owner: 'Owner',
                organization: 'Organization',
            },
            config: {
                geolocation_period: 'Geolocation frequency (minutes)',
                retry_period: 'Geolocation retry (minutes)',
                wifi_sniffing: 'Wifi sniffing',
                bluetooth_advertising: 'Bluetooth advertising',
                bluetooth_advertising_frequency: 'Bluetooth advertising frequency (secondes)',
                temperature_low_limit: 'Low temperature threshold',
                temperature_up_limit: 'Up temperature threshold',
                humidity_low_limit: 'Low humidity threshold',
                humidity_up_limit: 'Up humidity threshold',
                send_message_in_motion: 'Send messages in motion',
                high_g_enabled: "High G",
                co2: {
                    frequencies: "Frequencies :",
                    plateform: "Plateform :",
                    plateformFrequency: "Plateform frequency",
                    plateformCaption: "Frequency of data sending to the platform (Default = 30 min)",
                    sensor: "Sensor :",
                    extra_measurement_count: "Number of extra measures",
                    sensorCaption: "Number of measurements in the sensor between two data sending (Default = 1)",
                    threshold: "Alert threshold :",
                    thresholdCaption: "(Default = 800 ppm)",
                    offset: "Offset",
                    offsetCaption: "It corresponds to the minimum concentration level recorded (Default = 400 ppm)"

                },
                plans: {
                    downgrading_sub_alert: "YOU ARE DOWNGRADING YOUR SUBSCRIPTION PLAN",
                    upgrading_sub_alert: "YOU ARE UPGRADING YOUR SUBSCRIPTION PLAN",
                    switching_sub_alert: "By selecting this configuration, your monthly subscription fee will be switched from",
                    switching_sub_alert_to: "By selecting this configuration, your monthly subscription fee will be switched to",
                    notice: "Notice",
                    to: "to",
                    return: "Return",
                    continue: "Continue",
                    select_plan: "Select a subscription plan",
                    select_plan_as_admin: "Select a configuration as Super Admin",
                    current_conf: "Current configuration",
                    current_subs: "Current subscription",
                    diff_conf_subs: "ALERT : The device configuration is not up to date with the subscription",
                    once_every: "Once every",
                    geolocation_period: "geolocation period (while Moving)",
                    keep_alive: "keep alive (Stationary)",
                    subscription_cost: "subscription cost",
                    price: "Price",
                    minutes: "minutes",
                    hours: "hours",
                    per_mounth: " / month",
                    since: "since"
                },
                egiot: {
                    init_battery: "Batteries renewal : "
                },
                tilocker: {
                    unlock_requested: "Unlock",
                    unlock_request_date_time: "Last unlock request",
                    geolocation_upload_period: "Geolocation upload period (in seconds)",
                    geolocation_upload_period_request_date_time: "Last geolocation upload period update",
                    biked_number: "Unlock code",
                    bike_number_max: "The code must be composed of 16 digits in hexadecimal",
                    bike_number_min: "The code must be composed of 16 digits in hexadecimal",
                    bike_search_requested: "Ring",
                    bike_search_request_date_time: "Last ring request",
                    history: "History"
                },
                unabiz: {
                    mode: "Mode",
                    sensitivity: "Sensitivity",
                    sensitivity_choice_low: "Low",
                    sensitivity_choice_mid: "Mid",
                    sensitivity_choice_high: "High",
                    upload_period: "Measurement frequency (10 min - 24h)",
                    upload_period_choice_10_min: "5 minutes (updated every 10 minutes)",
                    upload_period_choice_30_min: "15 minutes (updated every 30 minutes)",
                    upload_period_choice_1_h: "30 minutes (updated every 1 hour)",
                    upload_period_choice_6_h: "3 hours (updated every 6 hours)",
                    upload_period_choice_12_h: "6 hours (updated every 12 hours)",
                    upload_period_choice_24_h: "12 hours (updated every 24 hours)",
                    temperature_max_threshold: "Upper temperature threshold (°C)",
                    temperature_min_threshold: "Lower temperature threshold (°C)",
                    humidity_max_threshold: "Upper humidity threshold (%)",
                    humidity_min_threshold: "Lower humidity threshold (%)",
                }
            },
            device: 'Device',
            general: 'General',
            group: 'Group',
            group_name: 'Group name',
            delete_group: 'Delete group',
            subscription: 'Subscription',
            owner: 'Owner',
            from: 'from',
            geofence: 'Geofence',
            browse_location: 'View location',
            browse_subscription: 'View subscription',
            geolocation: 'Devices Location',
            identifier: 'Identifier',
            labels: {
                acknowledgeAlert: 'Acknowledge alert',
                acknowledgeMotion: 'Acknowledge motion',
                acknowledgeRollover: 'Acknowledge rollover',
                checkBattery: 'Check battery',
                configuration: 'Configuration',
                subscription: 'Subscription',
                coordinates: 'Coordinates',
                last_transmission: 'Last transmission',
                latitude: 'Latitude',
                location: 'Location: ',
                location_nbr: 'Location ',
                location_geo: 'Location: ',
                locationsHistory: 'Locations history',
                longitude: 'Longitude',
                accuracy: "Accuracy",
                altitude: "Altitude",
                measures: "Measures",
                speed: "Speed",
                changeIcon: 'Change icon',
                tracking_mode: "Live tracking"
            },
            location: 'location |||| locations',
            name: 'Device |||| Devices',
            no_device_subscription: 'No device does not have a subscription',
            not_found: 'Unknown device id',
            notifications: 'Events',
            properties: 'Properties',
            review_info: 'Device informations review',
            saisir_code: 'Enter the code of the prepaye card',
            select_offer: 'Please, select an offer below',
            status: {
                unknow: 'Unknown',
            },
            thks_number: 'Fill with the number located on the back of your Tifiz',
            tifiz_number: 'Device identifier',
            to: 'to',
            your_device: 'Your device',
            no_location: 'No location',
            no_subscription: 'No subscription',
            timestamp: 'Date/Time',
            rollover: {
                state: 'State',
                name: 'Rollover',
                detected: 'Rollovered',
                clear: 'Clear'
            },
            activity: {
                still: 'Still',
                moving: 'Moving'
            }
        },
        export: {
            create: 'Data export',
            action: 'Start export',
            maxDurationWarning: "Data export duration must be less than 7 days",
            fields: {
                data: 'Data type'
            },
            export_starting: 'An archive is generating. You will receive a link by email to download it.'
        },
        exportPdf: {
            download: 'Download',
            downloading: 'Downloading ...',
            creationInProgress: 'Creation in progress...',
            errors: {
                noLocations: 'No locations to download !',
                noMeasures: 'No measures to download !'
            },
            locationsHistoryDocument: {
                title: 'LOCATIONS REPORT',
                date: 'Date : ',
                beacon: 'Beacon',
                period: 'Period',
                id: "#ID",
                name: "Name",
                from: 'From',
                to: 'To',
                table: {
                    title: "History table",
                    location: 'Location',
                    dateTime: 'Date & Time',
                    adress: 'Adress',
                    latitude: 'Latitude',
                    longitude: 'Longitude',
                }
            },
            measuresHistoryDocument: {
                title: 'MEASURES REPORT',
                date: 'Date : ',
                beacon: 'Beacon',
                period: 'Period',
                id: "#ID",
                name: "Name",
                from: 'From',
                to: 'To',
                table: {
                    title: "History table",
                    measure: 'measure',
                    dateTime: 'Date et heure',
                    type: 'Type',
                    value: 'Value',
                }
            },
        },
        fops: {
            fields: {
                default_card: 'Default',
            },
            labels: {
                expiry_date: "Expiry date",
                number: 'Number',
                type: 'Type',
            },
            name: 'Form of payment |||| Forms of payment',
            card_number: 'Credit card number',
            card_expiry: 'Expiry date',
            card_cvc: 'CVC',
            create: 'Add form of payment',
        },
        fops_sepa: {
            fields: {
                default_card: 'Default',
                accountholder_name: "Name : ",
                email: "Email : ",
                note: `By providing your payment information and confirming such payment, you authorize (A) ${process.env.REACT_APP_ROOT_ORGANIZATION} and Stripe, our payment service provider and/or PPRO, its local service provider, to instruct your bank to debit your account, and (B) your bank to debit your account in accordance with such instructions. As part of your rights, you are entitled to a refund from your bank in accordance with the terms of your contract with your bank. Any request for a refund must be made within 8 weeks of the date your account was debited. Your rights are set out in a statement available from your bank. You agree to receive notifications of future debits up to 2 days before they occur.`,
            },
            labels: {
                iban: "IBAN",
                bank_code: 'Bank Code',
                type: 'Type',
            },
            name: 'SEPA direct debit',
            create: 'Add form of payment',
            found_nothing: "No SEPA direct debits are registered"
        },
        fops_card: {
            fields: {
                default_card: 'Default',
            },
            labels: {
                iban: "IBAN",
                bank_code: 'Bank Code',
                type: 'Type',
            },
            name: 'Credit Card',
            card_number: 'Credit card number',
            card_expiry: 'Expiry date',
            card_cvc: 'CVC',
            create: 'Add a credit card',
            found_nothing: "No credit card is registered"
        },
        geofences: {
            name: 'Geofence |||| Geofences',
            delete: 'Delete geofence',
            create: 'Create geofence',
            edit: 'Edit geofence',
            center: 'Center',
            latitude: 'Latitude',
            longitude: 'Longitude',
            trigger: 'Alert',
            fields: {
                radius: 'Radius',
                address: 'Address',
                on_entry: 'on entry',
                on_exit: 'on exit',
                active: 'Active',
            },
            draw: {
                save_modifications: 'Save modifications',
                cancel_modifications: 'Cancel modifications',
                select_geofence: 'Drag handles or markers to edit features',
                click_cancel: 'Click cancel to undo changes',
            },
            device_search: 'Search a device',
            enter_address: 'Enter an address',
            no_device_selected: 'You must select a device',
            no_center_defined: "You haven't defined a center",
        },
        humidity: {
            head: 'Humidity',
            last: 'Value',
            date: 'Last check',
        },
        battery: {
            head: 'Battery level',
        },
        pressure: {
            head: 'Pressure',
        },
        luminosity: {
            head: 'Ambiant light',
        },
        formaldehyde: {
            head: 'Formaldehyde',
        },
        tvoc: {
            head: 'Tvoc (Total volatile organic compounds)',
        },
        noise: {
            head: 'Noise',
        },
        ammonia: {
            head: 'Ammonia',
        },
        mox: {
            head: 'Mox',
        },
        aqi: {
            head: 'Air Quality Index',
            help_legend: {
                head_ref_levels: "Refrence Levels",
                head_air_info: "Air Information",
                head_tvoc_mg_m3: "TVOC (mg/m3)",
                head_air_quality: "Air Quality",
                level1: "Level 1",
                level2: "Level 2",
                level3: "Level 3",
                level4: "Level 4",
                level5: "Level 5",
                air_info_level1: "Clean Hygienic Air (Target Value)",
                air_info_level2: "Good Air Quality (if no threshold is exceeded)",
                air_info_level3: "Noticeable Comfort Concerns (Not recommended for exposure > 12 months)",
                air_info_level4: "Significant Comfort Issues (Not recommended for exposure > 1 months)",
                air_info_level5: "Unacceptable conditions (Not recommended)",
                air_quality_level1: "Very Good",
                air_quality_level2: "Good",
                air_quality_level3: "Medium",
                air_quality_level4: "Poor",
                air_quality_level5: "Bad",

            }
        },
        pm1: {
            head: 'PM1 Particles',
        },
        pm2P5: {
            head: 'PM2.5 Particles',
        },
        pm10: {
            head: 'PM10 Particles',
        },
        benzen: {
            head: 'Benzen',
        },
        co2: {
            head: 'CO2',
            head_min: 'Co2',
            treshold: 'Threshold',
            max: 'Maximum',
            min: 'Minimum',
            avg: 'Average',

        },
        internal_error: 'Internal error',
        lang: 'Language',
        door: {
            head: 'Lid',
            last: 'Value',
            date: 'Last check',
            open: 'Open',
            closed: 'Closed',
        },
        waterLeak: {
            head: 'Water leak',
            on: 'On',
            off: 'Off',
        },
        presence: {
            head: 'Presence',
        },
        battery_status: {
            head: 'Battery status',
        },
        button: {
            head: 'Button',
        },
        motion: {
            head: 'Motion',
        },
        high_g: {
            head: 'High G',
            average: 'Average',
            duration: 'Duration',
            peak: 'Peak',
            gForce: "Force G",
            durationInMs: "Duration (ms)"
        },
        location: {
            head: 'Location'
        },
        locations: {
            name: 'Location |||| Locations',
            labels: {
                addDevice: 'Add new device',
                devicesPositions: 'Devices Location',
                hideMenu: 'Menu',
                exportData: 'Export data',
                delete: "Delete history",
                location: 'Location',
                search: 'Search a device',
            },
            locationNone: 'None',
            location25: 'Last 25 locations',
            location50: 'Last 50 locations',
            location100: 'Last 100 locations',
            location200: 'Last 200 locations',
            location500: 'Last 500 locations',
            locationYesterday: 'Yesterday',
            locationToday: 'Today',
            locationWeek: 'Last week',
            locationMonth: 'Last month',
            locationYear: 'Last year',
        },
        map: {
            road: 'Road',
            satellite: 'Satellite',
            routing: 'Itinerary',
            routing_mode: {
                driving: 'Driving',
                cycling: 'Cycling',
                walking: 'Walking',
                boat: 'Boat',
                no_routing: 'No routing'
            },
            clustring: {
                on: "Clustring ON",
                off: "Clustring OFF"
            }
        },
        markerpopup: 'Display on click',
        notifications: {
            button_press: 'Button press',
            button_double_pressed: 'Button press - Double click',
            button_held: 'Button press - Long click',
            button_pressed: 'Button press - Simple click',
            shock_detected: 'Shock detected',
            motion_detected: 'Motion detected',
            rollover_detected: 'Rollover detected',
            door_opened: 'Door opened',
            water_leak_detected: 'Water leak detected',
            temperature_threshold_reached: 'Temperature threshold reached',
            co2_threshold_reached: 'Co2 concentration threshold reached',
            humidity_threshold_reached: 'Humidity threshold reached',
            high_g_thresholds_reached: "High G thresholds reached",
            high_g_peak_threshold: "Peak threshold (G)",
            high_g_average_threshold: "Average threshold (G)",
            high_g_duration_threshold: "Duration threshold (ms)",
            battery_status_bad: 'Bad battery status',
            battery_low: 'Low battery level',
            address_country_changed: 'Country change',
            address_state_changed: 'State change',
            address_city_changed: 'City change',
            channel: 'Channel',
            create: 'Create alert',
            edit: "Edit alert",
            delete: "Delete alert",
            enter_phone_number: 'Enter a phone number',
            email: 'Email',
            event: 'Event',
            fields: {
                device_id: 'Device',
                channel: 'Channel',
                event: 'Event',
                to: 'Recipients',
            },
            geofence_crossed: 'Geofence Crossed',
            message: 'message',
            name: 'Alert |||| Alerts',
            option: 'Option',
            update: 'Edit',
        },
        integrations: {
            choose_platform: "Choose platform",
            name: 'Integration |||| Integrations',
            create: 'Create integration',
            organization: "Organization",
            jdOrganization: "John Deere organization ID",
            email: 'Email',
            status: "Status",
            last_send: "Last connection",
            created_at: "Created"

        },
        organization:
        {
            description: 'Description',
            name: 'Name',
            organization: 'Organization',
            parent: 'Parent organization',
            root: 'Root organization',
            update: 'Edit',
        },
        organizations: {
            create: "Create organization",
            name: 'Organizations',
            edit: 'Edit une organization'
        },
        invoices: {
            name: 'Invoice |||| Invoices',
            date: 'Date',
            description: 'Description',
            number: 'Reference',
            price: 'Price',
            download: 'Download',
            period: "Period",
            price_tax_included: "Price (incl all taxes)",
            tax_amount: "Tax amount"
        },
        product: {
            name: 'Product Name',
            release: 'Release Date',
            versionNumber: 'Version Number',
            patch: 'Patch Number',
        },
        profile: 'User Profile',
        role: {
            owner: 'Owner',
            member: 'Member',
            admin: 'Admin',
            superadmin: 'Super Admin',
        },
        shock: {
            head: 'Shock',
            last: 'Value',
            date: 'Last check',
            detected: 'Detected',
            clear: 'Clear'
        },
        measures: {
            name: 'Measure |||| Measures',
            sensorNone: 'None',
            sensor25: 'Last 25 measures',
            sensor50: 'Last 50 measures',
            sensor100: 'Last 100 measures',
            sensor200: 'Last 200 measures',
            sensor500: 'Last 500 measures',
            sensorYesterday: 'Yesterday',
            sensorToday: 'Today',
            sensorWeek: 'Last week',
            sensorMonth: 'Last month',
            sensorYear: 'Last year',
            temphum: 'Temperature/Humidity',
            timestamp: 'Date',
            history: 'See measures history',
            status: 'Status',
            date: 'Date',
            water_leak_status_off: 'Off',
            water_leak_status_on: 'On',
            door_status_open: 'Open',
            door_status_closed: 'Closed',
            shock_status_clear: 'Not Detected',
            shock_status_detected: 'Detected',
            presence_status_detected: 'Detected',
            presence_status_none: 'Undetected',
            battery_status_status_good: 'Good',
            battery_status_status_bad: 'Bad',
            button_status_click: 'Click',
            button_status_double_click: 'Double click',
            button_status_hold: 'Hold',
            motion_status_still: 'Fixed',
            motion_status_moving: 'Moving',
        },
        plans: {
            name: 'Plan |||| Plans',
        },
        subscriptions: {
            accept_tc: 'Please accept sales condition',
            add_card_success: 'Form of payment saved',
            add_card_error: 'Please verify your paiement details',
            add_fop_error: 'An error has occured during credit card registration process, please retry',
            description: "You will be charged every month on your credit card",
            charged_now: 'Charged now : %{amount} %{currency_symbol}',
            enter_voucher: 'Please, enter the number written on your voucher',
            fields: {
                active: 'Active',
                device: {
                    name: 'Device name',
                },
                end_date: 'End date',
                plan: {
                    description: 'Offer',
                    type: 'Type',
                },
                start_date: 'Start date',
                status: 'Status',
            },
            labels: {
                active: 'Active',
            },
            statuses: {
                active: 'Active',
                unpaid: 'Unpaid',
                past: 'Past',
                canceled: 'Canceled',
            },
            states: {
                onGoing: 'On going',
                comingNext: 'Coming next',
                past: 'Past',
                canceled: 'Canceled',
                unpaid: 'Waiting for next payment attempt',
            },
            name: 'Subscription |||| Subscriptions',
            add_subscription: 'Add subscription',
            cancel_subscription: 'Cancel subscription',
            subscription_canceled: 'Subscription canceled',
            no_subscription_found: 'No subscription associated to the device',
            subscription_success: 'Your subscription has been registered',
            subscription_creating: 'Your subscription is in progress...',
            subscription_creation_error: "An error occurred while creating your subscription!  You will be redirected to the support contact page... ",
            no_selected_plan: 'No plan selected',
            postpaid_name: 'Monthly plan',
            prepaid: 'Prepaid',
            prepaid_name: 'Prepaid plan - %{quantity} month',
            prepaid_offer: 'Online prepaid',
            prepaid_offer_description: 'Purchase a prepaid plan',
            selected_offer: 'Selected offer',
            tc:
                'I accept <a href="http://mytifiz.com/legal/cgv" target="_blank"> the terms of use</a> and related subscription terms above.',
            tc_postpaid:
                "You will be charged %{amount} %{currency_symbol} monthly until you decide to terminate your subscription. Amounts already charged will not be refunded upon cancellation. A cancellation fee will be applied if you cancel within the first twelve months. Your payment information is encrypted and secure. All amounts are shown in %{currency_code}.",
            tc_prepaid:
                'You will be charged %{amount} %{currency_symbol}. Amounts already deducted will not be refunded. At the end of the subscription period, your subscription will not be automatically renewed. Your payment details are encrypted and secure. All amounts are indicated in %{currency_code}.',
            voucher: 'Voucher',
            voucher_description: 'When you have a voucher card (code) to activate the device',
            voucher_already_burnt: 'Code already used',
            voucher_code: 'Number',
            voucher_name: 'Voucher - %{quantity} month',
            voucher_not_found: 'Unknown code',
            voucher_title: 'Voucher code : %{voucher_code}',
        },
        temperatures: {
            head: 'Temperature',
            value: 'Last temperature',
            date: 'Date',
        },
        theme: 'Theme',
        timechart: 'Temperature history',
        time_range: 'Zoom',
        title: {
            configuration: {
                list: 'Configuration',
            },
            devices: {
                list: 'Devices',
                editer: 'Device Edit',
                create: 'Device Create',
            },
            geofences: {
                list: 'Geofences',
                editer: 'Geofence Edit',
                create: 'Geofence create',
            },
            locations: {
                list: 'Locations',
                editer: 'Location History',
            },
            notifications: {
                list: 'Notifications',
                editer: 'Notification Edit',
                create: 'Notification Create',
            },
            organizations: {
                list: 'Organizations',
                editer: 'Organization Edit',
                create: 'Organization Create',
            },
            measures: {
                list: 'Sensors',
                history: 'Measures History',
                create: 'Sensor Create',
            },
            users: {
                list: 'Users',
                editer: 'User Edit',
                create: 'User Create',
            },
        },
        upload: 'Choose your file ...',
        account: {
            edit: 'Profile'
        },
        users: {
            invite_action: 'Invite user',
            create: 'Create user',
            edit: 'Edit user',
            delete: 'Remove the user from your organization',
            email: 'Email',
            cemail: 'Confirm email',
            password: 'Password',
            cpassword: 'Confirm password',
            companyName: 'Company',
            customer: 'Customer',
            firstName: 'First Name',
            phoneNumber: 'Phone number',
            birth_date: 'Birth date',
            invite: 'Send Invitation',
            lastName: 'Last Name',
            name: 'User |||| Users',
            role: 'Role',
            update: 'Edit',
            user: 'User',
            users: 'Users',
            language: 'Language',
            profile: 'Profile',
            passwords_not_match: 'The passwords don\'t match',
            invalid_password: 'The password must be at least 6 characters long',
            password_changed: 'Password changed',
            email_changed: 'Email updated',
            emails_not_match: 'The emails don\'t match',
            invalid_email: 'Invalid email',
            lang: {
                fr: 'French',
                en: 'English',
            }
        },
        hooks: {
            create: 'Create webhook',
            edit: 'Edit webhook',
            name: 'Webhook |||| Webhooks',
            location_changed: 'Location changed',
            new_location: 'New location',
            state_changed: 'State changed',
            button_pressed: 'Button press',
            button_pressed_click: 'Button press - Click',
            button_pressed_double_click: 'Button press - Double click',
            button_pressed_hold: 'Button press - Long click',
            new_co2: "Co2 rate - New measure",
            new_temperature: 'Temperature - New measure',
            new_humidity: 'Humidity - New measure',
            new_door: 'Door - New status',
            new_water_leak: 'Water leak - New status',
            new_shock: 'Shock - New status',
            new_rollover: 'Rollover - New status',
            new_battery_level: 'Battery level - New measure',
            new_battery_status: 'Battery - New status',
            new_motion: 'Motion - New status',
            device: {
                warning: 'Leave empty to apply on all devices'
            },
            version: {
                warning: 'Only webhook v3 creation is allowed',
            },
            fields: {
                target_url: "Url",
                events: "Events",
                version: 'Version',
                active: 'Active',
                auth: "Authentication",
                auth_type: "Type",
                auth_username: 'Username',
                auth_password: 'Password',
            },
            message: {
                new_message: "What's new in V3:",
                message_part1: "GPS locations can be sent back via two events:",
                message_part2: "- New location: this event sends you the location of the device at each message",
                message_part3: "- Location changed: this event sends you the location of the device only if it is different from the previous one (with a accuracy of 20 meters)"
            }
        }
    },
}
