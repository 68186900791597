import React from 'react'
import {
    Create,
    email,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    translate,
} from 'react-admin'
import { ROLE_ORGA_ADMIN, ROLE_ORGA_USER, ROLE_USER } from '../../configuration/actions'
import { withStyles } from '@material-ui/core/styles'

const validateRequired = [required('Champs obligatoire')]
const validateEmail = [required('Champs obligatoire'), email('Syntaxe incorrecte')]
const styles = theme => ({
    list: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        marginTop: 20,
    }
})
const UserInvite = translate(({ classes, ...props }) => {
    const { translate } = props

    return (
        <Create {...props} title={'resources.users.invite_action'} basePath={"/users/invite"} resource="users" className={classes.list}>
            <SimpleForm redirect="list">
                <TextInput
                    label={translate('resources.users.email')}
                    source="email"
                    translate={translate}
                    validate={validateEmail}
                />
                <SelectInput
                    label={translate('resources.users.role')}
                    source="role"
                    choices={choices}
                    optionText="full_name"
                    optionValue="value"
                    translate={translate}
                    defaultValue={() => choices[0].value}
                    validate={validateRequired}
                />
                <ReferenceInput
                    label={translate('resources.organization.organization')}
                    source="organization_id"
                    reference="organizations"
                    translate={translate}
                    validate={validateRequired}
                    defaultValue={() => localStorage.getItem('organizationId')}
                    perPage={500}
                    sort={{ field: 'name', order: 'ASC' }}
                >
                    <SelectInput source="name" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    )
})

const choices = [
    { _id: 1, full_name: 'resources.role.member', value: ROLE_ORGA_USER },
    { _id: 2, full_name: 'resources.role.admin', value: ROLE_ORGA_ADMIN },
    { _id: 3, full_name: 'resources.role.owner', value: ROLE_USER },
]

export default withStyles(styles)(UserInvite)