import React, { Component } from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import classNames from 'classnames'
//material
import { withStyles } from '@material-ui/core/styles'
import { Avatar, Divider, ListItem, ListItemText } from '@material-ui/core'
//react-admin
import { translate } from 'react-admin'

const styles = theme => ({
    smallAvatar: {
        width: 25,
        height: 25,
        marginRight: 10,
        padding: 5,
    },
    listItem: {
        padding: '8px',
    },
    listItemSelected: {
        padding: '8px',
        backgroundColor: theme.palette.background.active,
        borderLeftWidth: 2,
        borderLeftColor: theme.palette.background.activemenuborder,
        borderStyle: 'solid',
    },
    listItemText: {
        paddingRight: 100,
        '& .MuiTypography-colorTextSecondary': {
            color: theme.palette.text.secondary,
        },


    },
})

const getSecondaryText = location => {
    if (location.formatted_address) {
        return <small>{location.formatted_address}</small>
    } else if (location.latitude && location.longitude) {
        return (
            <small>
                {location.latitude.toFixed(5)} / {location.longitude.toFixed(5)}
            </small>
        )
    } else {
        return null
    }
}

class menuItem extends Component {
    shouldComponentUpdate(nextProps) {
        if (nextProps.record !== this.props.record || nextProps.selected !== this.props.selected) return true

        return false
    }
    render() {
        if (!this.props.record) return <div />

        const displayTimestampOptions = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        }
        const { index, record, classes, onClick, selected } = this.props
        const { timestamp } = record
        return (
            <div>
                <ListItem
                    button
                    key={record.id}
                    onClick={() => onClick(record)}
                    className={selected ? classNames(classes.listItemSelected) : classNames(classes.listItem)}
                >
                    <Avatar className={classNames(classes.smallAvatar)}>{index + 1}</Avatar>
                    <ListItemText
                        primary={new Date(timestamp).toLocaleDateString('fr-FR', displayTimestampOptions)}
                        secondary={getSecondaryText(record)}
                        className={classes.listItemText}
                    />
                </ListItem>
                <Divider />
            </div>
        )
    }
}

menuItem.propTypes = {
    index: PropTypes.number.isRequired,
    record: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired,
    translate: PropTypes.func.isRequired,
}

const enhance = compose(
    withStyles(styles),
    translate
)

export default enhance(menuItem)
