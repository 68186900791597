
import React, { Fragment } from 'react'
import {
    AutocompleteInput,
    BooleanInput,
    Create,
    FormDataConsumer,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    translate,
} from 'react-admin'
import { withStyles } from '@material-ui/core/styles'
import { DeviceAddButton } from './DeviceAddButton'

const styles = theme => ({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    list: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
    }

})


const technologyChoices = [
    { technology: 'sigfox', label: 'SIGFOX' },
    { technology: 'lora', label: 'LoRaWAN' },
    { technology: 'ble', label: 'Bluetooth' },
    { technology: 'sim', label: 'SIM' },
]

const productChoices = (technology) => {
    const choices = []

    switch (technology) {
        case 'sigfox':
            choices.push(
                { id: '8B241599-72AE-4E4B-8C43-0735186585A1', product: 'Ticatag - TiFiz v1' },
                { id: '06711B23-C273-48DC-B240-FA789BD90F0D', product: 'Ticatag - TiFiz HP' },
                { id: '5662E613-896E-41A0-8E10-C90BF8D82B78', product: 'Thinxtra - XPress' },
                { id: '	6191F46E-1560-4407-A187-D5F64D8C83CC', product: 'Thinxtra - XTrakR' },
                { id: 'AA8691A1-295F-4821-8493-BFC7EB759C71', product: 'Thinxtra - XTrak' },
                { id: '39A35BBE-41CA-46B4-87B2-4FE4C5828352', product: 'Thinxtra - XTrakS' },
                { id: '28D25963-AE1D-461C-A513-61B5A6DC3AE9', product: 'Thinxtra - XSense' },
                { id: '281A9A54-D33C-4A8A-ACFA-3365A6739ADF', product: 'DigitalMatter - Oyster' },
                { id: '35C63158-4879-4DC6-B1DC-102F4077E41C', product: 'Ercogener - EG-IOT 8281 Sigfox' },
                { id: '	643B640B-59B6-48E6-8D25-F7529B26DE18', product: 'Unabiz - UnaProtect' },
                { id: '28D25963-AE1D-461C-A513-61B5A6DC3AE9', product: 'Unabiz - UnaSense' },
                { id: '9B807756-C799-4BCD-AFE7-54186B6FB640', product: 'Unabiz - UnaBeacon' },
            )
            break;
        case 'lora':
            choices.push(
                { id: '5E606810-C98E-4068-A802-CFF4FEB7FE64', product: 'Ercogener - EG-IOT 4281 Lora' },
            )
            break;
        case 'sim':
            choices.push(
                { id: '160D0142-901E-45E7-8CFE-F0E6D2349C7C', product: 'Ercogener - EG-IoT - SIM LTE-M' },
                { id: '160D0142-901E-45E7-8CFE-F0E6D2349C7D', product: 'Ercogener - EG-IoT - SIM 3G' },
                { id: '160D0142-901E-45E7-8CFE-F0E6D2349C7E', product: 'Ercogener - EG-IOT - SIM 1AA6' },
                { id: '13AEF9FB-3CE4-4999-898B-571501631651', product: 'PYNG-ez (YabbyEDGE)' },
                { id: '281A9A54-D33C-4A8A-ACFA-3365A6739ADE', product: 'Xtrak-4G (Yabby3-4G)' },
                { id: '159A9664-D44C-407A-ACFA-9165A6737C52', product: 'YabbyGPS' },
                { id: '0d90d455-e073-46d8-bcce-fee510dbc37e', product: 'XtrakR-4G (Queclink-GL521MG)' },
                { id: 'fcea0d74-1543-458a-be5b-560f26785a39', product: 'Xtrak-4G XL(Oyster3-4G)' },
                { id: '364BC268-6725-41EC-92AE-540DA2F35324', product: 'TG003-OBD' },
                { id: '364BC268-6725-41EC-92AE-540DA2F35325', product: 'TG100' },
                { id: '364BC268-6725-41EC-92AE-540DA2F35326', product: 'TG250' },
                { id: '364BC268-6725-41EC-92AE-540DA2F35327', product: 'TG5200' },
                { id: '364BC268-6725-41EC-92AE-540DA2F35328', product: 'TG920' },
                { id: '364BC268-6725-41EC-92AE-540DA2F35329', product: 'TG204' },
                { id: '364BC268-6725-41EC-92AE-540DA2F35330', product: 'LIGHTER_100' },
                { id: '364BC268-6725-41EC-92AE-540DA2F35331', product: 'TFT100-60V' },
                { id: '364BC268-6725-41EC-92AE-540DA2F35332', product: 'TFT100-72V' },
                { id: '364BC268-6725-41EC-92AE-540DA2F35333', product: 'TFT100-60V-HIGH' },
                { id: '364BC268-6725-41EC-92AE-540DA2F35334', product: 'TG130-4G' },
                { id: '364BC268-6725-41EC-92AE-540DA2F35338', product: 'TG230 - IP67' },
                { id: 'EECD47D3-1953-4C40-8A12-C60D1A4A2907', product: 'TG905-4G' },

            )
            break;
        case 'ble':
            choices.push(
                { id: 'B775F439-7DAC-4997-BA15-C9C374E249B2', product: 'Minew - E8' },
                { id: 'D3B9A954-35AE-4D5C-968E-9029B6CC5AB0', product: 'Minew - E6' },
                { id: 'C5A9EB37-18E0-452E-BFB7-CD053DC35D22', product: 'Ticatag - TiBe Connect' }
            )
            break;
        default:
            break;
    }

    return choices;
}

const DeviceAddToolbar = props => (
    <Toolbar  {...props} >
        <DeviceAddButton {...props} />
    </Toolbar>
);

const DeviceAdd = withStyles(styles)(
    translate(props => {
        const { classes, translate } = props
        return (
            <Create {...props} title={translate('resources.devices.add_new_device')} basePath={"/devices/add"} resource="devices" className={classes.list}>
                <SimpleForm variant="standard" margin="normal" redirect="list" toolbar={<DeviceAddToolbar />}>
                    <TextInput source="name" helperText="Ex: XPress, Tifiz #1234" validate={[required()]} />
                    <ReferenceInput label={translate('resources.organizations.name', { smart_count: 1 })} source="organization_id" reference="organizations" validate={[required()]} defaultValue={localStorage.getItem('organizationId')}>
                        <AutocompleteInput optionText="name" matchSuggestion={() => true} />
                    </ReferenceInput>
                    <SelectInput label="resources.devices.technology"
                        source="technology"
                        optionText="label"
                        optionValue="technology"
                        defaultValue="sigfox"
                        choices={technologyChoices}
                        validate={[required()]} />
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.technology === 'sigfox' && (<Fragment>
                                <TextInput variant="standard" margin="normal" source="sigfox_id" label="Sigfox #ID" className={classes.inlineBlock} validate={[required()]} />
                                <TextInput variant="standard" margin="normal" source="sigfox_pac" label="Sigfox PAC" className={classes.inlineBlock} />
                            </Fragment>
                            )
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.technology === 'lora' && (
                                <TextInput variant="standard" margin="normal" source="dev_eui" label="Dev EUI" validate={[required()]} />
                            )
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.technology === 'sim' && (
                                <TextInput variant="standard" margin="normal" source="imei" label="IMEI" validate={[required()]} />
                            )
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.technology === 'ble' && (
                                <TextInput variant="standard" margin="normal" source="mac_address" label="MAC Address" validate={[required()]} />
                            )
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.technology === 'ble' && (
                                <BooleanInput source="ibeacon" label="iBeacon" validate={[required()]} />
                            )
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.ibeacon === true && (
                                <Fragment>
                                    <NumberInput variant="standard" margin="normal" source="minor" label="resources.devices.fields.minor" className={classes.inlineBlock} validate={[required()]} />
                                    <NumberInput variant="standard" margin="normal" source="major" label="resources.devices.fields.major" className={classes.inlineBlock} validate={[required()]} />
                                    <TextInput variant="standard" margin="normal" source="proximity_uuid" label="resources.devices.fields.proximity_uuid" className={classes.inlineBlock} validate={[required()]} />
                                </Fragment>
                            )
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            const choices = productChoices(formData.technology)
                            return (
                                <SelectInput label="resources.devices.product"
                                    variant="standard"
                                    margin="normal"
                                    source="product_id"
                                    optionText="product"
                                    optionValue="id"
                                    defaultValue={choices.length > 0 ? choices[0].id : null}
                                    choices={choices}
                                    validate={[required()]} />)
                        }
                        }
                    </FormDataConsumer>
                    <TextInput source="secondary_mac_address" />
                    <TextInput source="software_version" />
                </SimpleForm>
            </Create>
        )
    }))

export default DeviceAdd