import React, { useState, useEffect } from 'react'
import compose from 'recompose/compose'
import { Create, translate } from 'react-admin'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js';
import { FopForm } from './creditCard/CreditCardForm'
import SepaSetupForm from './sepa/SepaForm'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Card, CardActions, CardContent, CardMedia, Button, Typography } from '@mui/material'
import sepaIcon from './sepa/sepa.svg'
import creditCardIcon from './creditCard/creditCard.svg';

const NO_METHOD = 1
const ADD_CARD = 2
const ADD_SEPA = 3

const styles = theme => ({
    list: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        marginTop: 20,
    },

})
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const _FopCreate = translate((props) => {
    const [checkedMethod, setCheckedMethod] = useState(1);
    const [stripe, setStripe] = useState(null);
    const { translate, classes } = props
    useEffect(() => {
        if (window.Stripe) {
            setStripe(window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY))
        } else {
            document.querySelector('#stripe-js').addEventListener('load', () => {
                setStripe(window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY))
            })
        }

    }, []);
    const MethodCard = props => {
        return (
            <Card sx={{ maxWidth: 345 }}>
                {
                    props.title === 'creditCard' ?
                        <CardMedia
                            component="svg"
                            height="240"
                            image={creditCardIcon}
                            alt={props.title}
                            sx={{ margin: 3 }}
                        />
                        :
                        <CardMedia
                            component="svg"
                            height="240"
                            image={sepaIcon}
                            alt={props.title}
                            sx={{ margin: 3 }}
                        />
                }

                <CardContent >
                    <Typography gutterBottom variant="h5" component="div" >
                        {props.label}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {props.description}
                    </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: "center" }}>
                    <Button onClick={() => { setCheckedMethod(props.stateNumber); }} variant="contained" size="medium">Select</Button>
                </CardActions>
            </Card >
        )
    }

    const SelectMethod = props => {
        return (
            <Grid container
                spacing={2}
                m={4}
                direction="row"
                justifyContent="center"
                alignItems="center">

                <Grid item xs={4}>
                    <MethodCard title="creditCard" label="Carte de crédit" stateNumber={2} />
                </Grid>

                <Grid item xs={4}>
                    <MethodCard title="sepa" label="Prélèvement bancaire" stateNumber={3} />
                </Grid>

            </Grid>
        )
    }
    const renderForm = props => {

        if (checkedMethod === ADD_CARD) {
            return (
                <div>
                    <Button onClick={() => { setCheckedMethod(1); }} variant="text" size="medium" sx={{ margin: 3 }}>Return</Button>

                    <Elements stripe={stripe} >
                        <FopForm {...props} />
                    </Elements>
                </div >)
        }
        if (checkedMethod === ADD_SEPA) {
            return (
                <div>
                    <Button onClick={() => { setCheckedMethod(1); }} variant="text" size="medium" sx={{ margin: 3 }}>Return</Button>
                    <Elements stripe={stripe} >
                        <SepaSetupForm {...props} />
                    </Elements>
                </div >
            )
        } else {
            return (<SelectMethod />)


        }

    }
    return (
        <Create {...props} title={translate('resources.fops.create')} className={classes.list}>

            {renderForm(props)}

        </Create>
    )
})




export default withStyles(styles)(_FopCreate)