import React from 'react';
import compose from 'recompose/compose'
import { TopToolbar, ListButton, translate, Show } from 'react-admin';
import Map from '../../../ui/maps/map1';
import { withStyles } from '@material-ui/core/styles'
import LocationHistoryButton from '../../../ui/button/LocationHistoryButton';

const styles = theme => ({
    actions: {
        alignSelf: 'center',
        paddingBottom: 11
    },
})

const enhanceActions = compose(
    translate,
    withStyles(styles)
)

const LocationActions = enhanceActions(({

    classes,
    translate,
    ...props
}) => (
    <TopToolbar className={classes.actions}>
        <ListButton basePath={"locations"} label={translate("resources.locations.name", { smart_count: 2 })} />
        <LocationHistoryButton recordId={props.data ? props.data.id : null} />
    </TopToolbar>
))

const LocationShow = ({ translate, ...props }) => (
    <Show {...props}
        actions={<LocationActions />}
        title={translate('resources.locations.name', { smart_count: 1 })}>


        <Map {...props} translate={translate} />


    </Show>

)

export default translate(LocationShow)