import React from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce';
import { withStyles } from '@material-ui/core/styles'
import { TextField, Typography } from '@material-ui/core'
import compose from 'recompose/compose'
import { translate } from 'react-admin'
import { NBR_HISTORY } from '../configuration/actions'

const styles = theme => ({
    input: {
        fontSize: 15,
    },
    select: {
        fontSize: 14,

    },
    menu: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        width: 200,
    },
    quickSearch: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 20,
        marginRight: 10,
        marginLeft: 10,
        minHeight: 52,
        width: 200,
    },
    pickers: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 36,
    },
    textField: {
        fontSize: 14,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        width: 200,
        heigth: 30,
    },
    title: {
        color: theme.palette.text.primary,
        height: 15,
        marginBottom: 10,
    },
})

const options = [
    'resources.locations.location25',
    'resources.locations.location50',
    'resources.locations.location100',
    'resources.locations.location200',
    'resources.locations.location500',
    'resources.locations.locationYesterday',
    'resources.locations.locationToday',
    'resources.locations.locationWeek',
    'resources.locations.locationMonth',
    'resources.locations.locationYear',
]

export const today = () => {
    let date = new Date()
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    return date
}

export const yesterday = () => {
    let date = new Date(today())
    date.setDate(date.getDate() - 1)
    return date.toISOString()
}

const last7days = () => {
    let date = new Date(today())
    date.setDate(date.getDate() - 7)
    return date.toISOString()
}

const last30days = () => {
    let date = new Date(today())
    date.setDate(date.getDate() - 30)
    return date.toISOString()
}

const lastyear = () => {
    let date = new Date(today())
    date.setDate(date.getDate() - 365)
    return date.toISOString()
}

const criterias = [
    { page: 1, perPage: 25 },
    { page: 1, perPage: 50 },
    { page: 1, perPage: 100 },
    { page: 1, perPage: 200 },
    { page: 1, perPage: 500 },
    {
        filter: {
            start_date: yesterday(),
            end_date: today().toISOString(),
            search_result: 'return_all',
        },
    },
    {
        filter: {
            start_date: today().toISOString(),
            search_result: 'return_all',
        },
    },
    {
        filter: { start_date: last7days(), search_result: 'return_all' },
    },
    {
        filter: { start_date: last30days(), search_result: 'return_all' },
    },
    {
        filter: { start_date: lastyear(), search_result: 'return_all' },
    },
]

class QuickSearchMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            location: 0,
        }
    }

    handleChange = event => {
        const { value } = event.target

        if (value !== undefined) {
            this.setState({ location: value })
            this.props.changeConfig(NBR_HISTORY, value)

            if (criterias[value].perPage) {
                this.props.setFilters({}, {})
            }
            this.changeFilters(value)
        }
    }

    changeFilters = debounce((value) => {

        if (criterias[value].perPage) {

            this.props.setFilters({ page: 0, size: criterias[value].perPage, }, {})
        } else {

            this.props.setFilters(criterias[value].filter, {})
        }
    }, this.props.debounce);

    render() {
        const { classes, translate } = this.props

        return (
            <div className={classes.quickSearch}>
                <Typography variant="caption" color="primary" className={classes.title}>
                    {translate('resources.devices.fastsearch')}
                </Typography>
                <div className={classes.pickers}>
                    <TextField
                        select
                        color="primary"
                        onChange={this.handleChange.bind(this)}
                        value={this.state.location}
                        SelectProps={{
                            native: true,
                        }}
                        InputProps={{
                            className: classes.input,
                        }}
                    >
                        {options.map((option, index) => (
                            <option key={index} value={index} className={classes.select}>
                                {translate(option)}
                            </option>


                        ))}

                    </TextField>
                </div>
            </div>
        )
    }
}

QuickSearchMenu.propTypes = {
    changeConfig: PropTypes.func.isRequired,
    debounce: PropTypes.number,
    setPerPage: PropTypes.func.isRequired,
    config: PropTypes.object,
}

QuickSearchMenu.defaultProps = {
    debounce: 100
}

const enhance = compose(
    withStyles(styles),
    translate
)
export default enhance(QuickSearchMenu)
