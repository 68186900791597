import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import { translate } from 'react-admin'
import { CardHeader, TextField, Chip } from '@material-ui/core'
import style from 'jss-material-ui'
import { withStyles } from '@material-ui/core/styles'
import { green, red, grey } from '@material-ui/core/colors'
import moment from 'moment'
import { Divider, Box, Grid, CardContent, Card, Typography, ButtonBase } from '@mui/material'


const SUBSCRIPTION_SHOW = 1
const SUBSCRIPTION_OFFER = 2

const SCard = style(Card)(theme => ({
    root: {
        maxWidth: 400,
        margin: 10,
    },
}))


const SSelectedCard = style(SCard)(theme => ({
    root: {
        borderColor: theme.palette.primary.main,
        borderStyle: 'solid',
        borderWidth: 2,
    },
}))

const SCardHeader = style(CardHeader)({
    root: {
        display: 'flex',
        minWidth: 120,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
    },
})

const SCardContent = style(CardContent)(theme => ({
    root: {
        display: 'flex',
        minWidth: 120,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.grey["400"], //mm
        color: theme.palette.common.black, //mm
    },
}))



const SCardSub = style(CardContent)({
    root: {
        display: 'flex',
        minWidth: 120,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
})

const SCardActions = style(Card)(theme => ({
    root: {
        alignContent: 'space-around',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: 10,
        backgroundColor: theme.palette.background.active,
    },
}))

const styles = theme => ({
    text: {
        textTransform: 'uppercase',
        fontSize: 16,
        height: 24,
    },
    active: { backgroundColor: green[500], color: 'white' },
    past: { backgroundColor: red[500], color: 'white' },
    canceled: { backgroundColor: grey[800], color: 'white' },
    textField: {
        width: 250,
        marginBottom: 15,
        alignSelf: 'center',
    },
})

const SText = style(Typography, { align: 'center', variant: 'body2' })({
    root: {},
})

const STextAction = style(Typography, { align: 'center', variant: 'h6' })({
    root: {},
})




const enhance = compose(
    translate,
);

const SelectedProfilCard = enhance(({ translate, ...props }) => {
    const plan = props.plan;
    const price = props.price;
    const duration = props.duration;
    const taxDescription = plan.currency && plan.currency.toLowerCase() === "aud" ? "Including GST" : "Inc.TAX"
    const priceDescription = `${price} ${duration}\n${taxDescription}`
    return (
        <Grid item xs={12} sx={{ display: 'flex', width: "100%", paddingX: 30 }}    >
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ display: 'flex', width: "100%" }}
            >
                <Card sx={{ display: 'flex', width: "100%", margin: 1, border: 3, borderColor: 'primary.main' }}>
                    <ButtonBase
                        onClick={props.onClick}
                        sx={{ display: 'flex', width: "100%" }}
                    >

                        <Box sx={{ margin: 3, width: "30%", textAlign: "center" }}>
                            <Typography variant="h6" color="text.main"><strong>{plan.title}</strong> </Typography>

                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <CardContent sx={{ width: "100%", textAlign: "left" }}>

                            <Typography variant="subtitle1" color="text.secondary">
                                {plan.description.split("/").map(function (line) { return (<strong> {line}  < br />        </strong>) })}
                            </Typography>

                        </CardContent>
                        <Box sx={{ pl: 1, pb: 1, width: "40%", textAlign: "left" }}>
                            {priceDescription.split("\n").map(function (line) { return (<Typography variant="h6" color="text.secondary"><strong>{line}</strong> </Typography>) })}

                        </Box>
                    </ButtonBase>
                </Card >
            </Box>

        </Grid >)


});


const NonSelectedProfilCard = enhance(({ translate, ...props }) => {
    const plan = props.plan;
    const price = props.price;
    const duration = props.duration;
    const taxDescription = plan.currency && plan.currency.toLowerCase() === "aud" ? "Including GST" : "Inc.TAX"
    const priceDescription = `${price} ${duration}\n${taxDescription}`
    return (
        <Grid item xs={12} sx={{ display: 'flex', width: "100%", paddingX: 30 }} >
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ display: 'flex', width: "100%" }}
            >
                <Card sx={{ display: 'flex', width: "100%", margin: 1 }}>
                    <ButtonBase
                        onClick={props.onClick}
                        sx={{ display: 'flex', width: "100%" }}
                    >
                        <Box sx={{ margin: 3, width: "30%", textAlign: "center" }}>
                            <Typography variant="h6" color="text.main"><strong>{plan.title}</strong> </Typography>

                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <CardContent sx={{ width: "100%", textAlign: "left" }}>

                            {plan.description.split("/").map(function (line) { return (<Typography variant="subtitle1" color="text.secondary">{"" + line} </Typography>) })}

                        </CardContent>
                        <Box sx={{ pl: 1, pb: 1, width: "40%", textAlign: "left" }}>
                            {priceDescription.split("\n").map(function (line) { return (<Typography variant="h6" color="text.secondary">{line}</Typography>) })}


                        </Box>
                    </ButtonBase>
                </Card >
            </Box>

        </Grid>)


});


class CardSubscription extends React.Component {
    state = { expanded: false }

    render() {
        const { classes, endDate, status, startDate, state, translate } = this.props;
        const { cardType, plan, selectedPlan, price, duration, handleClick } = this.props;
        const isSelected = selectedPlan && selectedPlan.plan_id === plan.plan_id;


        if (cardType === SUBSCRIPTION_OFFER) {
            const card = isSelected ?
                <SelectedProfilCard onClick={() => handleClick(plan)} plan={plan} price={price} duration={duration} /> :
                <NonSelectedProfilCard onClick={() => handleClick(plan)} plan={plan} price={price} duration={duration} />
            return card
        } else {
            if (status === 'canceled') {
                return (<SCard>
                    <SCardHeader
                        title={translate(`resources.subscriptions.states.canceled`).toUpperCase()}
                        titleTypographyProps={{ variant: 'h3', color: 'primary' }}
                    />
                    <SCardSub>
                        <Chip
                            className={[classes.text, classes.canceled]}
                            label={translate('resources.subscriptions.statuses.canceled')}
                        />

                    </SCardSub>
                    <SCardActions>
                        <TextField
                            id="datetime-local"
                            label={translate('resources.subscriptions.fields.start_date')}
                            type="datetime-local"
                            value={moment.utc(startDate).local().format('YYYY-MM-DDTHH:mm')}
                            className={classes.textField}
                            disabled={true}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            id="datetime-local"
                            label={translate('resources.subscriptions.fields.end_date')}
                            type="datetime-local"
                            value={moment.utc(endDate).local().format('YYYY-MM-DDTHH:mm')}
                            className={classes.textField}
                            disabled={true}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </SCardActions>
                </SCard>)
            }
            if (status === 'unpaid') {
                return (<SCard>
                    <SCardHeader
                        title={translate(`resources.subscriptions.states.unpaid`).toUpperCase()}
                        titleTypographyProps={{ variant: 'h6', color: 'primary' }}
                    />
                    <SCardSub>

                        <Chip
                            className={[classes.text, classes.past]}
                            label={translate('resources.subscriptions.statuses.unpaid')}
                        />


                    </SCardSub>
                    <SCardActions>
                        <TextField
                            id="datetime-local"
                            label={translate('resources.subscriptions.fields.start_date')}
                            type="datetime-local"
                            value={moment.utc(startDate).local().format('YYYY-MM-DDTHH:mm')}
                            className={classes.textField}
                            disabled={true}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            id="datetime-local"
                            label={translate('resources.subscriptions.fields.end_date')}
                            type="datetime-local"
                            value={moment.utc(endDate).local().format('YYYY-MM-DDTHH:mm')}
                            className={classes.textField}
                            disabled={true}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </SCardActions>
                </SCard>)
            } else {
                return (<SCard>
                    <SCardHeader
                        title={translate(`resources.subscriptions.states.${state}`).toUpperCase()}
                        titleTypographyProps={{ variant: 'h3', color: 'primary' }}
                    />
                    <SCardSub>
                        {status === 'active' &&
                            <Chip
                                className={[classes.text, classes.active]}
                                label={translate('resources.subscriptions.statuses.active')}
                            />
                        }
                        {status === 'past' &&
                            <Chip
                                className={[classes.text, classes.past]}
                                label={translate('resources.subscriptions.statuses.past')}
                            />
                        }

                    </SCardSub>
                    <SCardActions>
                        <TextField
                            id="datetime-local"
                            label={translate('resources.subscriptions.fields.start_date')}
                            type="datetime-local"
                            value={moment.utc(startDate).local().format('YYYY-MM-DDTHH:mm')}
                            className={classes.textField}
                            disabled={true}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            id="datetime-local"
                            label={translate('resources.subscriptions.fields.end_date')}
                            type="datetime-local"
                            value={moment.utc(endDate).local().format('YYYY-MM-DDTHH:mm')}
                            className={classes.textField}
                            disabled={true}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </SCardActions>
                </SCard>)
            }

        }
    }
}

CardSubscription.propTypes = {
    status: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
}

export default compose(
    translate,
    withStyles(styles)
)(CardSubscription)
