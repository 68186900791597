import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import { required, TextInput, Toolbar, translate } from 'react-admin'
import tifizImage from '../tifiz.png'
import { H100Grid, HGrid, TitleForm } from '../../../ui/Grid'
import { WizardForm } from '../../../ui/form/WizardForm'
import NextButton from '../../../ui/button/NextButton'

const DeviceCreateToolbar = props => (
    <Toolbar  {...props}>
        <NextButton type="submit" label={props.translate('ra.navigation.next')} />
    </Toolbar>
)

const validateRequired = [required('Champs obligatoire')]

class CheckDevice extends React.Component {
    constructor(props) {
        super(props)

        this.setTextInputRef = element => {
            this.textInput = element
        }

        this.focusTextInput = () => {
            if (this.textInput) this.textInput.focus()
        }
    }

    componentDidMount() {
        this.focusTextInput()
    }

    render() {
        const { onSubmit, nextPage, translate } = this.props
        return (
            <WizardForm onSubmit={onSubmit} nextPage={nextPage} toolbar={<DeviceCreateToolbar {...this.props} />} {...this.props}>
                <HGrid>
                    <TitleForm>{translate('resources.devices.enter_device_id_instruction')}</TitleForm>
                </HGrid>
                <H100Grid>
                    <img src={tifizImage} alt="balise" style={{ maxHeight: 320, maxWidth: 400 }} />
                    <TextInput
                        inputRef={this.setTextInputRef}
                        label={'resources.devices.device_id'}
                        placeholder={translate('resources.devices.enter_device_id')}
                        source="name"
                        style={{ width: 300 }}
                        translate={translate}
                        validate={validateRequired}
                    />
                </H100Grid>
            </WizardForm >
        )
    }
}

CheckDevice.propTypes = {
    nextPage: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    value: PropTypes.object,
}

CheckDevice.defaultProps = {
    value: {
        name: '',
    },
}

CheckDevice.contextTypes = {
    theme: PropTypes.func,
}


export default translate(props => <Form {...props} component={CheckDevice} />)
