
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import * as Leaflet from 'leaflet'
import { Marker, useMap, Popup, CircleMarker, Circle } from 'react-leaflet'
//ticatag
import MarkerPopup from './markerPopup'
import * as ReactDOM from 'react-dom'
import { getFormattedAddress, getId, getLatitude, getLongitude, sVars } from '../../utils/utils'
import { formatAddress, geoReverse } from '../../utils/geocode'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
    //
})
const MarkerWithPopup = (props) => {
    const { index, record, translate } = props
    return (<MarkerPopup index={index} record={record} translate={translate} />)

}


const TifizMarker = React.memo((props) => {

    const markerRef = useRef()
    const { record, selected } = props
    const reverseGeocoding = async (record) => {
        try {
            const { latitude, longitude } = record.last_location || record
            const res = await geoReverse(latitude, longitude)
            const json = await res.json()

            const formatted_address = formatAddress(json.address)

            if (record.last_location) {
                record.last_location.formatted_address = formatted_address
            }


            props.updateFormattedAddress(
                getId(record),
                record,
                formatted_address,
                record.last_location ? 'locations' : 'locations_events'
            )
        } catch (ex) { }
    }

    const checkGeocode = (record) => {
        const formatted_address = getFormattedAddress(record)

        if (!formatted_address) reverseGeocoding(record)
    }



    useEffect(() => {
        if (record && selected) {
            checkGeocode(record)

            setTimeout(() => {
                markerRef && markerRef.current && markerRef.current.openPopup ? markerRef.current.openPopup() : null
            }, 500);
        }

    }, [])



    if (selected && record.accuracy !== null && record.accuracy > 50) {
        return (
            <Circle
                ref={markerRef}
                center={props.position}
                radius={record.accuracy}
                color={'blue'}
                fillOpacity={0.4}
            >
                <Popup closeButton={false} onClick={e => e.preventDefault()}>
                    <MarkerWithPopup index={props.index} record={props.record} translate={props.translate} />
                </Popup>
            </Circle>
        )
    }
    return (
        <Marker
            ref={markerRef}
            icon={props.icon}
            position={props.position}
        >

            <Popup closeButton={false} onClick={e => e.preventDefault()}>
                <MarkerWithPopup index={props.index} record={props.record} translate={props.translate} />
            </Popup>

        </Marker>

    )
})


TifizMarker.propTypes = {
    index: PropTypes.number.isRequired,
    lastLocation: PropTypes.bool.isRequired,
    record: PropTypes.object.isRequired,
    selected: PropTypes.bool.isRequired,
    translate: PropTypes.func.isRequired,
    updateFormattedAddress: PropTypes.func.isRequired,
}

export default withStyles(styles)(TifizMarker)

