import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import { TextInput, Toolbar, translate } from 'react-admin'
import PreviousButton from '../../../ui/button/PreviousButton'
import NextButton from '../../../ui/button/NextButton'
// @material-ui/icon
import CardSubscription from './CardSubscription'

import { Divider } from '@material-ui/core'
import style from 'jss-material-ui'
import { TitleForm } from '../../../ui/Grid'
import { TitlePlan } from '../../../theme/layout'
import Typography from '@material-ui/core/Typography'
import { getCurrencySymbol, getFormattedPrice, getAmount } from '../../../helper/resourceHelper'
import { WizardForm } from '../../../ui/form/WizardForm'
import { VGrid, V100Grid, H100Grid } from '../../../ui/Grid'
import { checkoutSession, checkoutSessionL } from '../../../rest/restClient'


const SDivider = style(Divider)(theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        width: '50%',
        marginTop: 20,
        marginBottom: 20
    },
}))


const PostpaidPlansExists = (plans) => {
    return plans && plans && plans.filter(plan => plan.type.toLowerCase() === 'postpaid').length > 0

}

const PrepaidPlansExists = (plans) => {
    return plans && plans && plans.filter(plan => plan.type.toLowerCase() === 'prepaid').length > 0

}

const VoucherAllowed = (plans) => {
    return plans && plans && plans.filter(plan => plan.operator.toLowerCase() === 'sigfox').length > 0

}

const OnePlanTypeAllowed = (plans) => {

    return (
        (PostpaidPlansExists(plans) && !PrepaidPlansExists(plans) && !VoucherAllowed(plans)) ? 'postpaid' :
            (!PostpaidPlansExists(plans) && PrepaidPlansExists(plans) && !VoucherAllowed(plans)) ? 'prepaid' :
                (!PostpaidPlansExists(plans) && !PrepaidPlansExists(plans) && VoucherAllowed(plans)) ? 'voucher' :
                    null
    )

}

const DeviceCreateToolbar = props => {
    if (props.selectedPlanType != "voucher") {
        return <div />
    } else {
        return (<Toolbar  {...props}>
            <PreviousButton label={props.translate('ra.navigation.next')} onClick={props.previousPage} />
            <NextButton type="submit" label={props.translate('ra.navigation.next')} />
        </Toolbar>)
    }
}

class SelectPlan extends React.Component {
    constructor(props) {
        super(props)

        this.setTextInputRef = element => {
            this.textInput = element
        }

        this.focusTextInput = () => {
            if (this.textInput) this.textInput.focus()
        }
    }

    componentDidMount() {
        this.focusTextInput()
    }

    getTitle = plan => {
        const { translate } = this.props
        const type =
            plan.type === 'postpaid'
                ? translate('resources.subscriptions.name', { smart_count: 1 })
                : translate('resources.subscriptions.prepaid_offer')
        const currency = getCurrencySymbol(plan.currency)
        const duration =
            plan.type === 'postpaid'
                ? translate('resources.per') + ' ' + translate('resources.' + plan.frequency)
                : translate('resources.for') +
                ' ' +
                plan.frequency_interval +
                ' ' +
                translate('resources.' + plan.frequency)
        const title = type + ' - ' + getAmount(plan.amount, plan.tax_percent) + currency + ' ' + duration
        return title
    }

    getVoucherTitle = voucher => {
        const { code, plan } = voucher
        const { translate } = this.props
        const type = translate('resources.subscriptions.voucher')
        const title =
            type +
            ' - #' +
            code +
            ' - ' +
            plan.frequency_interval +
            ' ' +
            translate('resources.' + plan.frequency)
        return title
    }

    checkoutRedirect = () => {
        const { value } = this.props
        const { selectedPlanType, selectedPlan } = value

        if (selectedPlanType != "voucher") {
            checkoutSession({
                device_id: value.device.device_id,
                plan_id: selectedPlan.plan_id,
                payment_method_types: [selectedPlan.payment_method_type]
            }, "attach")
                .then((response) => {
                    if (response.json && response.json.session_url) {
                        const sessionUrl = response.json.session_url
                        window.open(sessionUrl, "_self");
                    } else {
                        console.log("checkoutSession error");
                    }

                })
                .catch(error => {
                    console.log("checkoutSession error");

                })

        } else {
            console.log("No selected plan");
        }

    }

    render() {
        const { onSubmit, nextPage, previousPage, checkDevicePage, value, translate, onChangePlan, onChangeVoucher } = this.props
        const { selectedPlan, voucher, plans, selectedPlanType } = value

        // go directly to checkout if only 1 plan exists
        if (selectedPlanType != null && selectedPlanType != "voucher" && plans.filter(plan => plan.type === selectedPlanType).length === 1) {
            onChangePlan(plans.filter(plan => plan.type === selectedPlanType)[0])
            this.checkoutRedirect()
        }

        // regulier/regular plan selected by default for yabby
        if (selectedPlan === null && voucher === null || selectedPlan === undefined && voucher === undefined) {
            if (plans && plans.filter(plan => plan.yabbyConfPlan !== null && plan.yabbyConfPlan !== undefined).length > 0) {
                onChangePlan(plans.filter(plan => plan.type === 'postpaid').sort((a, b) => a.amount - b.amount)[Math.round((plans.filter(plan => plan.type === 'postpaid').length - 1) / 2)])
            }
        }
        return (
            <WizardForm
                onSubmit={onSubmit}
                previousPage={previousPage}
                nextPage={nextPage}
                submitOnEnter={false}
                toolbar={<DeviceCreateToolbar {...this.props} selectedPlanType={selectedPlanType} previousPage={previousPage} />}
                {...this.props}
            >

                <V100Grid>
                    {selectedPlanType != "voucher" &&
                        <TitleForm>{translate('resources.devices.select_offer')} </TitleForm>}


                    <V100Grid center >
                        {selectedPlanType === "postpaid" &&
                            plans && plans.filter(plan => plan.type === 'postpaid').sort((a, b) => a.amount - b.amount).map(function (plan, index) {
                                return (
                                    <CardSubscription
                                        plan={plan}
                                        duration={'/ ' + translate('resources.' + plan.frequency)}
                                        price={getFormattedPrice(plan)}
                                        footer1={translate('resources.subscriptions.name', { smart_count: 1, })}
                                        footer2={plan.trial_period_days && plan.description ? plan.trial_period_days > 0 ? plan.description : "Engagement 12 mois" : "Engagement 12 mois"}
                                        handleClick={onChangePlan}
                                        selectedPlan={selectedPlan}
                                    />
                                )
                            })
                        }
                    </V100Grid>
                    <V100Grid center>

                        {selectedPlanType === "prepaid" &&
                            <H100Grid noMargin noPadding>
                                {plans && plans.filter(plan => plan.type === 'prepaid').sort((a, b) => a.amount - b.amount).map(function (plan, index) {
                                    return (
                                        <CardSubscription
                                            key={index}
                                            plan={plan}
                                            duration={""}
                                            price={getFormattedPrice(plan)}
                                            footer1={translate('resources.subscriptions.prepaid')}
                                            handleClick={onChangePlan}
                                            selectedPlan={selectedPlan}
                                        />
                                    )
                                })}
                            </H100Grid>
                        }
                    </V100Grid>
                    {plans.filter(plan => plan.yabbyConfPlan != null).length === 0 && plans.filter(plan => plan.operator.toLowerCase() != "sigfox").length === 0 &&
                        <V100Grid center>
                            {selectedPlanType === "voucher" &&
                                <H100Grid noMargin>
                                    <TextInput inputRef={this.setTextInputRef}
                                        label={translate('resources.subscriptions.voucher_code')}
                                        placeholder={translate('resources.subscriptions.enter_voucher')}
                                        translate={translate}
                                        onChange={onChangeVoucher}
                                        source="value.voucher.code"
                                        style={{ width: 300 }} />
                                </H100Grid>
                            }
                        </V100Grid>
                    }
                    <SDivider />
                    <V100Grid center>
                        <TitlePlan fontSize={14}>{translate('resources.subscriptions.selected_offer').toUpperCase()}</TitlePlan>
                        <H100Grid noMargin>
                            {!selectedPlan && !voucher &&
                                <Typography variant="h5" gutterBottom>
                                    {translate('resources.subscriptions.no_selected_plan').toUpperCase()}
                                </Typography>
                            }
                            {selectedPlan &&
                                <Typography variant="h5" gutterBottom>
                                    {this.getTitle(selectedPlan)}
                                </Typography>
                            }
                            {voucher &&
                                <Typography variant="h5" gutterBottom>
                                    {this.getVoucherTitle(voucher)}
                                </Typography>
                            }
                        </H100Grid>
                    </V100Grid>
                    {selectedPlanType != "voucher" &&
                        <H100Grid center>
                            <PreviousButton label={translate('ra.navigation.next')} onClick={OnePlanTypeAllowed(plans) ? checkDevicePage : previousPage} />
                            <NextButton handleSubmitWithRedirect={this.checkoutRedirect} type="submit" label={translate('ra.navigation.next')} />
                        </H100Grid>
                    }
                </V100Grid>
            </WizardForm>

        )
    }
}

SelectPlan.propTypes = {
    classes: PropTypes.object.isRequired,
    previousPage: PropTypes.func.isRequired,
    nextPage: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    value: PropTypes.object,
}

SelectPlan.defaultProps = {
    value: {
        selectedPlan: null,
        voucher: null,
    },
}

export default translate(props => <Form {...props} component={SelectPlan} />)

