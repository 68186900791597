import React, { Fragment } from 'react'
import { AutocompleteInput, BooleanField, TopToolbar, CreateButton, BulkDeleteButton, Datagrid, EditButton, Filter, FunctionField, List, ReferenceField, ReferenceInput, Responsive, TextField, translate } from 'react-admin'
import { hasWriteAccess, PER_PAGE } from '../../../helper/resourceHelper'
import MobileGrid from './MobileGrid'
import { withStyles } from '@material-ui/core/styles'
import compose from 'recompose/compose'


const styles = theme => ({
    list: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        marginTop: 20,
    }
})


const GeofenceActions = ({ basePath, filterValues, onUnselectItems, permissions, resource, selectedIds }) => (
    <TopToolbar>
        {hasWriteAccess(permissions) && <Fragment>
            <BulkDeleteButton basePath={basePath} selectedIds={selectedIds} filterValues={filterValues} resource={resource} onUnselectItems={onUnselectItems} />
        </Fragment>}
        {hasWriteAccess(permissions) && <CreateButton basePath={basePath} />}
    </TopToolbar>
)


const GeofenceList = ({ classes, permissions, translate, ...props }) => (
    <List
        {...props}
        title={translate('resources.geofences.name', { smart_count: 2 })}
        actions={<GeofenceActions />}
        className={classes.list}
        perPage={PER_PAGE}
        filters={
            <Filter {...props}>
                <ReferenceInput variant="standard" margin="normal" label={translate("resources.devices.name", { smart_count: 1 })} source="device_id" reference="devices" alwaysOn>
                    <AutocompleteInput optionText="name" matchSuggestion={() => true} />
                </ReferenceInput>
            </Filter>
        }
    >
        <Responsive
            small={<MobileGrid />}
            medium={
                <Datagrid>
                    <ReferenceField
                        allowEmpty={true}
                        label={translate('resources.devices.name', { smart_count: 1 })}
                        source="device_id"
                        reference="devices"
                        sortBy={false}
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <FunctionField
                        label={translate('resources.geofences.fields.address')}
                        render={geofence => geofence.formatted_address}
                    />
                    <FunctionField
                        label={translate('resources.geofences.fields.radius')}
                        render={geofence =>
                            geofence.radius < 10000 ? `${geofence.radius} m` : `${geofence.radius / 1000} km`
                        }
                        source="radius"
                    />
                    <BooleanField
                        source="active"
                    />
                    {hasWriteAccess(permissions) && <EditButton />}
                </Datagrid>
            }
        />
    </List>
)


export default compose(
    translate,
    withStyles(styles)
)(GeofenceList)