import L from 'leaflet'
import { useEffect } from "react";
import { useLeafletContext } from "@react-leaflet/core";
import '@geoman-io/leaflet-geoman-free';
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

const MapDraw = (props) => {
    const context = useLeafletContext();

    useEffect(() => {
        const leafletContainer = context.layerContainer || context.map;

        leafletContainer.pm.setGlobalOptions({
            pmIgnore: false,
            measurements: { measurement: true }

        });
        if (props.editing) {

            let prevCircle = L.circle(props.center, { radius: props.radius })
            prevCircle.setStyle({ pmIgnore: false });
            leafletContainer.addLayer(prevCircle)
            if (L.PM && prevCircle.pm) {
                L.PM.reInitLayer(prevCircle);

                prevCircle.pm.enable();
                prevCircle.on("pm:edit", (e) => {
                    const event = e;
                    props.onEdited(e)
                });
            }

        }

        return () => {
            leafletContainer.pm.removeControls();
            leafletContainer.pm.setGlobalOptions({ pmIgnore: true });
        };
    }, [context]);

    return null;
};

export default MapDraw;


