import React, { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose'
import { CREATE, crudUpdate, SaveButton, UPDATE, useRedirect, useCreate, useNotify, useUpdate, translate, useDataProvider } from 'react-admin';
import isEqual from 'lodash/isEqual'
import { isSuperAdmin } from '../../../helper/resourceHelper';
import { updateDeviceNameOrgIdOwnerId } from '../../../rest/restClient'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const YABBY_PRODUCTS_NAME = ["yabbygps", "yabbyedge", 'yabby3-4g']

const computeRetryPeriod = (geolocationPeriod) => geolocationPeriod <= 5 ? 0 : 5

const dialogRequired = (previousData, values) => {
    return (YABBY_PRODUCTS_NAME.includes(values.product.name.toLowerCase())
        &&
        YABBY_PRODUCTS_NAME.includes(previousData.product.name.toLowerCase())

        &&
        values.configuration.subscription_plan_id
        &&
        values.configuration.subscription_plan_id != previousData.configuration.subscription_plan_id
    )
}

const isYabby = (previousData, values) => {
    return (values &&
        previousData &&
        values.product &&
        previousData.product &&
        YABBY_PRODUCTS_NAME.includes(values.product.name.toLowerCase()) &&
        YABBY_PRODUCTS_NAME.includes(previousData.product.name.toLowerCase())

    )
}

export const SaveDeviceButton = translate(props => {

    const [create] = useCreate();
    const [update, { isLoading, error }] = useUpdate();
    const redirectTo = useRedirect();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [plans, setPlans] = useState();
    const [openDialog, setopenDialog] = useState(false);
    const [values, setValues] = useState(null);
    const { basePath, previousData, translate } = props;

    useEffect(() => {
        dataProvider.getList('yabby_configuration_plans', {})
            .then(({ data }) => {
                setPlans(data);

            })
            .catch(error => {

            });
    }, []);

    const handleCloseDialog = () => {
        setopenDialog(false);
    };
    const handleAccept = (valuesPar) => {
        const valuesParam = values ? values : valuesPar;
        setopenDialog(false);
        updateDeviceNameOrgIdOwnerId({
            id: valuesParam.id, device: {
                name: valuesParam.name,
                organization_id: valuesParam.organization_id,
                owner_id: valuesParam.owner_id,
            }
        }).then(() => {
            notify(translate('resources.common.update_success'), 'info');
            redirectTo("/devices");
        })
        if (valuesParam.configuration && !isEqual(previousData.configuration, valuesParam.configuration)) {
            // edit when /events? response changes  ----------------------
            valuesParam.configuration.co2_send_interval = valuesParam.configuration.send_interval
            valuesParam.configuration.co2_extra_measurement_count = valuesParam.configuration.extra_measurement_count
            delete valuesParam.configuration.extra_measurement_count
            delete valuesParam.configuration.send_interval
            //-------------------------------------------------------------
            update(
                {
                    payload: { id: valuesParam.configuration.device_configuration_id, data: { ...valuesParam.configuration, retry_period: computeRetryPeriod(valuesParam.configuration.geolocation_period) } },
                    resource: 'device_configurations',
                    fetch: UPDATE,

                },
                {
                    type: 'UPDATE_DEVICE_CONFIGURATION',
                    onSuccess: () => {
                        notify(translate('resources.common.update_success'), 'info');
                        redirectTo("list", "/devices");
                    },
                }
            ).catch((e) => {
                console.log("e ==> ", e)
            })
            if (error) {
                console.log(error)

            }
        }
        if (isSuperAdmin() && valuesParam.start_date && valuesParam.end_date && valuesParam.plan_id) {

            create(
                {
                    payload: { data: { start_date: valuesParam.start_date.toISOString(), end_date: valuesParam.end_date.toISOString(), plan_id: valuesParam.plan_id, device_id: valuesParam.id } },
                    resource: 'subscriptions_create',
                    fetch: CREATE,

                },
                {
                    type: 'ADD_SUBCRIPTION',
                    onSuccess: () => {
                        notify(translate('resources.common.update_success'), 'info');
                        redirectTo("list", "/devices");
                    },
                }
            )
        }
        if (valuesParam.group && !isEqual(previousData.group, valuesParam.group)) {
            let deviceIds = valuesParam.group.device_ids
            if (!deviceIds.includes(valuesParam.id)) {
                deviceIds = valuesParam.group.device_ids.concat([valuesParam.id])
            }
            create(
                {
                    payload: { id: valuesParam.group.device_group_id, data: { device_ids: deviceIds, name: valuesParam.group.name } },
                    resource: 'device_groups',
                    fetch: valuesParam.group.device_group_id ? UPDATE : CREATE,

                },
                {
                    type: 'UPDATE_DEVICE_GROUP',
                    onSuccess: () => {
                        notify(translate('resources.common.update_success'), 'info');
                        redirectTo("list", "/devices");
                    },
                }
            )

        }

    };
    const DialogPopUp = (props) => {
        if (!isYabby(previousData, values)) {
            return <div />
        } else {

            const currentPrice = previousData && plans && plans.filter((plan => plan.plan_id === previousData.configuration.yabby_configuration_plan_id)).length > 0 ? plans.filter((plan => plan.plan_id === previousData.configuration.yabby_configuration_plan_id))[0].price : "-"
            const currentCurrency = previousData && plans && plans.filter((plan => plan.plan_id === previousData.configuration.yabby_configuration_plan_id)).length > 0 ? plans.filter((plan => plan.plan_id === previousData.configuration.yabby_configuration_plan_id))[0].currency : "-"
            const newPrice = values && plans && plans.filter((plan => plan.plan_id === values.configuration.yabby_configuration_plan_id)).length > 0 ? plans.filter((plan => plan.plan_id === values.configuration.yabby_configuration_plan_id))[0].price : "-"
            const newCurrency = values && plans && plans.filter((plan => plan.plan_id === values.configuration.yabby_configuration_plan_id)).length > 0 ? plans.filter((plan => plan.plan_id === values.configuration.yabby_configuration_plan_id))[0].currency : "-"

            return (<Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

            >
                <DialogTitle id="alert-dialog-title">
                    {newPrice > currentPrice && translate('resources.devices.config.plans.upgrading_sub_alert')}
                    {newPrice < currentPrice && translate('resources.devices.config.plans.downgrading_sub_alert')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {translate('resources.devices.config.plans.switching_sub_alert')} <strong>{(currentPrice / 100).toFixed(2)} &nbsp;{currentCurrency.toUpperCase()}</strong> {translate('resources.devices.config.plans.to')} : <strong>{(newPrice / 100).toFixed(2)}&nbsp;{newCurrency.toUpperCase()}</strong>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}> {translate('resources.devices.config.plans.return')}</Button>
                    <Button onClick={handleAccept} autoFocus>
                        {translate('resources.devices.config.plans.continue')}
                    </Button>
                </DialogActions>
            </Dialog>)
        }

    }

    const handleSave = useCallback(
        (values, redirect) => {
            setValues(values);
            if (dialogRequired(previousData, values)) {
                setopenDialog(true);
            } else {
                handleAccept(values);
            }

        },
        [notify, redirectTo, basePath]
    );

    // set onSave props instead of handleSubmitWithRedirect
    return <div><SaveButton {...props} onSave={handleSave} /><DialogPopUp /></div>;
});


