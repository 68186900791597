import React from 'react';
import compose from 'recompose/compose'

import { translate } from 'react-admin';

import { Button } from '@mui/material';
import { VERSION_CHECK_DATE } from '../../pages/configuration/actions';
import { getAppVersion } from '../../rest/restClient';




const RefreshVersionButton = ({
    basePath = '',
    record = {},
    translate,
    ...rest
}) => {



    const checkVersion = () => {
        getAppVersion().then(response => {
            if (response && response.json && response.json._embedded && response.json._embedded.software_versions
                && response.json._embedded.software_versions.length === 1
                && response.json._embedded.software_versions[0].software_name === "connect-app"
            ) {
                const software_version = response.json._embedded.software_versions[0].release_version
                if (software_version != process.env.REACT_APP_VERSION) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        }).then(required => {
            if (required === true) {
                console.log("[App Version Check] Refresh required!");
                handleClick();
            } else {
                console.log("[" + new Date().toLocaleString() + "] [App Version Check] " + process.env.REACT_APP_VERSION);
            }
        })
    }
    const handleClick = (e) => {
        sessionStorage.clear()
        localStorage.clear()
        caches.keys().then(keys => {
            keys.forEach(key => caches.delete(key))
        })

        indexedDB.databases().then(dbs => {
            dbs.forEach(db => indexedDB.deleteDatabase(db.name))
        })

        var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }

        window.location.reload(false)


    }

    if (localStorage.getItem(VERSION_CHECK_DATE) !== null) {
        const tenMinutesAfterCheckDate = new Date(localStorage.getItem(VERSION_CHECK_DATE)).getTime() + 600000;
        const now = new Date().getTime();
        if (now > tenMinutesAfterCheckDate) {
            localStorage.setItem(VERSION_CHECK_DATE, new Date().toISOString())
            checkVersion();
        }
    }

    return (<Button
        color="primary"
        onClick={handleClick}
        {...rest}
    >
        {`@${process.env.REACT_APP_ROOT_ORGANIZATION} ${process.env.REACT_APP_VERSION}`}

    </Button>


    )

};



const enhance = compose(
    translate,
);

export default enhance(RefreshVersionButton);