import React from 'react'
import { Button, Card, CardContent } from '@material-ui/core'
import { CHANGE_THEME, USER_FAV_LANGUAGE } from './actions'
import { useTranslate, useLocale, useSetLocale } from 'react-admin'
import { Themes } from '../../theme/theme'
import style from 'jss-material-ui'
//const themeConfig = {}
const Label = style('div')({
    root: {
        label: { width: '10em', display: 'inline-block' },
    },
})

const SButton = style(Button)({
    root: {
        margin: 10,
    },
})



export function ColorsAndInt({ theme, changeConfig }) {
    const setLocale = useSetLocale();
    const translate = useTranslate();
    const locale = useLocale();
    return (
        <Card>
            <CardContent>
                <Label>{translate('resources.theme')}</Label>
                {Object.keys(Themes).map((iTheme, idx) => (
                    <SButton
                        key={idx}
                        variant="contained"
                        color={theme === iTheme ? 'primary' : 'default'}
                        onClick={() => changeConfig(CHANGE_THEME, iTheme)}
                    >
                        {iTheme}
                    </SButton>
                ))}
            </CardContent>
            <CardContent>
                <Label>{translate('resources.lang')}</Label>
                <SButton
                    variant="contained"
                    color={locale === 'en' ? 'primary' : 'default'}
                    onClick={() => { setLocale('en'); changeConfig(USER_FAV_LANGUAGE, 'en') }}
                >
                    en
                </SButton>
                <SButton
                    variant="contained"
                    color={locale === 'fr' ? 'primary' : 'default'}
                    onClick={() => { setLocale('fr'); changeConfig(USER_FAV_LANGUAGE, 'fr') }}
                >
                    fr
                </SButton>
            </CardContent>
        </Card>
    )
}

