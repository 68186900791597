import React from 'react';
import { useStripe, useElements, IbanElement } from '@stripe/react-stripe-js';
import { SimpleForm, Toolbar, translate, TextInput, required } from 'react-admin'
import { SaveSepaButton } from './SaveSepaButton'
import { Grid, Divider, Typography } from '@mui/material'

// Custom styling can be passed as options when creating an Element.
const IBAN_STYLE = {
    base: {
        color: '#32325d',
        fontSize: '16px',
        '::placeholder': {
            color: '#aab7c4'
        },
        ':-webkit-autofill': {
            color: '#32325d',
        },
    },
    invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
        ':-webkit-autofill': {
            color: '#fa755a',
        },
    }
};

const IBAN_ELEMENT_OPTIONS = {
    supportedCountries: ['SEPA'],
    placeholderCountry: 'FR',
    style: IBAN_STYLE,
};

const IbanFormToolBar = props => {
    return (
        <Toolbar {...props} >
            <SaveSepaButton {...props} submitOnEnter={true} onSubmit={props.onSubmit} stripe={props.stripe} />
        </Toolbar>
    )
}

const IbanForm = ({ onSubmit, disabled }) => {
    return (


        <SimpleForm redirect="list" toolbar={<IbanFormToolBar onSubmit={onSubmit} />}>

            <Grid container spacing={2}>
                <Grid item xs={12}>

                    <Typography> Name :</Typography>

                    <TextInput label="ex. Jean DUBOIS" source="accountholderName" variant="standard" validate={[required()]} />


                </Grid>
                <Grid item xs={12}>

                    <Typography> Email Address :</Typography>

                    <TextInput label="ex. jd@domain.com" source="email" variant="standard" validate={[required()]} />


                </Grid>
                <Grid item xs={12}>
                    <label>
                        <Typography> IBAN :</Typography>
                        <IbanElement options={IBAN_ELEMENT_OPTIONS} />
                    </label>
                </Grid>
                <Grid item xs={8}>
                    <Divider />
                    <Typography varant="body1" m={2}>   En fournissant vos informations de paiement et en confirmant ce paiement, vous autorisez (A) TICATAG et Stripe, notre prestataire de services de paiement et/ou PPRO, son prestataire de services local, à donner à votre banque l’instruction de débiter votre compte, et (B) votre banque à débiter votre compte conformément à ces instructions. Dans le cadre de vos droits, vous êtes en droit d’obtenir un remboursement de la part de votre banque, conformément aux conditions de votre contrat avec cette dernière. Toute demande de remboursement doit être présentée dans les 8 semaines suivant la date de débit de votre compte. Vos droits sont précisés dans une déclaration que vous pouvez obtenir auprès de votre banque. Vous acceptez de recevoir des notifications concernant les débits futurs jusqu’à 2 jours avant qu’ils n’aient lieu.
                    </Typography>
                </Grid>
            </Grid>

        </SimpleForm>
    );
};




export default function SepaSetupForm() {

    return (
        <IbanForm />
    );
}