/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import Chip from '@material-ui/core/Chip'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { green, red } from '@material-ui/core/colors'
import { translate } from 'react-admin'

import { withStyles } from '@material-ui/core/styles'
import { Title100Form, SubTitle100Form } from '../../ui/Grid'
import { Paper } from '@mui/material'

//ticatag

const styles = {
    text: {
        textTransform: 'uppercase',
        fontSize: 10,
        height: 24,
    },
    ok: { backgroundColor: green[500], color: 'white' },
    nok: { backgroundColor: red[500], color: 'white' },
}



const SensorTable = translate(({ classes, ...props }) => {

    const { data, ids, translate, label } = props

    var events = []
    if (ids.length === 0) {
        // no data to display 
        return null
    } else {
        events = Object.values(data)
    }


    return (
        <Paper elevation={3} sx={{
            backgroundColor: '#fff',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            margin: 5
        }}>
            <Title100Form>{translate(label)}</Title100Form>
            <SubTitle100Form>{`${events.length} ${translate('resources.measures.name', { smart_count: 2 }).toLowerCase()}`}</SubTitle100Form>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{translate('resources.measures.date')}</TableCell>
                        <TableCell>{translate('resources.measures.status')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {events.map((elem, idx) => {
                        return (
                            <TableRow key={idx}>
                                <TableCell component="th" scope="row">
                                    {new Date(elem.timestamp).toLocaleDateString()} -{' '}
                                    {new Date(elem.timestamp).toLocaleTimeString()}
                                </TableCell>
                                <TableCell>
                                    {(elem.status === 'clear' || elem.status === 'closed' || elem.status === 'off' || elem.status === 'none' || elem.status === 'still') &&
                                        <Chip
                                            className={[classes.text, classes.ok]}
                                            label={translate(`resources.measures.${elem.name}_status_${elem.status}`)}
                                        />
                                    }
                                    {(elem.status === 'open' || elem.status === 'detected' || elem.status === 'on' || elem.status === 'moving') &&
                                        <Chip
                                            className={[classes.text, classes.nok]}
                                            label={translate(`resources.measures.${elem.name}_status_${elem.status}`)}
                                        />
                                    }
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </Paper>

    )


})

export default withStyles(styles)(translate(SensorTable));
