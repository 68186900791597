import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { Filter, Responsive, changeListParams, } from 'react-admin'
import AdvancedSearchMenu from './advancedsearch'
import QuickSearchMenu from './quicksearch'
import { HGrid } from '../../ui/Grid'
import { changeConfig } from '../configuration/actions'

class LocationHistoryFilters extends React.Component {

    componentWillUnmount() {
        this.props.changeListParams('locations_events', { filter: {}, order: "DESC", page: "1", perPage: 25, sort: "timestamp" });
    }

    render() {
        return (
            <Responsive
                xsmall={<HGrid noflex mEnd sCenter />}
                small={
                    <HGrid noflex mEnd sCenter>
                        <QuickSearchMenu setPerPage={this.props.setPerPage} setFilters={this.props.setFilters} changeConfig={this.props.changeConfig} config={this.props.config} />
                    </HGrid>
                }
                medium={
                    <HGrid noflex mEnd sCenter>
                        <AdvancedSearchMenu setFilters={this.props.setFilters} changeConfig={this.props.changeConfig} config={this.props.config} />
                        <QuickSearchMenu setPerPage={this.props.setPerPage} setFilters={this.props.setFilters} changeConfig={this.props.changeConfig} config={this.props.config} />
                    </HGrid>
                }
            />
        )
    }
}

LocationHistoryFilters.propTypes = {
    setPerPage: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    withMenu: PropTypes.bool,
    ...Filter.propTypes,
}

const mapStateToProps = state => ({
    theme: state.config.theme,
    config: state.config,
})

const enhance = compose(
    connect(
        mapStateToProps,
        {
            changeListParams,
            changeConfig,
        }
    )
)

export default enhance(LocationHistoryFilters)
