import { Component } from 'react'

class Login extends Component {

     render() {
        setTimeout(function(){ window.location.href = process.env.REACT_APP_AUTH_URL }, 500)
        return null
    }
}

export default Login
