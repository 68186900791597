import React from 'react';
import compose from 'recompose/compose'
import PropTypes from 'prop-types';
import SubscriptionIcon from '@material-ui/icons/CreditCard';
import { Link } from 'react-router-dom';
import { linkToRecord, translate } from 'react-admin';
import { Button, Tooltip } from '@material-ui/core'

const SubscriptionButton = ({
    basePath = '',
    record = {},
    translate,
    ...rest
}) => (
    <Tooltip title={translate(`resources.devices.browse_subscription`)}>
        <Button 
            color="primary"
            component={Link}
            to={`${linkToRecord('devices', record.id)}/subscribe`}
            {...rest}
        >
            <SubscriptionIcon/>
        </Button>
    </Tooltip>
);

SubscriptionButton.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.object,
    record: PropTypes.object,
};

const enhance = compose(
    translate,
);

export default enhance(SubscriptionButton);