import React from 'react'
import { TopToolbar, Datagrid, Filter, FunctionField, List, Responsive, TextField, TextInput, translate } from 'react-admin'
import { PER_PAGE } from '../../../helper/resourceHelper'
import { Chip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { green, red } from '@material-ui/core/colors'
import style from 'jss-material-ui'
import MeasureHistoryButton from '../../../ui/button/MeasureHistoryButton'
import MobileGrid from './MobileGrid'
import { Box, Typography } from '@mui/material'
const PSmall = style('p')({
    root: {
        fontSize: 11,
        marginTop: 6,
        marginBottom: 2,
    },
})

const P = style('p')({
    root: {
        //textAlign: 'center',
        //width: 120,
        //marginTop: 2,
        //marginBottom: 6,
    },
})


const sstyle = theme => ({
    text: {
        textTransform: 'uppercase',
        fontSize: 10,
        height: 24,
    },
    ok: { backgroundColor: green[500], color: 'white' },
    nok: { backgroundColor: red[500], color: 'white' },
    headerPadding: {
        padding: '0px 12px !important'
    },
    listItemBackground: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
    },
    list: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        marginTop: 20,

    },
    box: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    }
})

function getTimestamp(record) {
    let timestamps = [];

    if (record.last_temperature) {
        timestamps.push(record.last_temperature.timestamp)
    }
    if (record.last_humidity) {
        timestamps.push(record.last_humidity.timestamp)
    }
    if (record.last_door) {
        timestamps.push(record.last_door.timestamp)
    }
    if (record.last_shock) {
        timestamps.push(record.last_shock.timestamp)
    }
    if (record.last_water_leak) {
        timestamps.push(record.last_water_leak.timestamp)
    }

    const latest_timestamp = timestamps.length === 0 ? null : timestamps.sort().reverse()[0]

    let timestamp = null;
    if (latest_timestamp) {
        timestamp = new Date(latest_timestamp).toLocaleDateString() + ' - ' + new Date(latest_timestamp).toLocaleTimeString()
    }


    return timestamp

}

function hasSameTimestamp(record) {
    let timestamps = [];

    if (record.last_temperature) {
        timestamps.push(record.last_temperature.timestamp)
    }
    if (record.last_humidity) {
        timestamps.push(record.last_humidity.timestamp)
    }
    if (record.last_door) {
        timestamps.push(record.last_door.timestamp)
    }
    if (record.last_shock) {
        timestamps.push(record.last_shock.timestamp)
    }
    if (record.last_water_leak) {
        timestamps.push(record.last_water_leak.timestamp)
    }
    if (record.last_co2) {
        timestamps.push(record.last_co2.timestamp)
    }
    if (record.last_battery) {
        timestamps.push(record.last_battery.timestamp)
    }

    return timestamps.length > 0 && timestamps.every(timestamp => timestamp === timestamps[0])
}

class MeasureList extends React.Component {
    render() {
        const { classes, translate } = this.props
        return (
            <List
                {...this.props}
                className={classes.list}
                title={translate('resources.measures.name', { smart_count: 2 })}
                filters={
                    <Filter>
                        <TextInput variant="standard" margin="normal" label={translate('resources.common.search')} source="q" alwaysOn />
                    </Filter>
                }
                filter={{
                    includes: 'last_humidity, last_battery, last_temperature, last_co2, last_door, last_water_leak, last_shock, product',
                    sensors: true,
                }}
                actions={<TopToolbar />}
                sort={{ field: 'name', order: 'ASC' }}
                bulkActionButtons={false}
                perPage={PER_PAGE}
            >
                <Responsive
                    className={classes.listItemBackground}
                    xsmall={
                        <MobileGrid />
                    }
                    medium={
                        <Datagrid>
                            <TextField
                                source="name"
                                label={translate('resources.devices.name', { smart_count: 1 })}
                            />
                            <FunctionField
                                label={'resources.measures.timestamp'}
                                source="last_temperature_timestamp"
                                render={record => getTimestamp(record)}
                            />
                            <FunctionField
                                label={'resources.devices.fields.battery'}
                                source="last_battery_value"
                                render={record => this.displayBattery(record)}
                            />
                            <FunctionField
                                label={'resources.temperatures.head'}
                                source="last_temperature_value"
                                render={record => this.displayTemperature(record)}
                            />
                            <FunctionField
                                label='CO2'
                                source="last_co2_value"
                                render={record => this.displayCo2(record)}
                            />
                            <FunctionField
                                label={'resources.humidity.head'}
                                source="last_humidity_value"
                                render={record => this.displayHumidity(record)}
                            />
                            <FunctionField
                                label={'resources.door.head'}
                                source="last_door_status"
                                render={record => this.displayLid(record)}
                            />
                            <FunctionField
                                label={'resources.waterLeak.head'}
                                source="last_water_leak_status"
                                render={record => this.displayWaterLeak(record)}
                            />
                            <FunctionField
                                label={'resources.shock.head'}
                                source="last_shock_status"
                                render={record => this.displayShock(record)}
                            />
                            <FunctionField
                                headerClassName={classes.headerPadding}
                                label={'resources.common.history'}
                                render={record => <MeasureHistoryButton record={record} />}
                            />
                        </Datagrid>
                    }
                />
            </List>
        )
    }

    displayHumidity(record) {
        if (!record.last_humidity) return ''

        return (
            <div>
                <P>{Math.round(record.last_humidity.value * 100) / 100}%</P>
                {!hasSameTimestamp(record) && <PSmall>
                    {new Date(record.last_humidity.timestamp).toLocaleDateString()} -{' '}
                    {new Date(record.last_humidity.timestamp).toLocaleTimeString()}
                </PSmall>}
            </div>
        )
    }

    displayTemperature(record) {
        if (!record.last_temperature) return ''

        return (
            <div>
                <P>{Math.round(record.last_temperature.value * 100) / 100}°</P>
                {!hasSameTimestamp(record) && <PSmall>
                    {new Date(record.last_temperature.timestamp).toLocaleDateString()} -{' '}
                    {new Date(record.last_temperature.timestamp).toLocaleTimeString()}
                </PSmall>}
            </div>
        )
    }

    displayBattery(record) {

        const { translate, classes } = this.props
        if (!record.last_battery) return ''
        if (record.last_battery.unit === 'percent') {
            return (
                <Box className={[classes.box]}>
                    <Typography variant='body2'>{`${record.last_battery.value}%`}</Typography>
                    <Typography variant="caption" style={{ fontSize: '10px' }}  >{new Date(record.last_battery.timestamp).toLocaleDateString()}</Typography>
                </Box>
            )

        } else if (record.last_battery.status === 'good') {
            return (
                <Box className={[classes.box]}>
                    <Chip
                        className={[classes.text, classes.nok]}
                        label={translate('resources.devices.battery_status.good')}
                    />
                    <Typography variant="caption" style={{ fontSize: '7px' }}>{new Date(record.last_battery.timestamp).toLocaleDateString()}</Typography>
                </Box>
            )
        } else if (record.last_battery.status === 'bad') {
            return (
                <Box className={[classes.box]}>
                    <Chip
                        className={[classes.text, classes.nok]}
                        label={translate('resources.devices.battery_status.bad')}
                    />
                    <Typography variant="caption" style={{ fontSize: '7px' }}>{new Date(record.last_battery.timestamp).toLocaleDateString()}</Typography>
                </Box>
            )
        } else {
            return null
        }

    }

    displayCo2(record) {
        if (!record.last_co2) return ''

        return (
            <div>
                <P>{record.last_co2.value} ppm</P>
                {!hasSameTimestamp(record) && <PSmall>
                    {new Date(record.last_co2.timestamp).toLocaleDateString()} -{' '}
                    {new Date(record.last_co2.timestamp).toLocaleTimeString()}
                </PSmall>}
            </div>
        )
    }

    displayLid(record) {
        if (!record.last_door) return ''
        const { translate, classes } = this.props

        return (
            <div>
                {record.last_door.status === 'closed' && (
                    <P>
                        <Chip
                            className={[classes.text, classes.ok]}
                            label={translate('resources.door.closed')}
                        />
                    </P>
                )}
                {record.last_door.status === 'open' && (
                    <P>
                        <Chip
                            className={[classes.text, classes.nok]}
                            label={translate('resources.door.open')}
                        />
                    </P>
                )}
                {!hasSameTimestamp(record) && <PSmall>
                    {new Date(record.last_door.timestamp).toLocaleDateString()} -{' '}
                    {new Date(record.last_door.timestamp).toLocaleTimeString()}
                </PSmall>}
            </div>
        )
    }

    displayWaterLeak(record) {
        if (!record.last_water_leak) return ''
        const { translate, classes } = this.props

        return (
            <div>
                {record.last_water_leak.status === 'off' && (
                    <P>
                        <Chip
                            className={[classes.text, classes.ok]}
                            label={translate('resources.waterLeak.off')}
                        />
                    </P>
                )}
                {record.last_water_leak.status === 'on' && (
                    <P>
                        <Chip
                            className={[classes.text, classes.nok]}
                            label={translate('resources.waterLeak.on')}
                        />
                    </P>
                )}
                {!hasSameTimestamp(record) && <PSmall>
                    {new Date(record.last_water_leak.timestamp).toLocaleDateString()} -{' '}
                    {new Date(record.last_water_leak.timestamp).toLocaleTimeString()}
                </PSmall>}
            </div>
        )
    }

    displayShock(record) {
        if (!record.last_shock) return ''

        const { translate, classes } = this.props

        return (
            <div>
                {record.last_shock.status === 'clear' && (
                    <P>
                        <Chip
                            className={[classes.text, classes.ok]}
                            label={translate('resources.shock.clear')}
                        />
                    </P>
                )}
                {record.last_shock.status === 'detected' && (
                    <P>
                        <Chip
                            className={[classes.text, classes.nok]}
                            label={translate('resources.shock.detected')}
                        />
                    </P>
                )}
                {!hasSameTimestamp(record) && <PSmall>
                    {new Date(record.last_shock.timestamp).toLocaleDateString()} -{' '}
                    {new Date(record.last_shock.timestamp).toLocaleTimeString()}
                </PSmall>}
            </div>
        )
    }
}

export default withStyles(sstyle)(translate(MeasureList))
