import React from 'react';
import compose from 'recompose/compose'
import PropTypes from 'prop-types';
import MultilineChart from '@material-ui/icons/MultilineChart';
import { Link } from 'react-router-dom';
import { translate } from 'react-admin';
import { Button, Tooltip } from '@material-ui/core'

const MeasureHistoryButton = ({
    basePath = '',
    label = 'resources.configuration.name',
    record = {},
    translate,
    ...rest
}) => (
    <Tooltip title={translate(`resources.measures.history`)}>
        <Button 
            color="primary"
            component={Link}
            to={'/measures/' + record.id + '/history'}
            {...rest}
        >
            <MultilineChart/>
        </Button>
    </Tooltip>
);

MeasureHistoryButton.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.object,
    label: PropTypes.string,
    record: PropTypes.object,
};

const enhance = compose(
    translate,
);

export default enhance(MeasureHistoryButton);