import React from 'react'
import PropTypes from 'prop-types'
import style from 'jss-material-ui'
import { green } from '@mui/material/colors'
import { Divider, CardMedia, Box, Grid, CardContent, Card, Typography, ButtonBase, Avatar } from '@mui/material'
import compose from 'recompose/compose';
import { translate } from 'react-admin'


const enhance = compose(
    translate
);

const SelectedProfilCard = enhance(({ translate, ...props }) => {
    const plan = props.plan;
    const price = props.price;
    const duration = props.duration;
    const taxDescription = plan.currency && plan.currency.toLowerCase() === "aud" ? "Including GST" : "Inc.TAX"
    const priceDescription = `${price} ${duration}\n${taxDescription}`
    return (
        <Grid item xs={12} sx={{ display: 'flex', width: "100%", paddingX: 30 }}    >
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ display: 'flex', width: "100%" }}
            >
                <Card sx={{ display: 'flex', width: "100%", margin: 1, border: 3, borderColor: 'primary.main' }}>
                    <ButtonBase
                        onClick={props.onClick}
                        sx={{ display: 'flex', width: "100%" }}
                    >

                        <Box sx={{ margin: 3, width: "30%", textAlign: "center" }}>
                            <Typography variant="h6" color="text.main"><strong>{plan.title}</strong> </Typography>

                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <CardContent sx={{ width: "100%", textAlign: "left" }}>

                            <Typography variant="subtitle1" color="text.secondary">
                                {plan.description.split("/").map(function (line) { return (<strong> {line}  < br />        </strong>) })}
                            </Typography>

                        </CardContent>
                        <Box sx={{ pl: 1, pb: 1, width: "40%", textAlign: "left" }}>
                            {priceDescription.split("\n").map(function (line) { return (<Typography variant="h6" color="text.secondary"><strong>{line}</strong> </Typography>) })}

                        </Box>
                    </ButtonBase>
                </Card >
            </Box>

        </Grid >)


});


const NonSelectedProfilCard = enhance(({ translate, ...props }) => {
    const plan = props.plan;
    const price = props.price;
    const duration = props.duration;
    const taxDescription = plan.currency && plan.currency.toLowerCase() === "aud" ? "Including GST" : "Inc.TAX"
    const priceDescription = `${price} ${duration}\n${taxDescription}`
    return (
        <Grid item xs={12} sx={{ display: 'flex', width: "100%", paddingX: 30 }} >
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ display: 'flex', width: "100%" }}
            >
                <Card sx={{ display: 'flex', width: "100%", margin: 1 }}>
                    <ButtonBase
                        onClick={props.onClick}
                        sx={{ display: 'flex', width: "100%" }}
                    >
                        <Box sx={{ margin: 3, width: "30%", textAlign: "center" }}>
                            <Typography variant="h6" color="text.main"><strong>{plan.title}</strong> </Typography>

                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <CardContent sx={{ width: "100%", textAlign: "left" }}>

                            {plan.description.split("/").map(function (line) { return (<Typography variant="subtitle1" color="text.secondary">{"" + line} </Typography>) })}

                        </CardContent>
                        <Box sx={{ pl: 1, pb: 1, width: "40%", textAlign: "left" }}>
                            {priceDescription.split("\n").map(function (line) { return (<Typography variant="h6" color="text.secondary">{line}</Typography>) })}


                        </Box>
                    </ButtonBase>
                </Card >
            </Box>

        </Grid>)


});



class CardSubscription extends React.Component {
    state = { expanded: false }

    render() {
        const { plan, selectedPlan, price, duration, handleClick } = this.props
        const isSelected = selectedPlan && selectedPlan.plan_id === plan.plan_id

        if (plan) {
            const card = isSelected ?
                <SelectedProfilCard onClick={() => handleClick(plan)} plan={plan} price={price} duration={duration} /> :
                <NonSelectedProfilCard onClick={() => handleClick(plan)} plan={plan} price={price} duration={duration} />
            return card
        }

    }
}

CardSubscription.propTypes = {
    classes: PropTypes.object.isRequired,
    plan: PropTypes.object.isRequired,
    selectedPlan: PropTypes.object,
    price: PropTypes.string.isRequired,
    duration: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
}

export default CardSubscription
