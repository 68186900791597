import React, { Component, Fragment } from 'react';
import compose from 'recompose/compose'
import { connect } from 'react-redux';
import {
    Button,
    SaveButton,
} from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Card, CardMedia, MenuItem } from '@material-ui/core'
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import style from 'jss-material-ui'
import { getMarkerIcons, } from '../../../rest/restClient'
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { changeConfig } from '../../configuration/actions';

const SCard = style(Card)(theme => ({
    root: {
        maxWidth: 64,
    },
}))

const SselectedColorCard = style(SCard)(theme => ({
    root: {
        borderColor: theme.palette.primary.main,
        borderStyle: 'solid',
        borderWidth: 2,
    },
}))

const styles = theme => ({
    grid: {
        flexGrow: 1,
        marginTop: 10,
        marginBottom: 10,
    },
    media: {
        height: 64,
    },
    small: {
        height: 12,
        width: 12,
    },
    medium: {
        height: 24,
        width: 24,
    },
    large: {
        height: 48,
        width: 48,
    },
});

const sizes = ["small", "medium", "large"]

class ChangeIcon extends Component {
    constructor(props) {
        super(props)
        this.handleSelectColor = this.handleSelectColor.bind(this)
        this.handleSelectSize = this.handleSelectSize.bind(this)

        this.state = {
            error: false,
            showDialog: false,
            markers: [],
            selectedColor: props.icon,
            selectedSize: props.size,
        };
    }

    componentDidMount() {
        getMarkerIcons().then(response => {
            this.setState({
                markers: response.json,
            })
        })
    }

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleSaveClick = () => {
        const { changeConfig, updateIcon, record } = this.props;
        const { selectedColor, selectedSize } = this.state;
        updateIcon(record.device_id, record, selectedColor)
        this.setState({ showDialog: false });
        changeConfig(`${record.device_id}_icon_url`, selectedColor);
        changeConfig(`${record.device_id}_icon_size`, selectedSize);
    };

    handleSelectColor = (e, marker) => {
        this.setState({ selectedColor: marker.url })
    }

    handleSelectSize = (e, size) => {
        this.setState({ selectedSize: size })
    }

    render() {
        const { showDialog, markers, selectedColor, selectedSize } = this.state;
        const { classes, isSubmitting, translate } = this.props;
        return (
            <Fragment>
                <MenuItem
                    key="changeIcon"
                    onClick={this.handleClick}
                >
                    {translate('resources.devices.labels.changeIcon')}
                </MenuItem>
                <Dialog
                    fullWidth
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-label="Change Icon"
                >
                    <DialogTitle>{translate('resources.devices.labels.changeIcon')}</DialogTitle>
                    <DialogContent>
                        <Typography variant="h6" gutterBottom>
                            Color
                        </Typography>
                        <Grid container className={classes.grid} spacing={24}>
                            {markers.map((marker, index) => {
                                return (
                                    <Grid item xs={3}>
                                        {marker.url === selectedColor ? <SselectedColorCard>
                                            <CardMedia
                                                className={classes.media}
                                                image={marker.url}
                                            />
                                        </SselectedColorCard> :
                                            <SCard onClick={e => this.handleSelectColor(e, marker)}>
                                                <CardMedia
                                                    className={classes.media}
                                                    image={marker.url}
                                                />
                                            </SCard>}
                                    </Grid>
                                )
                            })}
                        </Grid>
                        <Divider />
                        <Typography variant="h6" gutterBottom style={{ marginTop: 10 }}>
                            Taille
                        </Typography>
                        <Grid container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            className={classes.grid}
                            spacing={32}>
                            {sizes.map((size) => {
                                return (<Grid item>
                                    {selectedSize === size ? <SselectedColorCard>
                                        <CardMedia
                                            className={classes[size]}
                                            image={selectedColor}
                                        />
                                    </SselectedColorCard> : <SCard onClick={e => this.handleSelectSize(e, size)}>
                                        <CardMedia
                                            className={classes[size]}
                                            image={selectedColor}
                                        />
                                    </SCard>}
                                </Grid>)
                            })}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <SaveButton
                            saving={isSubmitting}
                            handleSubmitWithRedirect={() => { }}
                            onClick={this.handleSaveClick}
                        />
                        <Button
                            label="ra.action.cancel"
                            onClick={this.handleCloseClick}
                        >
                            <IconCancel />
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    config: state.config,
})

const enhance = compose(
    connect(
        mapStateToProps,
        {
            changeConfig,
        }
    ),
    withStyles(styles),
);


export default enhance(ChangeIcon);
