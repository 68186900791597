import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import {
    translate,
    MenuItemLink,
    Responsive,
    WithPermissions,
} from 'react-admin';
import { withRouter } from 'react-router-dom';

import { DeviceIcon } from '../pages/device'
import { GeofenceIcon } from '../pages/geofence'
import { LocationIcon } from '../pages/location'
import { MeasureIcon } from '../pages/measure'
import { NotificationIcon } from '../pages/notification'
import { IntegrationIcon } from '../pages/integration'

import { OrganizationIcon } from '../pages/organization'
import { UserIcon } from '../pages/user'
import { DeveloperIcon, DocumentationIcon, HookIcon } from '../pages/hook'
import { isAdminPermissions, isSuperAdmin } from '../helper/resourceHelper'
import SubMenu from './SubMenu'
import MenuItem from '@material-ui/core/MenuItem';
import { visitedPageList } from '../analytics/events';
import { CollectionsOutlined } from '@mui/icons-material';
import { Divider, Typography } from '@mui/material';
import RefreshVersionButton from '../ui/button/RefreshVersionButton';



const styles = theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '100%',
    },
    menu: {
        marginTop: theme.spacing(4),


    },
    footer: {
        marginBottom: "1.5em"
    },
    sideBar: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },

    root: {
        color: theme.palette.text.secondary,
        display: 'flex',
        alignItems: 'flex-start',

    },
    active: {
        color: theme.palette.text.primary,
    },
    icon: {
        paddingRight: '2rem',

    },
    subMenuicon: {
        paddingRight: '1rem',

    },
    menuLinkText: {
        fontSize: '1rem',
        color: theme.palette.text.secondary,
    },
    menuLink: {
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
        "& .RaMenuItemLink-icon-58": {
            minWidth: '56px',
        },
    },
    subMenu: {
        //marginRight: '10rem',
        minWidth: '0.8rem',
    },


})

class Menu extends Component {

    state = {
        menuDevelopers: false,
    };

    static propTypes = {
        onMenuClick: PropTypes.func,
        logout: PropTypes.object,
    };

    handleToggle = menu => {
        this.setState(state => ({ [menu]: !state[menu] }));
    };

    handleClick = (page) => {
        const onMenuClick = this.props;

        visitedPageList(page);
        return (onMenuClick)
    }

    render() {
        const { onMenuClick, open, logout, translate, classes } = this.props;
        return (
            <div className={classes.sideBar} >
            <div style={styles.menu} className={classes.menu}>

                <MenuItemLink
                    key='devices'
                    to={`/devices`}
                    primaryText={translate(`resources.devices.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<DeviceIcon className={classes.icon} />}
                    onClick={onMenuClick}
                    className={classes.menuLink}

                />
                <MenuItemLink
                    key='locations'
                    to={`/locations`}
                    primaryText={translate(`resources.locations.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<LocationIcon className={classes.icon} />}
                    onClick={onMenuClick}
                    className={classes.menuLink}
                />
                <MenuItemLink
                    key='measures'
                    to={`/measures`}
                    primaryText={translate(`resources.measures.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<MeasureIcon className={classes.icon} />}
                    onClick={onMenuClick}
                    className={classes.menuLink}
                />
                <MenuItemLink
                    key='geofences'
                    to={`/geofences`}
                    primaryText={translate(`resources.geofences.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<GeofenceIcon className={classes.icon} />}
                    onClick={onMenuClick}
                    className={classes.menuLink}
                />
                <MenuItemLink
                    key='notifications'
                    to={`/notifications`}
                    primaryText={translate(`resources.notifications.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<NotificationIcon className={classes.icon} />}
                    onClick={onMenuClick}
                    className={classes.menuLink}
                />
                    <WithPermissions
                        render={({ permissions }) => (
                            isAdminPermissions(permissions) ? <MenuItemLink
                        key='integrations'
                        to={`/integrations`}
                        primaryText={translate(`resources.integrations.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<IntegrationIcon className={classes.icon} />}
                        onClick={onMenuClick}
                        className={classes.menuLink}
                            /> : null
                        )}
                    />
                <WithPermissions
                    render={({ permissions }) => (
                        isAdminPermissions(permissions) ? <MenuItemLink
                            key='users'
                            to={`/users`}
                            primaryText={translate(`resources.users.name`, {
                                smart_count: 2,
                            })}
                            leftIcon={<UserIcon className={classes.icon} />}
                            onClick={onMenuClick}
                            className={classes.menuLink}
                        /> : null
                    )}
                />
                <WithPermissions
                    render={({ permissions }) => (
                        isAdminPermissions(permissions) ? <MenuItemLink
                            key='organizations'
                            to={`/organizations`}
                            primaryText={translate(`resources.organizations.name`, {
                                smart_count: 2,
                            })}
                            leftIcon={<OrganizationIcon className={classes.icon} />}
                            onClick={onMenuClick}
                            className={classes.menuLink}
                        /> : null
                    )}
                />

                <div className={classes.subMenu}>
                    <SubMenu
                        handleToggle={() => this.handleToggle('menuDevelopers')}
                        isOpen={this.state.menuDevelopers}
                        sidebarIsOpen={open}
                        name={<span className={classes.menuLinkText}>
                            {translate("resources.common.developers")}
                        </span>}
                        icon={<div className={classes.subMenu}><DeveloperIcon className={classes.icon} /></div>}

                    >
                        <MenuItemLink
                            key='hooks'
                            to={`/hooks`}
                            primaryText={translate(`resources.hooks.name`, {
                                smart_count: 2,
                            })}
                            leftIcon={<HookIcon />}
                            onClick={onMenuClick}
                        />
                        <MenuItem component="a" href="https://developers.ticatag.com" target="_blank" className={classes.root}>
                            <span className={classes.subMenuicon}>
                                <DocumentationIcon />
                            </span>
                            {translate('resources.common.documentation')}
                        </MenuItem>
                    </SubMenu>
                </div>
                <Responsive xsmall={logout} medium={null} />

                </div >
                <div className={classes.footer} >

                    <Divider ><RefreshVersionButton /></Divider>
                </div>
            </div>



        )
    }
}

const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
    theme: state.theme,
    //locale: state.i18n.locale,
});

const enhance = compose(
    withStyles(styles),
    withRouter,
    connect(
        mapStateToProps,
        {}
    ),
    translate
);

export default enhance(Menu);





