import React, { useState, useReducer } from 'react'
import { useSelector } from 'react-redux'
import { CHANGE_THEME, changeConfig, USER_FAV_LANGUAGE } from '../pages/configuration/actions'
import { useTranslate, useLocale, useSetLocale } from 'react-admin'
import { Themes } from './theme'
import style from 'jss-material-ui'
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import LanguageIcon from '@mui/icons-material/Language';
import { Fade, MenuItem, Menu, IconButton, Button, Typography } from '@mui/material';
import Flag from 'react-world-flags'
//const themeConfig = {}
const Label = style('div')({
    root: {
        label: { width: '10em', display: 'inline-block' },
    },
})

const SButton = style(Button)({
    root: {
        margin: 10,
    },
})

export function ThemeMenu() {

    const theme = useSelector((state) => state.config[CHANGE_THEME])
    const [_, forceUpdate] = useState((x) => x + 1, 0);
    const handleClick = (e) => {

        if (theme === 'light') {
            changeConfig(CHANGE_THEME, 'dark')
        } else {
            changeConfig(CHANGE_THEME, 'light')
        }
        forceUpdate(e)

    };



    return (
        <div>

            <IconButton
                id="fade-button"
                variant="text"
                onClick={handleClick}
            >

                <SettingsBrightnessIcon style={{ color: "#fff" }} />

            </IconButton>

        </div>
    );
}

export function LanguageMenu() {
    const [anchorEl, setAnchorEl] = useState(null);
    const setLocale = useSetLocale();
    const translate = useTranslate();
    const locale = useLocale();
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>

            <IconButton
                id="fade-button"
                variant="text"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >

                <LanguageIcon style={{ color: "#fff" }} />
                <Typography style={{ color: "#fff" }} variant="body2">&nbsp;{locale.toUpperCase()}</Typography>
            </IconButton>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >

                <MenuItem onClick={() => { setLocale('en'); changeConfig(USER_FAV_LANGUAGE, 'en') }} sx={{ backgroundColor: locale === 'en' ? 'FE0037' : 'f6b26b' }}> <Flag code="gb" height="20" width="20" />&nbsp; EN </MenuItem>

                <MenuItem onClick={() => { setLocale('fr'); changeConfig(USER_FAV_LANGUAGE, 'fr') }} sx={{ backgroundColor: locale === 'fr' ? 'FE0037' : 'f6b26b' }}><Flag code="fr" height="20" width="20" />&nbsp; FR </MenuItem>


            </Menu>
        </div>
    );
}

//  <MenuItem onClick={() => setLocale('spa')} sx={{ backgroundColor: locale === 'spa' ? 'FE0037' : 'f6b26b' }}><Flag code="es" height="20" width="20" />&nbsp; ES </MenuItem>


