import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import { Toolbar, translate } from 'react-admin'
import { H100Grid, V100Grid } from '../../../ui/Grid'
import { TitlePlan } from '../../../theme/layout'
import { WizardForm } from '../../../ui/form/WizardForm'
import NextButton from '../../../ui/button/NextButton'
import CardSubscription from './CardSubscription'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import { canCancelSubscription } from '../../../helper/resourceHelper'

const SUBSCRIPTION_SHOW = 1
const SUBSCRIPTION_OFFER = 2

const DeviceCreateToolbar = props => (
    <Toolbar  {...props}>
        <NextButton type="submit" disabled={props.disabled} label={props.translate('resources.subscriptions.add_subscription')} />
    </Toolbar>
)

const DeviceCancelToolbar = props => (
    <Toolbar  {...props}>
        <NextButton type="submit" label={props.translate('resources.subscriptions.cancel_subscription')} />
    </Toolbar>
)

const canAddSubscription = subscriptions => {
    if (!subscriptions || subscriptions.length === 0)
        return true

    const nbOfPostPaidSubs = subscriptions.filter(sub => sub.plan && sub.plan.status === 'active' && sub.plan.type === 'postpaid').length
    if (nbOfPostPaidSubs > 0)
        return false

    const maxEndDate = subscriptions.reduce(
        (max, sub) => (moment.utc(sub.end_date).isAfter(moment.utc(max)) ? sub.end_date : max),
        subscriptions[0].end_date
    )
    const latestSubIsCanceled = subscriptions.filter(sub => sub.end_date === maxEndDate && sub.status === 'canceled').length > 0

    const nbOfDays = Date.daysBetween(new Date(), new Date(maxEndDate))
    if (nbOfDays < 30 || latestSubIsCanceled)
        return true

    return false
}

const getState = subscription => {
    const now = moment.utc()
    const start = moment.utc(subscription.start_date)
    const end = moment.utc(subscription.end_date)
    if (subscription.status === "unpaid") {
        return 'unpaid'
    }
    if (start.isBefore(now) && end.isAfter(now)) {
        return 'onGoing'
    } else {
        return 'comingNext'
    }
}


class CheckSubscription extends React.Component {

    render() {
        const { onSubmit, nextPage, translate } = this.props
        const { subscriptions } = this.props
        const currentSubscriptions = subscriptions && subscriptions.length > 0 ? subscriptions.filter(sub => new Date(sub.end_date) > new Date()) : null
        return (
            <WizardForm onSubmit={onSubmit} nextPage={nextPage} toolbar={canCancelSubscription(currentSubscriptions) ? <DeviceCancelToolbar {...this.props} /> : <DeviceCreateToolbar disabled={!canAddSubscription(currentSubscriptions)} {...this.props} />} {...this.props}>
                <V100Grid center>
                    <TitlePlan fontSize={14}>{translate('resources.subscriptions.name', { smart_count: 1 }).toUpperCase()}</TitlePlan>
                    {currentSubscriptions &&
                        <H100Grid noMargin noPadding>
                            {
                                currentSubscriptions.map(function (sub, index) {
                                    return (<CardSubscription
                                        key={index}
                                        status={sub.status}
                                        startDate={sub.start_date}
                                        endDate={sub.end_date}
                                        cardType={SUBSCRIPTION_SHOW}
                                        state={getState(sub)} />
                                    )
                                })
                            }
                        </H100Grid>
                    }
                    {(!currentSubscriptions || currentSubscriptions.length === 0) &&
                        <Typography variant="h5" gutterBottom>
                            {translate('resources.subscriptions.no_subscription_found').toUpperCase()}
                        </Typography>
                    }
                </V100Grid>
            </WizardForm>

        )
    }
}

CheckSubscription.propTypes = {
    nextPage: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    value: PropTypes.object,
}

CheckSubscription.contextTypes = {
    theme: PropTypes.func,
}


export default translate(props => <Form {...props} component={CheckSubscription} />)