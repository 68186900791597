import React from 'react'
import {
    TopToolbar,
    CreateButton,
    Datagrid,
    EditButton,
    Filter,
    List,
    ReferenceField,
    Responsive,
    SelectField,
    TextField,
    TextInput,
    translate,
} from 'react-admin'
import { PER_PAGE, isSuperAdmin } from '../../../helper/resourceHelper'
import { LinkFullNameField } from './Customer'
import { ROLE_ORGA_ADMIN, ROLE_ORGA_USER, ROLE_USER } from '../../configuration/actions'
import InviteUserButton from '../../../ui/button/InviteUserButton'
import { withStyles } from '@material-ui/core/styles'

const stylesUserActions = theme => ({
    inviteButton: {
        marginLeft: 15
    },

})
const styles = theme => ({
    list: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        marginTop: 20,
    }
})

const UserActions = withStyles(stylesUserActions)(({
    basePath,
    classes,
}) => (
    <TopToolbar>
        <CreateButton basePath={basePath} label={'resources.users.create'} variant="contained" />
        <InviteUserButton basePath={basePath} to={`${basePath}/invite`} label={'resources.users.invite_action'} variant="contained" className={classes.inviteButton} />
    </TopToolbar>
))

const UserList = ({ translate, classes, ...props }) => (
    <List
        {...props}
        title={translate('resources.users.name', { smart_count: 2 })}
        className={classes.list}
        filters={
            <Filter>
                <TextInput
                    variant="standard"
                    margin="normal"
                    label={translate('resources.common.search')}
                    source="q"
                    alwaysOn
                    style={{ width: 200 }}
                />
            </Filter>
        }
        filter={{
            includes:
                'phone_number,company_name',
        }}
        actions={<UserActions />}
        perPage={PER_PAGE}
        bulkActionButtons={isSuperAdmin() ? true : false}
    >
        <Responsive
            xsmall={
                <Datagrid>
                    <LinkFullNameField label={'resources.users.user'} sortable={false} />
                    <SelectField
                        label={'resources.users.role'}
                        source="role"
                        translate={translate}
                        sortable={false}
                        choices={[
                            { id: ROLE_ORGA_ADMIN, name: translate('resources.role.admin') },
                            { id: ROLE_ORGA_USER, name: translate('resources.role.user') },
                            { id: ROLE_USER, name: translate('resources.role.owner') },
                        ]}
                    />
                </Datagrid>
            }
            medium={
                <Datagrid>
                    <LinkFullNameField label={'resources.users.user'} sortable={false} />
                    <TextField source="email" />
                    <TextField label={'resources.users.phoneNumber'} source="phone_number" />
                    <SelectField
                        label={'resources.users.role'}
                        source="role"
                        translate={translate}
                        sortable={false}
                        choices={[
                            { id: ROLE_ORGA_ADMIN, name: translate('resources.role.admin') },
                            { id: ROLE_ORGA_USER, name: translate('resources.role.member') },
                            { id: ROLE_USER, name: translate('resources.role.owner') },
                        ]}
                    />
                    <ReferenceField
                        label={'resources.organization.organization'}
                        source="organization_id"
                        reference="organizations"
                        sortable={false}
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <EditButton />
                </Datagrid>
            }
        />
    </List>
)

export default withStyles(styles)(translate(UserList))