import React, { useEffect, useState } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import {
    AutocompleteArrayInput,
    AutocompleteInput,
    BooleanInput,
    TopToolbar,
    DateTimeInput,
    DeleteButton,
    EditController,
    EditView,
    FormDataConsumer,
    FormTab,
    FunctionField,
    ListButton,
    number,
    maxValue,
    minValue,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    SelectInput,
    TabbedForm,
    TextInput,
    Toolbar,
    translate,
    showNotification,
    useLocale,
    minLength,
    maxLength,
} from 'react-admin'
import { hasWriteAccess, isAdminPermissions, isSuperAdmin, isXPressOrXTrakR, isXTrak, isXTrakS, isCo2Sensor, isYabby, isTiLocker, isEgIot, isQueclink, isOyster, isDeviceWithConfigurationPlan, isUnaSense, isUnaProtect } from '../../../helper/resourceHelper'
import { Avatar } from '../../../ui/Avatar'
import ConfigProfilCard from '../../../ui/card/ConfigProfilCard'
import { formFetch, getImage, deviceProduct } from '../../../rest/restClient'
import { withStyles } from '@material-ui/core/styles'
import { SaveDeviceButton } from './SaveDeviceButton'
import { Box, Typography } from '@mui/material';
import TiLockerConfInfo from '../../../ui/card/TiLockerConfInfo'



const styles = {
    geolocation_period: { width: 290, marginRight: '1rem' },
    retry_period: { display: 'inline-block', width: 290 },
    bluetooth_advertising: { display: 'inline-flex', marginRight: '1rem' },
    bluetooth_advertising_frequency: { display: 'inline-flex', width: 330 },
    send_message_in_motion: { display: 'inline-flex', width: 310 },
    temperature_low_limit: { display: 'inline-block', marginRight: '1rem' },
    temperature_up_limit: { display: 'inline-flex', marginRight: '1rem' },
    humidity_low_limit: { display: 'inline-block', marginRight: '1rem' },
    humidity_up_limit: { display: 'inline-flex', marginRight: '1rem' },
    yabby_configuration_profil: { alignItems: 'center', display: 'flex', justifyContent: 'flex-start', marginRight: '1rem', marginTop: '0.5em' },
    //yabby_configuration_select: { verticalAlign: 'middle', display: 'inline-flex', marginRight: '1rem' },
    co2_alert_treshold: { display: 'inline-flex', marginRight: '1rem', marginLeft: '2rem', width: 320 },
    co2_offset: { display: 'inline-flex', marginRight: '1rem', marginLeft: '2rem', width: 320 },
    co2_send_in_interval: { display: 'inline-flex', marginRight: '1rem', marginLeft: '2rem', width: 320 },
    co2_extrameasurementcount: { display: 'inline-flex', marginRight: '1rem', marginLeft: '2rem', width: 320 },
    margin: { display: 'inline-flex', marginRight: '1rem' },
    marginTop: { marginTop: '1rem' },
    unlock_requested: { display: 'inline-flex', marginRight: '1rem', marginLeft: '2rem', width: 320 },
    unlock_request_date_time: { display: 'inline-flex', marginRight: '1rem', marginLeft: '2rem', width: 320 },
    tilockerPatchBox: { display: 'flex', flex: 1, flexDirection: 'column', marginRight: '1rem', marginLeft: '2rem' },
    tilockerInfoBox: { display: 'flex', flex: 1, flexDirection: 'column', marginRight: '1rem', marginLeft: '2rem' },
    tilockerBox: { display: 'flex', flexDirection: 'row' },

    unabiz: { marginRight: '1rem', marginLeft: '2rem', width: 320 },
}

const onFileLoad = (id, image) => {
    const url = `/devices/${id}/image`

    formFetch(url, {
        image,
    }).catch(error => {
        const err = JSON.stringify(error)

        console.log(err)
    })
}

const onClose = id => {
    const url = `/devices/${id}/image`

    formFetch(url, null, 'delete').catch(error => JSON.stringify(error))
}

const validateRequired = [required('Champs obligatoire')]

const DeviceEditTitle = translate(({ record, translate }) => {
    return <span>{translate("resources.devices.edit_title", { deviceName: record.name })}</span>;
})

const DeviceEditActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
        <DeleteButton basePath={basePath} record={data} resource={resource} label="resources.devices.delete" />
    </TopToolbar>
);

const DeviceEditToolbar = ({ record, ...props }) => (
    <Toolbar {...props} >
        <SaveDeviceButton
            previousData={record}
            redirect="list"
        />
    </Toolbar>
);

const ownerOptionRenderer = (choice) => {
    if (choice !== undefined && choice !== null) {
        if (choice.first_name && choice.last_name) {
            return (`${choice.first_name.toUpperCase()} ${choice.last_name.toUpperCase()}`)
        } else {
            return (choice.email)
        }
    }

}
const co2SensorFreqValidate = (value, allValues) => {

    if (!value) {
        return 'Fréquence de capteur est obligatoire';
    }
    if ((allValues.configuration.send_interval / (value + 1)) < 1) {
        return 'La fréquence capteur doit être inférieur à celle de la plateforme ';
    }
    return undefined;
};

const getDeviceWithOperator = async (deviceId) => {
    const res = await deviceProduct(deviceId);
    const device = res.json;
    const operator = device && device.product ? device.product.operator : null
    return { device, operator }
}




const DeviceEdit = (props) => {
    const [image, setImage] = useState(null);
    const [deviceOperator, setDeviceOperator] = useState(null);
    const [device, setDevice] = useState(null);
    const locale = useLocale();
    const { classes, translate } = props

    useEffect(() => {
        getDeviceWithOperator(props.match.params.id)
            .then(({ device, operator }) => {
                setDeviceOperator(operator)
                setDevice(device)

            })

    }, []);


    const loadImage = (url) => {
        getImage(url).then(res => {
            if (res.headers.status >= 200 && res.headers.status <= 200) setImage(atob(res))
        })
    }


    const renderXPress = (classes) => {
        let inputs = [];

        inputs.push(<NumberInput variant="standard" label="resources.devices.config.geolocation_period" source="configuration.geolocation_period" step={1} className={classes.geolocation_period} validate={[required(), number(), minValue(0), maxValue(4095)]} />)
        inputs.push(<BooleanInput variant="standard" label="resources.devices.config.wifi_sniffing" source="configuration.wifi_sniffing" validate={[required()]} />)

        if (isSuperAdmin()) {
            inputs.push(<BooleanInput variant="standard" label="resources.devices.config.bluetooth_advertising" source="configuration.bluetooth_advertising" className={classes.bluetooth_advertising} validate={[required()]} />)
            inputs.push(<NumberInput variant="standard" label="resources.devices.config.bluetooth_advertising_frequency" source="configuration.bluetooth_advertising_frequency" step={1} className={classes.bluetooth_advertising_frequency} validate={[required(), number(), minValue(0.1), maxValue(25.5)]} />)
        }

        return inputs;
    }

    const renderHighGConfiguration = (classes) => {
        let inputs = [];

        inputs.push(<BooleanInput variant="standard" label="resources.devices.config.high_g_enabled" source="configuration.high_g_enabled" validate={[required()]} />)


        return inputs;
    }

    const renderXTrak = (classes) => {
        let inputs = [];
        inputs.push(<NumberInput variant="standard" label="resources.devices.config.geolocation_period" source="configuration.geolocation_period" step={1} className={classes.geolocation_period} validate={[required(), number(), minValue(5), maxValue(60)]} />)

        if (isSuperAdmin()) {
            inputs.push(<BooleanInput variant="standard" label="resources.devices.config.send_message_in_motion" source="configuration.send_message_in_motion" className={classes.send_message_in_motion} validate={[required()]} />)
        }
        return inputs;
    }

    const renderXTrakS = (classes) => {
        return [
            <NumberInput variant="standard" label="resources.devices.config.temperature_low_limit" source="configuration.temperature_low_limit" step={1} className={classes.temperature_low_limit} validate={[required(), number(), minValue(-40), maxValue(85)]} />,
            <NumberInput variant="standard" label="resources.devices.config.temperature_up_limit" source="configuration.temperature_up_limit" step={1} className={classes.temperature_up_limit} validate={[required(), number(), minValue(-40), maxValue(85)]} />,
            <NumberInput variant="standard" label="resources.devices.config.humidity_low_limit" source="configuration.humidity_low_limit" step={1} className={classes.humidity_low_limit} validate={[required(), number(), minValue(0), maxValue(100)]} />,
            <NumberInput variant="standard" label="resources.devices.config.humidity_up_limit" source="configuration.humidity_up_limit" step={1} className={classes.humidity_up_limit} validate={[required(), number(), minValue(0), maxValue(100)]} />,
        ]
    }


    const renderConfigurationPlans = (classes, translate, rest, formData) => {

        return (
            <div className={classes.yabby_configuration_profil}>
                <ReferenceInput step={1} label="Profil" source="configuration.subscription_plan_id" reference="plans" filter={{ deviceId: props.match.params.id, operator: deviceOperator, organizationId: device ? device.organization.organization_id : null, language: locale.toUpperCase() }} sort={{ field: null, order: null }} {...rest} >
                    <ConfigProfilCard
                        record={rest && rest.record ? rest.record : null}
                        highGEnabled={formData && formData.configuration && formData.configuration.high_g_enabled !== null ? formData.configuration.high_g_enabled : null}
                    />
                </ReferenceInput>
            </div >
        )
    }




    const renderTiLocker = (classes, translate, rest) => {
        return <Box className={classes.tilockerBox}>
            <Box className={classes.tilockerPatchBox} >
                <Typography variant="subtitle1" mt={5} >{translate("resources.devices.config.tilocker.unlock_requested")}<BooleanInput variant="standard" label="" source="configuration.unlock_requested" validate={[required()]} /></Typography>
                <NumberInput variant="standard" label="resources.devices.config.tilocker.geolocation_upload_period" source="configuration.geolocation_upload_period" validate={[required(), number(), minValue(0), maxValue(100000)]} />
                <TextInput variant="standard" label="resources.devices.config.tilocker.biked_number" source="configuration.biked_number" validate={[required(), minLength(16, translate("resources.devices.config.tilocker.bike_number_min")), maxLength(16, translate("resources.devices.config.tilocker.bike_number_max"))]} />
                <Typography variant="subtitle1" >{translate("resources.devices.config.tilocker.bike_search_requested")}<BooleanInput variant="standard" label="" source="configuration.bike_search_requested" validate={[required()]} /></Typography>

            </Box>
            <Box className={classes.tilockerInfoBox} >
                <TiLockerConfInfo record={rest && rest.record ? rest.record : null} />
            </Box>


        </Box>

    }


    const renderUnaSense = (classes, translate, rest) => {

        return <Box className={classes.tilockerBox}>

            <Box className={classes.tilockerPatchBox}>
                <SelectInput variant="standard" label="resources.devices.config.unabiz.mode" source="configuration.mode" className={classes.unabiz}
                    choices={[
                        { id: 'timer', name: 'Timer' },
                        { id: 'event', name: 'Event' },
                        { id: 'timer_plus_event', name: 'Event + Timer' },
                        { id: 'event_plus_heartbeat', name: 'Event + Heartbeat' },

                    ]} />

                <SelectInput variant="standard" label="resources.devices.config.unabiz.sensitivity" source="configuration.sensitivity" className={classes.unabiz}
                    choices={[
                        { id: 'low', name: "resources.devices.config.unabiz.sensitivity_choice_low" },
                        { id: 'mid', name: "resources.devices.config.unabiz.sensitivity_choice_mid" },
                        { id: 'high', name: "resources.devices.config.unabiz.sensitivity_choice_high" },

                    ]} />

                <SelectInput variant="standard" label="resources.devices.config.unabiz.upload_period" source="configuration.upload_period" className={classes.unabiz}
                    choices={[
                        { id: 10, name: "resources.devices.config.unabiz.upload_period_choice_10_min" },
                        { id: 30, name: "resources.devices.config.unabiz.upload_period_choice_30_min" },
                        { id: 60, name: "resources.devices.config.unabiz.upload_period_choice_1_h" },
                        { id: 360, name: "resources.devices.config.unabiz.upload_period_choice_6_h" },
                        { id: 720, name: "resources.devices.config.unabiz.upload_period_choice_12_h" },
                        { id: 1440, name: "resources.devices.config.unabiz.upload_period_choice_24_h" },

                    ]}
                />
                <NumberInput variant="standard" label="resources.devices.config.unabiz.temperature_max_threshold" source="configuration.temperature_max_threshold" validate={[required(), number(), minValue(-100), maxValue(100)]} className={classes.unabiz} />
                <NumberInput variant="standard" label="resources.devices.config.unabiz.temperature_min_threshold" source="configuration.temperature_min_threshold" validate={[required(), number(), minValue(-100), maxValue(100)]} className={classes.unabiz} />
                <NumberInput variant="standard" label="resources.devices.config.unabiz.humidity_max_threshold" source="configuration.humidity_max_threshold" validate={[required(), number(), minValue(0), maxValue(100)]} className={classes.unabiz} />
                <NumberInput variant="standard" label="resources.devices.config.unabiz.humidity_min_threshold" source="configuration.humidity_min_threshold" validate={[required(), number(), minValue(0), maxValue(100)]} className={classes.unabiz} />
            </Box>
            <Box className={classes.tilockerInfoBox} >
            </Box>
        </Box>

    }



    const renderEgIot = (classes, translate, rest) => {
        let inputs = [];


        if (rest &&
            rest.record &&
            rest.record.configuration &&
            rest.record.configuration.battery_init_request !== null &&
            rest.record.configuration.battery_init_request !== undefined &&
            rest.record.configuration.battery_init_request === true) {

            inputs.push(
                <Typography variant="subtitle1" mt={3} ml={3}>{translate("resources.devices.config.egiot.init_battery")}<BooleanInput disabled variant="standard" label="" source="configuration.battery_init_request" validate={[required()]} /></Typography>,
            );

        } else {
            inputs.push(
                <Typography variant="subtitle1" mt={3} ml={3}>{translate("resources.devices.config.egiot.init_battery")}<BooleanInput variant="standard" label="" source="configuration.battery_init_request" validate={[required()]} /></Typography>,
            );

        }

        return inputs;
    }

    const renderCo2Sensor = (classes, translate) => {
        return [
            <Typography variant="h6" mt={2}>{translate("resources.devices.config.co2.frequencies")}</Typography>,
            <Typography variant="subtitle1" mt={2} ml={2}>{translate("resources.devices.config.co2.plateform")}<Typography variant="caption" mt={1} ml={2} >{translate("resources.devices.config.co2.plateformCaption")} </Typography ></Typography>,
            <NumberInput variant="standard" margin="normal" label="resources.devices.config.co2.plateformFrequency" source="configuration.send_interval" step={1} className={classes.co2_send_in_interval} validate={[required(), number(), minValue(10), maxValue(1440)]} />,
            <Typography variant="subtitle1" mt={2} ml={2} >{translate("resources.devices.config.co2.sensor")}<Typography variant="caption" mt={1} ml={2} > {translate("resources.devices.config.co2.sensorCaption")}</Typography ></Typography>,
            <NumberInput variant="standard" margin="normal" label="resources.devices.config.co2.extra_measurement_count" source="configuration.extra_measurement_count" step={1} className={classes.co2_extrameasurementcount} validate={[required(), number(), minValue(1), co2SensorFreqValidate]} />,
            <Typography variant="h6" mt={2} > {translate("resources.devices.config.co2.threshold")}<Typography variant="caption" mt={1} ml={2} >{translate("resources.devices.config.co2.thresholdCaption")} </Typography ></Typography>,
            <NumberInput variant="standard" margin="normal" label="resources.devices.config.co2.threshold" source="configuration.co2_alert_threshold" step={1} className={classes.co2_alert_treshold} validate={[required(), number(), minValue(0), maxValue(9999)]} />,
            <Typography variant="h6" mt={2} > {translate("resources.devices.config.co2.offset")}<Typography variant="caption" mt={1} ml={2} >{translate("resources.devices.config.co2.offsetCaption")} </Typography ></Typography>,
            <NumberInput variant="standard" margin="normal" label="resources.devices.config.co2.offset" source="configuration.co2_offset" step={1} className={classes.co2_offset} validate={[required(), number(), minValue(0), maxValue(9999)]} />,
        ]
    }

    const renderSubscriptionInfo = (classes) => {
        return [
            <TextInput variant="standard" margin="normal" label="resources.devices.fields.last_subscription.status" source="last_subscription.status" disabled={true} format={v => v.toUpperCase()} className={classes.margin} />,
            <DateTimeInput variant="standard" margin="normal" label="resources.devices.fields.last_subscription.start_date" source="last_subscription.start_date" disabled={true} options={{ format: 'dd/MM/YYYY HH:mm' }} className={classes.margin} />,
            <DateTimeInput variant="standard" margin="normal" label="resources.devices.fields.last_subscription.end_date" source="last_subscription.end_date" disabled={true} options={{ format: 'dd/MM/YYYY HH:mm' }} className={classes.margin} />,
        ]
    }

    const renderSubscriptionForm = (classes, translate, deviceId, operator) => {

        return [
            <ReferenceInput label={translate('resources.plans.name', { smart_count: 1 })} source="plan_id" reference="plans" filter={{ deviceId: deviceId, operator: operator }} className={classes.margin}>
                <SelectInput optionText="title" />
            </ReferenceInput>,
            <DateTimeInput source="start_date" options={{ format: 'dd/MM/YYYY HH:mm' }} className={classes.margin} />,
            <DateTimeInput source="end_date" options={{ format: 'dd/MM/YYYY HH:mm' }} className={classes.margin} />,
        ]
    }

    const renderGroup = (formData, classes, translate) => {
        const optionRenderer = choice => choice ? choice.serial_number ? `${choice.name} #${choice.serial_number}` : `${choice.first_name} #${choice.mac_address}` : ''
        const group = [
            <ReferenceArrayInput source="group.device_ids" label={'resources.title.devices.list'} reference="devices" filter={{ includes: "organization_id, thumbnail_url, product, last_subscription,configuration, owner_id, group" }} className={classes.margin} disabled={!hasWriteAccess()}>
                <AutocompleteArrayInput optionText={optionRenderer} matchSuggestion={() => true} />
            </ReferenceArrayInput>]

        if (formData.group && formData.group.device_group_id && hasWriteAccess()) {
            group.push(<DeleteButton record={{ ...formData.group, id: formData.group.device_group_id }} resource="device_groups" label="resources.devices.delete_group" redirect="/devices" className={classes.marginTop} />)
        }

        return group
    }


    return (
        <EditController {...props}>
            {controllerProps => <EditView
                {...controllerProps}
                {...props}
                title={<DeviceEditTitle />}
                actions={<DeviceEditActions />}>
                <TabbedForm toolbar={<DeviceEditToolbar />}>
                    <FormTab label="resources.devices.general">
                        <TextInput source="name" disabled={!hasWriteAccess()} options={{ fullWidth: true }} variant="standard" />
                        {isAdminPermissions() && (
                            <ReferenceInput
                                variant="standard"
                                label={translate('resources.organization.organization')}
                                source="organization_id"
                                reference="organizations"
                                disabled={!isAdminPermissions()}
                                validate={validateRequired}
                            >
                                <AutocompleteInput optionText="name" matchSuggestion={() => true} />
                            </ReferenceInput>
                        )}
                        {isAdminPermissions() && (
                            <ReferenceInput
                                variant="standard"
                                label={'resources.devices.owner'}
                                source="owner_id"
                                reference="users"
                                allowEmpty
                                disabled={!isAdminPermissions()}
                            >
                                <AutocompleteInput optionText={ownerOptionRenderer} matchSuggestion={() => true} />
                            </ReferenceInput>
                        )}
                        <FunctionField
                            label="Image (Type : jpeg,png ; Max : 150 kB)"
                            source="thumbnail_url"
                            render={device => {
                                return (
                                    <Avatar
                                        width={300}
                                        height={300}
                                        className={classes.image}
                                        label={translate('resources.upload')}
                                        onClose={() => onClose(device.id)}
                                        onFileLoad={image => onFileLoad(device.id, image)}
                                        src={device.thumbnail_url}
                                    />
                                )
                            }}
                        />
                    </FormTab>
                    {controllerProps.record && controllerProps.record && <FormTab variant="standard" label="resources.devices.device" >
                        <FormDataConsumer>

                            {({ formData, ...rest }) => isXPressOrXTrakR(formData) && renderXPress(classes)
                            }
                        </FormDataConsumer>
                        <FormDataConsumer>

                            {({ formData, ...rest }) => isCo2Sensor(formData) && renderCo2Sensor(classes, translate)
                            }
                        </FormDataConsumer>
                        <FormDataConsumer>
                            {({ formData, ...rest }) => (isOyster(formData) || isYabby(formData)) && renderHighGConfiguration(classes, translate, rest)
                            }
                        </FormDataConsumer>
                        <FormDataConsumer>
                            {({ formData, ...rest }) => isDeviceWithConfigurationPlan(formData) && renderConfigurationPlans(classes, translate, rest, formData)
                            }
                        </FormDataConsumer>

                        <FormDataConsumer>
                            {({ formData, ...rest }) => isTiLocker(formData) && renderTiLocker(classes, translate, rest)
                            }
                        </FormDataConsumer>

                        <FormDataConsumer>
                            {({ formData, ...rest }) => isUnaSense(formData) && renderUnaSense(classes, translate, rest)
                            }
                        </FormDataConsumer>

                        <FormDataConsumer>
                            {({ formData, ...rest }) => isEgIot(formData) && renderEgIot(classes, translate, rest)
                            }
                        </FormDataConsumer>
                        <FormDataConsumer>
                            {({ formData, ...rest }) => isXTrak(formData) && renderXTrak(classes)
                            }
                        </FormDataConsumer>
                        <FormDataConsumer>
                            {({ formData, ...rest }) => isXTrakS(formData) && renderXTrakS(classes)
                            }
                        </FormDataConsumer>
                    </FormTab>}
                    {controllerProps.record && <FormTab label="resources.devices.group">
                        <FormDataConsumer>
                            {({ formData, ...rest }) => renderGroup(formData, classes, translate)}
                        </FormDataConsumer>
                    </FormTab>}
                    {controllerProps.record && isSuperAdmin() && <FormTab label="resources.devices.subscription">
                        <FormDataConsumer>
                            {({ formData, ...rest }) => !formData.last_subscription ? renderSubscriptionForm(classes, translate, props.match.params.id, deviceOperator) : renderSubscriptionInfo(classes)
                            }
                        </FormDataConsumer>
                    </FormTab>}
                </TabbedForm>
            </EditView>}
        </EditController>
    )


}



export default compose(
    translate,
    withStyles(styles),
    connect(
        null,
        { showNotification }
    )
)(DeviceEdit)

