import { ROLE_ORGA_ADMIN, ROLE_ORGA_USER, ROLE_SUPER_ADMIN, ROLE_USER } from '../pages/configuration/actions'
import moment from 'moment'

Date.daysBetween = function (date1, date2) {
    //Get 1 day in milliseconds
    var one_day = 1000 * 60 * 60 * 24
    // Convert both dates to milliseconds
    var date1_ms = date1.getTime()
    var date2_ms = date2.getTime()

    // Calculate the difference in milliseconds
    var difference_ms = date2_ms - date1_ms

    // Convert back to days and return
    return Math.round(difference_ms / one_day)
}
/**
 * devices
 */

const deviceTypes = [
    'unb',
    'ibeacon',
];

function capitalize(string) {
    return string.toUpperCase();
}

export default deviceTypes.map(deviceType => ({
    id: deviceType,
    name: capitalize(deviceType),
}));

export const hasRollover = device => {
    let res = false

    if (device && device.last_rollover && device.last_rollover.status && device.last_rollover.timestamp) {
        res = true
    }

    return res
}

export const hasRolloverDetected = device => {
    let res = false

    if (device && device.last_rollover && device.last_rollover.status === 'detected') {
        res = true
    }

    return res
}

export const hasGroupDevices = device => {
    let res = false

    if (device && device.group_devices && device.group_devices.length > 0) {
        res = true
    }
    return res
}

export const hasMotionDetected = device => {
    let res = false

    if (device && device.last_motion && device.last_motion.status === 'moving') {
        res = true
    }

    return res
}

export const hasActiveSubscription = device => {
    let res = false

    if (device && device.type) {
        switch (device.type) {
            case 'ibeacon':
                res = true
                break
            case 'unb':
                res =
                    device.last_subscription &&
                    device.last_subscription.end_date &&
                    new Date() < new Date(device.last_subscription.end_date)
                break
            default:
                return null
        }
    }

    return res
}

export const canCancelSubscription = subscriptions => {
    if (!subscriptions || subscriptions.length === 0)
        return false

    const nbOfPostPaidSubs = subscriptions.filter(sub => sub.plan && (sub.status === 'active' || sub.status === 'unpaid') && sub.plan.type === 'postpaid' && new Date() < new Date(sub.end_date)).length
    if (nbOfPostPaidSubs > 0)
        return true

    return false
}

export const getPostPaidSubscription = subscriptions => {
    if (!subscriptions || subscriptions.length === 0)
        return null

    const postPaidSubs = subscriptions.filter(sub => sub.plan && sub.plan.type === 'postpaid' && sub.status !== 'canceled')
    if (postPaidSubs.length > 0)
        return postPaidSubs[0]

    return null
}

export const getRemainingDaysBeforeSubscriptionEnds = device => {
    let res = -1

    if (device && device.last_subscription && device.last_subscription.end_date) {
        res = Date.daysBetween(new Date(), new Date(device.last_subscription.end_date))
    }

    return res
}

export const getNumberOfDaysSinceLastSeen = device => {
    let res = -1

    if (device && device.last_seen) {
        const last_seen = new Date(device.last_seen)
        const now = new Date()
        res = Date.daysBetween(last_seen, now)
    }

    return res
}

/**
 * plans
 */
export const getFormattedPrice = plan => {
    let res = (plan.amount * (1 + (plan.tax_percent / 100)) / 100).toFixed(2)
    //let res = (plan.amount / 100).toFixed(2)
    res = res + ' ' + plan.currency.toUpperCase()

    return res
}

export const getAmount = (amount, taxPercent) => {
    //return (amount / 100).toFixed(2)
    return (amount * (1 + (taxPercent / 100)) / 100).toFixed(2)
}

export const getCurrencySymbol = currency => {
    return currency === 'eur' ? '€' : currency === 'aud' ? 'AU$' : '$'
}

export const PER_PAGE_MAP = 500
export const PER_PAGE = 25
export const PER_PAGE_INVOICE_LIST = 100
export const PER_PAGE_MAP_HISTORY = 25
export const PER_PAGE_HISTORY = 25
export const PER_PAGE_SENSOR = 100 // temperature + humidity + cO2 + battery : 25 * 4
export const CONTAINER_TOP = 170
export const LIST_HEADER = 56
export const TBODY_Y = CONTAINER_TOP + LIST_HEADER
const PAGINATION_HEIGHT = 38
const TAB_PANEL = 116
export const containerHeight = () => window.innerHeight - CONTAINER_TOP
export const containerGridHeight = () => containerHeight() - PAGINATION_HEIGHT
export const containerListHeight = () => containerGridHeight() - LIST_HEADER
export const containerHeightForm = () => containerHeight() - TFORM_HEADER - 8
export const containerTabbedHeight = () => containerHeightForm() - TAB_PANEL
export const containerTabbedTabbedHeight = () => containerTabbedHeight() - 20
export const containerWizardHeight = () => containerHeightForm()
export const TFORM_HEADER = 0

export const deviceHistoryHeight = () => window.innerHeight - 195

let gPermissions = null

export const isAdminPermissions = (mPermissions = null) => {
    if (mPermissions) {
        gPermissions = mPermissions
    }
    return gPermissions === ROLE_SUPER_ADMIN || gPermissions === ROLE_ORGA_ADMIN
}

export const isRootOrganizationUser = (mPermissions = null) => {
    if (mPermissions) {
        gPermissions = mPermissions
    }

    return gPermissions === ROLE_USER && localStorage.getItem('organizationId') === 'F9EB39CC-0899-41A0-9A55-2F9820F0DE44'
}

export const isOrgaUser = (mPermissions = null) => {
    if (mPermissions) {
        gPermissions = mPermissions
    }

    return gPermissions === ROLE_ORGA_USER
}

export const isUser = (mPermissions = null) => {
    if (mPermissions) {
        gPermissions = mPermissions
    }

    return gPermissions === ROLE_USER
}

export const isSuperAdmin = (mPermissions = null) => {
    if (mPermissions) {
        gPermissions = mPermissions
    }
    return gPermissions === ROLE_SUPER_ADMIN
}

export const hasWriteAccess = (mPermissions = null) => {
    if (mPermissions) {
        gPermissions = mPermissions
    }

    return isAdminPermissions() || isUser()
}

export const hasDeviceConfiguration = (device) => {
    let res = false;

    if (device && device.configuration) {
        res = true
    }
    return res
}

export const isXPressOrXTrakR = (device) => {
    let res = false;

    if (device && device.product && (device.product.name.toLowerCase() === 'xpress' || device.product.name.toLowerCase() === 'xtrakr')) {
        res = true
    }
    return res
}

export const isXTrak = (device) => {
    let res = false;

    if (device && device.product && device.product.name.toLowerCase() === 'xtrak') {
        res = true
    }
    return res
}
export const isCo2Sensor = (device) => {
    let res = false;
    if (device && device.product && device.product.name.toLowerCase() === 'tico2plus') {
        res = true
    }
    return res
}


export const isEgIot = (device) => {
    let res = false;
    if (device && device.product && (device.product.name.toLowerCase() === 'eg-iot - sim lte-m'
        || device.product.name.toLowerCase() === 'eg-iot - sigfox'
        || device.product.name.toLowerCase() === 'eg-iot - sim 3g'
        || device.product.name.toLowerCase() === 'eg-iot - sim 1aa6')) {
        res = true
    }
    return res
}

export const isYabby = (device) => {
    let res = false;
    if (device && device.product && (device.product.name.toLowerCase() === 'yabbygps'
        || device.product.name.toLowerCase() === 'yabbyedge'
        || device.product.name.toLowerCase() === 'yabby3-4g')) {
        res = true
    }
    return res
}

export const isOyster = (device) => {
    let res = false;
    if (device && device.product && (device.product.name.toLowerCase() === 'oyster3-4g')) {
        res = true
    }
    return res
}

export const isDgmatter = (device) => {
    let res = false;
    if (isYabby(device) ||
        isOyster(device)
    ) {
        res = true
    }
    return res
}
export const isQueclink = (device) => {
    let res = false;
    if (device && device.product && (device.product.name.toLowerCase() === "queclink-gl521mg")) {
        res = true
    }
    return res
}

export const isDeviceWithConfigurationPlan = (device) => {
    let res = false;
    if (isYabby(device) ||
        isQueclink(device) ||
        isOyster(device)
    ) {
        res = true
    }
    return res
}


export const isTiLocker = (device) => {
    let res = false;
    if (device && device.product && device.product.name.toLowerCase() === 'tilocker') {
        res = true
    }
    return res
}


export const isUnaProtect = (device) => {
    let res = false;
    if (device && device.product && device.product.name.toLowerCase() === 'unaprotect') {
        res = true
    }
    return res
}

export const isUnaSense = (device) => {
    let res = false;
    if (device && device.product && device.product.name.toLowerCase() === 'unasense') {
        res = true
    }
    return res
}

export const isXTrakS = (device) => {
    let res = false;

    if (device && device.product && device.product.name.toLowerCase() === 'xtraks') {
        res = true
    }
    return res
}

export const isTiFizHP = (device) => {
    let res = false;

    if (device && device.product && device.product.name.toLowerCase() === 'tifizhp') {
        res = true
    }
    return res
}

const getMinutesBetweenNowAndTimestamp = (date) => {
    const now = moment(new Date())
    const end = moment(date)
    const duration = moment.duration(now.diff(end))
    return duration.asMinutes();
}

export const getTiFizHPActivityStatus = (device) => {
    let res = 'still';

    if (device && device.last_location && device.last_location.timestamp && getMinutesBetweenNowAndTimestamp(device.last_location.timestamp) < 30) {
        res = 'moving'
    }
    return res
}
