import { nominalTypeHack } from 'prop-types'
import defaultMessages from 'ra-language-french'

export default {
    ...defaultMessages,
    resources: {
        about: 'Au sujet de',
        month: 'mois',
        year: 'an',
        for: 'pour',
        per: 'par',
        common: {
            errorOccured: 'Une erreur s\'est produite',
            account: 'Compte',
            search: 'Rechercher',
            cancel: 'Annuler',
            clear: 'Effacer',
            ok: 'Ok',
            history: 'Historique',
            help: 'Aide',
            documentation: 'Documentation',
            developers: 'Développeurs',
            delete_history: 'Suppression historique balise',
            delete_history_paragraph: 'Etes-vous sûr de vouloir supprimer l\'historique de la balise ? Toutes les positions, mesures, etc seront supprimées définitivement. Cette action est permanente et irréversible.',
            update_success: "Mise à jour réussie"
        },
        configuration: {
            name: 'Configuration',
            general: 'Général',
        },
        devices: {
            add_bulk_device: 'Ajouter des balises',
            add_new_device: 'Ajouter une balise',
            add_new_device_succes: 'Balise Ajoutée',
            technology: 'Technologie',
            activate_new_device: 'Activer une balise',
            activate: 'Activation',
            advsearch: 'Recherche avancée',
            go_tracking_mode: "Passer en mode LIVE",
            last_tracking_mode_entry: "Dernière entrée en mode LIVE",
            success_setting_on_tracking_mode: "Requête de mise en mode LIVE envoyée avec succès",
            error_setting_on_tracking_mode: "Une erreur s'est produite lors de l'envoi de la requête de mise en mode LIVE.",
            already_on_tracking_mode: "La balise est déjà en mode LIVE",
            tracking_mode_pending: "La demande de mise en mode LIVE est déjà envoyée.",
            setting_on_tracking_mode: "Envoi de la requête en cours",
            setting_on_tracking_mode_timeout: "Timeout error",
            already_attached: 'Equipement déja attaché',
            attaching: "La balise est en cours d'activation...",
            attached: 'La balise a été activée',
            error_attachement_checkout: "Une erreur s'est produite lors de l'attachement de votre balise !  Vous allez être redirigé vers la page de contact du support... ",
            available: 'Equipement disponible',
            battery_status: {
                bad: 'Mauvais',
                good: 'Bon',
            },
            product: 'Produit',
            configuration: 'Configurer la balise',
            create: 'Ajouter une nouvelle balise',
            delete: 'Détacher',
            device_id: 'Identifiant balise',
            device_name: 'Nom balise',
            enter_device_id: "Entrez l'identifiant de votre balise",
            enter_device_id_instruction: `Merci de renseigner l'identifiant inscrit au dos de votre balise.`,
            enter_voucher: 'Saisir le code inscrit sur votre carte prépayée',
            error_attachement: 'Erreur attachement device: ',
            fastsearch: 'Recherche rapide',
            edit_title: 'Configuration %{deviceName}',
            fields: {
                name: 'Nom',
                mac_address: '#ID',
                battery: 'Batterie',
                software_version: 'Firmware',
                secondary_mac_address: 'Adresse mac secondaire',
                minor: 'Mineur',
                major: 'Majeur',
                proximity_uuid: 'Proximity UUID',
                last_subscription: {
                    status: 'Statut',
                    start_date: 'Date de début',
                    end_date: 'Date de fin',
                },
                thumbnail_url: 'Image',
                last_seen: 'Dernière transmission',
                owner: 'Propriétaire',
                organization: 'Organisation',
            },
            config: {
                geolocation_period: 'Fréquence de géolocalisation (minutes)',
                retry_period: 'Retransmission de la position (minutes)',
                wifi_sniffing: 'Scanner les réseaux WIFI',
                bluetooth_advertising: 'Emission bluetooth',
                bluetooth_advertising_frequency: 'Fréquence d\'émission bluetooth (secondes)',
                temperature_low_limit: 'Seuil température basse',
                temperature_up_limit: 'Seuil température haute',
                humidity_low_limit: 'Seuil humidité basse',
                humidity_up_limit: 'Seuil humidité haute',
                send_message_in_motion: 'Envoi des messages en mouvement',
                high_g_enabled: "Force G élevée (High G)",
                co2: {
                    frequencies: "Fréquences :",
                    plateform: "Plateforme :",
                    plateformFrequency: "fréquence plateforme (min)",
                    plateformCaption: "Fréquence de remonter de mesure sur la plateforme (Défaut = 30 min)",
                    sensor: "Capteur :",
                    extra_measurement_count: "Nombre de mesures supplémentaires",
                    sensorCaption: "Nombre de mesures dans le capteur entre deux remontées (Défaut = 2)",
                    threshold: "Seuil d'alerte :",
                    thresholdCaption: "(Défaut = 800 ppm)",
                    offset: "Étalonnage",
                    offsetCaption: "Il correspond au taux de concentration minimum enregistré (Défaut = 400 ppm)"

                },
                plans: {
                    downgrading_sub_alert: "VOUS CHANGEZ VOTRE PLAN D'ABONNEMENT",
                    upgrading_sub_alert: "VOUS CHANGEZ VOTRE PLAN D'ABONNEMENT",
                    switching_sub_alert: "En choisissant cette configuration, votre abonnement mensuel passera de",
                    switching_sub_alert_to: "En choisissant cette configuration, votre abonnement mensuel passera à",
                    notice: "Notice",
                    to: "à",
                    return: "Retourner",
                    continue: "Continuer",
                    select_plan: "Sélectionnez un plan d'abonnement",
                    select_plan_as_admin: "Sélectionnez une configuration en tant que Super Admin",
                    current_conf: "Configuration en cours",
                    current_subs: "Abonnement en cours",
                    diff_conf_subs: "ALERTE : La configuration de la balise n'est pas à jour avec l'abonnement",
                    once_every: "Une position tous les",
                    geolocation_period: "période de géolocalisation en mouvement",
                    keep_alive: "période de géolocalisation en arrêt",
                    subscription_cost: "coût de l'abonnement",
                    price: "Prix",
                    minutes: "minutes",
                    hours: "heures",
                    per_mounth: " / mois",
                    since: "depuis le"
                },
                egiot: {
                    init_battery: "Remplacement des piles : "
                },
                tilocker: {
                    unlock_requested: "Déverrouiller ",
                    unlock_request_date_time: "Dernière demande de déverrouillage",
                    geolocation_upload_period: "Période de géolocalisation (en secondes)",
                    geolocation_upload_period_request_date_time: "Dernière mise à jour de la période de géolocalisation",
                    biked_number: "Code de déverrouillage",
                    bike_number_max: "Le code doit être composé de 16 digits en hexadecimal",
                    bike_number_min: "Le code doit être composé de 16 digits en hexadecimal",
                    bike_search_requested: " Sonnerie ",
                    bike_search_request_date_time: "Dernière demande de sonnerie",
                    history: "Historique"

                },
                unabiz: {
                    mode: "Mode",
                    sensitivity: "Sensitivité",
                    sensitivity_choice_low: "Faible",
                    sensitivity_choice_mid: "Moyenne",
                    sensitivity_choice_high: "Élevée",
                    upload_period: "Fréquence des mesures (10 min - 24h)",
                    upload_period_choice_10_min: "5 minutes (remontée toutes les 10 minutes)",
                    upload_period_choice_30_min: "15 minutes (remontée toutes les 30 minutes)",
                    upload_period_choice_1_h: "30 minutes (remontée toutes les 1 heure)",
                    upload_period_choice_6_h: "3 heures (remontée toutes les 6 heures)",
                    upload_period_choice_12_h: "6 heures (remontée toutes les 12 heures)",
                    upload_period_choice_24_h: "12 heures (remontée toutes les 24 heures)",
                    temperature_max_threshold: "Seuil de température supérieur (°C)",
                    temperature_min_threshold: "Seuil de température inférieur (°C)",
                    humidity_max_threshold: "Seuil d'humidité supérieur (%)",
                    humidity_min_threshold: "Seuil d'humidité inférieur (%)",
                }
            },
            device: 'Balise',
            general: 'Général',
            group: 'Groupe',
            group_name: 'Nom du groupe',
            delete_group: 'Supprimer le groupe',
            subscription: 'Abonnement',
            owner: 'Propriétaire',
            from: 'du',
            geofence: 'Barrière virtuelle',
            browse_location: 'Consulter la position',
            browse_subscription: 'Consulter l\'abonnement',
            geolocation: 'Devices Location',
            identifier: 'Identifiant',
            labels: {
                acknowledgeAlert: "Acquitter l'alerte",
                acknowledgeMotion: 'Acquitter le mouvement',
                acknowledgeRollover: 'Acquitter le renversement',
                checkBattery: 'Vérifier la batterie',
                configuration: 'Configuration',
                subscription: 'Abonnement',
                coordinates: 'Coordonnées',
                last_transmission: 'Dernière transmission',
                latitude: 'Latitude',
                location: 'Position: ',
                location_nbr: 'Position ',
                location_geo: 'Adresse',
                locationsHistory: 'Historique des positions',
                longitude: 'Longitude',
                accuracy: "Précision",
                altitude: "Altitude",
                measures: "Mesures",
                speed: "Vitesse",
                changeIcon: 'Changer l\'icône',
                tracking_mode: "Mode LIVE"
            },
            location: 'position |||| positions',
            name: 'Balise |||| Balises',
            no_device_subscription: `La balise ne possède pas d'abonnement`,
            not_found: 'Identifiant balise inconnu',
            notifications: 'Evènements',
            properties: 'Propriétés',
            review_info: `Revue de vos informations`,
            saisir_code: 'Saisir le code inscrit sur votre carte prépayée',
            select_offer: 'Sélectionnez une offre ci-dessous',
            status: {
                unknow: 'Inconnu',
            },
            thks_number: `Merci de renseigner l'identifiant inscrit au dos de votre balise.`,
            tifiz_number: 'Identifiant balise',
            to: 'au',
            your_device: 'Votre balise',
            no_location: 'Aucune position',
            no_subscription: 'Aucun abonnement',
            timestamp: 'Date/Heure',
            rollover: {
                state: 'Etat',
                name: 'Renversement',
                detected: 'Renversé',
                clear: 'Pas renversé'
            },
            activity: {
                still: 'Immobile',
                moving: 'En mouvement'
            }
        },
        export: {
            create: 'Export des données',
            action: 'Lancer l\'export',
            maxDurationWarning: "La durée d'export des données doit être inférieur à 7 jours",
            fields: {
                data: 'Type de données'
            },
            export_starting: 'Une archive est en cours de génération. Vous allez recevoir un lien de téléchargement par email.'
        },
        exportPdf: {
            download: 'Télécharger',
            downloading: 'Téléchargement en cours...',
            creationInProgress: 'Création en cours...',
            errors: {
                noLocations: 'Aucune position à télécharger !',
                noMeasures: 'Aucune mesure à télécharger !'
            },
            locationsHistoryDocument: {
                title: 'RAPPORT DES POSITIONS',
                date: 'Date : ',
                beacon: 'Balise',
                period: 'Période',
                id: "#ID",
                name: "Nom",
                from: 'De',
                to: 'À',
                table: {
                    title: "Table de l'historique",
                    location: 'Position',
                    dateTime: 'Date et heure',
                    adress: 'Adresse',
                    latitude: 'Latitude',
                    longitude: 'Longitude',
                }
            },
            measuresHistoryDocument: {
                title: 'RAPPORT DES MESURES',
                date: 'Date : ',
                beacon: 'Balise',
                period: 'Période',
                id: "#ID",
                name: "Nom",
                from: 'De',
                to: 'À',
                table: {
                    title: "Table de l'historique",
                    measure: 'Mesure',
                    dateTime: 'Date et heure',
                    type: 'Type',
                    value: 'Valeur',
                }
            }

        },
        fops: {
            fields: {
                default_card: 'Défault',
            },
            labels: {
                expiry_date: "Date d'expiration",
                number: 'Numéro',
                type: 'Type',
            },
            name: 'Moyen de paiement |||| Moyens de paiement',
            card_number: 'N° de carte',
            card_expiry: "Date d'expiration",
            card_cvc: 'CVC',
            create: 'Ajout d\'un moyen de paiement',
        },
        fops_sepa: {
            fields: {
                default_card: 'Défault',
                accountholder_name: "Nom : ",
                email: "Email : ",
                note: `En fournissant vos informations de paiement et en confirmant ce paiement, vous autorisez (A) ${process.env.REACT_APP_ROOT_ORGANIZATION} et Stripe, notre prestataire de services de paiement et/ou PPRO, son prestataire de services local, à donner à votre banque l’instruction de débiter votre compte, et (B) votre banque à débiter votre compte conformément à ces instructions. Dans le cadre de vos droits, vous êtes en droit d’obtenir un remboursement de la part de votre banque, conformément aux conditions de votre contrat avec cette dernière. Toute demande de remboursement doit être présentée dans les 8 semaines suivant la date de débit de votre compte. Vos droits sont précisés dans une déclaration que vous pouvez obtenir auprès de votre banque. Vous acceptez de recevoir des notifications concernant les débits futurs jusqu’à 2 jours avant qu’ils n’aient lieu.`
            },
            labels: {
                iban: "IBAN",
                bank_code: 'Code bancaire',
                type: 'Type',
            },
            name: 'Prélèvement bancaire SEPA',
            create: 'Ajout d\'un moyen de paiement (prélèvement Sepa)',
            found_nothing: "Aucun prélèvement bancaire SEPA n'est enregistré"
        },
        fops_card: {
            fields: {
                default_card: 'Défault',
            },
            labels: {
                iban: "IBAN",
                bank_code: 'Code bancaire',
                type: 'Type',
            },
            name: 'Carte bancaire',
            card_number: 'N° de carte',
            card_expiry: "Date d'expiration",
            card_cvc: 'CVC',
            create: 'Ajout d\'un moyen de paiement (carte bancaire)',
            found_nothing: "Aucune carte bancaire n'est enregistrée"
        },
        geofences: {
            name: 'Barrière virtuelle |||| Barrières virtuelles ',
            delete: 'Supprimer la barrière virtuelle',
            create: "Création d'une barrière virtuelle",
            edit: "Edition d'une barrière virtuelle",
            center: 'Centre',
            latitude: 'Latitude',
            longitude: 'Longitude',
            trigger: 'Alerte',
            fields: {
                radius: 'Rayon',
                address: 'Adresse',
                on_entry: 'sur entrée',
                on_exit: 'sur sortie',
                active: 'Actif',
            },
            draw: {
                save_modifications: 'Enregistrer les modifications',
                cancel_modifications: 'Annuler les modifications',
                select_geofence: 'Sélectionnez les carrés pour modifier le geofence',
                click_cancel: 'Cliquez sur annuler pour rétablir les modifications',
            },
            device_search: 'Recherchez une balise',
            enter_address: 'Entrez une adresse',
            no_device_selected: 'Vous devez sélectionnez une balise',
            no_center_defined: "Vous n'avez pas défini le centre du geofence",
        },
        humidity: {
            head: 'Humidité',
            value: 'Valeur',
            date: 'Dernier relevé',
        },
        battery: {
            head: 'Niveau de la batterie / piles',
        },
        pressure: {
            head: 'Pression',
        },
        luminosity: {
            head: 'Luminosité',
        },
        formaldehyde: {
            head: 'Formaldéhyde',
        },
        tvoc: {
            head: 'Tvoc (Composés organiques volatils)',

        },
        noise: {
            head: 'Bruit',
        },
        ammonia: {
            head: 'Amoniac',
        },
        mox: {
            head: 'Mox',
        },
        aqi: {
            head: 'Indice de qualité d\’air',
            help_legend: {
                head_ref_levels: "Niveaux de référence",
                head_air_info: "Informations sur l'air",
                head_tvoc_mg_m3: "TVOC (mg/m3)",
                head_air_quality: "Qualité de l'air",
                level1: "Niveau 1",
                level2: "Niveau 2",
                level3: "Niveau 3",
                level4: "Niveau 4",
                level5: "Niveau 5",
                air_info_level1: "Air pur et hygiénique (valeur cible)",
                air_info_level2: "Bonne qualité de l'air (si aucun seuil n'est dépassé)",
                air_info_level3: "Préoccupations notables en matière de confort (non recommandé pour une exposition > 12 mois)",
                air_info_level4: "Problèmes de confort importants (exposition déconseillée > 1 mois)",
                air_info_level5: "Conditions inacceptables (déconseillé)",
                air_quality_level1: "Très bonne",
                air_quality_level2: "Bonne",
                air_quality_level3: "Moyenne",
                air_quality_level4: "médiocre",
                air_quality_level5: "Mauvaise",

            }

        },
        pm1: {
            head: 'Particules PM1',
        },
        pm2P5: {
            head: 'Particules PM2.5',
        },
        pm10: {
            head: 'Particules PM10',
        },
        benzen: {
            head: 'Benzen',
        },
        co2: {
            head: 'CO2',
            head_min: 'Co2',
            treshold: 'Seuil',
            max: 'Maximum',
            min: 'Minimum',
            avg: 'Moyenne',

        },
        internal_error: 'Erreur interne',
        lang: 'Langage',
        door: {
            head: 'Ouverture',
            last: 'Value',
            date: 'Last check',
            open: 'Ouvert',
            closed: 'Fermé',
        },
        waterLeak: {
            head: 'Fuite eau',
            on: 'Active',
            off: 'Inactive',
        },
        presence: {
            head: 'Presence',
        },
        battery_status: {
            head: 'Status de la batterie',
        },
        button: {
            head: 'Bouton',
        },
        motion: {
            head: 'Mouvement',
        },
        high_g: {
            head: 'High G',
            average: 'Moyenne',
            duration: 'Durée',
            peak: 'Pic',
            gForce: "Force G",
            durationInMs: "Durée (ms)"
        },
        location: {
            head: 'Position'
        },
        locations: {
            name: 'Position |||| Positions',
            labels: {
                addDevice: 'Ajouter une nouvelle balise',
                devicesPositions: 'Positions des balises',
                hideMenu: 'Menu',
                exportData: 'Exporter les données',
                delete: "Supprimer l'historique",
                location: 'Position',
                search: 'Rechercher une balise',
            },
            locationNone: 'Non',
            location25: '25 dernières positions',
            location50: '50 dernières positions',
            location100: '100 dernières positions',
            location200: '200 dernières positions',
            location500: '500 dernières positions',
            locationYesterday: 'Hier',
            locationToday: `Aujourd'hui`,
            locationWeek: 'Semaine dernière',
            locationMonth: 'Mois dernier',
            locationYear: 'Cette année',
        },
        map: {
            road: 'Route',
            satellite: 'Satellite',
            routing: 'Itinéraire',
            routing_mode: {
                driving: 'Voiture',
                cycling: 'Vélo',
                walking: 'Marche',
                boat: 'Bateau',
                no_routing: 'Aucun itinéraire',
            },
            clustring: {
                on: "Regroupement ON",
                off: "Regroupement OFF"
            }
        },
        markerpopup: 'Afficher sur click',
        notifications: {
            button_press: 'Appui bouton',
            button_double_pressed: 'Appui bouton - Double clic',
            button_held: 'Appui bouton - Appui long',
            button_pressed: 'Appui bouton - Simple clic',
            shock_detected: 'Choc détecté',
            motion_detected: 'Mouvement détécté',
            rollover_detected: 'Renversement détecté',
            door_opened: 'Porte ouverte',
            water_leak_detected: 'Détection d\'eau',
            temperature_threshold_reached: 'Seuil de température dépassé',
            co2_threshold_reached: 'Seuil de concentration du Co2 dépassé',
            humidity_threshold_reached: 'Seuil d\'humidité dépassé',
            high_g_thresholds_reached: "Seuils de force G dépassés (High G)",
            high_g_peak_threshold: "Seuil du pic (G)",
            high_g_average_threshold: "Seuil de la moyenne (G)",
            high_g_duration_threshold: "Seuil de la durée (ms)",
            battery_status_bad: 'Statut batterie mauvais',
            battery_low: 'Niveau de batterie faible',
            address_country_changed: 'Changement de pays',
            address_state_changed: 'Changement de région',
            address_city_changed: 'Changement de ville',
            channel: 'Canal',
            create: "Création d'une alerte",
            edit: "Edition d'une alerte",
            delete: "Supprimer l'alerte",
            enter_phone_number: 'Entrez un numéro de téléphone',
            email: 'Email',
            event: 'Evènement',
            fields: {
                device_id: 'Balise',
                channel: 'Canal',
                event: 'Evènement',
                to: 'Destinataires',
            },
            geofence_crossed: 'Franchissement de barrière virtuelle',
            message: 'message',
            name: 'Alerte |||| Alertes',
            option: 'Option',
            update: 'Editer',
        },
        integrations: {
            choose_platform: "Choisissez la plateforme",
            name: 'Intégration |||| Intégrations',
            create: "Création d'une intégration",
            organization: "Organisation",
            jdOrganization: "ID de l'organisation John Deere ",
            email: 'Email',
            status: "Status",
            last_send: "Dernière connexion",
            created_at: "Créée"

        },
        organization:
        {
            description: 'Description',
            name: 'Nom',
            organization: 'Organisation',
            parent: 'Organisation parente',
            root: 'Organisation root',
            update: 'Editer',
        },
        organizations: {
            create: "Création d'une organisation",
            name: 'Organisation |||| Organisations',
            edit: 'Editer une organization'
        },
        invoices: {
            name: 'Facture |||| Factures',
            date: 'Date',
            description: 'Description',
            number: 'Référence',
            price: 'Prix',
            download: 'Télécharger',
            period: "Période",
            price_tax_included: "Prix TTC",
            tax_amount: "TVA"
        },
        product: {
            name: 'Nom du produit',
            release: 'Date de la version',
            versionNumber: 'Numero de version',
            patch: 'Numero du patch',
        },
        profile: 'Profil Utilisateur',
        role: {
            owner: 'Propriétaire',
            member: 'Membre',
            admin: 'Administrateur',
            superadmin: 'Super Administrateur',
        },
        shock: {
            head: 'Choc',
            last: 'Valeur',
            date: 'Date',
            detected: 'Détecté',
            clear: 'Non détécté',
        },
        measures: {
            name: 'Mesure |||| Mesures',
            sensorNone: 'Non',
            sensor25: '25 dernières mesures',
            sensor50: '50 dernières mesures',
            sensor100: '100 dernières mesures',
            sensor200: '200 dernières mesures',
            sensor500: '500 dernières mesures',
            sensorYesterday: 'Hier',
            sensorToday: `Aujourd'hui`,
            sensorWeek: 'Semaine dernière',
            sensorMonth: 'Mois dernier',
            sensorYear: 'Cette année',
            temphum: 'Température/Humidité',
            timestamp: 'Date',
            history: 'Consulter l\'historique des mesures',
            status: 'Etat',
            date: 'Date',
            water_leak_status_off: 'Inactive',
            water_leak_status_on: 'Active',
            door_status_open: 'Ouverte',
            door_status_closed: 'Fermé',
            shock_status_clear: 'Non détecté',
            shock_status_detected: 'Détecté',
            presence_status_detected: 'Détectée',
            presence_status_none: 'Non détéctée',
            battery_status_status_good: 'Bon',
            battery_status_status_bad: 'Mauvais',
            button_status_click: 'Click',
            button_status_double_click: 'Double click',
            button_status_hold: 'Appui long',
            motion_status_still: 'En arrêt',
            motion_status_moving: 'Mouvement détectée',
        },
        plans: {
            name: 'Plan |||| Plans',
        },
        subscriptions: {
            accept_tc: "Merci d'accepter les conditions générales de vente",
            add_card_success: 'Moyen de paiement enregistré',
            add_card_error: 'Merci de vérifier vos informations de paiement',
            add_fop_error: "Une erreur est survenue lors de l'enregistrement de votre carte. Veuillez réessayer",
            description: "Vous serez prélevé tous les mois sur votre carte de crédit",
            charged_now: 'Montant facturé : %{amount} %{currency_symbol}',
            enter_voucher: 'Entrez le numéro inscrit sur votre carte',
            fields: {
                active: 'Active',
                device: {
                    name: 'Nom de la balise',
                },
                end_date: 'Date de fin',
                plan: {
                    description: 'Offre',
                    type: 'Type',
                },
                start_date: 'Date de début',
                status: 'Statut',
            },
            labels: {
                active: 'Actif',
            },
            statuses: {
                active: 'Actif',
                unpaid: 'Impayé',
                past: 'Passé',
                canceled: 'Annulé',
            },
            states: {
                onGoing: 'En cours',
                comingNext: 'A venir',
                past: 'Passé',
                canceled: 'Annulé',
                unpaid: 'En attente de la prochaine tentative de paiement',
            },
            name: 'Abonnement |||| Abonnements',
            add_subscription: 'Ajouter un abonnement',
            cancel_subscription: 'Annuler l\'abonnement',
            subscription_canceled: 'Abonnement annulé',
            no_subscription_found: 'Aucun abonnement associé à la balise',
            subscription_success: 'Votre abonnement a bien été pris en compte',
            subscription_creating: 'Votre abonnement est en cours de création...',
            subscription_creation_error: "Une erreur s'est produite lors de la création de votre abonnement ! Vous allez être redirigé vers la page de contact support... ",
            no_selected_plan: 'Aucune offre sélectionnée',
            postpaid_name: 'Abonnement mensuel',
            prepaid: 'Prépayée',
            prepaid_name: 'Abonnement prépayée - %{quantity} mois',
            prepaid_offer: 'Recharge en ligne',
            prepaid_offer_description: 'Achetez une formule prépayée',
            selected_offer: 'Offre sélectionnée',
            tc:
                'J\'accepte <a href="http://mytifiz.com/legal/cgv" target="_blank"> les conditions d\'utilisation</a> et les conditions relatives à l\'abonnement ci-dessus.',
            tc_postpaid:
                "Vous serez facturé %{amount} %{currency_symbol} mensuellement jusqu'à ce que vous décidiez de résilier votre abonnement. Les sommes déjà prélevées ne seront pas remboursées lors de la résiliation. Des frais de résiliation seront appliquées si vous résiliez dans les douze premiers mois. Vos données de paiement sont chiffrées et sécurisées. Tous les montants sont indiqués en %{currency_code}.",
            tc_prepaid:
                'Vous serez facturé %{amount} %{currency_symbol}. Les sommes déjà prélevées ne seront pas remboursées. A échéance, votre abonnement ne sera pas reconduit automatiquement. Vos données de paiement sont chiffrées et sécurisées. Tous les montants sont indiqués en %{currency_code}.',
            voucher: 'Recharge par carte prépayée',
            voucher_description: 'Si vous avez une carte prépayée (code) pour activer votre balise',
            voucher_already_burnt: 'Code déjà utilisé',
            voucher_code: 'Code',
            voucher_name: 'Recharge par carte prépayée - %{quantity} mois',
            voucher_not_found: 'Code inconnu',
            voucher_title: 'N° Carte : %{voucher_code}',
        },
        temperatures: {
            head: 'Température',
            value: 'Valeur',
            date: 'Dernier relevé',
        },
        theme: 'Thème',
        timechart: 'Historisation des températures',
        time_range: 'Zoom',
        title: {
            configuration: {
                list: 'Configuration',
            },
            devices: {
                list: 'Balises',
                editer: 'Editer Balise',
                create: 'Créer Balise',
            },
            geofences: {
                list: 'Barrières virtuelles',
                editer: 'Editer Barrières virtuelles',
                create: 'Créer Barrières virtuelles',
            },
            locations: {
                list: 'Positions',
                editer: 'Historique Positions',
            },
            notifications: {
                list: 'Alertes',
                editer: 'Editer Alerte',
                create: 'Créer Alerte',
            },
            organizations: {
                list: 'Organisations',
                editer: 'Editer Organisations',
                create: 'Créer Organisation',
            },
            measures: {
                list: 'Mesures',
                history: 'Historique des mesures',
                create: 'Créer Mesure',
            },
            users: {
                list: 'Utilisateurs',
                editer: 'Editer Utilisateurs',
                create: 'Créer Utilisateur',
            },
        },
        upload: 'Choisissez votre fichier ...',
        account: {
            edit: 'Profil'
        },
        users: {
            invite_action: 'Inviter un utilisateur',
            create: 'Créer un utilisateur',
            edit: 'Edition d\'un utilisateur',
            delete: "Enlever l'utilisateur de votre organisation",
            email: 'Email',
            cemail: 'Confirmation email',
            password: 'Mot de passe',
            cpassword: 'Confirmation mot de passe',
            companyName: 'Entreprise',
            customer: 'Client',
            firstName: 'Prénom',
            phoneNumber: 'Numéro de téléphone',
            birth_date: 'Date de naissance',
            invite: "Envoyer l'invitation",
            lastName: 'Nom',
            name: 'Utilisateur |||| Utilisateurs',
            role: 'Rôle',
            update: 'Editer',
            user: 'Utilisateur',
            users: 'Utilisateurs',
            language: 'Langue',
            profile: 'Profil',
            passwords_not_match: 'Les mots de passe ne correspondent pas',
            invalid_password: 'Le mot de passe doit comporter au moins 6 caractères',
            password_changed: 'Mot de passe mise à jour',
            email_changed: 'Email mis à jour',
            emails_not_match: 'Les emails ne correspondent pas',
            invalid_email: 'Email invalide',
            lang: {
                fr: 'Français',
                en: 'Anglais',
            }
        },
        hooks: {
            create: 'Création d\'un webhook',
            edit: 'Edition d\'un webhook',
            name: 'Webhook |||| Webhooks',
            location_changed: 'Position différente',
            new_location: 'Nouvelle position',
            state_changed: 'Changement d\'état',
            button_pressed: 'Appui bouton',
            button_pressed_click: 'Appui bouton - Clic',
            button_pressed_double_click: 'Appui bouton - Double clic',
            button_pressed_hold: 'Appui bouton - Appui long',
            new_co2: "Taux de Co2 - Nouvelle mesure",
            new_temperature: 'Température - Nouvelle mesure',
            new_humidity: 'Humidité - Nouvelle mesure',
            new_door: 'Porte - Nouvel état',
            new_water_leak: 'Détection d\'eau - Nouvel état',
            new_shock: 'Choc - Nouvel état',
            new_rollover: 'Renversement - Nouvel état',
            new_battery_level: 'Niveau de batterie - Nouvelle mesure',
            new_battery_status: 'Batterie - Nouveau status',
            new_motion: 'Déplacement - Nouvel état',
            device: {
                warning: 'Laisser vide pour appliquer à toutes les balises'
            },
            version: {
                warning: 'Seul la création de webhook v3 est désormais autorisé',
            },
            fields: {
                target_url: "Url",
                events: "Evénements",
                version: 'Version',
                active: 'Actif',
                auth: "Authentification",
                auth_type: "Type",
                auth_username: 'Username',
                auth_password: 'Password',

            },
            message: {
                new_message: "Nouveautés de la V3 :",
                message_part1: "Les positions GPS peuvent être renvoyées via deux événements :",
                message_part2: "- Nouvelle position: cet événement vous envoie la position de la balise à chaque message",
                message_part3: "- Position différente: cet événement vous envoie la position de la balise uniquement si elle est différente de la précédente (avec une précision de 20 mètres)."
            }
        }
    },
}
