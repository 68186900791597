import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import { Toolbar, translate } from 'react-admin'
import PreviousButton from '../../../ui/button/PreviousButton'
import NextButton from '../../../ui/button/NextButton'
import { Divider } from '@material-ui/core'
import style from 'jss-material-ui'
import { H310Grid } from '../../../ui/Grid'
import Typography from '@material-ui/core/Typography'
import { Card, CardContent, ButtonBase, Box } from '@mui/material'
import { WizardForm } from '../../../ui/form/WizardForm'
import { VGrid, V100Grid } from '../../../ui/Grid'
import { TitleForm } from '../../../ui/Grid'


const DeviceCreateToolbar = props => (
    <Toolbar {...props}>
        <PreviousButton label={props.translate('ra.navigation.next')} onClick={props.previousPage} />
        <NextButton type="submit" label={props.translate('ra.navigation.next')} />
    </Toolbar>
)

const PostpaidPlansExists = (plans) => {
    return plans && plans && plans.filter(plan => plan.type.toLowerCase() === 'postpaid').length > 0

}

const PrepaidPlansExists = (plans) => {
    return plans && plans && plans.filter(plan => plan.type.toLowerCase() === 'prepaid').length > 0

}

const VoucherAllowed = (plans) => {
    return plans && plans && plans.filter(plan => plan.operator.toLowerCase() === 'sigfox').length > 0

}

const OnePlanTypeAllowed = (plans) => {
    return (
        (PostpaidPlansExists(plans) && !PrepaidPlansExists(plans) && !VoucherAllowed(plans)) ? 'postpaid' :
            (!PostpaidPlansExists(plans) && PrepaidPlansExists(plans) && !VoucherAllowed(plans)) ? 'prepaid' :
                (!PostpaidPlansExists(plans) && !PrepaidPlansExists(plans) && VoucherAllowed(plans)) ? 'voucher' :
                    null
    )

}

const SelectPlanType = (props) => {

    const { onSubmit, nextPage, previousPage, translate, value, onChangePlanType } = props
    const { plans } = value
    if (!(value.selectedPlanType)) {
        onChangePlanType("postpaid")
    }
    if (OnePlanTypeAllowed(plans) === 'postpaid') {
        onChangePlanType("postpaid")
        nextPage()
    }
    if (OnePlanTypeAllowed(plans) === 'prepaid') {
        onChangePlanType("prepaid")
        nextPage()
    }
    if (OnePlanTypeAllowed(plans) === 'voucher') {
        onChangePlanType("voucher")
        nextPage()

    }
    return (
        <WizardForm
            onSubmit={onSubmit}
            previousPage={previousPage}
            nextPage={nextPage}
            submitOnEnter={false}
            toolbar={<DeviceCreateToolbar {...props} previousPage={previousPage} />}
            {...props}
        >
            <VGrid>
                <Box sx={{ m: 2 }}>
                    <TitleForm >{translate('resources.devices.select_offer')}</TitleForm>
                </Box>
                <H310Grid center xs={12} >
                    {PostpaidPlansExists(plans) &&
                        <ButtonBase
                            onClick={() => { onChangePlanType("postpaid") }}
                        >
                            {value.selectedPlanType === "postpaid" ?
                                <Card sx={{ display: 'flex', width: "350px", margin: 1, border: 3, borderColor: 'primary.main' }}>
                                    <CardContent sx={{ width: "100%" }}>
                                        <Typography variant="h5" color="text.secondary">
                                            {translate('resources.subscriptions.name', { smart_count: 1, })}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {translate('resources.subscriptions.description', { smart_count: 1, })}
                                        </Typography>
                                    </CardContent>
                                </Card >
                                :
                                <Card sx={{ display: 'flex', width: "350px", margin: 1 }}>
                                    <CardContent sx={{ width: "100%" }}>
                                        <Typography variant="h5" color="text.secondary">
                                            {translate('resources.subscriptions.name', { smart_count: 1, })}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {translate('resources.subscriptions.description', { smart_count: 1, })}
                                        </Typography>
                                    </CardContent>
                                </Card >
                            }
                        </ButtonBase>
                    }


                </H310Grid >
                <V100Grid center xs={12}>
                    {PrepaidPlansExists(plans) &&
                        <ButtonBase
                            onClick={() => { onChangePlanType("prepaid") }}
                        >
                            {value.selectedPlanType === "prepaid" ?
                                <Card sx={{ display: 'flex', width: "350px", margin: 1, border: 3, borderColor: 'primary.main' }}>
                                    <CardContent sx={{ width: "100%" }}>
                                        <Typography variant="h5" color="text.secondary">
                                            {translate('resources.subscriptions.prepaid_offer')}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {translate('resources.subscriptions.prepaid_offer_description', { smart_count: 1, })}
                                        </Typography>
                                    </CardContent>
                                </Card >
                                :
                                <Card sx={{ display: 'flex', width: "350px", margin: 1 }}>

                                    <CardContent sx={{ width: "100%" }}>
                                        <Typography variant="h5" color="text.secondary">
                                            {translate('resources.subscriptions.prepaid_offer')}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {translate('resources.subscriptions.prepaid_offer_description', { smart_count: 1, })}
                                        </Typography>
                                    </CardContent>

                                </Card >
                            }
                        </ButtonBase>
                    }


                </V100Grid>
                <V100Grid center xs={12}>
                    {VoucherAllowed(plans) &&
                        <ButtonBase
                            onClick={() => { onChangePlanType("voucher") }}
                        >

                            {value.selectedPlanType === "voucher" ?
                                <Card sx={{ display: 'flex', width: "350px", margin: 1, border: 3, borderColor: 'primary.main' }}>

                                    <CardContent sx={{ width: "100%" }}>
                                        <Typography variant="h5" color="text.secondary">
                                            {translate('resources.subscriptions.voucher')}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {translate('resources.subscriptions.voucher_description', { smart_count: 1, })}
                                        </Typography>
                                    </CardContent>

                                </Card >
                                :
                                <Card sx={{ display: 'flex', width: "350px", margin: 1 }}>

                                    <CardContent sx={{ width: "100%" }}>
                                        <Typography variant="h5" color="text.secondary">
                                            {translate('resources.subscriptions.voucher')}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {translate('resources.subscriptions.voucher_description', { smart_count: 1, })}
                                        </Typography>
                                    </CardContent>

                                </Card >
                            }
                        </ButtonBase>}

                </V100Grid>
            </VGrid>
        </WizardForm>
    )
}


SelectPlanType.propTypes = {
    classes: PropTypes.object.isRequired,
    previousPage: PropTypes.func.isRequired,
    nextPage: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    value: PropTypes.object,
}



export default translate(props => <Form {...props} component={SelectPlanType} />)
