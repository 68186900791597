import React from 'react'

import {
    Edit,
    minLength,
    ReferenceInput,
    required,
    SelectInput,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    translate,
} from 'react-admin'
import { withStyles } from '@material-ui/core/styles'


const validateRequired = [required('Champs obligatoire')]
const validateMinLength = [
    required('Champs obligatoire'),
    minLength(5, 'La longueur de la données doit etre >= 5'),
]

const OrganizationEditToolBar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);
const styles = theme => ({
    list: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        marginTop: 20,
    }
})


const OrganizationEdit = ({ classes, translate, ...props }) => (
    <Edit {...props} title={'resources.organizations.edit'} className={classes.list}>
        <SimpleForm toolbar={<OrganizationEditToolBar />} >
            <TextInput
                label={translate('resources.organization.name')}
                source="name"
                translate={translate}
                validate={validateMinLength}
            />
            <ReferenceInput
                label={translate('resources.organization.parent')}
                source="parent_organization_id"
                reference="organizations"
                translate={translate}
                validate={validateRequired}
            >
                <SelectInput optionText="name" />
            </ReferenceInput>

            <SelectInput label={translate('resources.organization.root')}
                source="root_organization_id"
                optionText="label"
                optionValue="root_organization_id"
                defaultValue={process.env.REACT_APP_ROOT_ORGANIZATION_ID}
                choices={[{ root_organization_id: process.env.REACT_APP_ROOT_ORGANIZATION_ID, label: 'TICATAG' }]}
                validate={validateRequired} />

            <TextInput label={translate('resources.organization.description')} source="description" />
        </SimpleForm>
    </Edit>
)

export default withStyles(styles)(translate(OrganizationEdit))