import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import GetApp from '@material-ui/icons/GetApp';
import { Button } from 'react-admin'

const sanitizeRestProps = ({
    basePath,
    crudGetAll,
    dispatch,
    exporter,
    filter,
    maxResults,
    resource,
    sort,
    ...rest
}) => rest;

class ExportButton extends Component {
    static propTypes = {
        basePath: PropTypes.string,
        dispatch: PropTypes.func,
        exporter: PropTypes.func,
        filter: PropTypes.object,
        label: PropTypes.string,
        maxResults: PropTypes.number.isRequired,
        resource: PropTypes.string.isRequired,
        sort: PropTypes.object,
    };

    render() {
        const { basePath, label, selectedIds, ...rest } = this.props;

        return (
            <Button
                component={Link}
                to={`${basePath}?selectedIds=${selectedIds}`}
                label={label}
                {...sanitizeRestProps(rest)}
            >
                <GetApp />
            </Button>
        );
    }
}

ExportButton.defaultProps = {
    label: 'ra.action.export',
    maxResults: 1000,
};

export default connect()(ExportButton); // inject redux dispatch