// in src/comments.js
import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import { withStyles } from '@material-ui/core/styles';
import { DateField, translate, TextField } from 'react-admin';
import SettingsButton from '../../../ui/button/SettingsButton'
import SubscriptionButton from '../../../ui/button/SubscriptionButton'
import LocationButton from '../../../ui/button/LocationButton'
import { hasWriteAccess } from '../../../helper/resourceHelper'
import { hasLocation } from '../../../utils/utils'

import AvatarField from '../../../ui/field/AvatarField'
import { Battery } from './Battery'

const listStyles = theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardHeader: {
        paddingBottom: 0,
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
    },
    cardContentRow: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        margin: '0.5rem 0',
    },
});

const getAvatar = record => {
    const defaultAvatar = <AvatarField avatarUrl="https://ticatagsas.s3.amazonaws.com/57f3315c-e601-410a-9749-57fa11e123fc" size="45" />
    if (!record)
        return defaultAvatar

    if (record.thumbnail_url)
        return <AvatarField avatarUrl={record.thumbnail_url} size="45" />

    if (record.product && record.product.image_url)
        return <AvatarField avatarUrl={record.product.image_url} size="45" />

    return defaultAvatar
}

const MobileGrid = ({ classes, ids, data, basePath, permissions, translate }) => (
    <div style={{ margin: '1em' }}>
        {ids.map(id => (
            <Card key={id} className={classes.card} record={data[id]}>
                <CardHeader
                    title={
                        <div className={classes.cardTitleContent}>
                            <h2>{data[id] ? `${data[id].name}` : ""}</h2>
                        </div>
                    }
                    avatar={getAvatar(data[id])}
                    className={classes.cardHeader}
                />
                <CardContent className={classes.cardContent}>
                    {data[id].mac_address && <span className={classes.cardContentRow}>
                        {translate('resources.devices.fields.mac_address')}:&nbsp;
                        <TextField
                            source="mac_address"
                            record={data[id]}
                        />
                    </span>}
                    {data[id].serial_number && <span className={classes.cardContentRow}>
                        {translate('resources.devices.fields.mac_address')}:&nbsp;
                        <TextField
                            source="serial_number"
                            record={data[id]}
                        />
                    </span>}
                    {data[id].software_version && <span className={classes.cardContentRow}>
                        {translate('resources.devices.fields.software_version')}:&nbsp;
                        <TextField
                            source="software_version"
                            record={data[id]}
                        />
                    </span>}
                    {data[id].last_battery && <span className={classes.cardContentRow}>
                        {translate('resources.devices.fields.battery')}:&nbsp;
                        <Battery record={data[id]} />
                    </span>}
                    {data[id].last_seen && <span className={classes.cardContentRow}>
                        {translate('resources.devices.fields.last_seen')}:&nbsp;
                        <DateField
                            record={data[id]}
                            source="last_seen"
                            type="date"
                            showTime
                        />
                    </span>}
                </CardContent>
                <CardActions>
                    {hasWriteAccess(permissions) && <SettingsButton basePath={"devices"} record={data[id]} />}
                    {hasWriteAccess(permissions) && data[id].type === 'unb' && <SubscriptionButton basePath={"devices"} record={data[id]} />}
                    {hasLocation(data[id]) && <LocationButton record={data[id]} />}
                </CardActions>
            </Card>
        ))}
    </div>
);

MobileGrid.defaultProps = {
    data: {},
    ids: [],
};

const enhance = compose(
    withStyles(listStyles),
    translate
);

export default enhance(MobileGrid);