import React from 'react'
import {
    AutocompleteInput,
    BooleanInput,
    Edit,
    FormDataConsumer,
    PasswordInput,
    ReferenceInput,
    required,
    SelectArrayInput,
    SimpleForm,
    TextInput,
    translate,
} from 'react-admin'
import 'react-phone-number-input/style.css'
import { withStyles } from '@material-ui/core/styles'

import { isAdminPermissions } from '../../../helper/resourceHelper'

const styles = theme => ({
    list: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        marginTop: 20,
    }
})

const eventChoicesV1 = [
    { id: 'location_changed', name: 'resources.hooks.location_changed' },
    { id: 'state_changed', name: 'resources.hooks.state_changed' },
    { id: 'button_pressed', name: 'resources.hooks.button_pressed' },
]

const eventChoicesV2 = [
    { id: 'new_location', name: 'resources.hooks.new_location' },
    { id: 'state_changed', name: 'resources.hooks.state_changed' },
    { id: 'button_pressed', name: 'resources.hooks.button_pressed' },
    { id: 'button_pressed_click', name: 'resources.hooks.button_pressed_click' },
    { id: 'button_pressed_double_click', name: 'resources.hooks.button_pressed_double_click' },
    { id: 'button_pressed_hold', name: 'resources.hooks.button_pressed_hold' },
    { id: 'new_temperature', name: 'resources.hooks.new_temperature' },
    { id: 'new_humidity', name: 'resources.hooks.new_humidity' },
    { id: 'new_door', name: 'resources.hooks.new_door' },
    { id: 'new_water_leak', name: 'resources.hooks.new_water_leak' },
    { id: 'new_shock', name: 'resources.hooks.new_shock' },
    { id: 'new_rollover', name: 'resources.hooks.new_rollover' },
    { id: 'new_battery_level', name: 'resources.hooks.new_battery_level' },
    { id: 'new_battery_status', name: 'resources.hooks.new_battery_status' },
    { id: 'new_motion', name: 'resources.hooks.new_motion' },
]

const eventChoicesV3 = [
    { id: 'new_location', name: 'resources.hooks.new_location' },
    { id: 'location_changed', name: 'resources.hooks.location_changed' },
    { id: 'state_changed', name: 'resources.hooks.state_changed' },
    { id: 'button_pressed', name: 'resources.hooks.button_pressed' },
    { id: 'button_pressed_click', name: 'resources.hooks.button_pressed_click' },
    { id: 'button_pressed_double_click', name: 'resources.hooks.button_pressed_double_click' },
    { id: 'button_pressed_hold', name: 'resources.hooks.button_pressed_hold' },
    { id: 'new_temperature', name: 'resources.hooks.new_temperature' },
    { id: 'new_humidity', name: 'resources.hooks.new_humidity' },
    { id: 'new_door', name: 'resources.hooks.new_door' },
    { id: 'new_water_leak', name: 'resources.hooks.new_water_leak' },
    { id: 'new_shock', name: 'resources.hooks.new_shock' },
    { id: 'new_rollover', name: 'resources.hooks.new_rollover' },
    { id: 'new_battery_level', name: 'resources.hooks.new_battery_level' },
    { id: 'new_battery_status', name: 'resources.hooks.new_battery_status' },
    { id: 'new_motion', name: 'resources.hooks.new_motion' },
]

const HookEdit = withStyles(styles)(
    translate(({ classes, ...props }) => {
        const { translate } = props
        return (
            <Edit {...props} title={props.translate('resources.hooks.edit')} className={classes.list}>
                <SimpleForm variant="standard" margin="normal" redirect="list">
                    <FormDataConsumer>
                        {({ formData }) =>
                            formData.version === 'v1' ?
                                <SelectArrayInput variant="standard" margin="normal" label="resources.hooks.fields.events" source="events" choices={eventChoicesV1} validate={[required()]} /> :
                                formData.version === 'v2' ?
                                    <SelectArrayInput variant="standard" margin="normal" label="resources.hooks.fields.events" source="events" choices={eventChoicesV2} validate={[required()]} /> :
                                    <SelectArrayInput variant="standard" margin="normal" label="resources.hooks.fields.events" source="events" choices={eventChoicesV3} validate={[required()]} />

                        }
                    </FormDataConsumer>
                    {isAdminPermissions() && (<ReferenceInput label={translate('resources.organizations.name', { smart_count: 1 })} source="organization_id" reference="organizations" validate={[required()]} defaultValue={localStorage.getItem('organizationId')}>
                        <AutocompleteInput optionText="name" matchSuggestion={() => true} />
                    </ReferenceInput>)}
                    <ReferenceInput label={translate('resources.devices.name', { smart_count: 1 })} source="device_id" reference="devices">
                        <AutocompleteInput optionText="name" helperText={translate('resources.hooks.device.warning')} matchSuggestion={() => true} />
                    </ReferenceInput>
                    <TextInput label="resources.hooks.fields.target_url" source="target_url" validate={[required()]} fullWidth />
                    <BooleanInput label="resources.hooks.fields.auth" source="auth_active" />
                    <FormDataConsumer>
                        {({ formData }) =>
                            formData.auth_active === true &&
                            <TextInput variant="standard" label="resources.hooks.fields.auth_type" source="auth_type" defaultValue="Basic" disabled />

                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData }) =>
                            formData.auth_active === true &&
                            <TextInput variant="standard" label="resources.hooks.fields.auth_username" source="auth_username" />
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData }) =>
                            formData.auth_active === true &&
                            <PasswordInput variant="standard" label="resources.hooks.fields.auth_password" source="auth_password" />
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData }) => {
                            if (formData.auth_active === false) {
                                formData.auth_username = null;
                                formData.auth_password = null;
                            }
                        }
                        }
                    </FormDataConsumer>

                    <BooleanInput label="resources.hooks.fields.active" source="active" />
                    <TextInput label="resources.hooks.fields.version" source="version" disabled />
                </SimpleForm>
            </Edit>
        )
    }))

export default HookEdit
