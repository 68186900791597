import L from "leaflet";
import React, { useEffect } from 'react'
import 'leaflet-easyprint'
import { useMap } from "react-leaflet";


export const PrintMap = () => {
    const map = useMap();
    useEffect(() => {

        L.easyPrint({
            sizeModes: ["A4Portrait"],
            exportOnly: false
        }).addTo(map);
    }, []);

    return <></>;
};

