import {
    API_KEY,
    AUTHORITIES,
    CHANGE_MARKER,
    CHANGE_THEME,
    GOOGLE_KEY,
    NBR_HISTORY,
    ROLE_ORGA_USER,
    SELECT_RIGHTMENU,
    ROUTING_MODE,
    DISABLE_CLUSTERING_AT_ZOOM
} from '../pages/configuration/actions'

const initState = {
    [AUTHORITIES]: localStorage.getItem(AUTHORITIES) || ROLE_ORGA_USER,
    [CHANGE_THEME]: localStorage.getItem(CHANGE_THEME) || 'light',
    [CHANGE_MARKER]: true,
    [SELECT_RIGHTMENU]: localStorage.getItem(SELECT_RIGHTMENU) === "true" || true,
    [NBR_HISTORY]: localStorage.getItem(NBR_HISTORY) || 25,
    [GOOGLE_KEY]: localStorage.getItem(GOOGLE_KEY) || 'AIzaSyCtoDjTmVCYV4JNomLMf998ti5Hvmk4Azo',
    [API_KEY]: localStorage.getItem(API_KEY) || 'AIzaSyCtoDjTmVCYV4JNomLMf998ti5Hvmk4Azo',
    [ROUTING_MODE]: localStorage.getItem(ROUTING_MODE) || 'driving',
    [DISABLE_CLUSTERING_AT_ZOOM]: localStorage.getItem(DISABLE_CLUSTERING_AT_ZOOM) === "true" || true,

}

// temporary for demo
localStorage.setItem(API_KEY, 'AIzaSyCtoDjTmVCYV4JNomLMf998ti5Hvmk4Azo')

export default (previousState = initState, { type, payload }) => {
    if (previousState[type] !== undefined) {
        return { ...previousState, [type]: payload }
    }

    return previousState
}
