import realtimeSaga from 'ra-realtime'
import webstomp from 'webstomp-client'
import SockJS from 'sockjs-client'

const onConnect = (client, observer) => {
    client.subscribe(
        '/topic/users/' + localStorage.getItem('userId') + '/locations',
        onMessage.bind(this, client, observer)
    )
}

const onError = (client, error) => {
    console.log(error)
}

const onMessage = (client, observer, msg) => {
    const message = JSON.parse(msg.body)
    let resource = JSON.parse(localStorage.getItem('lastResource'))
    if (resource) {
        const recordIndex = resource.findIndex(r => r.id === message.device_id)

        if (resource[recordIndex]) {
            resource[recordIndex].last_location.latitude = message.latitude
            resource[recordIndex].last_location.longitude = message.longitude
            resource[recordIndex].last_location.timestamp = message.timestamp
            observer.next({ data: resource, total: resource.length })
        }
    }
}

const observeRequest = restClient => (type, resource, params) => {
    const token = localStorage.getItem('token')
    let endpoint
    if (resource === 'map') {
        endpoint = process.env.REACT_APP_WS_URL + '?access_token='
    } else {
        return
    }
    const url = endpoint + token
    const headers = {
        Authorization: 'Bearer: ' + token,
    }

    return {
        subscribe(observer) {
            const client = webstomp.over(new SockJS(url, { heartbeat: false }))
            client.connect(
                headers,
                onConnect.bind(this, client, observer),
                onError
            )

            const subscription = {
                unsubscribe() {
                    if (client.connected) {
                        client.disconnect()
                        observer.complete()
                    }
                },
            }

            return subscription
        },
    }
}

export default (restClient, resources) => realtimeSaga(observeRequest(restClient, resources))
