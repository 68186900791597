import React from 'react'
import { connect } from 'react-redux'
import { FunctionField, SimpleShowLayout, translate } from 'react-admin'
import compose from 'recompose/compose'
import { changeConfig } from './actions'
import { Card, CardContent, Tab, Tabs } from '@material-ui/core'
import { AccountShow } from '../account'
import { ColorsAndInt } from './colorsAndInt'
import { Card100 } from '../../ui/Grid'
import { withStyles } from '@material-ui/core/styles'
import style from 'jss-material-ui'
import { Typography } from '@mui/material'

const styles = theme => ({
    list: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        marginTop: 30,
    },
    context: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        }
    }
})

const match = {
    isExact: true,
    params: {
        id: 1,
    },
    path: '/uaa/account/:id/show',
    url: '/uaa/account/1/show',
}

const location = {
    hash: '',
    pathname: '/uaa/account/:id/show',
    url: '/uaa/account/1/show',
    search: '',
    state: undefined,
}


function ProfileContainer(props) {
    return <AccountShow {...props} resource={'account'} location={location} match={match} />
}

function AboutContainer(props) {

    return (
        <Card>
            <SimpleShowLayout {...props} >
                <FunctionField
                    label={props.translate('resources.product.name')}
                    render={() => ''}
                />
                <Typography variant="caption">{process.env.REACT_APP_PRODUCT_NAME}</Typography>
                <FunctionField
                    label={props.translate('resources.product.release')}
                    render={() => ''}
                />
                <Typography variant="caption">{process.env.REACT_APP_VERSION_DATE}</Typography>
                <FunctionField
                    label={props.translate('resources.product.versionNumber')}
                    render={() => process.env.REACT_APP_VERSION}
                />
                <Typography variant="caption">{process.env.REACT_APP_VERSION}</Typography>
            </SimpleShowLayout>
        </Card>
    )
}
const SaboutContainer = style(AboutContainer)(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        marginTop: 30,
    }
}))

class Configuration extends React.Component {
    state = {
        indexTab: 0,
    }

    handleChange = (event, indexTab) => {
        this.setState({ indexTab })
    }

    render() {
        const { translate, classes } = this.props
        const { indexTab } = this.state

        return (
            <Card100 className={classes.list}>
                <CardContent>
                    <Tabs value={indexTab} onChange={this.handleChange}>
                        <Tab label={translate('resources.profile')} />
                        <Tab label={translate('resources.configuration.general')} />
                        <Tab label={translate('resources.about')} />
                    </Tabs>
                </CardContent>
                <CardContent className={classes.context}>
                    {indexTab === 0 && <ProfileContainer {...this.props} className="tabLayout" />}
                    {indexTab === 1 && <ColorsAndInt {...this.props} />}
                    {indexTab === 2 && <SaboutContainer {...this.props} className="tabLayout" />}
                </CardContent>
            </Card100>
        )
    }
}

const mapStateToProps = state => ({
    config: state.config,
    theme: state.config.theme,
    marker: state.config.marker,
    //locale: state.i18n.locale,
})

export default compose(
    connect(
        mapStateToProps,
        {

            changeConfig,
        }
    ),
    translate
)(withStyles(styles)(Configuration))
