
import ticatagLogo from "./logo-ticatag.jpg"
import mytagLogo from "./logo-mytag.jpg"
import jsPDF from "jspdf";
import "jspdf-autotable";

const getLogoByRootOrg = () => {
    if (process.env.REACT_APP_ROOT_ORGANIZATION.toLowerCase() === 'mytag') {
        return mytagLogo
    }
    return ticatagLogo
}

const PdfDocument = (translate, deviceDetailsData, periodData, tableData, mapImageData) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const dateNow = new Date().toLocaleString({ timeZone: timezone })
    const docName = deviceDetailsData.name.replace(/\s/g, '_') + "_" + dateNow.replace(/\s/g | /\:/g, '_') + ".pdf"
    const pageParam = {
        unit: "pt",
        size: "A4",
        orientation: "portrait"

    };
    const logoImageParam = {
        imageData: getLogoByRootOrg(),
        format: "JPEG",
        x: 175,
        y: 10,
        width: 250,
        height: 60,
        alias: "",
        compression: 'MEDIUM',
        rotation: 0,

    };
    const titleParam = {
        text: translate('resources.exportPdf.locationsHistoryDocument.title'),
        x: 300,
        y: 130,
        options: { "align": "center" }
    };
    const DateParam = {
        text: translate('resources.exportPdf.locationsHistoryDocument.date') + dateNow,
        x: 50,
        y: 180,
        options: { "align": "left" }
    };
    const subTitleParam = {
        text: translate('resources.exportPdf.locationsHistoryDocument.table.title'),
        x: 300,
        y: 60,
        options: { "align": "center" }
    };

    const mapImageParam = {
        imageData: mapImageData,
        format: "JPEG",
        x: 50,
        y: 300,
        width: 500,
        height: 400,
        alias: "",
        compression: 'SLOW',
        // rotation: 0,

    };
    const detailsTableHead = [
        [
            { content: translate('resources.exportPdf.locationsHistoryDocument.beacon'), colSpan: 2, fillColor: [177, 177, 177], styles: { halign: 'center', lineWidth: 0.5, lineColor: 0 } },
            { content: translate('resources.exportPdf.locationsHistoryDocument.period'), colSpan: 2, fillColor: [177, 177, 177], styles: { halign: 'center', lineWidth: 0.5, lineColor: 0 } }
        ],
        [
            { content: translate('resources.exportPdf.locationsHistoryDocument.id'), colSpan: 1, fillColor: [215, 215, 215], styles: { halign: 'center', lineWidth: 0.5, lineColor: 0 } },
            { content: translate('resources.exportPdf.locationsHistoryDocument.name'), colSpan: 1, fillColor: [215, 215, 215], styles: { halign: 'center', lineWidth: 0.5, lineColor: 0 } },
            { content: translate('resources.exportPdf.locationsHistoryDocument.from'), colSpan: 1, fillColor: [215, 215, 215], styles: { halign: 'center', lineWidth: 0.5, lineColor: 0 } },
            { content: translate('resources.exportPdf.locationsHistoryDocument.to'), colSpan: 1, fillColor: [215, 215, 215], styles: { halign: 'center', lineWidth: 0.5, lineColor: 0 } }

        ],
    ];
    let detailsParam = {
        startY: 200,
        head: detailsTableHead,
        bodyStyles: { halign: 'center', },
        headStyles: { halign: 'center', },
        alternateRowStyles: {
            lineWidth: 0.5, lineColor: 0
        },
        body: [[deviceDetailsData.serial_number, deviceDetailsData.name, periodData.startDate, periodData.endDate]],
        //theme: 'plain'
    };


    let tableParam = {
        startY: 100,
        head: [[translate('resources.exportPdf.locationsHistoryDocument.table.location'), translate('resources.exportPdf.locationsHistoryDocument.table.dateTime'), translate('resources.exportPdf.locationsHistoryDocument.table.adress'), translate('resources.exportPdf.locationsHistoryDocument.table.latitude'), translate('resources.exportPdf.locationsHistoryDocument.table.longitude')]],
        body: tableData
    };

    const doc = new jsPDF(pageParam);
    doc.setFontSize(20);
    doc.addImage(logoImageParam.imageData, logoImageParam.format, logoImageParam.x, logoImageParam.y, logoImageParam.width, logoImageParam.height, logoImageParam.alias, logoImageParam.compression, logoImageParam.rotation)
    doc.setTextColor("#164CA0");
    doc.text(titleParam.text, titleParam.x, titleParam.y, titleParam.options,);
    doc.setTextColor("#000000");
    doc.setFontSize(13);
    doc.text(DateParam.text, DateParam.x, DateParam.y, DateParam.options,);
    doc.autoTable(detailsParam);
    doc.addImage(mapImageParam.imageData, mapImageParam.format, mapImageParam.x, mapImageParam.y, mapImageParam.width, mapImageParam.height, mapImageParam.alias, mapImageParam.compression, mapImageParam.rotation)
    doc.addPage(2, pageParam.orientation)
    doc.setTextColor("#164CA0")
    doc.setFontSize(15);
    doc.text(subTitleParam.text, subTitleParam.x, subTitleParam.y, subTitleParam.options,);
    doc.setFontSize(15);
    doc.autoTable(tableParam);
    return { doc, docName }
}












export default PdfDocument






